// Components
import Album360Preview from './types/album360/Album360Preview';
import DocumentPreview from './types/document/DocumentPreview';
import ImagePreview from './types/image/ImagePreview';
import VideoPreview from './types/video/VideoPreview';

// Helpers
import { IContentItem } from '@/libs/prompto-api/src';
import fileCategories from 'src/configs/SupportedFileCategories.json';

// Styles
import styled from 'styled-components';

const PreviewWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export interface PreviewProps {
  data: IContentItem;
  pageIndex: number;
  size?: number;
  videoFallbackThumbnail: string;
  isVerticalLayout: boolean;
  gridPreview?: boolean;
}

const Preview = ({
  data,
  pageIndex = 0,
  size,
  videoFallbackThumbnail,
  isVerticalLayout,
  gridPreview = false
}: PreviewProps) => {
  const sharedProps = { data, size, isVerticalLayout, gridPreview };
  // Preview
  const createPreview = () => { 
    switch (data.contentItemType) {
      case fileCategories.image:
      case fileCategories.turntable: {
        let icon;
        if (data.contentItemType === fileCategories.turntable) {
          icon = 'cube';
        }
        return (
          <ImagePreview pageIndex={pageIndex} {...sharedProps} icon={icon} />
        );
      }
      case fileCategories.video: {
        return (
          <VideoPreview
            {...sharedProps}
            fallbackThumbnail={videoFallbackThumbnail}
          />
        );
      }
      case fileCategories.document:
      case fileCategories.floorplan: {
        return <DocumentPreview {...sharedProps} />;
      }
      case fileCategories.album360: {
        return <Album360Preview {...sharedProps} />;
      }
      default: {
        return <ImagePreview {...sharedProps} />;
      }
    }
  };

  const preview = createPreview();

  return <PreviewWrapper key={data.objectId}>{preview}</PreviewWrapper>;
};

export default Preview;
