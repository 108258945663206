import styled from 'styled-components';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Helpers
import { motion } from 'framer-motion';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

// Styling
const Box = styled(motion.div)`
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled(FontAwesomeIcon)`
  font-weight: 300;
  text-align: center;
  color: ${(props) => props.color};
`;

const Description = styled.p`
  font-size: 14px;
  text-align: center;
`;

export interface LoaderProps {
  description?: string;
  className?: string;
  color?: string;
  size?: SizeProp;
}

const Loader = ({
  description,
  className,
  color,
  size = '1x'
}: LoaderProps) => {
  return (
    <Box
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={className}
    >
      <Spinner icon={['fal', 'spinner']} pulse size={size} color={color} />
      {description && <Description>{description}</Description>}
    </Box>
  );
};

export default Loader;
