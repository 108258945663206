import env from '../../environment/environment';
import axios from 'axios';
import urlJoin from 'url-join';
import qs from 'query-string';

interface PromptoHeaders {
  'X-Around-Api-Token': string;
  'X-Around-Session-Token'?: string; // Sessiontoken is optional in a lot of calls
}

export interface CreateParams {}

export interface Textmap {
  textMap: {
    [key: string]: string;
    nl: string;
    en: string;
  };
}

/**
 * Builds the headers we need for a call
 * @param {string} sessionToken
 */
const buildHeaders = (sessionToken?: string) => {
  const headers: PromptoHeaders = { 'X-Around-Api-Token': env().apiToken };
  if (sessionToken) {
    headers['X-Around-Session-Token'] = sessionToken;
  }
  return headers;
};

/**
 * Post call
 * @param {string} path Path to the endpoint
 * @param {CreateParams} createParams
 * @param {string} sessionToken
 */
export const performPostCall = (
  path: string,
  createParams: CreateParams,
  sessionToken?: string
) => {
  return axios.post(
    urlJoin(env().baseUrl, path, `?_=${Date.now()}`),
    createParams,
    {
      headers: buildHeaders(sessionToken)
    }
  );
};

/**
 * Get call
 * @param {string} path Path to the endpoint
 * @param {Object} params
 * @param {string} sessionToken
 */
export const performGetCall = (
  path: string,
  params?: CreateParams,
  sessionToken?: string
) => {
  return axios.get(urlJoin(env().baseUrl, path, `?_=${Date.now()}`), {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'none' });
    },
    headers: buildHeaders(sessionToken)
  });
};

/**
 * Put call
 * @param {string} path Path to the endpoint
 * @param {Object} updateParams
 * @param {string} sessionToken
 */
export const performPutCall = (
  path: string,
  updateParams: CreateParams,
  sessionToken?: string
) => {
  return axios.put(
    urlJoin(env().baseUrl, path, `?_=${Date.now()}`),
    updateParams,
    {
      headers: buildHeaders(sessionToken)
    }
  );
};

/**
 * Delete call
 * @param {string} path Path to the endpoint
 * @param {string} sessionToken
 */
export const performDeleteCall = (path: string, sessionToken: string) => {
  return axios.delete(urlJoin(env().baseUrl, path, `?_=${Date.now()}`), {
    headers: buildHeaders(sessionToken)
  });
};

export interface MultiLanguageTextField {
  textMap: { key: string; value: string };
}

export enum State {
  active = 'active',
  archived = 'archived'
}

export const environment = env();
