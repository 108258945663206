import { IProject } from '@/libs/prompto-api/src';
import { getCurrentLanguage } from 'src/localization/localizer';

/**
 * Transform address object into readable string
 * @param {{ addressLine1: string, city: string, country: string, zipCode: string }} address
 * @returns {string} returns readable address
 */
export const buildAddress = (address?: {
  addressLine1?: string;
  city?: string;
  country?: string;
  zipCode?: string;
}) => {
  if (!address) return '';

  let city = address?.city ? address?.city : '';
  // Ugly override because Molenbeek is full of crapuul and we don't want to show Molenbeek as a city name
  if (city === 'Molenbeek-Saint-Jean') {
    city = 'Brussel';
  }

  return `${address?.addressLine1 ? address?.addressLine1 + ',' : ''} ${
    address?.zipCode ?? ''
  } ${city} ${address?.country ? `, ${address?.country}` : ''} `;
};

export const getCurrentProject = (
  projects: Array<IProject>,
  projectId?: string
) => {
  return projects.find((x: IProject) => x.objectId === projectId);
};
