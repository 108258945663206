import { useState } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'src/localization/localizer';

// Styling
import styled, { StyledProps } from 'styled-components';

const MainBar = styled.div<StyledProps<{ isNight: boolean }>>`
  position: absolute;
  z-index: 10;
  top: 15px;
  left: calc(50% - 75px);
  width: 125px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.gray20};
  background-color: ${({ isNight, theme }) =>
    isNight ? theme.gray90 : theme.white};
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: top 200ms ease;
`;

const iconWidth = 25;

const ToggleIcon = styled.div<StyledProps<{ isNight: boolean }>>`
  position: absolute;
  width: ${iconWidth}px;
  height: ${iconWidth}px;
  border-radius: 50%;
  background-color: ${({ isNight, theme }) =>
    isNight ? theme.white : theme.gray90};
  transition: all 0.5s ease;
  ${({ isNight }) =>
    isNight ? 'left: 2%;' : `left: calc(98% - ${iconWidth}px);`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DayNightIcon = styled(FontAwesomeIcon)<StyledProps<{ isNight: boolean }>>`
  color: ${({ isNight, theme }) => (isNight ? theme.gray90 : theme.white)};
`;

const Text = styled.div<StyledProps<{ isNight: boolean }>>`
  font-size: 1rem;
  ${({ isNight }) =>
    isNight
      ? `margin-right: -${iconWidth / 2.0}px`
      : `margin-left: -${iconWidth / 2.0}px`};

  transition: all 0.5s ease;
  color: ${({ isNight, theme }) => (isNight ? theme.white : theme.gray90)};
  user-select: none;
`;

// Types
interface DayNightToggleProps {
  initialState: boolean;
  onToggle: (flag: boolean) => void;
}

const DayNightToggle = ({ onToggle, initialState }: DayNightToggleProps) => {
  const [isNightView, setIsNightView] = useState(initialState);

  return (
    <MainBar
      isNight={isNightView}
      onClick={() => {
        setIsNightView((current: boolean) => {
          onToggle(!current);
          return !current;
        });
      }}
    >
      <Text isNight={isNightView}>
        {isNightView
          ? localizer.projectTour.night.toUpperCase()
          : localizer.projectTour.day.toUpperCase()}
      </Text>
      <ToggleIcon isNight={isNightView}>
        <DayNightIcon
          isNight={isNightView}
          icon={isNightView ? ['fas', 'moon'] : ['fas', 'sun']}
          size="1x"
        />
      </ToggleIcon>
    </MainBar>
  );
};

export default DayNightToggle;
