import { useRef, useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Components
import ProvinceSvgMask from './ProvinceSvgMask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import useIntersectionObserver from 'src/hooks/use-intersection-observer';
import { isMobile, isTablet, isMobileOnly } from 'react-device-detect';
import {
  PROVINCES,
  convertSvgPath,
  prepareSvgNumbers,
  getOriginalSvgSize,
  IPolygonNumber,
  IPathTag
} from './helpers';
import localizer from 'src/localization/localizer';
import { provinceIdToLabelMap } from 'src/store/ProjectsFiltersStore';
import { useDataState } from 'src/store/DataStore';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';

// Assets
import BelgiumSvg from 'src/resources/svg/belgium-map.svg';
import BelgiumBackdrop from 'src/resources/images/belgiumBackdrop.png';

import BrusselsImg from 'src/resources/images/provinces/Brussels.png';
import AntwerpImg from 'src/resources/images/provinces/Antwerp.png';
import ArlonImg from 'src/resources/images/provinces/Arlon.jpg';
import BruggeImg from 'src/resources/images/provinces/Brugge.jpg';
import GhentImg from 'src/resources/images/provinces/Ghent.jpg';
import HasseltImg from 'src/resources/images/provinces/Hasselt.jpg';
import LeuvenImg from 'src/resources/images/provinces/Leuven.png';
import LiegeImg from 'src/resources/images/provinces/Liege.jpg';
import MonsImg from 'src/resources/images/provinces/Mons.png';
import NamurImg from 'src/resources/images/provinces/Namur.jpg';
import WavreImg from 'src/resources/images/provinces/Wavre.jpg';

// Styling
import styled, { withTheme, StyledProps } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';
import { Text as StyledText } from '../sharedStyledComponents';

const Wrapper = styled(motion.div)`
  width: 100%;
  background: ${({ theme }) => theme.gray90};
  position: relative;
  padding: 54px 16px 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 41px;
    top: -40px;
    left: 0;
    right: 0;
    border-radius: 40px 40px 0 0;
    background: ${({ theme }) => theme.gray90};
  }
  ${forBiggerThan.mobile`
    padding: 50px 64px 168px;
  `}
`;

const Container = styled.div`
  display: grid;
  grid-template-areas: 'list map';
  grid-template-columns: 50% 50%;
  grid-template-rows: minmax(600px, 1fr);
  width: 100%;
`;

const ListWrapper = styled.div`
  grid-area: list;
  padding-right: 40px;
  box-sizing: border-box;
`;

const MapWrapper = styled.div`
  grid-area: map;
`;

const MapContent = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 2rem;
  color: ${({ theme }) => theme.white};
  margin: 0;
  margin-bottom: 10px;
`;

const TextBold = styled(Text)`
  font-style: italic;
  font-weight: bold;
`;

const GAP = isTablet || isMobileOnly ? 16 : 32;

const ProvincesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${GAP}px;
  margin-top: ${GAP * 2}px;
`;

const Button = styled(motion.button)`
  height: 64px;
  border-radius: 32px;
  border: none;
  margin: 0;
`;

const ProvinceButton = styled(Button)<
  StyledProps<{ hovered: boolean; img: string }>
>`
  background-image: ${({ hovered, theme, img }) =>
    hovered
      ? `linear-gradient(
    to right,
    ${theme.gold},
    ${theme.gold}
  )`
      : `linear-gradient(
      to right,
      rgba(46, 49, 56, 0.6),
      rgba(46, 49, 56, 0.6)
    ),
    url(${img})`};
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: calc(50% - ${GAP / 2}px) 0 0;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 1.25rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  transition: all 120ms ease;
  &:hover {
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.gold},
      ${({ theme }) => theme.gold}
    );
  }

  ${forBiggerThan.desktop`
    flex: calc(33% - ${(GAP * 2) / 3}px) 0 0;
  `}
`;

const ViewAllProjects = styled.button`
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  flex: calc(50% - ${GAP / 2}px) 00;
  height: 64px;
  border-radius: 32px;
  border: none;
  padding: 5px 15px;
  box-sizing: border-box;
  cursor: pointer;

  span {
    text-align: center;
  }

  ${forBiggerThan.desktop`
  flex: calc(33% - ${(GAP * 2) / 3}px) 0 0;
`}
`;

const SvgContainer = styled.svg<
  StyledProps<{ top: string; left: string; transformation?: string }>
>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  ${(props) => props.transformation};
`;

const SvgOverlay = styled.div<StyledProps<{ width: number; height: number }>>`
  position: absolute;
  z-index: 5;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  top: 0;
  left: 0;
`;

const PolygonNumber = styled(motion.div)<
  StyledProps<{
    top: number;
    left: number;
    hovered: boolean;
    small: boolean;
    leftShifted: boolean;
    size: number;
  }>
>`
  position: absolute;
  z-index: 20;
  top: ${(props) => props.top - props.size / 2}px;
  left: ${(props) => props.left - (props.leftShifted ? 0 : props.size / 2)}px;
  border-radius: 50%;
  color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 1.125rem;
  line-height: ${(props) => props.size}px;

  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: ${({ hovered, theme }) =>
    hovered ? theme.gold : 'rgba(143, 149, 163, 0.7)'};
  pointer-events: none;
  font-style: italic;
`;

const Backdrop = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: contain;
  object-position: left top;
  width: 100%;
  height: 100%;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.white};
`;

const mapProvinceToImage: any = {
  Bruxelles: BrusselsImg,
  'Oost-Vlaanderen': GhentImg,
  'West-Vlaanderen': BruggeImg,
  Hainaut: MonsImg,
  Limburg: HasseltImg,
  Liège: LiegeImg,
  'Brabant Wallon': WavreImg,
  'Vlaams-Brabant': LeuvenImg,
  Namur: NamurImg,
  Luxembourg: ArlonImg,
  Antwerpen: AntwerpImg
};

interface ISize {
  width: number;
  height: number;
}

export interface ISvgPolygon {
  path: string;
  id?: string;
}

interface FindNearbyPropertyBlockProps {
  theme: { white: string };
}

const FindNearbyPropertyBlock = (props: FindNearbyPropertyBlockProps) => {
  const ref = useRef(null);
  const { gotVisible } = useIntersectionObserver(ref);

  const navigate = useNavigate();

  const [provinces, setProvinces] = useState<{ id?: string }[]>(
    isTablet || isMobileOnly ? PROVINCES : []
  );
  const [belgiumSvgPaths, setBelgiumSvgPaths] = useState<IPathTag[]>([]);
  const [svgPolygons, setSvgPolygons] = useState<ISvgPolygon[]>([]);
  const [polygonNumbers, setPolygonNumbers] = useState<IPolygonNumber[]>([]);

  const [containerSize, setContainerSize] = useState<ISize>();
  const [drawingAreaSize, setDrawingAreaSize] = useState<ISize>();
  const [svgOverlayTransformation, setSvgOverlayTransformation] = useState('');
  const [svgScale, setSvgScale] = useState(1);
  const [originalSvgSize, setOriginalSvgSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const [hoveredProvince, setHoveredProvince] = useState('');

  const { DataState } = useDataState();
  const { projectsPerProvince } = DataState;

  const { ProjectsFiltersDispatch } = useProjectsFiltersState();

  useEffect(() => {
    if (!projectsPerProvince) return;
    if (svgPolygons.length > 0) {
      const polygonNumbers = prepareSvgNumbers(
        '#svg-overlay',
        '#invisible-polygon'
      );
      setPolygonNumbers(polygonNumbers);
    }
  }, [svgPolygons, projectsPerProvince]);

  // Load SVG file, prepare province paths
  useEffect(() => {
    if (isTablet || isMobileOnly) return;

    var request = new XMLHttpRequest();
    request.open('GET', BelgiumSvg);
    request.setRequestHeader('Content-Type', 'image/svg+xml');
    request.addEventListener('load', function (event) {
      // @ts-ignore
      var response = event.target.responseText;
      var doc = new DOMParser();
      var xml = doc.parseFromString(response, 'image/svg+xml');
      var result = response.slice(0, response.indexOf('<svg'));
      result += xml.documentElement.outerHTML;

      // Retrieve all paths
      const tags = result.match(/<[^>]*?>/g);
      let pathTags: IPathTag[] = [];
      let provinces: { id?: string }[] = [];
      tags.forEach((tag: string) => {
        if (tag.includes('path')) {
          const [pathTag, province] = convertSvgPath(tag);
          pathTags.push(pathTag);
          provinces.push(province);
        }
        if (tag.includes('<svg')) {
          const originalSvgSize = getOriginalSvgSize(tag);
          setOriginalSvgSize(originalSvgSize);
        }
      });

      // Filter out paths without id
      pathTags = pathTags.filter((path) => !!path.id);

      setBelgiumSvgPaths(pathTags);
      setProvinces(provinces);
    });
    request.send();
  }, []);

  const container = useRef(null);
  const canvas = useRef(null);

  useEffect(() => {
    if (!originalSvgSize) return;
    if (belgiumSvgPaths.length > 0 && canvas.current) {
      // @ts-ignore
      const containerSize = container.current.getBoundingClientRect();
      setContainerSize(containerSize);
      setDrawingAreaSize(containerSize);

      // @ts-ignore
      canvas.current.width = containerSize.width;
      // @ts-ignore
      canvas.current.height = containerSize.height;

      if (belgiumSvgPaths) {
        const hScale = originalSvgSize.width / containerSize.width;
        const vScale = originalSvgSize.height / containerSize.height;
        const scale = Math.max(hScale, vScale);
        setSvgScale(scale);
        setSvgOverlayTransformation(`
        transform: scale(${1 / scale}) translateX(3px) translateY(4px);
        transform-origin: top left;
      `);
        // we want to create a svg clone of every polygon drawn in canvas
        setSvgPolygons(
          belgiumSvgPaths.map(
            (x: IPathTag): ISvgPolygon => ({
              path: x.pathAsString,
              id: x.id
            })
          )
        );
      }
    }
  }, [canvas, belgiumSvgPaths, originalSvgSize]);

  const onProvinceClick = useCallback((provinceId?: string) => {
    const actionBody: { type: string; payload?: any } = {
      type: 'setTargetLocation'
    };
    if (provinceId) {
      actionBody.payload = {
        searchByProvince: true,
        provinceValues: [provinceId]
      };
    }

    ProjectsFiltersDispatch(actionBody);

    navigate(`/projects` + window.location.search);
  }, []);

  const provincesComponent = (
    <ProvincesWrapper>
      <AnimatePresence>
        {provinces.map((province: { id?: string }, idx: number) =>
          province.id ? (
            <ProvinceButton
              key={province.id}
              onMouseEnter={() => setHoveredProvince(province?.id ?? '')}
              onMouseLeave={() => setHoveredProvince('')}
              hovered={province.id === hoveredProvince}
              initial={{ opacity: 0, x: -15 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -15 }}
              transition={{ delay: idx * 0.075 }}
              img={mapProvinceToImage[province.id]}
              onClick={() => onProvinceClick(province?.id)}
            >
              {
                localizer.belgiumProvinces[
                  provinceIdToLabelMap[
                    province.id
                  ] as keyof typeof localizer.belgiumProvinces
                ]
              }
            </ProvinceButton>
          ) : null
        )}
      </AnimatePresence>
      <ViewAllProjects onClick={() => onProvinceClick()}>
        <span>
          {isTablet || isMobileOnly
            ? localizer.homePage.allProjects
            : localizer.homePage.viewAllprojects}
        </span>
        <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
      </ViewAllProjects>
    </ProvincesWrapper>
  );

  const content =
    isTablet || isMobileOnly ? (
      <>
        <StyledText color={props.theme.white}>
          <span>{localizer.homePage.findNearby}</span>
        </StyledText>

        {provincesComponent}
      </>
    ) : (
      <Container>
        <ListWrapper>
          <TextBold>{localizer.homePage.findNearby}</TextBold>

          {provincesComponent}
        </ListWrapper>

        <MapWrapper>
          <MapContent ref={container}>
            {svgPolygons?.length > 0 &&
              drawingAreaSize &&
              containerSize &&
              projectsPerProvince && (
                <SvgOverlay
                  id="svg-overlay"
                  width={drawingAreaSize.width * svgScale}
                  height={drawingAreaSize.height * svgScale}
                >
                  <SvgContainer
                    width="100%"
                    height="100%"
                    top="0px"
                    left="0px"
                    viewBox={`0 0 ${containerSize.width * svgScale} ${
                      containerSize.height * svgScale
                    }`}
                    transformation={svgOverlayTransformation}
                  >
                    {svgPolygons.map((x: ISvgPolygon, i: number) => (
                      <ProvinceSvgMask
                        key={i}
                        maskData={x}
                        hoveredProvince={hoveredProvince}
                        onClick={() => onProvinceClick(x.id)}
                        onHover={(province: string) =>
                          setHoveredProvince(province)
                        }
                        number={x.id ? projectsPerProvince?.[x.id] : 0}
                      />
                    ))}
                  </SvgContainer>
                  <AnimatePresence>
                    {polygonNumbers.map((x: IPolygonNumber, i: number) => (
                      <PolygonNumber
                        key={i}
                        top={x.center.y}
                        left={x.center.x}
                        hovered={x.polygonId === hoveredProvince}
                        initial={{ opacity: 0, y: -15 }}
                        animate={{
                          opacity: x.availableProjects > 0 ? 1 : 0,
                          y: 0
                        }}
                        exit={{ opacity: 0, y: -15 }}
                        small={x.polygonId === 'Bruxelles'}
                        leftShifted={x.polygonId === 'Vlaams-Brabant'}
                        size={
                          x.polygonId === 'Bruxelles' ? 26 : isMobile ? 32 : 40
                        }
                      >
                        {x.availableProjects}
                      </PolygonNumber>
                    ))}
                  </AnimatePresence>
                </SvgOverlay>
              )}
            <canvas ref={canvas} />
            <Backdrop src={BelgiumBackdrop} />
          </MapContent>
        </MapWrapper>
      </Container>
    );

  return (
    <Wrapper
      ref={ref}
      animate={{ opacity: gotVisible ? 1 : 0, y: gotVisible ? 0 : 100 }}
      transition={{ ease: 'linear', duration: 0.3 }}
    >
      {content}
    </Wrapper>
  );
};

export default withTheme(FindNearbyPropertyBlock);
