import { UnitState } from '@/libs/prompto-api/src';
import numeral from 'numeral';
import localizer from 'src/localization/localizer';

/**
 *
 * @param {Object} showcaseConfiguration The showcase configuration
 * @returns returns true if the price should be hidden when shared, returns false otherwise
 */
export const shouldHidePriceWhenShared = (showcaseConfiguration: any) => {
  if (showcaseConfiguration) {
    const { hidePriceWhenShared } = showcaseConfiguration;
    if (hidePriceWhenShared !== null) {
      return hidePriceWhenShared;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 *
 * @param {Object} showcaseConfiguration The showcase configuration
 * @param {Object} unitState The state of the unit on which we check if the price should be hidden
 * @returns returns true if the price should be hidden when the unit is sold or in option, returns false otherwise
 */
export const shouldHidePriceWhenUnitIsSold = (
  showcaseConfiguration: any,
  unitState: string
) => {
  if (showcaseConfiguration) {
    const { hidePriceWhenUnitSold } = showcaseConfiguration;
    if (hidePriceWhenUnitSold !== null) {
      if (
        hidePriceWhenUnitSold &&
        (unitState === 'IN_OPTION' || unitState === 'SOLD')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 *
 * @param {Object} configuration The showcase configuration
 * @param {Object} unitState The state of the unit on which we check if the price should be hidden
 * @returns returns true if the price should be shown, returns false otherwise
 */
export const shouldShowUnitPrice = (configuration: any, unitState: string) => {
  return (
    !shouldHidePriceWhenShared(configuration) &&
    !shouldHidePriceWhenUnitIsSold(configuration, unitState)
  );
};

/**
 * Get the color from the theme that matches the unit state
 * @param {Object} theme theme object created by using ThemeProvider of StyledComponent
 * @param {String} unitState 'state' field of the 'vmUnit' object. The values are: AVAILABLE, IN_OPTION, SOLD and DRAFT.
 *                            You can see the documentation here: https://devapiv2.vr-tual.media/asciidoc/index.html#_vmunit_model
 * @returns return the color as a string or magenta if the state is not one of the expected values
 */
export const getColorForUnitState = (theme: any, unitState: string) => {
  const { sold, option, available, draft } = theme.unitStatus;

  switch (unitState) {
    case 'AVAILABLE':
      return available;
    case 'IN_OPTION':
      return option;
    case 'SOLD':
      return sold;
    default:
      return draft;
  }
};

/**
 * Calculate general field icon based on showcase configuration
 * @param {Object} showcaseConfiguration actual showcaseConfiguration
 * @param {string} fieldName general field name
 * @param {string} library FontAwesome icons collection: far, fal, fas
 * @returns {Array<string>} calculated icon
 */
export const getFieldIcon = (
  showcaseConfiguration: any,
  fieldName: string,
  library: string
) => {
  let defaultIcon = '';
  switch (fieldName) {
    case 'price':
      defaultIcon = 'tag';
      break;
    case 'surface':
      defaultIcon = 'vector-square';
      break;
    case 'numberOfBedrooms':
      defaultIcon = 'bed';
      break;
    case 'numberOfBathrooms':
      defaultIcon = 'bath';
      break;
    default:
  }
  const icon = showcaseConfiguration?.iconSettings?.[fieldName] ?? defaultIcon;
  if (!icon) return '';
  return library ? [library, icon] : icon;
};

/**
 * Get unit price. It adds 'per month' if unit flow is rental
 * @param {Object} unit
 * @returns {String} calculated price
 */
export const getUnitPrice = (unit: any) => {
  let unitPrice = `${numeral(unit?.unitPrice?.price).format()}`;

  if (unit?.unitFlow === 'rental') {
    unitPrice += ' / month';
  }

  return unitPrice;
};

export const unitSortableFields = [
  'title',
  'price',
  'surface',
  'numberOfBedrooms',
  'numberOfBathrooms',
  'state'
];

export const mapStateToLabel = {
  DRAFT: 'Draft',
  AVAILABLE: localizer.unitStatus.AVAILABLE,
  IN_OPTION: localizer.unitStatus.IN_OPTION,
  SOLD: localizer.unitStatus.SOLD
};

export const createRangeFilterValues = (values: any, unit: any) => {
  const purifiedValues = [...values]
    .filter(Boolean)
    .map((x) => Number(x))
    .filter((x) => !isNaN(x));
  const min = Math.min(...purifiedValues);
  const max = Math.max(...purifiedValues);
  return {
    limits: { min, max },
    values: { min, max },
    unit
  };
};

export const createPillFilterValues = (values: any) => {
  const purifiedValues = [...values]
    .map((val) => {
      if (!isNaN(Number(val))) {
        return Number(val);
      } else {
        return val;
      }
    })
    .sort((a, b) => {
      if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
      } else {
        return String(a).localeCompare(String(b));
      }
    });
  return {
    options: purifiedValues,
    values: []
  };
};

export const unitMatchFilter = (unit: any, filter: any, displayMax: any) => {
  const { surface, price, numberOfBedrooms, numberOfBathrooms, status } =
    filter;

  const isSurfaceFilterActive =
    surface.limits.min !== surface.values.min ||
    surface.limits.max !== surface.values.max;
  const isPriceFilterActive =
    price.limits.min !== price.values.min ||
    price.limits.max !== price.values.max;

  const unitSurface = unit.unitMetadata?.surface;
  const unitPrice = unit.unitPrice?.price;
  const unitBedrooms = unit.unitMetadata?.numberOfBedrooms;
  const unitBathrooms = unit.unitMetadata?.numberOfBathrooms;
  const unitStatus = unit.state;

  const surfaceMathes = isSurfaceFilterActive
    ? unitSurface &&
      unitSurface >= surface.values.min &&
      unitSurface <= surface.values.max
    : true;
  const priceMatches = !isPriceFilterActive
    ? true
    : unitStatus === 'SOLD'
    ? false
    : unitPrice &&
      unitPrice >= price.values.min &&
      unitPrice <= price.values.max;
  const displayMaxBedrooms = displayMax.numberOfBedrooms;
  const displayMaxBathrooms = displayMax.numberOfBathrooms;

  const bedroomsMatch =
    numberOfBedrooms.values?.length > 0
      ? (unitBedrooms !== null && numberOfBedrooms.values.includes(unitBedrooms)) ||
        (numberOfBedrooms.values.includes(
          numberOfBedrooms.options[displayMaxBedrooms - 1]
        ) &&
          numberOfBedrooms.options[displayMaxBedrooms - 1] < unitBedrooms)
      : true;
  const bathroomsMatch =
    numberOfBathrooms.values?.length > 0
      ? (unitBathrooms !==null && numberOfBathrooms.values.includes(unitBathrooms)) ||
        (numberOfBathrooms.values.includes(
          numberOfBathrooms.options[displayMaxBathrooms - 1]
        ) &&
          numberOfBathrooms.options[displayMaxBathrooms - 1] < unitBathrooms)
      : true;
  const statusMatches =
    status.values?.length > 0
      ? unitStatus && status.values.includes(unitStatus)
      : true;

  return (
    surfaceMathes &&
    priceMatches &&
    bedroomsMatch &&
    bathroomsMatch &&
    statusMatches
  );
};

/**
 * Get the specified field in the given unit
 * @param {object} unit The unit to get the field from
 * @param {string} field The field we want
 */
export const getUnitField = (unit: any, field: any) => {
  const fieldIsHiddenByUnitType =
    unit.unitType?.showGeneralFieldSettings?.[field] === false;
  if (fieldIsHiddenByUnitType) return null;

  switch (field) {
    default:
    case 'title':
    case 'state':
      return unit[field];
    case 'price':
      if (unit.unitPrice) {
        return unit.unitPrice[field];
      }
      return null;
    case 'surface':
    case 'numberOfBedrooms':
    case 'numberOfBathrooms':
      if (unit.unitMetadata) {
        return unit.unitMetadata[field];
      }
      return null;
  }
};

/**
 * Sort the unit based on the sortfield in ascending or descending order
 * @param {array} units The units to be sorted
 * @param {string} sortField The field we want to sort on
 * @param {boolean} isAscending If we need to sort in an ascending or descending order
 */
export const sortUnits = (
  units: any[],
  sortField: any,
  isAscending: boolean
) => {
  const sorted = units.sort((unitA: any, unitB: any) => {
    const a = getUnitField(unitA, sortField);
    const b = getUnitField(unitB, sortField);

    if (typeof a === 'string') {
      const compareResult = a.localeCompare(b);

      return isAscending ? compareResult : -1 * compareResult;
    }

    if (a < b) {
      return isAscending ? -1 : 1;
    }
    if (a > b) {
      return isAscending ? 1 : -1;
    }

    return 0;
  });

  return sorted;
};

export const calculateUsedFilters = (filter: any) => {
  const usedFilters = [];
  for (const key in filter) {
    if (
      filter[key].selected?.length > 0 ||
      filter[key].limits?.min !== filter[key].values?.min ||
      filter[key].limits?.max !== filter[key].values?.max
    ) {
      usedFilters.push(key);
    }
  }
  return usedFilters;
};

/**
 *
 * @param {String} unitState 'state' field of the 'vmUnit' object. The values are: AVAILABLE, IN_OPTION, SOLD and ARCHIVED.
 *                            You can see the documentation here: https://devapiv2.vr-tual.media/asciidoc/index.html#_vmunit_model
 * @returns return a localized string for that unit state or an empty string if the state is ARCHIVED or invalid
 */
export const getLocalizedTextForUnitState = (
  unitState: UnitState,
  unitFlow?: string
) => {
  const isRentalFlow = unitFlow === 'rental';
  const localized = localizer.unitListComponent;
  switch (unitState) {
    case 'AVAILABLE':
      return isRentalFlow
        ? localized.unitStateAvailableForRent.toUpperCase()
        : localized.unitStateAvailable.toUpperCase();
    case 'IN_OPTION':
      return localized.unitStateInOption.toUpperCase();
    case 'SOLD':
      return isRentalFlow
        ? localized.unitStateRented.toUpperCase()
        : localized.unitStateSold.toUpperCase();
    default:
      return '';
  }
};

// Localize an array of unit statuses
export const getLocalizedArrayOfUnitStates = (
  arrayOfUnitStates: Array<UnitState>,
  unitFlow?: string
) => {
  return arrayOfUnitStates.map((x) => {
    return getLocalizedTextForUnitState(x, unitFlow);
  });
};
