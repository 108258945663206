function OutOfViewportTopIcon(props: any) {
  return (
    <svg width={40} height={40} {...props}>
      <path
        d="M20 2.78 16.899 6.5c-4.115-.005-5.757.375-7.547 1.277a8.592 8.592 0 0 0-3.575 3.575c-.845 1.58-1.277 3.151-1.277 7.467l.001 6.759c.025 3.766.406 5.342 1.276 7.07a8.592 8.592 0 0 0 3.575 3.575c1.48.792 2.953 1.221 6.694 1.272l.777.005h6.358c4.053 0 5.69-.382 7.467-1.277a8.592 8.592 0 0 0 3.575-3.575c.792-1.48 1.221-2.953 1.272-6.694l.005-.777V18.82c0-4.053-.382-5.69-1.277-7.467a8.592 8.592 0 0 0-3.575-3.575c-1.559-.834-3.11-1.265-7.55-1.277L20 2.78z"
        fill="#C3C2CA"
        stroke="#FFF"
        fillRule="evenodd"
        fillOpacity=".8"
        strokeOpacity=".3"
      />
    </svg>
  );
}

export default OutOfViewportTopIcon;
