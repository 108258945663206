const defaultSortingOrder = [
  'tour360',
  'floorplan',
  'url',
  'image360',
  'image',
  'video',
  'document',
  'image360Linked',
  'imageLinked',
  'videoLinked',
  'documentLinked'
];

export default defaultSortingOrder;
