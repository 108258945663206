import { useEffect } from 'react';
// Helpers
import { useAppState } from 'src/store/AppStore';
import { useProjectsState } from 'src/store/ProjectsStore';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';
import { buildAssetURIWithOptions } from 'src/helpers/uri';
import { useNavigate } from 'react-router-dom';
import { Marketplace } from '@/libs/prompto-api/src';
import localizer from 'src/localization/localizer';
// Styling
import styled from 'styled-components';

const GAP = 100;
const CARDS = 3;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${GAP / 2}px;
  box-sizing: border-box;
  margin-top: 72px;
`;

const SearchResultCount = styled.div`
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  height: 60px;

  & > div {
    font-style: italic;
    span {
      font-weight: bold;
    }
  }
`;

const DeveloperWrapper = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: ${GAP}px;
  row-gap: ${GAP / 2}px;
`;

const ThumbnailWrapper = styled.div`
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.5);
  flex-basis: calc((100% - ${GAP * (CARDS - 1)}px) / ${CARDS});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const StyledImage = styled.img`
  max-width: calc(100vw - 128px);
  width: 320px;
  height: 200px;
  object-fit: contain;
`;

const ProjectCount = styled.div`
  margin-top: 15px;
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-style: italic;
  color: ${({ theme }) => theme.black};
`;

const DevelopersPage = () => {
  const { AppState } = useAppState();
  const { marketplaceId } = AppState;

  const { ProjectsState, ProjectsStateDispatch } = useProjectsState();
  const { unalteredProjects, allVaultLogos } = ProjectsState;

  const { ProjectsFiltersDispatch } = useProjectsFiltersState();

  const navigate = useNavigate();

  useEffect(() => {
    if (!unalteredProjects && marketplaceId) {
      const projectsFilter = { limit: 200 };

      Marketplace.searchProjects(marketplaceId, projectsFilter).then(
        (result) => {
          const projects = result.data?.projectList ?? [];

          ProjectsStateDispatch({
            type: 'setProjectsData',
            payload: {
              unalteredProjects: projects,
              allProjects: projects,
              totalCount: result.data?.totalCount ?? 0,
              allVaultLogos: result.data?.vaultIdToLogoUriMap ?? [],
              allProjectLogos: result.data?.projectIdToLogoUriMap ?? [],
              loading: false,
              error: false
            }
          });
        }
      );
    }
  }, [unalteredProjects, marketplaceId]);

  return (
    <Wrapper>
      <SearchResultCount>
        {allVaultLogos && (
          <div>{`${Object.keys(allVaultLogos).length - 1 ?? 0} ${
            localizer.developersPage.developersFound
          }`}</div>
        )}
      </SearchResultCount>
      <DeveloperWrapper>
        {allVaultLogos &&
          Object.keys(allVaultLogos).map((key) => {
            const logoToShow = buildAssetURIWithOptions(
              'h=250',
              allVaultLogos[key] ?? ''
            );

            // There is a project that is technically from Hyboma but was put on a different vault but with the same logo
            if (key === '6O6sKS0Eyp') return;

            const projectCount = unalteredProjects.filter(
              (x: any) => x.vaultId === key
            );

            return (
              <ThumbnailWrapper
                onClick={() => {
                  ProjectsFiltersDispatch({
                    type: 'setVaultFilter',
                    payload: { key, logo: allVaultLogos[key] }
                  });

                  navigate(`/projects${window.location.search}`);
                }}
              >
                <StyledImage src={logoToShow} />
                <ProjectCount>{`${projectCount.length} ${
                  projectCount.length > 1
                    ? localizer.developersPage.projects
                    : localizer.developersPage.project
                }`}</ProjectCount>
              </ThumbnailWrapper>
            );
          })}
      </DeveloperWrapper>
    </Wrapper>
  );
};

export default DevelopersPage;
