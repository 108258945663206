/**
 * Add a spot on the 360 canvas
 */
export const addSpotOnCanvas = (canvas: any, spot: any) => {
  const containerId = `spot${spot.objectId}`;

  // Add spot container on canvas, this is only used for keeping location in 360 context
  canvas.addHotSpot({
    pitch: Number(spot.xCoordinate),
    yaw: Number(spot.yCoordinate),
    cssClass: containerId,
    id: containerId
  });

  updateCanvas(canvas);
};

/**
 * Trigger an update on the canvas
 */
const updateCanvas = (canvas: any) => {
  const yaw = canvas.getYaw();
  const pitch = canvas.getPitch();
  canvas.lookAt(pitch, yaw);
};
