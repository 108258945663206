import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  MouseEvent,
  Key
} from 'react';

// Components
import ImageCard from './ImageCard';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay } from 'swiper/core';
import 'swiper/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocumentPreview from './contentItems/types/document/DocumentPreview';
import PageSwiper from './contentViewer/PageSwiper';
import Navigation from './contentViewer/Navigation';
import Preview from './contentItems/Preview';

//Helpers
import { useSwitcherState } from './SwitcherStore';
import { IContentItem } from '@/libs/prompto-api/src';
import { forBiggerThan } from 'src/helpers/ui';
import { isMobileOnly } from 'react-device-detect';
import localizer from 'src/localization/localizer';

// Styling
import styled, { StyledProps, css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

const gridGap = 30;
const gridHeight = 500;

const Grid = styled.div<StyledProps<{ screenWidth: number }>>`
  ${({ screenWidth }) => {
    if (screenWidth < 576) {
      return css`
        display: flex;
      `;
    } else {
      return css`
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;
      `;
    }
  }}

  ${({ screenWidth }) => {
    if (screenWidth < 576) {
      return 'padding: 0';
    } else {
      return 'padding: 16px';
    }
  }};

  .grid-0 {
    grid-area: 1 / 1 / 3 / 3;
  }

  .grid-1 {
    grid-area: 1 / 3 / 3 / 5;
  }

  .grid-2 {
    grid-area: 1 / 5 / 2 / 6;
  }

  .grid-3 {
    grid-area: 2 / 5 / 3 / 6;
  }

  height: 275px;
  margin-top: 32px;
  max-height: 480px;

  ${forBiggerThan.tablet`
    margin-top: 0;
    padding: 48px 50px;
    padding-bottom: 0;
    height: 325px;
  `}

  ${forBiggerThan.desktop`
    padding: 48px 50px;
    padding-bottom: 0;
    height: ${gridHeight}px;
  `}
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;

  .swiper-slide {
    width: 90vw;
    overflow: hidden;
    display: flex;
  }
`;

const ResourcesWrapper = styled.div<StyledProps<{ hideComponent: boolean }>>`
  outline: transparent;
  ${({ hideComponent }) => (hideComponent ? 'display: none;' : '')}
`;

const ResourcesModalContent = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: flex;
  width: 100%;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  padding: 52px 0 0 0;
  @media (max-width: 567px) {
    height: 100vh;
  }
`;

const Overlay = styled(motion.div)`
  position: fixed;
  background-color: rgba(46, 49, 56, 0.5);
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s;
  z-index: 500;
  padding-top: 60px;
  height: calc(100vh - 60px);
  @media (max-width: 567px) {
    height: 100vh;
    padding: 0;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
`;

const CloseButton = styled.button`
  border: none;
  color: ${({ theme }) => theme.white};
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 16px;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 567px) {
    background-color: transparent;
  }
`;

const GridWrapper = styled.div<
  StyledProps<{
    isMobile?: boolean;
  }>
>`
  width: calc(100vw - 135px);
  max-width: 100vw;
  display: flex;
  grid-gap: ${gridGap}px;
  height: fit-content;
  margin: 0 56px;
  @media (max-width: 567px) {
    margin: 0 20px;
    width: calc(100vw - 65px);
  }
  flex-direction: ${({ isMobile }) => {
    return isMobile ? 'column' : 'row';
  }};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${gridGap}px;
  width: calc(50% - ${gridGap / 2}px);
`;

const FlexItemWrapper = styled.div<
  StyledProps<{
    addBorderRadius?: boolean;
  }>
>`
  user-select: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;
  ${({ addBorderRadius }) => (addBorderRadius ? 'border-radius: 16px;' : '')}
`;

const OverflowScroll = styled.div`
  overflow-y: scroll;
`;

const GridItemWrapper = styled.div<StyledProps<{ showOverlay?: boolean }>>`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  ${({ showOverlay }) =>
    showOverlay
      ? css`
          &::after {
            position: absolute;
            z-index: 20;
            content: '${localizer.imageCard.viewAll ?? 'View all'}';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0.8) 100%
            );
            color: ${({ theme }) => theme.white};
            font-family: ${({ theme }) => theme.fonts.DMSans};
            font-size: 1rem;
            cursor: pointer;

            ${forBiggerThan.laptop`
              border-radius: 16px;
              font-size: 1.25rem;`}
            }
          }
        `
      : ''}
`;

let interval = 0;

export interface ISwitcherState {
  pages: never[];
  activePage: number;
  gridItemClicked: boolean;
  showGrid: boolean;
  activeCategory: string;
  activeFilter: string;
  orderedContentCollection: null;
  folders: never[];
  showFolders: boolean;
  activeFolder: string;
  foldersRefs: null;
  enableOnUpdateEvent: boolean;
}
export interface ISwitcherStore {
  SwitcherState: any;
  SwitcherStateDispatch: any;
}

export interface ResourcesProps {
  contentItemList: IContentItem[] | null;
  previewContent: IContentItem[] | null;
  fetchingContent: boolean;
  hideModal?: boolean;
}

type RefType = HTMLDivElement | null;

const ResourcesGrid = ({
  contentItemList,
  previewContent,
  fetchingContent,
  hideModal
}: ResourcesProps) => {
  const wrapperRef = useRef<RefType>(null);
  const overlayRef = useRef<RefType>(null);
  const [uriList, setUriList] = useState<IContentItem[]>([
    '',
    '',
    '',
    ''
  ] as unknown as IContentItem[]);
  const [docsList, setDocsList] = useState<IContentItem[]>([]);
  const [gridItemSize, setGridItemSize] = useState(0);
  const [isShowOverlay, setShowOverlay] = useState(false);
  const [realUriFetched, setRealUriFetched] = useState(false);

  const [oneResourceIsLoaded, setOneResourceIsLoaded] = useState(false);

  // @ts-ignore
  const { SwitcherState, SwitcherStateDispatch }: ISwitcherStore =
    useSwitcherState();
  const { activePage, showGrid, pages }: ISwitcherState = SwitcherState;

  useEffect(() => {
    if (previewContent?.length) {
      SwitcherStateDispatch({
        type: 'setPages',
        payload: previewContent
      });
    }
  }, [previewContent]);

  useEffect(() => {
    if (contentItemList && contentItemList.length > 0) {
      SwitcherStateDispatch({
        type: 'setPages',
        payload: contentItemList
          ?.filter((item) => item.contentItemState !== 'archived')
          ?.map((item, index) => ({
            ...item,
            pageIndex: index
          }))
      });
    }
  }, [contentItemList, SwitcherStateDispatch]);

  // Events
  const onPageClick = (e: MouseEvent, index: number) => {
    if (!!index || index === 0) {
      document.body.style.overflow = 'hidden';
      SwitcherStateDispatch({ type: 'setGridItemClicked', payload: true });
      SwitcherStateDispatch({ type: 'setActivePage', payload: index });
      const timer = setTimeout(() => {
        SwitcherStateDispatch({ type: 'setShowGrid', payload: false });
        clearTimeout(timer);
      }, 60);
    }
  };

  const [screenSize, setScreenSize] = useState({
    width:
      wrapperRef.current?.getBoundingClientRect()?.width ?? window.innerWidth,
    height:
      wrapperRef.current?.getBoundingClientRect()?.height ?? window.innerHeight
  });

  useEffect(() => {
    if (pages?.length) {
      const imgUriList: Array<IContentItem> = [];
      pages.forEach((contentItem: IContentItem) => {
        if (
          ['image', 'document', 'floorplan', 'video'].includes(
            contentItem.contentItemType
          )
        ) {
          imgUriList.push(contentItem);
        }
      });
      const maxListLength = imgUriList.length < 4 ? imgUriList.length : 4;
      setUriList(imgUriList.slice(0, maxListLength));
      setRealUriFetched(true);
      if (maxListLength === 0) {
        const onlyDocuments = pages.filter((contentItem: IContentItem) => {
          return (
            contentItem.contentItemType === 'document' ||
            contentItem.contentItemType === 'floorplan'
          );
        });
        setDocsList(onlyDocuments);
      }
    }
  }, [previewContent, pages, fetchingContent]);

  useEffect(() => {
    // if no content items - grid is hidden - no need to calculate container size
    if (!screenSize.width && contentItemList?.length) {
      windowSizeChanged();
    }
  }, [screenSize, contentItemList]);

  const windowSizeChanged = useCallback(() => {
    if (wrapperRef.current?.getBoundingClientRect()) {
      setScreenSize({
        width: wrapperRef.current.getBoundingClientRect().width,
        height: wrapperRef.current.getBoundingClientRect().height
      });
    }
  }, [screenSize]);

  useEffect(() => {
    window.addEventListener('resize', windowSizeChanged);
    return () => window.removeEventListener('resize', windowSizeChanged);
  }, [windowSizeChanged]);

  useEffect(() => {
    const wrapperWidth = wrapperRef.current?.getBoundingClientRect()?.width;

    if (wrapperWidth) {
      const gridGap = 16;
      const gaps = 2;
      const columns = 3;

      let itemSize = (wrapperWidth - gridGap * gaps) / columns;
      if (screenSize.width < 576) {
        itemSize = wrapperWidth;
      }
      setGridItemSize(() => itemSize);
    }
  }, [screenSize.width]);

  const isSmallScreen = screenSize.width < 576;

  const verticalGridRef = useRef<any>();
  const [gridTwoColumnsWidth, setGridTwoColumnsWidth] = useState(0);

  // split pages in two columns
  const leftColumn: any = [];
  const rightColumn: any = [];
  pages?.forEach((page: IContentItem, idx: number) => {
    idx % 2 ? rightColumn.push(page) : leftColumn.push(page);
  });

  useEffect(() => {
    interval = window.setInterval(
      (screenWidth: number) => {
        if (verticalGridRef.current) {
          const currentWidth = verticalGridRef?.current?.offsetWidth;
          setGridTwoColumnsWidth((currentWidth - gridGap) / 2);
          if (screenWidth < 576) {
            setGridTwoColumnsWidth(currentWidth);
          }
        }
        clearInterval(interval);
      },
      200,
      screenSize.width
    );
  }, []);

  useEffect(() => {
    if (verticalGridRef.current) {
      const currentWidth = verticalGridRef?.current?.offsetWidth;
      setGridTwoColumnsWidth((currentWidth - gridGap) / 2);
      if (screenSize.width < 576) {
        setGridTwoColumnsWidth(currentWidth);
      }
    }
  }, [screenSize.width]);

  const onNextPage = useCallback(() => {
    let newPageIndex = activePage + 1;
    if (newPageIndex >= pages?.length) {
      newPageIndex = 0;
    }

    SwitcherStateDispatch({ type: 'setActivePage', payload: newPageIndex });
  }, [SwitcherStateDispatch, activePage, pages]);

  const onPreviousPage = useCallback(() => {
    let newPageIndex = activePage - 1;
    if (newPageIndex < 0) {
      newPageIndex = pages?.length - 1;
    }

    SwitcherStateDispatch({ type: 'setActivePage', payload: newPageIndex });
  }, [SwitcherStateDispatch, activePage, pages]);

  const onCloseSwiper = useCallback(() => {
    if (!window.location.pathname.includes('unit')) {
      document.body.style.overflow = 'auto';
    }
    SwitcherStateDispatch({ type: 'setShowGrid', payload: true });
  }, [SwitcherStateDispatch]);

  const onPageLoaded = useCallback(() => {
    SwitcherStateDispatch({ type: 'setGridItemClicked', payload: false });
  }, [SwitcherStateDispatch]);

  const pageSwiper = (
    <PageSwiper
      activePage={activePage}
      pages={pages}
      onNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      onClose={onCloseSwiper}
      videoFallbackThumbnail={''}
      CustomNavigation={<Navigation />}
      onPageLoaded={onPageLoaded}
      show={!showGrid}
    />
  );

  const renderCard = (page: IContentItem, index: number) => {
    const content = (
      <Preview
        key={page.pageIndex}
        data={page}
        pageIndex={page.pageIndex as number}
        size={gridTwoColumnsWidth}
        videoFallbackThumbnail={''}
        isVerticalLayout={true}
      />
    );

    return (
      <FlexItemWrapper
        className={`grid-${index}`}
        addBorderRadius={!!index || index === 0}
        key={page.pageIndex as Key}
        onClick={(e) => onPageClick(e, page.pageIndex as number)}
      >
        {content}
      </FlexItemWrapper>
    );
  };

  const resourcesPlaceholder = isSmallScreen ? (
    <motion.div
      style={{ width: '92%', margin: '0 4%', flexShrink: 0 }}
      exit={{ opacity: 0, x: 40 }}
      transition={{ duration: 0.4 }}
    >
      <ImageCard idx={0} itemUri={''} size={gridItemSize} showOverlay={false} />
    </motion.div>
  ) : (
    ['', '', '', ''].map((string, index) => {
      return (
        <motion.div
          className={`grid-${index}`}
          key={index}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
        >
          <ImageCard
            idx={index}
            itemUri={''}
            size={gridItemSize}
            showOverlay={index === 3 && realUriFetched}
          />
        </motion.div>
      );
    })
  );

  const mobileContent = (
    <StyledSwiper
      slidesPerView={'auto'}
      spaceBetween={12}
      centeredSlides={true}
      // autoplay={{
      //   delay: 2500,
      //   disableOnInteraction: false
      // }}
      loop={true}
      pagination={{
        clickable: true
      }}
      navigation={true}
      // modules={[Autoplay]}
    >
      {docsList.length // if docsList provided - it means that no images are available - render docs previews
        ? docsList?.map((document, index) => {
            return (
              <SwiperSlide
                key={`slide_${document.objectId}`}
                onClick={(event) => {
                  if (!hideModal) return;
                  onPageClick(event, document.pageIndex as number);
                }}
              >
                <DocumentPreview
                  data={document}
                  key={document.objectId}
                  size={gridItemSize}
                  isVerticalLayout={false}
                  onLoaded={() => setOneResourceIsLoaded(true)}
                />
              </SwiperSlide>
            );
          }) // otherwise render only images
        : uriList?.map((contentItem, index) => {
            return (
              <SwiperSlide
                key={`${contentItem.objectId}-${index}`}
                onClick={(event) => {
                  if (!hideModal) return;
                  onPageClick(event, contentItem.pageIndex as number);
                }}
              >
                {contentItem.contentItemType === 'image' && (
                  <ImageCard
                    key={contentItem.objectId}
                    idx={index}
                    itemUri={contentItem.contentUri as string}
                    size={Math.max(gridItemSize, gridHeight - gridGap)}
                    onLoaded={() => setOneResourceIsLoaded(true)}
                  />
                )}
                {(contentItem.contentItemType === 'document' ||
                  contentItem.contentItemType === 'floorplan') && (
                  <DocumentPreview
                    data={contentItem}
                    key={contentItem.objectId}
                    size={Math.max(gridItemSize, gridHeight - gridGap)}
                    isVerticalLayout={false}
                    onLoaded={() => setOneResourceIsLoaded(true)}
                  />
                )}

                {contentItem.contentItemType === 'video' && (
                  <Preview
                    pageIndex={index}
                    key={contentItem.objectId}
                    data={contentItem}
                    size={Math.max(gridItemSize, gridHeight - gridGap)}
                    videoFallbackThumbnail={''}
                    isVerticalLayout={true}
                    gridPreview={true}
                  />
                )}
              </SwiperSlide>
            );
          })}
    </StyledSwiper>
  );

  const desktopContent = docsList.length
    ? docsList?.map((contentItem, index) => {
        return (
          <GridItemWrapper
            className={`grid-${index}`}
            key={`${contentItem.objectId}-${index}`}
            onClick={(event) => {
              if (!hideModal) return;
              onPageClick(event, contentItem.pageIndex as number);
            }}
          >
            <DocumentPreview
              data={contentItem}
              key={`${contentItem.objectId}-${index}`}
              size={300}
              isVerticalLayout={false}
              onLoaded={() => setOneResourceIsLoaded(true)}
            />
          </GridItemWrapper>
        );
      })
    : uriList?.map((contentItem, index) => {
        return (
          <GridItemWrapper
            className={`grid-${index}`}
            key={`${contentItem.objectId}-${index}`}
            onClick={(event) => {
              if (!hideModal) return;
              onPageClick(event, contentItem.pageIndex as number);
            }}
            showOverlay={index === 3 && realUriFetched}
          >
            {contentItem.contentItemType === 'image' && (
              <ImageCard
                idx={index}
                itemUri={contentItem.contentUri as string}
                size={Math.max(gridItemSize, gridHeight - gridGap)}
                onLoaded={() => setOneResourceIsLoaded(true)}
              />
            )}
            {(contentItem.contentItemType === 'document' ||
              contentItem.contentItemType === 'floorplan') && (
              <DocumentPreview
                data={contentItem}
                key={contentItem.objectId}
                size={Math.max(gridItemSize, gridHeight - gridGap)}
                isVerticalLayout={false}
                onLoaded={() => setOneResourceIsLoaded(true)}
              />
            )}
            {contentItem.contentItemType === 'video' && (
              <Preview
                pageIndex={index}
                key={contentItem.objectId}
                data={contentItem}
                videoFallbackThumbnail={''}
                isVerticalLayout={true}
                gridPreview={true}
              />
            )}
          </GridItemWrapper>
        );
      });

  return (
    <ResourcesWrapper
      hideComponent={false}
      ref={wrapperRef as React.RefObject<HTMLDivElement>}
    >
      {/* @ts-ignore */}
      {pageSwiper}
      <AnimatePresence>
        {isShowOverlay ? (
          <Overlay
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            id="resource-overlay"
            ref={overlayRef as React.RefObject<HTMLDivElement>}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              if (e.target === overlayRef.current) {
                setShowOverlay(false);
              }
            }}
          >
            <CloseButton onClick={() => setShowOverlay(false)}>
              <CloseIcon icon={['fal', 'times']} size="sm" />
            </CloseButton>
            <ResourcesModalContent>
              <OverflowScroll>
                <GridWrapper
                  ref={verticalGridRef}
                  isMobile={screenSize.width < 576}
                >
                  {' '}
                  {/*@ts-ignore*/}
                  {screenSize.width < 576
                    ? pages.map(renderCard).filter((x: any) => x)
                    : [leftColumn, rightColumn].map((col, index) => {
                        return (
                          <Column key={col[0]?.objectId || index}>
                            {col.map(renderCard).filter((x: any) => x)}
                          </Column>
                        );
                      })}
                </GridWrapper>
              </OverflowScroll>
            </ResourcesModalContent>
          </Overlay>
        ) : null}
      </AnimatePresence>

      <Grid
        key="ResourcesGrid"
        screenWidth={screenSize.width}
        onClick={() => {
          if (hideModal) return;
          setShowOverlay(true);
        }}
      >
        <AnimatePresence>
          {(fetchingContent || !oneResourceIsLoaded) && resourcesPlaceholder}
        </AnimatePresence>

        {isMobileOnly ? mobileContent : desktopContent}
      </Grid>
    </ResourcesWrapper>
  );
};

export default ResourcesGrid;
