import { useEffect, useState } from 'react';

// Components
import MainBlock from './MainBlock';
import ProjectsSlider from './projectsBlock/ProjectsSlider';
import DevelopersBlock from './DevelopersBlock';
import CreateAccountBlock from './CreateAccountBlock';
import FindNearbyPropertyBlock from './findNearbyPropertyBlock/FindNearbyPropertyBlock';
import Footer from './Footer';

// Helpers
import { IProject, Marketplace } from '@/libs/prompto-api/src';
import { Tracking } from '@prompto-api';
import { useAppState } from 'src/store/AppStore';
import localizer, { setLanguage } from 'src/localization/localizer';

// Styling
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div`
  min-height: 100vh;
`;

const LandingPage = () => {
  const [loadingRecentProjects, setLoadingRecentProjects] = useState(true);
  const [projectList, setProjectList] = useState([]);

  const [loadinglastChanceProjects, setLoadingLastChanceProjects] =
    useState(true);
  const [projectLastChanceList, setProjectLastChanceList] = useState([]);

  // App state
  const { AppState } = useAppState();
  const { marketplaceId, sessionId, visitorId } = AppState;

  const { language } = useParams<string>();

  const recentlyCreatedHeading = (
    <>
      <b>{localizer.projectSlider.recentlyAdded}</b>{' '}
      {localizer.projectSlider.beOneOfTheFirst}
    </>
  );
  const lastChanceHeading = (
    <>
      <b>{localizer.projectSlider.lastChance}</b>{' '}
      {localizer.projectSlider.hurryUp}
    </>
  );

  // Set language from url
  useEffect(() => {
    if (language) {
      setLanguage(language as string);
    }
  });

  // Track Home page visitor
  useEffect(() => {
    if (marketplaceId && sessionId && visitorId) {
      const trackingData = {
        actionName: 'trackMarketplaceHomePageVisitor',
        marketplaceId,
        visitorId,
        sessionId
      };
      Tracking.trackMarketplaceAction(trackingData).catch(() => {});
    }
  }, [marketplaceId, sessionId, visitorId]);

  useEffect(() => {
    if (marketplaceId) {
      Marketplace.getProjectsWithUnitsPercentage(marketplaceId, 40, [
        'AVAILABLE',
        'IN_OPTION'
      ])
        .then(({ data }) => {
          setProjectLastChanceList(data?.projectList || []);
          setLoadingLastChanceProjects(false);
        })
        .catch(() => {
          setProjectLastChanceList([]);
          setLoadingLastChanceProjects(false);
        });

      Marketplace.getLastPublishedProjects(marketplaceId, 10)
        .then(({ data }) => {
          const projectList = data?.projectList || [];
          const idList = (data?.projectList || []).map((project: IProject) => {
            return project.projectId;
          });
          if (idList.length > 0) {
            Marketplace.getUnitCount({
              marketplaceId,
              projectIdList: idList as string[],
              unitStateList: ['AVAILABLE', 'IN_OPTION']
            })
              .then((result) => {
                const unitCountObject = result.data?.projectsUnitsCountMap;
                const updatedProjectList = projectList.map(
                  (project: IProject) => {
                    return {
                      ...project, // @ts-ignore
                      unitCount: unitCountObject[project.projectId] || 0
                    };
                  }
                );
                setProjectList(updatedProjectList);
                setLoadingRecentProjects(false);
              })
              .catch(() => {});
          } else {
            setProjectList(projectList);
            setLoadingRecentProjects(false);
          }
        })
        .catch(() => {});
    }
  }, [marketplaceId]);

  return (
    <Wrapper>
      <MainBlock />
      <ProjectsSlider
        loading={loadingRecentProjects}
        projects={projectList}
        heading={recentlyCreatedHeading}
        slidesPerScreen={[1, 2, 2, 3, 4]}
      />
      <CreateAccountBlock />

      {projectLastChanceList.length !== 0 && (
        <ProjectsSlider
          loading={loadinglastChanceProjects}
          projects={projectLastChanceList}
          heading={lastChanceHeading}
          hideBadge
          slidesPerScreen={[2, 2, 3, 4, 5]}
        />
      )}
      <DevelopersBlock />
      {/* <CreateAccountBlock /> */}
      <FindNearbyPropertyBlock />
      <Footer />
    </Wrapper>
  );
};

export default LandingPage;
