import { useEffect } from 'react';
import { Marketplace } from '@/libs/prompto-api/src';
import { useAuthState } from 'src/store/AuthStore';
import { useAppState } from 'src/store/AppStore';

const useUserFavourites = () => {
  const { AuthState, AuthStateDispatch } = useAuthState();
  const {
    favouriteProjects,
    favouriteUnits,
    projectToToggle,
    projectVaultToToggle,
    unitToToggle,
    unitVaultToToggle,
    unitProjectToToggle,
    vaultObjectId,
    sessionToken,
    tempFavouriteProjects,
    tempFavouriteUnits
  } = AuthState;

  const { AppState } = useAppState();
  const { marketplaceId } = AppState;

  // Favourite project
  useEffect(() => {
    // If Authenticated, store in backend
    if (
      projectToToggle &&
      marketplaceId &&
      projectVaultToToggle &&
      vaultObjectId &&
      sessionToken
    ) {
      const copyOfList: any = [...favouriteProjects];
      const project = favouriteProjects.find(
        (x: any) => x.favoriteProjectId === projectToToggle
      );

      if (project) {
        // Item is present in the array, remove it
        copyOfList.splice(favouriteProjects.indexOf(project), 1);
        AuthStateDispatch({
          type: 'setFavouriteProjects',
          payload: copyOfList
        });

        Marketplace.deleteFavouriteProject(project.objectId, sessionToken);
      } else {
        // Item is not present in the array, add it

        // Add it locally first to show instant feedback
        copyOfList.push({ favoriteProjectId: projectToToggle });
        AuthStateDispatch({
          type: 'setFavouriteProjects',
          payload: copyOfList
        });

        const favouriteCreateParams = {
          marketplaceId,
          vaultId: vaultObjectId,
          favoriteVaultId: projectVaultToToggle,
          favoriteProjectId: projectToToggle
        };

        Marketplace.createFavouriteProject(
          favouriteCreateParams,
          sessionToken
        ).then((result: any) => {
          AuthStateDispatch({
            type: 'setFavouriteProjects',
            payload: [
              ...copyOfList.filter(
                (x: any) => x.favoriteProjectId !== projectToToggle
              ),
              result.data
            ]
          });
        });
      }

      AuthStateDispatch({
        type: 'toggleFavouriteProject',
        payload: {
          projectId: null,
          vaultId: null
        }
      });
    } else if (projectToToggle && marketplaceId && projectVaultToToggle) {
      // If not authenticated, store in temp list

      const copyOfList: any = [...tempFavouriteProjects];
      const project = tempFavouriteProjects.find(
        (x: any) => x.favoriteProjectId === projectToToggle
      );

      if (project) {
        // Item is present in the array, remove it
        copyOfList.splice(tempFavouriteProjects.indexOf(project), 1);
      } else {
        // Add it
        copyOfList.push({
          favoriteProjectId: projectToToggle,
          favoriteVaultId: projectVaultToToggle
        });
      }

      AuthStateDispatch({
        type: 'setTempFavouriteProjects',
        payload: copyOfList
      });

      AuthStateDispatch({
        type: 'toggleFavouriteProject',
        payload: {
          projectId: null,
          vaultId: null
        }
      });
    }
  }, [
    projectToToggle,
    projectVaultToToggle,
    vaultObjectId,
    marketplaceId,
    sessionToken
  ]);

  // Favourite unit
  useEffect(() => {
    if (
      unitToToggle &&
      unitVaultToToggle &&
      unitProjectToToggle &&
      marketplaceId &&
      vaultObjectId &&
      sessionToken
    ) {
      const copyOfList: any = [...favouriteUnits];
      const unit = favouriteUnits.find(
        (x: any) => x.favoriteUnitId === unitToToggle
      );

      if (unit) {
        // Item is present in the array, remove it
        copyOfList.splice(favouriteUnits.indexOf(unit), 1);
        AuthStateDispatch({
          type: 'setFavouriteUnits',
          payload: copyOfList
        });

        Marketplace.deleteFavouriteUnit(unit.objectId, sessionToken);
      } else {
        // Item is not present in the array, add it

        // Add it locally first to show instant feedback
        copyOfList.push({ favoriteUnitId: unitToToggle });
        AuthStateDispatch({
          type: 'setFavouriteUnits',
          payload: copyOfList
        });

        const favouriteCreateParams = {
          marketplaceId,
          vaultId: vaultObjectId,
          favoriteVaultId: unitVaultToToggle,
          favoriteProjectId: unitProjectToToggle,
          favoriteUnitId: unitToToggle
        };

        Marketplace.createFavouriteUnit(
          favouriteCreateParams,
          sessionToken
        ).then((result: any) => {
          AuthStateDispatch({
            type: 'setFavouriteUnits',
            payload: [
              ...copyOfList.filter(
                (x: any) => x.favoriteUnitId !== unitToToggle
              ),
              result.data
            ]
          });
        });
      }

      AuthStateDispatch({
        type: 'toggleFavouriteUnit',
        payload: { unitId: null, vaultId: null, projectId: null }
      });
    } else if (
      unitToToggle &&
      marketplaceId &&
      unitVaultToToggle &&
      unitProjectToToggle
    ) {
      // If not authenticated, store in temp list

      const copyOfList: any = [...tempFavouriteUnits];
      const unit = tempFavouriteUnits.find(
        (x: any) => x.favoriteUnitId === unitToToggle
      );

      if (unit) {
        // Item is present in the array, remove it
        copyOfList.splice(tempFavouriteUnits.indexOf(unit), 1);
      } else {
        // Add it
        copyOfList.push({
          favoriteUnitId: unitToToggle,
          favoriteProjectId: unitProjectToToggle,
          favoriteVaultId: unitVaultToToggle
        });
      }

      AuthStateDispatch({
        type: 'setTempFavouriteUnits',
        payload: copyOfList
      });

      AuthStateDispatch({
        type: 'toggleFavouriteUnit',
        payload: {
          unitId: null,
          projectId: null,
          vaultId: null
        }
      });
    }
  }, [
    unitToToggle,
    unitVaultToToggle,
    unitProjectToToggle,
    vaultObjectId,
    marketplaceId,
    sessionToken
  ]);
};

export default useUserFavourites;
