import { useState } from 'react';

// Components
import { PlayThinIcon } from 'src/resources/icons';

// Helpers
import { computeCloudinaryThumbnail } from 'src/helpers/utils';
import { isMobileOnly } from 'react-device-detect';
import env from 'src/environment';
import { IContentItem } from '@/libs/prompto-api/src';

// Styling
import styled, { StyledProps } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
`;

const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const StyledPlayThinIcon = styled(PlayThinIcon)`
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const ThumbnailError = styled.p`
  z-index: 1;
  height: 15px;
  opacity: 0.75;
  font-size: 0.688rem;
  text-align: center;
  color: white;
  margin: 5px;
`;

const Thumbnail = styled.img<StyledProps<{ error: boolean }>>`
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  object-position: center;
  ${({ error }) =>
    error &&
    `
    filter: blur(10px);
    -moz-filter: blur(10px);
    transform: scale(1.1);
  `}
`;

const RATIO = 1.78; // standard 16x9 video ratio

interface VideoPreviewProps {
  data: IContentItem;
  size?: number;
  fallbackThumbnail: string;
  isVerticalLayout?: boolean;
  gridPreview?: boolean;
}

const VideoPreview = ({
  data,
  size,
  fallbackThumbnail,
  isVerticalLayout,
  gridPreview
}: VideoPreviewProps) => {
  const currentEnvironment = env();

  // calculate sizes
  let height = size ? size : '100%';
  let width = size ? (size * RATIO) : '100%';

  if (isVerticalLayout && size) {
    width = size;
    height = width / RATIO;
  }

  const [thumbnailError, setThumbnailError] = useState(false);
  const [thumbnailUri, setThumbnailUri] = useState(
    computeCloudinaryThumbnail(
      decodeURIComponent(data.contentUri),
      currentEnvironment.googleStorageBucketId,
      currentEnvironment.cloudinaryVideoBaseUrl
    )
  );

  const previewAttributes: any = {
    style: {
      objectFit: 'cover',
      objectPosition: 'center'
    }
  };
  if (isMobileOnly) {
    previewAttributes.style = {
      ...previewAttributes.style,
      height: '100%',
      width: '100%'
    };
  } else {
    previewAttributes.height = height;
    previewAttributes.width = width;
    previewAttributes.style = {
      ...previewAttributes.style,
      height: `${height}px`,
      width: `${width}px`
    };
  }

  return (
    <Wrapper style={gridPreview ? {} :previewAttributes.style}>
      <PreviewContainer>
        <StyledPlayThinIcon />
        {thumbnailError && <ThumbnailError>No preview provided</ThumbnailError>}
      </PreviewContainer>
      <Thumbnail
        error={thumbnailError}
        src={thumbnailUri}
        alt="thumbnail"
        onError={() => {
          setThumbnailError(true);
          setThumbnailUri(fallbackThumbnail);
        }}
        {...previewAttributes}
      />
    </Wrapper>
  );
};

export default VideoPreview;
