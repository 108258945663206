import styled, { StyledProps } from 'styled-components';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export { Wrapper };

const Wrapper = styled.div<StyledProps<{ isFullscreen?: boolean }>>`
  position: ${({ isFullscreen }) => (isFullscreen ? 'fixed' : 'absolute')};
  z-index: ${({ isFullscreen }) => (isFullscreen ? '50000' : '10')};
  top: 0;
  left: 0;
  right: 0;
  height: ${({ isFullscreen }) => (isFullscreen ? '100vh' : '100%')};
  width: ${({ isFullscreen }) => (isFullscreen ? '100vw' : '100%')};

  .react-transform-component {
    width: 100% !important;
    height: 100% !important;
  }

  .react-transform-element {
    width: fit-content !important;
    width: -moz-fit-content !important;
    height: fit-content !important;
    height: -moz-fit-content !important;
  }
`;

export const FirstImageWrapper = styled(motion.div)<
  StyledProps<{ width: number; height: number }>
>`
  position: absolute;
  width: ${(props) => `${props.width}px` || '100%'};
  height: ${(props) => `${props.height}px` || '100%'};
  top: ${(props) => `-${(props.height - window.innerHeight) / 2 + 40}px`};
  left: ${(props) => `-${(props.width - window.innerWidth) / 2}px`};
  display: flex;
`;

export const MediaViewWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;

export const UnderlayImage = styled.img`
  position: absolute;
  top: -15px;
  left: -15px;
  width: calc(100% + 30px);
  height: calc(100vh + 30px);
  object-fit: cover;
  object-position: center;
  filter: brightness(0.25) blur(3px);
`;

export const NavigationPrompt = styled(motion.div)`
  position: absolute;
  z-index: 10;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 96px;
  max-width: calc(100vw - 80px);
  overflow: hidden;
  min-height: ${isMobile ? 40 : 50}px;
  padding: ${isMobile ? 10 : 15}px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.gray40};
    margin-left: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
`;

export const PromptIcon = styled(FontAwesomeIcon)`
  margin: 0 8px;
`;
