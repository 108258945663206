import { forwardRef, useRef, memo } from 'react';

// Components
import { Pannellum } from 'pannellum-react';

import { IRotation } from './MediaView';

// Styling
import styled from 'styled-components';

const Viewer360Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

// Types
interface MediaView360Props {
  imageSrc: string;
  setViewer360Dimensions: (dimensions: any) => void;
  setViewer360Loaded: (flag: boolean) => void;
  setCurrentRotation: (rotation: IRotation) => void;
  children: any;
}

const MediaView360 = forwardRef((props: MediaView360Props, ref) => {
  const {
    imageSrc,
    setViewer360Dimensions,
    setViewer360Loaded,
    setCurrentRotation
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Viewer360Wrapper ref={wrapperRef}>
      <Pannellum
        ref={ref}
        width="100%"
        height="100%"
        image={imageSrc}
        pitch={10}
        yaw={180}
        hfov={110}
        autoLoad
        onLoad={() => {
          const timer = setTimeout(() => {
            clearTimeout(timer);
            setViewer360Loaded(true);
            const viewerDimensions =
              wrapperRef.current?.getBoundingClientRect();
            setViewer360Dimensions(viewerDimensions);
          }, 100);
        }}
        onRender={() => {
          // @ts-ignore
          if (ref && ref.current) {
            setCurrentRotation({
              // @ts-ignore
              pitch: ref.current.getViewer().getPitch(),
              // @ts-ignore
              yaw: ref.current.getViewer().getYaw(),
              // @ts-ignore
              hfov: ref.current.getViewer().getHfov()
            });
          }
        }}
        showControls={false}
      />
      {props.children}
    </Viewer360Wrapper>
  );
});

export default memo(MediaView360);
