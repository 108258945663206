import { motion } from 'framer-motion';
import { useState } from 'react';

// Components
import ThumbnailComponent from 'src/components/Thumbnail';

// Helpers
import localizer from 'src/localization/localizer';

// Styling
import styled, { css, StyledProps } from 'styled-components';

const Card = styled(motion.div)<
  StyledProps<{
    gridArea: string | number;
    showOverlay?: boolean;
  }>
>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  grid-area: ${({ gridArea }) => gridArea};
  cursor: pointer;
  transition: all 150ms ease;

  @media (max-width: 1024px) {
    border-radius: 8px;
  }
  ${({ showOverlay }) =>
    showOverlay
      ? css`
          &::after {
            position: absolute;
            content: '${localizer.imageCard.viewAll ?? 'View all'}';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0.8) 100%
            );
            z-index: 2;
            color: ${({ theme }) => theme.white};
            font-family: ${({ theme }) => theme.fonts.DMSans};
            font-size: 1.25rem;
            @media (max-width: 1024px) {
              border-radius: 8px;
              font-size: 0.75rem;
            }
          }
        `
      : ''}
`;

export interface ImageCardProps {
  itemUri: string;
  size: number;
  idx: number;
  className?: string;
  showOverlay?: boolean;
  onLoaded?: () => void;
}

const ImageCard = ({
  itemUri,
  size,
  idx,
  className,
  showOverlay,
  onLoaded
}: ImageCardProps) => {
  const [cardHovered, setCardHovered] = useState<boolean>(false);

  return (
    <Card
      key={itemUri}
      gridArea={`p-${idx}`}
      className={className || ''}
      showOverlay={showOverlay}
      onHoverStart={() => {
        setCardHovered(true);
      }}
      onHoverEnd={() => {
        setCardHovered(false);
      }}
    >
      <ThumbnailComponent
        uri={itemUri}
        height={Math.round(size)}
        hideAnimation
        noGradient
        hovered={cardHovered}
        onLoaded={onLoaded}
      />
    </Card>
  );
};

export default ImageCard;
