import { useState, useRef, useEffect, useCallback } from 'react';

// Helpers
import { AnimatePresence, motion } from 'framer-motion';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';

// Styling
import styled, { StyledProps } from 'styled-components';

const Wrapper = styled(motion.div)`
  position: relative;
`;

const Menu = styled(motion.div)<StyledProps<{ fixedHeight: boolean }>>`
  position: absolute;
  z-index: 100;
  top: 56px;
  left: 0;
  width: 320px;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.white};
  max-height: ${({ fixedHeight }) => (fixedHeight ? '320px' : 'auto')};
  overflow: hidden;
  overflow-y: auto;
`;

interface DropDownProps {
  initiallyOpened?: boolean;
  renderButton: (props: {
    onClick: () => void;
    focused: boolean;
    onToggleMenu: () => void;
  }) => JSX.Element;
  renderDropDownContent: () => JSX.Element;
  fixedHeight?: boolean;
}

const DropDown = ({
  initiallyOpened = false,
  renderButton: RenderButton,
  renderDropDownContent: RenderDropDownContent,
  fixedHeight
}: DropDownProps) => {
  const { ProjectsFiltersDispatch } = useProjectsFiltersState();

  const [showMenu, setShowMenu] = useState(initiallyOpened);
  const [hovered, setHovered] = useState(initiallyOpened);
  const [mounted, setMounted] = useState(false);

  const hoverTimer = useRef<any>();
  const wrapperRef = useRef<any>();

  useEffect(() => {
    if (initiallyOpened) {
      setShowMenu(true);
      ProjectsFiltersDispatch({ type: 'onClearFocus' });
    }
  }, [initiallyOpened]);

  useEffect(() => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      setMounted(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (!hovered) {
      hoverTimer.current = setTimeout(() => {
        setShowMenu(false);
      }, 500);
    } else {
      clearTimeout(hoverTimer.current);
    }
  }, [hovered]);

  const checkOutsideClick = useCallback(
    (event: any) => {
      if (!mounted) return;
      if (showMenu && !wrapperRef.current?.contains(event.target)) {
        setShowMenu(false);
      }
    },
    [wrapperRef, showMenu, mounted]
  );

  useEffect(() => {
    window.addEventListener('click', checkOutsideClick);
    return () => window.removeEventListener('click', checkOutsideClick);
  }, [checkOutsideClick]);

  const onClickButton = useCallback(
    () => setShowMenu((prev: boolean) => !prev),
    []
  );

  return (
    <Wrapper
      ref={wrapperRef}
      onHoverStart={() => {
        setHovered(true);
      }}
      onHoverEnd={() => {
        setHovered(false);
      }}
    >
      <RenderButton
        onClick={onClickButton}
        focused={showMenu}
        onToggleMenu={() => setShowMenu((prev: boolean) => !prev)}
      />

      <AnimatePresence>
        {showMenu && (
          <Menu {...transitions} fixedHeight={!!fixedHeight}>
            <RenderDropDownContent />
          </Menu>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

const transitions = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
  transition: { duration: 0.12 }
};

export default DropDown;
