import { number, bool, shape } from 'prop-types';

// Components
import { GridviewIcon } from 'src/resources/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { Variants, motion } from 'framer-motion';

// Styling
import styled, { css } from 'styled-components';
import { useEffect } from 'react';
import { forBiggerThan } from 'src/helpers/ui';

const Button = styled.button`
  align-items: center;
  backdrop-filter: blur(3px);
  border: none;
  background-color: ${(props) => props.theme.fadeLightGray};
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  justify-content: center;
  outline: none;
  cursor: pointer;
  z-index: 15;
  margin-right: 3px;
  border-radius: 6px;
  min-width: 48px;
  width: 48px;
  height: 48px;

  ${forBiggerThan.mobile`
    border-radius: 8px;
    height: 64px;
    width: 64px;
  `}
`;

const StyledCloseIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`;

const innerButtonStyle = css`
  position: fixed;
  bottom: 60px;
  height: fit-content;

  ${forBiggerThan.mobile`
    top: 50%;
    transform: translateY(-50%);
  `}
  > ${Button} {
    width: 48px;
    min-width: 48px;
    height: 48px;

    ${forBiggerThan.mobile`
      height: 64px;
      min-width: 64px;
    `}
  }
`;

const PrevButtonWrapper = styled(motion.div)`
  left: 16px;
  ${innerButtonStyle}
`;

const NextButtonWrapper = styled(motion.div)`
  right: 16px;
  ${innerButtonStyle}
`;

const CloseButtonWrapper = styled(motion.div)`
  position: fixed;
  top: 16px;
  right: 16px;

  > ${Button} {
    width: 40px;
    min-width: 40px;
    height: 40px;

    ${forBiggerThan.mobile`
      height: 48px;
      min-width: 48px;
    `}
  }
`;

const Navigator = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
`;

Navigation.propTypes = {
  activePageIndex: number,
  pageLength: number,
  showGrid: bool,
  showUI: bool,
  centerButtonContent: shape({})
};

export interface NavigationProps {
  activePage?: number;
  onGridView?: () => void;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  pageLength?: number;
  showGrid?: boolean;
  showUI?: boolean;
  centerButtonContent?: any;
}

function Navigation({
  activePage = 0,
  onGridView,
  onNextPage,
  onPreviousPage,
  pageLength = 0,
  showGrid,
  showUI,
  centerButtonContent
}: NavigationProps) {
  // UI
  const uiMotionState = showUI && !showGrid;
  const showPrevious = activePage > 0;
  const showNext = activePage < pageLength - 1;

  let nextButtonState = showNext ? 'visible' : 'disabled';
  let previousButtonState = showPrevious ? 'visible' : 'disabled';
  let gridViewButtonState = 'visible';

  if (!uiMotionState) {
    nextButtonState = 'hidden';
    previousButtonState = 'hidden';
    gridViewButtonState = 'hidden';
  }

  let centerButton = <GridviewIcon />;
  if (centerButtonContent) {
    centerButton = centerButtonContent;
  }

  useEffect(() => {
    if (onPreviousPage && onNextPage) {
      const handleKeyDown = (event: any) => {
        if (event.key === 'ArrowLeft') {
          onPreviousPage && onPreviousPage();
        } else if (event.key === 'ArrowRight') {
          onNextPage && onNextPage();
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [onPreviousPage, onNextPage]);

  return (
    <Navigator>
      <PrevButtonWrapper
        key="prevButton"
        initial={'hidden'}
        animate={previousButtonState}
        variants={buttonVariants}
      >
        <Button
          onClick={() => {
            onPreviousPage && onPreviousPage();
          }}
        >
          <FontAwesomeIcon icon={['fal', 'long-arrow-left']} size="sm" />
        </Button>
      </PrevButtonWrapper>
      <CloseButtonWrapper
        key="gridButton"
        initial={'hidden'}
        animate={gridViewButtonState}
        variants={buttonVariants}
      >
        <Button
          onClick={() => {
            onGridView && onGridView();
          }}
        >
          <StyledCloseIcon icon={['fal', 'times']} size="sm" />
        </Button>
      </CloseButtonWrapper>
      <NextButtonWrapper
        key="nextButton"
        initial={'hidden'}
        animate={nextButtonState}
        variants={buttonVariants}
      >
        <Button
          onClick={() => {
            onNextPage && onNextPage();
          }}
        >
          <FontAwesomeIcon icon={['fal', 'long-arrow-right']} size="sm" />
        </Button>
      </NextButtonWrapper>
    </Navigator>
  );
}

export default Navigation;

// Motion
const buttonVariants: Variants = {
  visible: {
    opacity: 1,
    display: 'block',
    pointerEvents: 'auto'
  },
  disabled: {
    opacity: 0.5,
    display: 'block',
    pointerEvents: 'none'
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    transitionEnd: {
      display: 'none'
    }
  }
};
