import { ReactNode } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noThumbnailMarker from 'src/resources/images/unit-preview-placeholder.png';
import ThumbnailComponent from 'src/components/Thumbnail';

// Helpers
import { getValueFromLocalized } from 'src/localization/localizer';
import { buildAddress } from 'src/helpers/vmProjectSection';
import { IProject } from '@/libs/prompto-api/src';
import env from 'src/environment';
import { useNavigate } from 'react-router-dom';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 106px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 1px;
  padding: 5px 16px 5px;
  background-color: transparent;
  box-sizing: border-box;

  &:hover {
    background-color: ${({ theme }) => theme.gray100};
  }
`;

const ProjectTitle = styled.div`
  font-weight: 600;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
  margin-left: 20px;
`;

const ThumbnailWrapper = styled.div`
  width: 96px;
  height: 96px;
  position: relative;
  flex-shrink: 0;
`;

const AddressField = styled.div`
  font-size: 0.875rem;
  display: flex;
  margin: 5px 0;
`;
const ProjectAddressWrapper = styled.div`
  display: flex;
  flex-flox: row;
  align-items: center;
  cursor: pointer;
`;

const AddressIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  margin-right: 6px;
  color: ${({ theme }) => theme.gray50};
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  margin: 0 6px 0 auto;
  color: ${({ theme }) => theme.gray50};
`;

interface ProjectCardProps {
  project: IProject | any;
  setSearchValue: (value: string) => void;
  setIsMenuOpened: (value: boolean) => void;
  mobileView?: boolean;
}

const ProjectCard = ({
  project,
  setSearchValue,
  setIsMenuOpened,
  mobileView
}: ProjectCardProps) => {
  const navigate = useNavigate();
  const projectTitle = getValueFromLocalized(
    project?.localizedTitle,
    project?.title ?? ''
  );
  const formattedAddress = buildAddress(project?.address);

  const arrowIcon = mobileView && (
    <ArrowIcon icon={['far', 'chevron-right']} size="sm" />
  );

  return (
    <Card
      key={project.projectId}
      onClick={() => {
        setSearchValue('');
        setIsMenuOpened(false);
        setTimeout(() => {
          navigate(
            `/vault/${project.vaultId}/project/${project.projectId}${window.location.search}`
          );
        }, 300);
      }}
      id={`project-from-search_${project.projectId}`}
    >
      <ThumbnailWrapper>
        <ThumbnailComponent
          uri={
            project.thumbnailUri ??
            project.projectThumbnailUri ??
            noThumbnailMarker
          }
          height={96}
        />
      </ThumbnailWrapper>

      <SubtitleWrapper>
        <ProjectTitle>{projectTitle}</ProjectTitle>
        <ProjectAddressWrapper>
          <AddressIcon icon={['fas', 'map-marker-alt']} size="sm" />
          <AddressField>{formattedAddress}</AddressField>
        </ProjectAddressWrapper>
      </SubtitleWrapper>
      {arrowIcon as ReactNode}
    </Card>
  );
};

export default ProjectCard;
