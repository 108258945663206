import { forwardRef, useState } from 'react';

// Components
import Loader from 'src/components/Loader';
import NoThumbnailMarker from 'src/resources/images/unit-preview-placeholder.png';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import env from 'src/environment';

// Types
import { IMapProject } from '../ProjectsPage';

// Styling
import styled, { keyframes } from 'styled-components';

const markerSize = 50;

const wave = keyframes`
  0% {
    width: 100%;
    height: 100%;
  }
  50% {
    width: 200%;
    height: 200%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
`;

const Marker = styled(motion.div)`
  position: absolute;
  top: -25px;
  left: -50%;
  z-index: 100;
  width: ${markerSize}px;
  height: ${markerSize}px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  transition: all 150ms ease;
  transform-origin: center;
  cursor: pointer;
  &::before,
  &::after {
    position: absolute;
    z-index: 0;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.gold};
    opacity: 0.3;
  }
  &::before {
    animation: ${wave} 3s linear infinite;
  }
  &::after {
    animation: ${wave} 4s linear 1s infinite;
  }
`;

const MarkerBody = styled.div`
  position absolute;
  z-index: 2;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Thumbnail = styled.img`
  postion: absolute;
  width: ${markerSize - 4}px;
  height: ${markerSize - 4}px;
  border: 2px solid ${({ theme }) => theme.white};
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
  z-index: 2;
`;

const StyledLoader = styled(Loader)`
  margin: 0;
  flex-grow: 0;
`;

const LoaderWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface HighlightedMarkerProps {
  project: IMapProject | null;
  onHovered: (project: IMapProject | null) => void;
  onClick: (project: IMapProject) => void;
}

const HighlightedMarker = forwardRef(
  ({ project, onHovered, onClick }: HighlightedMarkerProps, ref: any) => {
    const [loaded, setLoaded] = useState(false);

    // bring the highlighted marker in front of all others
    if (ref?.current) {
      ref.current.parentNode.parentNode.style.zIndex = 1000;
    }

    const imageUrl = project?.projectThumbnailUri
      ? `${env().baseImageUrl}/h=50/${project?.projectThumbnailUri}`
      : NoThumbnailMarker;

    return (
      <Marker
        ref={ref}
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: loaded ? 1 : 0 }}
        exit={{ opacity: 0, scale: 1 }}
        onMouseLeave={() => onHovered(null)}
        onClick={() => {
          if (project) onClick(project);
        }}
      >
        <MarkerBody>
          <AnimatePresence>
            {!loaded && (
              <LoaderWrapper
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <StyledLoader />
              </LoaderWrapper>
            )}
          </AnimatePresence>
          <Thumbnail
            src={imageUrl}
            alt={`project thumbnail`}
            onLoad={() => setLoaded(true)}
          />
        </MarkerBody>
      </Marker>
    );
  }
);

export default HighlightedMarker;
