// Helpers
import useGetUnitImagePreview from 'src/hooks/use-get-unit-image-preview';

// Styling
import styled, { StyledProps } from 'styled-components';

const Wrapper = styled.div<StyledProps<{ height: number }>>`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  width: ${({ height }) => height}px;
  height: ${({ height }) => height}px;
  background: ${({ theme }) => theme.gray5};
  grid-area: img;
  flex-shrink: 0;
`;

const PreviewImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;
`;

const UnitPreview = ({ unit, height = 80 }: { unit: any; height?: number }) => {
  const { previewImageUrl } = useGetUnitImagePreview(unit, null, `h=${height}`);

  return (
    <Wrapper height={height}>
      {previewImageUrl ? <PreviewImage src={previewImageUrl} /> : null}
    </Wrapper>
  );
};

export default UnitPreview;
