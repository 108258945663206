import { useEffect, useState } from 'react';

import { useAuthState } from 'src/store/AuthStore';
import { useNavigate } from 'react-router-dom';
import { useAppState } from 'src/store/AppStore';
import { useProjectsState } from 'src/store/ProjectsStore';

// Resources
import MainBackground from 'src/resources/images/account_page.png';

// Components
import DropZoneComponent from 'src/components/upload/DropZoneComponent';

// Styling
import styled from 'styled-components';
import { fetchSettingsFromURL, readAsDataUrl } from 'src/helpers/utils';
import ProfileHeader from './components/ProfileHeader';
import TabSwitcher from './components/TabSwitcher';
import MainInfoBlock from './components/MainInfoBlock';
import { Marketplace, Vault } from '@/libs/prompto-api/src';
import SearchPreferences from './components/SearchPreferences';
import FavouritesBlock from './components/FavouritesBlock';
import { forBiggerThan } from 'src/helpers/ui';
import CreateAccountBlock from '../landingPage/CreateAccountBlock';

const Wrapper = styled.div`
  margin-top: 72px;
  width: 100%;
  height: calc(100vh - 72px);
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.95);
`;

const ContentWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  margin: 12px;
  margin-top: 0;
  padding-left: 16px;
  padding-right: 16px;

  ${forBiggerThan.tablet`
    padding-left: 216px;
  padding-right: 216px;
`}
`;

const HorizontalWrapper = styled(ContentWrapper)`
  margin-top: 48px;

  ${forBiggerThan.tablet`
   flex-flow: row;
`}
`;

const MainContentWrapper = styled.div`
  height: 100%;
  padding-right: 12px;
`;

const Bar = styled.div`
  height: 20px;
  background-color: ${({ theme }) => theme.beigeBg10};
`;

const AccountPage = () => {
  const [activeTab, setActiveTab] = useState<string>('profile');

  const { ProjectsState, ProjectsStateDispatch } = useProjectsState();
  const { allProjects } = ProjectsState;

  const { AuthState, AuthStateDispatch } = useAuthState();
  const {
    user,
    vault,
    vaultObjectId,
    sessionToken,
    cookiesValidated,
    tempFavouriteProjects,
    tempFavouriteUnits
  } = AuthState;

  const { AppState } = useAppState();
  const { marketplaceId } = AppState;

  const navigate = useNavigate();

  const { tab } = fetchSettingsFromURL();

  useEffect(() => {
    if (tab === 'favourites') {
      setActiveTab('favourites');
      let url = new URL(window.location.href);
      let searchParams = url.searchParams;
      searchParams.delete('tab');
      url.search = searchParams.toString();
      window.history.replaceState({}, '', url);
    }
  }, [tab]);

  useEffect(() => {
    if (!user && cookiesValidated) {
      navigate(`/login${window.location.search}`);
    }
  }, [user, cookiesValidated]);

  useEffect(() => {
    if (vaultObjectId && !vault) {
      // Get the loaded vault
      Vault.getInfo(vaultObjectId, sessionToken, {}).then((result) => {
        const { data } = result;

        if (data?.vault) {
          AuthStateDispatch({
            type: 'setVault',
            payload: data.vault
          });
        }
      });
    }
  }, [vaultObjectId, vault, sessionToken]);

  // Convert temp local favourites into backend
  useEffect(() => {
    if (
      sessionToken &&
      vaultObjectId &&
      (tempFavouriteProjects?.length > 0 || tempFavouriteUnits?.length > 0)
    ) {
      tempFavouriteProjects.forEach((favProj: any) => {
        const favouriteCreateParams = {
          marketplaceId,
          vaultId: vaultObjectId,
          favoriteVaultId: favProj.favoriteVaultId,
          favoriteProjectId: favProj.favoriteProjectId
        };

        Marketplace.createFavouriteProject(favouriteCreateParams, sessionToken);
      });

      tempFavouriteUnits?.forEach((favUnit: any) => {
        const favouriteCreateParams = {
          marketplaceId,
          vaultId: vaultObjectId,
          favoriteVaultId: favUnit.favoriteVaultId,
          favoriteProjectId: favUnit.favoriteProjectId,
          favoriteUnitId: favUnit.favoriteUnitId
        };

        Marketplace.createFavouriteUnit(favouriteCreateParams, sessionToken);
      });

      setTimeout(() => {
        Marketplace.getAllMarketplaceProjectFavourites(
          vaultObjectId,
          marketplaceId,
          sessionToken
        ).then((result: any) => {
          if (result.data) {
            AuthStateDispatch({
              type: 'setTempFavouriteProjects',
              payload: []
            });

            AuthStateDispatch({
              type: 'setFavouriteProjects',
              payload: result.data.projectFavoriteList
            });
          }
        });

        Marketplace.getAllMarketplaceUnitFavourites(
          vaultObjectId,
          marketplaceId,
          sessionToken
        ).then((result: any) => {
          if (result.data) {
            AuthStateDispatch({
              type: 'setTempFavouriteUnits',
              payload: []
            });

            AuthStateDispatch({
              type: 'setFavouriteUnits',
              payload: result.data.unitFavoriteList
            });
          }
        });
      }, 500);
    }
  }, [tempFavouriteProjects, tempFavouriteUnits, sessionToken, vaultObjectId]);

  const profileContent = (
    <>
      <MainInfoBlock />
      <SearchPreferences />
    </>
  );

  const favouritesContent = (
    <>
      <FavouritesBlock />
    </>
  );

  if (!user)
    return (
      <Wrapper>
        <Bar />
        <HorizontalWrapper>
          <FavouritesBlock />
          <CreateAccountBlock />
        </HorizontalWrapper>
      </Wrapper>
    );

  return (
    <Wrapper>
      <Bar />
      <ContentWrapper>
        <ProfileHeader />
        <TabSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
        <MainContentWrapper>
          {activeTab === 'profile' && profileContent}
          {activeTab === 'favourites' && favouritesContent}
        </MainContentWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default AccountPage;
