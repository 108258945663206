import { useReducer, createContext, useContext } from 'react';

import Cookies from 'universal-cookie';
import { Marketplace } from '@prompto-api';

export const AuthStoreContext = createContext({
  authenticated: false,
  sessionToken: '',
  user: null,
  vaultObjectId: '',
  vault: null,
  cookiesValidate: false
});

const AuthStore = (props) => {
  const [AuthState, AuthStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'login':
          // Set cookies
          const cookies = new Cookies();

          const cookieParams = {
            maxAge: 86400, // 24 hours
            sameSite: 'none',
            secure: true
          };

          cookies.set(
            `mp_sessionToken`,
            action.payload.sessionToken,
            cookieParams
          );

          cookies.set(`mp_user`, action.payload.user, cookieParams);

          cookies.set(
            `mp_vaultObjectId`,
            action.payload.vaultObjectId,
            cookieParams
          );

          return {
            ...state,
            user: action.payload.user,
            sessionToken: action.payload.sessionToken,
            vaultObjectId: action.payload.vaultObjectId,
            cookiesValidated: true
          };

        case 'logout': {
          const cookies = new Cookies();
          cookies.remove(`mp_sessionToken`);
          cookies.remove(`mp_user`);
          cookies.remove(`mp_vaultObjectId`);
          return {
            authenticated: false,
            sessionToken: '',
            user: null,
            vaultObjectId: '',
            vault: null,
            cookiesValidate: false,
            favouriteProjects: [],
            favouriteUnits: [],
            tempFavouriteProjects: [],
            tempFavouriteUnits: []
          };
        }
        case 'setUser':
          return {
            ...state,
            user: action.payload
          };
        case 'setVault':
          return {
            ...state,
            vault: action.payload
          };
        case 'setFavouriteProjects': {
          return {
            ...state,
            favouriteProjects: action.payload
          };
        }
        case 'setTempFavouriteProjects': {
          return {
            ...state,
            tempFavouriteProjects: action.payload
          };
        }
        case 'setTempFavouriteUnits': {
          return {
            ...state,
            tempFavouriteUnits: action.payload
          };
        }
        case 'setFavouriteUnits': {
          return {
            ...state,
            favouriteUnits: action.payload
          };
        }
        case 'toggleFavouriteProject': {
          return {
            ...state,
            projectToToggle: action.payload.projectId,
            projectVaultToToggle: action.payload.vaultId
          };
        }
        case 'toggleFavouriteUnit': {
          return {
            ...state,
            unitToToggle: action.payload.unitId,
            unitVaultToToggle: action.payload.vaultId,
            unitProjectToToggle: action.payload.projectId
          };
        }
        default:
          return state;
      }
    },
    {
      authenticated: false,
      sessionToken: '',
      user: null,
      vaultObjectId: '',
      vault: null,
      cookiesValidate: false,
      favouriteProjects: [],
      projectToToggle: '',
      projectVaultToToggle: '',
      favouriteUnits: [],
      unitToToggle: '',
      unitVaultToToggle: '',
      tempFavouriteProjects: [],
      tempFavouriteUnits: []
    }
  );

  return (
    <AuthStoreContext.Provider value={{ AuthState, AuthStateDispatch }}>
      {props.children}
    </AuthStoreContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStoreContext);

export default AuthStore;
