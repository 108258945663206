import { useNavigate } from 'react-router-dom';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThumbnailComponent from 'src/components/Thumbnail';
import PromptoLogo from 'src/resources/images/prompto-logo-white.png';

// Helpers
import { motion } from 'framer-motion';
import { buildAddress } from 'src/helpers/vmProjectSection';
import env from 'src/environment';
import { isMobileOnly } from 'react-device-detect';
import { getValueFromLocalized } from 'src/localization/localizer';

// Styling
import styled, { css } from 'styled-components';

const Wrapper = styled(motion.div)`
  position: absolute;
  z-index: 100;
  font-family: ${({ theme }) => theme.fonts.DMSans};

  ${isMobileOnly
    ? css`
        position: fixed;
        bottom: 16px;
        left: 24px;
        transform-origin: bottom;
      `
    : css`
        top: 32px;
        left: 32px;
        transform-origin: top left;
      `}
`;

const CardWrapper = styled.div`
  width: ${isMobileOnly ? 'calc(100vw - 48px)' : '320px'};
  min-height: 320px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.white};
  border-radius: 12px;
  overflow: hidden;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  z-index: 5;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.gray5};
  border: none;
  backdrop-filter: blur(10px);
  cursor: pointer;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.black};
`;

const ProjectData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const LogoWrapper = styled.img`
  max-width: 100px;
  max-height: 50px;
  object-fit: contain;
`;

const TitleAndAdressWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const Logo = styled.img`
  position: absolute;
  top: 16px;
  left: 16px;
  width: auto;
  height: 45px;
`;

const LocationIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75rem;
  color: ${({ theme }) => theme.gray50};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: 1.25rem;
  line-height: 26px;
  margin: 0 0 5px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
`;

const Address = styled(Title)`
  color: ${({ theme }) => theme.black};
  font-size: 0.875rem;
  line-height: 18px;
  margin: 0;
  padding-left: 16px;
  position: relative;
  flex-shrink: 0;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 240px;
  position: relative;
`;

const PillWrapper = styled.div`
  display: flex;
  flex-flow: row;
  position: absolute;
  right: 16px;
  top: calc(100% - 45px);
  z-index: 5;
  column-gap: 8px;
`;

const TotalUnitsPill = styled.div`
  height: 32px;
  padding: 0px 8px;
  border-radius: 18px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-right: 16px;
`;

const TotalUnitsIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const SelectedProjectCard = ({
  project,
  onCloseCard,
  allVaultLogos,
  unitCount
}: any) => {
  const projectTitle = getValueFromLocalized(
    project?.localizedTitle,
    project?.title ?? ''
  );

  const navigate = useNavigate();

  const onCardClicked = () => {
    if (!project) return;

    navigate(
      `/vault/${project.vaultId}/project/${project.projectId}${window.location.search}`
    );
  };

  const logo = allVaultLogos ? allVaultLogos[project.vaultId] : '';
  const vaultLogoForProject = logo
    ? `${env().baseImageUrl}/q=100:w=${120}/${logo}`
    : '';

  return (
    <Wrapper {...transitions} transition={{ ease: 'linear', duration: 0.12 }}>
      <CloseButton onClick={onCloseCard}>
        <CloseIcon icon={['far', 'times']} size="1x" />
      </CloseButton>
      <CardWrapper onClick={onCardClicked}>
        <ImageContainer>
          <ThumbnailComponent
            key={project.thumbnailUri}
            uri={project.thumbnailUri ?? project.projectThumbnailUri}
            height={320}
          />
          <Logo src={PromptoLogo} alt="Company logo" />
          {/* <FavoriteToggle>
            <FavoriteIcon icon={['fal', 'heart']} size="lg" />
          </FavoriteToggle> */}
          <PillWrapper>
            {!!unitCount && (
              <TotalUnitsPill>
                <TotalUnitsIcon icon={['far', 'building']} size="sm" />
                {unitCount}
              </TotalUnitsPill>
            )}
          </PillWrapper>
        </ImageContainer>

        <ProjectData>
          <TitleAndAdressWrapper>
            <Title>{projectTitle}</Title>
            <Address>
              <LocationIcon icon={['fas', 'map-marker-alt']} size="1x" />
              {buildAddress(project?.address)}
            </Address>
          </TitleAndAdressWrapper>
          {vaultLogoForProject && <LogoWrapper src={vaultLogoForProject} />}
        </ProjectData>
      </CardWrapper>
    </Wrapper>
  );
};

const transitions = {
  initial: { opacity: 0, x: -22, y: -22, scale: 0.5 },
  animate: { opacity: 1, x: 0, y: 0, scale: 1 },
  exit: { opacity: 0, x: -22, y: -22, scale: 0.5 }
};

export default SelectedProjectCard;
