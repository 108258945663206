function OutOfViewportBottomIcon(props: any) {
  return (
    <svg width={40} height={40} {...props}>
      <path
        d="m23.18 2.5-6.758.001c-3.766.025-5.342.406-7.07 1.276a8.592 8.592 0 0 0-3.575 3.575C4.932 8.932 4.5 10.503 4.5 14.819l.001 6.759c.025 3.766.406 5.342 1.276 7.07a8.592 8.592 0 0 0 3.575 3.575c1.559.834 3.11 1.265 7.55 1.277L20 37.22l3.101-3.72c4.115.005 5.757-.375 7.547-1.277a8.592 8.592 0 0 0 3.575-3.575c.792-1.48 1.221-2.953 1.272-6.694l.005-.777V14.82c0-4.053-.382-5.69-1.277-7.467a8.592 8.592 0 0 0-3.575-3.575c-1.58-.845-3.151-1.277-7.467-1.277z"
        fill="#C3C2CA"
        stroke="#FFF"
        fillRule="evenodd"
        fillOpacity=".8"
        strokeOpacity=".3"
      />
    </svg>
  );
}

export default OutOfViewportBottomIcon;
