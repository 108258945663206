import { useAuthState } from 'src/store/AuthStore';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfilePicture from 'src/components/ProfilePicture';
import localizer from 'src/localization/localizer';

// Styling
import styled from 'styled-components';
import { useState } from 'react';
import EditProfileModal from './EditProfileModal';
import { isMobile } from 'react-device-detect';
import { forBiggerThan } from 'src/helpers/ui';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  margin-top: 24px;
  margin-bottom: 24px;
  align-items: center;
`;

const MainInfo = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 32px;
  justify-content: center;
`;

const Name = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  margin-bottom: 8px;

  ${forBiggerThan.tablet`
 font-size: 2.75rem;
`}
`;

const Address = styled.div`
  display: flex;
  flex-flow: row;
`;

const EditProfileButton = styled.div`
  display: none;
  padding: 12px 48px;
  border-radius: 8px;
  background: ${({ theme }) => theme.beigeBg20};
  height: fit-content;
  margin-left: 162px;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  user-select: none;
  cursor: pointer;
  min-width: fit-content;

  ${forBiggerThan.tablet`
  display: flex;

`}
`;

const AddressIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${({ theme }) => theme.gray60};
  margin-right: 12px;
`;

const PencilIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${({ theme }) => theme.black};
  margin-right: 8px;
`;

const ProfileHeader = () => {
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] =
    useState<boolean>(false);

  const { AuthState } = useAuthState();
  const { user, vault } = AuthState;

  const name = `${user.firstName} ${user.lastName}`;

  const billingAddress = vault?.billingAddress;
  let addressString,
    city,
    zipCode = null;

  if (billingAddress?.zipCode || billingAddress?.city) {
    zipCode = billingAddress?.zipCode;
    city = billingAddress?.city;
    addressString = `${zipCode}${zipCode && city ? ', ' : ''}${city}`;
  }

  return (
    <Wrapper>
      <ProfilePicture size={isMobile ? 100 : 120} />

      <MainInfo>
        <Name>{name}</Name>
        {(zipCode || city) && (
          <Address>
            <AddressIcon icon={['fas', 'map-marker-alt']} size="sm" />
            {addressString}
          </Address>
        )}
      </MainInfo>

      <EditProfileButton
        onClick={() => {
          setIsEditProfileModalOpen(true);
        }}
      >
        <PencilIcon icon={['far', 'pencil']} size="sm" />
        {localizer.accountPage.editProfile}
      </EditProfileButton>

      {isEditProfileModalOpen && (
        <EditProfileModal
          onClose={() => {
            setIsEditProfileModalOpen(false);
          }}
        />
      )}
    </Wrapper>
  );
};

export default ProfileHeader;
