import { useState, memo, useEffect, useRef } from 'react';

// Components
import { GoogleMap, OverlayView, Circle } from '@react-google-maps/api';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markers from './Markers';
import SelectedProjectCard from './SelectedProjectCard';
import HighlightedMarker from './HighlightedMarker';

// Helpers
import { isMobileOnly } from 'react-device-detect';
import uniqid from 'uniqid';
import { buildAddress } from 'src/helpers/vmProjectSection';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';
import { useProjectsMapState } from 'src/store/ProjectsMapStore';
import { useAppState } from 'src/store/AppStore';
import isEqual from 'lodash.isequal';
import screenfull from 'screenfull';

// Types
import { IMapProject } from '../ProjectsPage';

// Styling
import { activeMapStyling, disabledMapStyling } from './marketplaceMapStyling';
import styled, { StyledProps } from 'styled-components';
import { IProject } from '@/libs/prompto-api/src';

const Wrapper = styled(motion.div)<
  StyledProps<{ expanded: boolean; isFullscreen: boolean }>
>`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.beightBg10};
  border-radius: ${({ isFullscreen }) => (isFullscreen ? 0 : 18)}px;
  border: 1px solid ${({ theme }) => theme.gray20};
  overflow: hidden;
  transition: all 450ms ease;
  cursor: pointer;

  img[src$='#custom-marker-image'],
  img[src$='#custom-marker-image_inactive'],
  img[src$='#custom-marker-image_mobile'],
  img[src$='#custom-marker-image_inactive_mobile'] {
    width: 46px !important;
    height: 46px !important;
    border: 2px solid ${({ theme }) => theme.white} !important;
    border-radius: 30px;
    object-position: center;
    object-fit: cover;
  }

  img[src$='#custom-marker-image_mobile'],
  img[src$='#custom-marker-image_inactive_mobile'] {
    width: 36px !important;
    height: 36px !important;
    border-radius: 20px;
  }

  img[src$='#custom-marker-image_inactive'],
  img[src$='#custom-marker-image_inactive_mobile'] {
    filter: grayscale(1);
  }

  .marketplaceMapCluster,
  .marketplaceMapCluster_mobile {
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 20px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 150ms ease;
    &:hover {
      box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.2), 0 0 25px 0 rgba(0, 0, 0, 0.3);
    }
    div {
      top: 0 !important;
      left: 0 !important;
      background-color: ${({ theme }) => theme.clusterBgColor} !important;
      color: ${({ theme }) => theme.clusterTextColor} !important;
      width: 100% !important;
      height: 100% !important;
      border-radius: 50%;
      border: 2px solid ${({ theme }) => theme.white};
      line-height: 42px !important;
      font-size: 1.25rem !important;
      font-family: ${({ theme }) => theme.fonts.DMSans};
      font-style: italic !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      display: none;
    }
  }

  .marketplaceMapCluster_mobile {
    div {
      line-height: 32px;
    }
  }
`;

const MapWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  transform: scale(1);
  transition: all 150ms linear;
`;

const ExpandMapBlock = styled(motion.div)`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;

  &:hover {
    + ${MapWrapper} {
      transform: scale(1.1);
    }
  }
`;

const ToggleMapButton = styled.button`
  position: relative;
  width: 272px;
  height: 60px;
  padding: 10px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin: auto;
  overflow: hidden;
`;

const ToggleButtonIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.white};
  margin-right: 10px;
`;

const StyledOverlayView = styled(OverlayView)`
  & > div {
    z-index: 1000;
  }
`;

const FullscreenButton = styled(motion.button)<
  StyledProps<{ isFullscreen: boolean }>
>`
  position: fixed;
  right: 16px;
  bottom: ${({ isFullscreen }) => (isFullscreen || !isMobileOnly ? 16 : 74)}px;
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 7;
  top: 10px;
  right: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
`;

const FullscreenButtonIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
`;

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 7;
  top: 60px;
  right: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  margin: auto;
  color: ${({ theme }) => theme.black};
`;

const minZoomLevel = 2;
const defaultZoomLevel = 7;

const mapTypes = ['roadmap', 'satellite'];

const getProjectCoords = (project: IProject, geocoder: any) => {
  let coords;
  const addressObject = project?.address;

  if (addressObject) {
    if (addressObject.latitude && addressObject.longitude) {
      const latLong = {
        lat: parseFloat(addressObject.latitude),
        lng: parseFloat(addressObject.longitude)
      };

      coords = latLong;
    } else {
      const addressString = buildAddress(addressObject);
      geocoder.geocode({ address: addressString }, (results: any[]) => {
        if (results && results.length > 0) {
          const location = results[0].geometry.location;
          const newLatLong = {
            lat: location.lat(),
            lng: location.lng()
          };
          coords = newLatLong;
        }
      });
    }
  }
  return coords;
};

interface ProjectsMapProps {
  mapInstance: any;
  expandMap: boolean;
  fullList: IProject[];
  toggleButtonTitle: string;
  selectedProject: IMapProject | null;
  projectsForMap: IMapProject[];
  hoveredProjectId: string;
  projectsVisibleInMap: string[];
  allVaultLogos: any;
  projectsUnitCount: any;
}

const ProjectsMap = memo(
  ({
    mapInstance,
    expandMap,
    fullList,
    toggleButtonTitle,
    selectedProject,
    projectsForMap,
    hoveredProjectId,
    projectsVisibleInMap,
    allVaultLogos,
    projectsUnitCount
  }: ProjectsMapProps) => {
    const [loaderId] = useState(uniqid('loader-'));
    const [mapId] = useState(uniqid('map-'));

    // App state
    const { AppState } = useAppState();
    const { googleApiLoaded } = AppState;

    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [isMapReady, setIsMapReady] = useState(false);
    const [centerLatLng, setCenterLatLng] = useState<{
      lat: number;
      lng: number;
    }>();
    const [zoomLevel, setZoomLevel] = useState();

    // map intercation related
    const [bounds, setBounds] = useState<any>(null);
    const [isDraggingMap, setIsDraggingMap] = useState(false);
    const [cachedBoundsApplied, setCachedBoundsApplied] = useState(false);

    // markers related
    const [highlightedProject, setHighlightedProject] =
      useState<IMapProject | null>(null);

    const [isFullscreen, setIsFullscreen] = useState(false);
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        setIsFullscreen(screenfull.isFullscreen);
      });
    }

    const updateSharedMapState = (payload: any) =>
      ProjectsMapDispatch({
        type: 'updateProjectsMapState',
        payload
      });

    const mapBoundsChangedDebounce = useRef<number>();

    const { ProjectsFiltersState, ProjectsFiltersDispatch } =
      useProjectsFiltersState();
    const { filterValues } = ProjectsFiltersState;

    const { ProjectsMapState, ProjectsMapDispatch } = useProjectsMapState();
    const {
      bounds: cachedBounds,
      zoom: cachedZoom,
      initialCenter,
      initialZoom,
      initialSelectedProject
    } = ProjectsMapState;

    const searchLocation = filterValues?.location?.coords;
    const searchRadius = filterValues?.location?.values * 1000;

    const expandMapBlock = (
      <ExpandMapBlock
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={() => updateSharedMapState({ expandMap: true })}
      >
        <ToggleMapButton>
          <ToggleButtonIcon icon={['far', 'map']} size="1x" />
          {toggleButtonTitle}
        </ToggleMapButton>
      </ExpandMapBlock>
    );

    const highlightedMarkerRef = useRef();
    const fullscreenRef = useRef<any>();

    // Set initial center
    useEffect(() => {
      if (initialCenter && isMapLoaded && projectsForMap) {
        setCenterLatLng(initialCenter);

        const visibleProjectsIDs = projectsForMap.map(
          (project) => project.projectId
        );

        updateSharedMapState({
          projectsVisibleInMap: visibleProjectsIDs
        });
      }
    }, [initialCenter, isMapLoaded, projectsForMap]);

    // Set initial zoom
    useEffect(() => {
      if (initialZoom && isMapLoaded) {
        setZoomLevel(initialZoom);
      }
    }, [initialZoom, isMapLoaded]);

    // Set initial selected project
    useEffect(() => {
      if (
        initialSelectedProject &&
        mapInstance &&
        isMapReady &&
        cachedBoundsApplied &&
        projectsForMap
      ) {
        const selectedP = projectsForMap?.find(
          (project: IMapProject) => project.projectId === initialSelectedProject
        );

        updateSharedMapState({
          selectedProject: selectedP
        });
      }
    }, [
      initialSelectedProject,
      mapInstance,
      isMapReady,
      cachedBoundsApplied,
      projectsForMap
    ]);

    // Apply cached map bounds
    useEffect(() => {
      if (!googleApiLoaded) return;
      if (!isMapReady) return;
      if (!mapInstance) return;
      if (!cachedBounds) {
        setCachedBoundsApplied(true);
        return;
      }
      if (cachedBoundsApplied) return;

      const latitude = cachedBounds.mb ?? cachedBounds.eb;
      const longitude = cachedBounds.Oa ?? cachedBounds.La;

      if (latitude && longitude) {
        // @ts-ignore
        let googleMapBounds = new window.google.maps.LatLngBounds(
          { lat: latitude.lo, lng: longitude.lo },
          { lat: latitude.hi, lng: longitude.hi }
        );

        const timer = setTimeout(() => {
          clearTimeout(timer);
          // if fitBound call fails the app will not crash
          // The worst case is that cached map's state is not applied
          try {
            mapInstance.fitBounds(googleMapBounds);
            if (cachedZoom) {
              mapInstance.setZoom(cachedZoom);
            }
            updateSharedMapState({ mapInstance });
          } catch {
            () => {};
          }
          setCachedBoundsApplied(true);
        }, 200);
      } else {
        setCachedBoundsApplied(true);
      }
    }, [
      cachedBoundsApplied,
      cachedBounds,
      cachedZoom,
      mapInstance,
      isMapReady,
      googleApiLoaded
    ]);

    // Highlight the project hovered in the list
    useEffect(() => {
      const highlighted = projectsForMap?.find(
        (project: IMapProject) => project.projectId === hoveredProjectId
      );
      if (highlighted) {
        setHighlightedProject(highlighted);
      } else {
        setHighlightedProject(null);
      }
    }, [hoveredProjectId, projectsForMap]);

    // calculate projects currently visible in map
    useEffect(() => {
      // wait until map dragging is done
      if (isDraggingMap) return;
      if (!bounds) return;
      const visibleProjectsIDs = projectsForMap
        .filter(({ coords }) => coords && bounds?.contains(coords))
        .map((project) => project.projectId);

      if (!isEqual(visibleProjectsIDs, projectsVisibleInMap)) {
        updateSharedMapState({
          projectsVisibleInMap: visibleProjectsIDs
        });
      }
    }, [bounds, projectsForMap, projectsVisibleInMap, isDraggingMap]);

    // Prepare the projects to be displayed on map,
    // calculate bounds and center the map to show all projects, only if user didn't specify a search location
    useEffect(() => {
      if (
        googleApiLoaded &&
        // @ts-ignore
        window.google &&
        mapInstance &&
        fullList &&
        (!initialZoom || !initialCenter)
      ) {
        // @ts-ignore
        const geocoder = new window.google.maps.Geocoder();

        const existingCoords: any = {};

        const latitudes: number[] = [],
          longitudes: number[] = [];
        const projects = fullList
          .map((project: IProject, idx: number) => {
            const coords = getProjectCoords(project, geocoder);
            if (coords) {
              const occurencies =
                existingCoords[
                  `${coords.lat.toString()}-${coords.lng.toString()}`
                ];
              if (occurencies > 0) {
                // slightly move the project with duplicated coordinates
                coords.lng += 0.0000075 + occurencies * 0.0000075;
              }
              latitudes.push(coords.lat);
              longitudes.push(coords.lng);

              existingCoords[
                `${coords.lat.toString()}-${coords.lng.toString()}`
              ] = occurencies ? occurencies + 1 : 1;
            }
            return { ...project, coords };
          })
          // display only projects with coordinates
          .filter(({ coords }: any) => !!coords);

        // If new projects list is the same as the old one, do nothing
        if (isEqual(projects, projectsForMap)) return;

        updateSharedMapState({ projectsForMap: projects });

        const bounds = {
          north: Math.max(...latitudes),
          south: Math.min(...latitudes),
          east: Math.max(...longitudes),
          west: Math.min(...longitudes)
        };

        const { north, south, west, east } = bounds;
        // @ts-ignore
        let googleMapBounds = new window.google.maps.LatLngBounds(
          { lat: south, lng: west },
          { lat: north, lng: east }
        );

        let centerLatLng = {
          lat: (north + south) / 2,
          lng: (east + west) / 2
        };

        setCenterLatLng(centerLatLng);

        try {
          mapInstance.fitBounds(googleMapBounds);
        } catch (e) {
          console.warn('ERROR IN PROJECTS MAP ', e);
        }

        updateSharedMapState({ mapInstance });

        setIsMapReady(true);
      } else if (
        googleApiLoaded &&
        // @ts-ignore
        window.google
      ) {
        // @ts-ignore
        const geocoder = new window.google.maps.Geocoder();
        const projects = fullList.map((project: IProject, idx: number) => {
          const coords = getProjectCoords(project, geocoder);
          return { ...project, coords };
        });
        updateSharedMapState({ projectsForMap: projects });
      }
    }, [googleApiLoaded, fullList, mapInstance]);

    useEffect(() => {
      if (searchLocation) {
        // User specified a search location, let's focus the map here.
        setCenterLatLng(searchLocation);
      }
    }, [searchLocation]);

    // Deselect a project when tha map gets closed
    useEffect(() => {
      if (selectedProject && !expandMap) {
        updateSharedMapState({ selectedProject: null });
      }
    }, [expandMap, selectedProject]);

    if (!googleApiLoaded) {
      return null;
    }

    const onMapLoad = (instance: any) => {
      updateSharedMapState({ mapInstance: instance });
      ProjectsFiltersDispatch({ type: 'setMapInstance', payload: instance });
      setTimeout(() => {
        setIsMapLoaded(true);
      }, 200);
    };

    // map options reference:
    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    const mapOptions = {
      clickableIcons: false,
      disableDefaultUI: true,
      styles: expandMap ? activeMapStyling : disabledMapStyling,
      streetViewControl: expandMap,
      zoomControl: !isMobileOnly && expandMap,
      zoomControlOptions: {
        // @ts-ignore
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      mapTypeControl: false,
      mapTypeId: mapTypes[0],
      controlSize: 40,
      draggableCursor: 'default',
      gestureHandling: 'greedy',
      keyboardShortcuts: false,
      minZoom: minZoomLevel
    };

    // radius indicator options reference:
    // https://developers.google.com/maps/documentation/javascript/reference/polygon#CircleOptions
    const radiusIndicatorOptions = {
      fillColor: '#bfa666',
      strokeColor: '#bfa666',
      strokeWeight: 2,
      fillOpacity: 0.1
    };

    // show highlighted marker
    let highlighted;
    const coords = highlightedProject?.coords || selectedProject?.coords;
    if (coords)
      highlighted = (
        // @ts-ignore
        <StyledOverlayView
          position={coords}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <HighlightedMarker
            ref={highlightedMarkerRef}
            project={highlightedProject || selectedProject}
            onHovered={setHighlightedProject}
            onClick={(project) =>
              updateSharedMapState({ selectedProject: project })
            }
          />
        </StyledOverlayView>
      );

    return (
      <AnimatePresence>
        <Wrapper
          ref={fullscreenRef}
          isFullscreen={isFullscreen}
          initial={{ opacity: 0 }}
          animate={
            isMapLoaded ? { opacity: 1, zIndex: 3 } : { opacity: 0, zIndex: -1 }
          }
          expanded={expandMap}
          exit={{ opacity: 0 }}
        >
          <AnimatePresence>{!expandMap && expandMapBlock}</AnimatePresence>

          <MapWrapper>
            {(zoomLevel || defaultZoomLevel) && (
              /* @ts-ignore */
              <GoogleMap
                id={mapId}
                defaultZoom={defaultZoomLevel}
                defaultCenter={{ lat: 50.59, lng: 6.2 }} // border between Belgium and Germany
                zoom={zoomLevel}
                center={centerLatLng}
                options={mapOptions}
                mapContainerStyle={{
                  height: '100%',
                  width: '100%'
                }}
                onLoad={onMapLoad}
                onDragStart={() => {
                  updateSharedMapState({ selectedProject: null });
                  setIsDraggingMap(true);
                }}
                onDragEnd={() => setIsDraggingMap(false)}
                onZoomChanged={() => {
                  if (!mapInstance) return;
                  if (cachedBoundsApplied) {
                    updateSharedMapState({ zoom: mapInstance.getZoom() });
                  }
                }}
                onClick={() => updateSharedMapState({ selectedProject: null })}
                onBoundsChanged={() => {
                  if (!mapInstance) return;
                  setBounds(mapInstance.getBounds());

                  if (mapInstance.getCenter()) {
                    if (mapBoundsChangedDebounce?.current) {
                      clearTimeout(mapBoundsChangedDebounce.current);
                    }

                    mapBoundsChangedDebounce.current = window.setTimeout(() => {
                      clearTimeout(mapBoundsChangedDebounce.current);
                      updateSharedMapState({
                        center: {
                          lat: mapInstance.getCenter().lat(),
                          lng: mapInstance.getCenter().lng()
                        }
                      });
                    }, 200);
                  }
                  if (cachedBoundsApplied) {
                    updateSharedMapState({ bounds: mapInstance.getBounds() });
                  }
                }}
              >
                {expandMap && (
                  <Markers
                    projects={projectsForMap}
                    onHoverMarker={(project: IMapProject) => {
                      ProjectsMapDispatch({
                        type: 'updateProjectsMapState',
                        payload: {
                          hoveredProjectId: project.projectId
                        }
                      });
                      setHighlightedProject(project);
                    }}
                    onClickMarker={(project: IMapProject) =>
                      updateSharedMapState({ selectedProject: project })
                    }
                    expandMap={expandMap}
                  />
                )}
                <Circle
                  center={searchLocation}
                  radius={searchRadius}
                  options={radiusIndicatorOptions}
                />

                {highlighted}
              </GoogleMap>
            )}
          </MapWrapper>

          <AnimatePresence>
            {selectedProject && expandMap && (
              <SelectedProjectCard
                project={selectedProject}
                onCloseCard={() => {
                  updateSharedMapState({ selectedProject: null });
                  setHighlightedProject(null);
                }}
                allVaultLogos={allVaultLogos}
                unitCount={projectsUnitCount?.[selectedProject?.projectId] ?? 0}
              />
            )}
          </AnimatePresence>

          <CloseButton
            onClick={() => {
              if (screenfull.isEnabled && screenfull.isFullscreen) {
                screenfull.toggle(fullscreenRef.current);
              }
              updateSharedMapState({ expandMap: false });
            }}
          >
            <CloseIcon icon={['far', 'times']} size="lg" />
          </CloseButton>

          <AnimatePresence>
            {expandMap && (
              <FullscreenButton
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1 }}
                isFullscreen={isFullscreen}
                onClick={() => {
                  if (screenfull.isEnabled) {
                    screenfull.toggle(fullscreenRef.current);
                  }
                }}
              >
                <FullscreenButtonIcon
                  icon={isFullscreen ? ['fal', 'compress'] : ['far', 'expand']}
                  size="1x"
                />
              </FullscreenButton>
            )}
          </AnimatePresence>
        </Wrapper>
      </AnimatePresence>
    );
  }
);

export default ProjectsMap;
