import { useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { IProject } from '@/libs/prompto-api/src';
import { useAuthState } from 'src/store/AuthStore';
import ThumbnailComponent from 'src/components/Thumbnail';
import { buildAddress } from 'src/helpers/vmProjectSection';
import localizer, { getValueFromLocalized } from 'src/localization/localizer';
import Cookies from 'universal-cookie';
import env from 'src/environment';

// Types
import { IMapProject } from '../ProjectsPage';

// Styling
import styled, { StyledProps, css } from 'styled-components';
import { fetchSettingsFromURL } from 'src/helpers/utils';

const ProjectTitle = styled.div`
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  margin-top: 16px;
`;

const AddressName = styled.div`
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
`;

const AddressIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  margin-right: 6px;
  margin-top: 4px;
`;

const ProjectAddress = styled.div`
  display: flex;
  flex-flox: row;
  align-items: flex-start;

  cursor: pointer;
  margin-top: 8px;

  ${AddressIcon} {
    color: ${({ theme }) => theme.gray50};
  }

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.blueDark};

    ${AddressIcon} {
      color: ${({ theme }) => theme.blueDark};
    }
  }
`;

const Card = styled.div<StyledProps<{ viewed: boolean }>>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
`;

const ThumbnailWrapper = styled.div`
  height: 240px;
  position: relative;
`;

const InfoPill = styled.div`
  height: 32px;
  padding: 0px 8px;
  border-radius: 18px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-right: 16px;
`;

const TotalUnitsPill = styled(InfoPill)``;
const TotalUnitsIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const TotalViewsPill = styled(InfoPill)``;
const TotalViewsIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const PillWrapper = styled.div`
  display: flex;
  flex-flow: row;
  position: absolute;
  right: 16px;
  top: calc(100% - 45px);
  z-index: 5;
  column-gap: 8px;
`;

const LogoWrapper = styled.img`
  max-width: 100px;
  max-height: 50px;
  object-fit: contain;
`;

const BottomCardWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
`;

const TitleAndAdressWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const FavouriteButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  z-index: 5;
`;

const FavouriteIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  height: 20px !important;
  color: white;
`;

const cookies = new Cookies();

interface ProjectCardProps {
  project: IProject | any;
  projectForMap?: IMapProject;
  onProjectAddressClick?: (project: IMapProject) => void;
  allVaultLogos: any;
  allProjectLogos?: any;
  unitCount?: number;
  onToggleHoveredState?: (id: string) => void;
}

const ProjectCard = memo(
  ({
    project,
    projectForMap,
    onProjectAddressClick,
    allVaultLogos,
    allProjectLogos,
    unitCount,
    onToggleHoveredState
  }: ProjectCardProps) => {
    const { AuthState, AuthStateDispatch } = useAuthState();
    const { favouriteProjects, tempFavouriteProjects } = AuthState;

    const projectTitle = getValueFromLocalized(
      project?.localizedTitle,
      project?.title ?? ''
    );
    const formattedAddress = buildAddress({ ...project?.address, country: '' });

    const thumbnailUri = project.thumbnailUri ?? project.projectThumbnailUri;

    const navigate = useNavigate();

    const { disableAccount} = fetchSettingsFromURL()

    const viewedMarketplaceProjects = cookies.get('viewedMarketplaceProjects');

    const vaultLogoForProject = allVaultLogos
      ? allVaultLogos[project.vaultId]
      : '';
    const projectLogoForProject = allProjectLogos
      ? allProjectLogos[project.projectId]
      : '';

    let logo = '';

    if (vaultLogoForProject) {
      logo = `${env().baseImageUrl}/q=100:w=${120}/${vaultLogoForProject}`;
    }
    if (projectLogoForProject) {
      logo = `${env().baseImageUrl}/q=100:w=${120}/${projectLogoForProject}`;
    }

    const projectWasAlreadyViewed = viewedMarketplaceProjects
      ? viewedMarketplaceProjects.includes(project.projectId)
      : false;

    const onCardClicked = useCallback(() => {
      if (!project) return;

      if (!projectWasAlreadyViewed) {
        cookies.set(
          'viewedMarketplaceProjects',
          viewedMarketplaceProjects
            ? [...viewedMarketplaceProjects, project.projectId]
            : [project.projectId]
        );
      }

      navigate(
        `/vault/${project.vaultId}/project/${project.projectId}${window.location.search}`
      );
    }, [project, viewedMarketplaceProjects, projectWasAlreadyViewed]);

    const onAddressClick = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        projectForMap &&
          onProjectAddressClick &&
          onProjectAddressClick(projectForMap);
      },
      [projectForMap]
    );

    return (
      <Card
        onClick={onCardClicked}
        viewed={projectWasAlreadyViewed}
        onMouseEnter={() =>
          onToggleHoveredState && onToggleHoveredState(project.projectId)
        }
        onMouseLeave={() => onToggleHoveredState && onToggleHoveredState('')}
      >
        <a
          href={`${env().marketplaceUrl}/vault/${project.vaultId}/project/${
            project.projectId
          }`}
        />
        <ThumbnailWrapper>
          <ThumbnailComponent uri={thumbnailUri} height={240} />
          <PillWrapper>
            {!!unitCount && (
              <TotalUnitsPill>
                <TotalUnitsIcon icon={['far', 'building']} size="sm" />
                {unitCount}
              </TotalUnitsPill>
            )}
            {/*<TotalViewsPill>*/}
            {/*  <TotalViewsIcon icon={['far', 'eye']} size="sm" />*/}
            {/*  1200*/}
            {/*</TotalViewsPill>*/}
          </PillWrapper>
          {!disableAccount && <FavouriteButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              AuthStateDispatch({
                type: 'toggleFavouriteProject',
                payload: {
                  projectId: project.projectId,
                  vaultId: project.vaultId
                }
              });
            }}
          >
            <FavouriteIcon
              icon={[
                [...favouriteProjects, ...tempFavouriteProjects]?.find(
                  (x: any) => x.favoriteProjectId === project.projectId
                )
                  ? 'fas'
                  : 'far',
                'heart'
              ]}
              size="sm"
            />
          </FavouriteButton>}
        </ThumbnailWrapper>
        <BottomCardWrapper>
          <TitleAndAdressWrapper>
            <ProjectTitle>{projectTitle}</ProjectTitle>
            <ProjectAddress
              title={localizer.projectDetailPage.viewOnTheMap}
              onClick={onAddressClick}
            >
              <AddressIcon icon={['fas', 'map-marker-alt']} size="sm" />
              <AddressName>{formattedAddress}</AddressName>
            </ProjectAddress>
          </TitleAndAdressWrapper>
          {logo && <LogoWrapper src={logo} />}
        </BottomCardWrapper>
      </Card>
    );
  }
);

export default ProjectCard;
