import { CountryListDict } from './CountryList';

const supportedCountries = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'GR',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'GB'
];

const vatPrefixes = {
  GR: { prefix: 'EL' }
};

export const Countries = {
  getFilteredCountryList: () => {
    const filteredList = [];
    for (let i = 0; i < supportedCountries.length; i++) {
      const code = supportedCountries[i];
      const country = CountryListDict.countries[code];

      if (country) {
        filteredList.push({
          native: country.native,
          name: country.name,
          //@ts-ignore
          vatPrefix: vatPrefixes[code] ? vatPrefixes[code].prefix : code,
          code: code
        });
      }
    }

    filteredList.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return filteredList.map((x) => {
      const newObj: any = {};
      newObj[x.code] = x;
      return newObj;
    });
  }
};
