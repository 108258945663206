import { useEffect, useState, useCallback, useRef } from 'react';

// Components
import { IdealImage } from '@prompto-ui';
import UnitSpotTooltip from '../UnitSpotTooltip';
import UspSpotTooltip from '../UspSpotTooltip';
import ShowcaseLoader from 'src/components/Loader';

// Helpers
import { motion } from 'framer-motion';
import env from 'src/environment';

// Styling
import styled, { css, StyledProps } from 'styled-components';
import { getDisplayDirection } from '../Spot';

const maxSpotPreviewWidth = 370;
const maxSpotPreviewHeight = 150;
const previewMargin = 20;
const NAV_ITEM_FIXED_WIDTH = 250;
const NAV_ITEM_FIXED_HEIGHT = 148;

const PreviewWrapper = styled.div<StyledProps<any>>`
  z-index: 150;
  position: absolute;
  cursor: pointer;

  ${({ cornerPos }) => cornerPos}

  ${({ scale, direction }) => {
    if (!direction) {
      return css`
        transform: scale(${scale});
      `;
    }
    const x =
      direction.x === 'left'
        ? `translateX(-${NAV_ITEM_FIXED_WIDTH / scale}px)`
        : direction.x === 'right'
        ? ''
        : `translateX(-${(NAV_ITEM_FIXED_WIDTH * 0.5) / scale}px)`;
    const xOrigin =
      direction.x === 'left' ? 'right' : direction.x === 'right' ? 'left' : '';
    if (direction.y === 'top') {
      return css`
        transform-origin: bottom ${xOrigin};
        transform: scale(${scale})
          translateY(-${(NAV_ITEM_FIXED_HEIGHT + 10) / scale}px) ${x};
      `;
    } else if (direction.y === 'bottom') {
      return css`
        transform-origin: top ${xOrigin};
        transform: scale(${scale}) translateY(10px) ${x};
      `;
    }
  }}
`;

const ThumbContainer = styled(motion.div)<StyledProps<any>>`
  max-width: ${maxSpotPreviewWidth}px;
  max-height: ${maxSpotPreviewHeight}px;
  pointer-events: ${(props) => (props.isSpotHovered ? 'initial' : 'none')};
  cursor: ${(props) => (props.isSpotHovered ? 'pointer' : 'default')};
  border: 1px solid white;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
  z-index: ${(props) => (props.isSpotHovered ? '5' : '1')};
  transform-origin: bottom;
`;

const TargetThumbnailWrapper = styled.div`
  display: block;
  height: 148px;
  width: 250px;
  object-fit: cover;
  background-color: ${({ theme }) => theme.showcaseWhite};
`;

const NameWrapper = styled(motion.div)`
  height: 40px;
  margin: 0 ${previewMargin}px;
  margin-top: -20px;
  color: white;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  user-select: none;
  max-width: ${maxSpotPreviewWidth - 2 * previewMargin}px;
  width: auto;
  text-transform: uppercase;
  text-overflow: ellipsis;
  z-index: 50;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
  background-color: rgba(175, 175, 175, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
`;

const defaultLocation = { x: 0, y: 0 };

interface PolygonSpotTooltipProps {
  item: any;
  isMobile: boolean;
  onUnitTooltipClicked: (unit: any, parentMediaViewId?: string) => void;
  onNavigationTooltipClicked: (spot: any) => void;
  hoveredLocation: any;
  setPolygonTooltipHovered: any;
  polygonTooltipHovered: boolean;
  parentId: string;
  currentRotation: any;
  is360Viewer: boolean;
  parentDimensions: any;
  zoomScale: number;
  idealImageRelativePosition: any;
  containerDimensions: any;
  parentMediaViewId?: string;
  hidePrices: boolean;
}

const PolygonSpotTooltip = ({
  item,
  isMobile,
  onUnitTooltipClicked,
  onNavigationTooltipClicked,
  hoveredLocation,
  setPolygonTooltipHovered,
  polygonTooltipHovered,
  parentId,
  currentRotation,
  is360Viewer,
  parentDimensions,
  zoomScale,
  idealImageRelativePosition,
  containerDimensions,
  parentMediaViewId,
  hidePrices
}: PolygonSpotTooltipProps) => {
  const [tooltipLocation, setTooltipLocation] = useState(defaultLocation);
  const [tooltipLocationHigh, setTooltipLocationHigh] =
    useState(defaultLocation);
  const [tooltipAbsoluteLocation, setTooltipAbsoluteLocation] =
    useState(defaultLocation);
  const [unitTooltipLocation, setUnitTooltipLocation] =
    useState<any>(defaultLocation);
  const [uspTooltipLocation, setUspTooltipLocation] = useState(defaultLocation);
  const [uspTooltipDirection, setUspTooltipDirection] = useState();

  const [tooltipCornerPosition, setTooltipCornerPosition] = useState<any>('');
  const [nestedTooltipPosition, setNestedTooltipPosition] = useState<any>('');

  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  // Refs
  const unitSpotTooltipRef = useRef(null);

  const onTooltipSizeComputed = useCallback(
    (size: any) => {
      if (size.height === 0) {
        setUnitTooltipLocation(null);
      }

      const direction = getDisplayDirection(
        tooltipAbsoluteLocation,
        size.width,
        size.height,
        window.innerWidth
      );

      const unitTooltipFinalPosition =
        direction.y === 'top' ? tooltipLocation : tooltipLocationHigh;

      setUnitTooltipLocation(unitTooltipFinalPosition);
    },
    [tooltipAbsoluteLocation, tooltipLocation, tooltipLocationHigh]
  );

  let content = null;

  const calculateTooltipPositionBasedOnPolygonLocation = useCallback(
    ({ xL, xH, yL, yH }: any) => {
      // Get parent image size and container size
      const parentWidth = parentDimensions?.width || 0;
      const parentHeight = parentDimensions?.height || 0;

      // Calculate the half size of the image and the offset of the marker according to the center of the image
      const parentHalfWidth = parentWidth / 2.0;
      const parentHalfHeight = parentHeight / 2.0;

      // Calculate offsets from image center for every coordinate
      // xL - very left polygon's point
      // yL - very top polygon's point
      // xH - very right polygon's point
      // yH - very bottom polygon's point
      const centerOffsetXLow = parentHalfWidth * xL;
      const centerOffsetYLow = parentHalfHeight * yL;
      const centerOffsetXHigh = parentHalfWidth * xH;
      const centerOffsetYHigh = parentHalfHeight * yH;

      // Calculate offsets from left and top image's sides
      const cornerOffsetXLow = centerOffsetXLow + parentHalfWidth;
      const cornerOffsetYLow = centerOffsetYLow + parentHalfHeight;
      const cornerOffsetXHigh = centerOffsetXHigh + parentHalfWidth;
      const cornerOffsetYHigh = centerOffsetYHigh + parentHalfHeight;

      // Calculate size of available space not covered by a polygon
      const spaceToLeftSide =
        cornerOffsetXLow + idealImageRelativePosition.offsetX;
      const spaceToRightSide =
        containerDimensions.width -
        (cornerOffsetXHigh + idealImageRelativePosition.offsetX);
      const spaceToTopSide =
        cornerOffsetYLow + idealImageRelativePosition.offsetY;
      const spaceToBottomSide =
        containerDimensions.height -
        (cornerOffsetYHigh + idealImageRelativePosition.offsetY);

      const PADDING = 20;
      const TOUR_CONTROL_HEIGHT = 50; // Back button, Zoom control, Show extra info toggle

      // Calculate tooltip absolute position
      const top =
        (idealImageRelativePosition.offsetY * -1 + PADDING) / zoomScale;
      const left =
        (idealImageRelativePosition.offsetX * -1 + PADDING) / zoomScale;
      const bottom =
        (parentDimensions.height +
          idealImageRelativePosition.offsetY -
          containerDimensions.height +
          PADDING) /
        zoomScale;
      const right =
        (parentDimensions.width +
          idealImageRelativePosition.offsetX -
          containerDimensions.width +
          PADDING) /
        zoomScale;

      // Define where to display the tooltip
      if (spaceToLeftSide > maxSpotPreviewWidth + PADDING) {
        return [
          `top: ${
            top + TOUR_CONTROL_HEIGHT / zoomScale
          }px; left: ${left}px; transform-origin: top left;`,
          `top: 0px !important; left: 0px !important; transform-origin: top left !important;`
        ];
      } else if (spaceToRightSide > maxSpotPreviewWidth + PADDING) {
        return [
          `top: ${top}px; right: ${right}px; transform-origin: top right;`,
          `top: 0px !important; right: 0px !important; transform-origin: top right !important; transform: translateX(-100%) !important;`
        ];
      } else if (
        spaceToLeftSide < maxSpotPreviewWidth + PADDING &&
        spaceToRightSide < maxSpotPreviewWidth + PADDING
      ) {
        if (spaceToTopSide > maxSpotPreviewHeight) {
          return [
            `top: ${
              top + TOUR_CONTROL_HEIGHT / zoomScale
            }px; left: ${left}px; transform-origin: top left;`,
            `top: 0px !important; left: 0px !important; transform-origin: top left !important;`
          ];
        } else if (spaceToBottomSide > maxSpotPreviewHeight + PADDING) {
          return [
            `bottom: ${
              bottom + TOUR_CONTROL_HEIGHT / zoomScale
            }px; right: ${right}px; transform-origin: bottom right;`,
            `bottom: 0px !important; right: 0px !important; transform-origin: bottom right !important; transform: translateX(-100%) translateY(calc(-100% - 60px)) !important;`
          ];
        }
      }

      return [
        `top: ${
          top + TOUR_CONTROL_HEIGHT / zoomScale
        }px; left: ${left}px; transform-origin: top left;`,
        `top: 0px !important; left: 0px !important; transform-origin: top left !important;`
      ];
    },
    [parentDimensions, zoomScale, idealImageRelativePosition]
  );

  // Calculate tooltip position
  useEffect(() => {
    if (hoveredLocation) {
      const [tooltipPositionRules, nestedTooltipPositionRules] =
        calculateTooltipPositionBasedOnPolygonLocation(hoveredLocation);
      setTooltipCornerPosition(tooltipPositionRules);
      setNestedTooltipPosition(nestedTooltipPositionRules);
    }
  }, [hoveredLocation, calculateTooltipPositionBasedOnPolygonLocation]);

  // We show this tooltip if either a mediaview or turntable is linked and if the polygon or the tooltip itself is hovered
  if (
    (item.assignedItem.navigationItemType === 'mediaView' ||
      item.assignedItem.navigationItemType === 'turntableSpot' ||
      item.assignedItem.navigationItemType === 'album360Spot') &&
    (hoveredLocation || polygonTooltipHovered)
  ) {
    // for that type of navigationItemType size of tooltip is fixed
    const direction = getDisplayDirection(
      tooltipAbsoluteLocation,
      NAV_ITEM_FIXED_WIDTH,
      NAV_ITEM_FIXED_HEIGHT,
      window.innerWidth
    );

    const tooltipFinalPosition =
      direction.y === 'top' ? tooltipLocation : tooltipLocationHigh;

    content = (
      <PreviewWrapper
        pos={tooltipFinalPosition}
        direction={direction}
        onMouseEnter={() => {
          if (!isMobile) {
            setPolygonTooltipHovered(item?.assignedItem?.objectId);
          }
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setPolygonTooltipHovered('');
          }
        }}
        onClick={() => {
          if (!isMobile) {
            onNavigationTooltipClicked(item);
          }
        }}
        onTouchStart={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          onNavigationTooltipClicked(item);
        }}
      >
        <ThumbContainer
          initial={{ opacity: 0.5, scale: 0.4 }}
          animate={{
            opacity: thumbnailLoaded ? 1 : 0.5,
            scale: thumbnailLoaded ? 1 : 0.4
          }}
          transition={{ duration: 0.25, type: 'tween' }}
        >
          <TargetThumbnailWrapper>
            {!thumbnailLoaded && <ShowcaseLoader />}
            <IdealImage
              key={`polygonSpotTooltip${item.objectId}`}
              contentUri={item.contentItemToRender?.contentUri}
              fallbackUri={item.contentItemToRender?.contentUri}
              imageSize={{
                width: maxSpotPreviewWidth,
                height: maxSpotPreviewHeight
              }}
              containerSize={{
                width: maxSpotPreviewWidth,
                height: maxSpotPreviewHeight
              }}
              mustFillParent={true}
              onLoad={() => setThumbnailLoaded(true)}
              shouldFit={false}
              displayDemanded={false}
              displayOnDemand={false}
              baseImageUrl={env().baseImageUrl}
            />
          </TargetThumbnailWrapper>
        </ThumbContainer>
        <NameWrapper
          id={item.objectId}
          key={'name'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {item.title.textMap.en}
        </NameWrapper>
      </PreviewWrapper>
    );
  }

  if (
    item.assignedItem.navigationItemType === 'uspSpot' &&
    (hoveredLocation || polygonTooltipHovered)
  ) {
    const uspSpotObject = {
      ...item.assignedItem,
      usp: { ...item.usp }
    };
    content = (
      <PreviewWrapper
        pos={uspTooltipLocation}
        key={`unitTooltip${item.assignedItem.objectId}`}
        onMouseEnter={() => {
          if (!isMobile) {
            setPolygonTooltipHovered(item?.assignedItem?.objectId);
          }
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setPolygonTooltipHovered('');
          }
        }}
        onClick={() => {
          if (!isMobile) {
            onNavigationTooltipClicked(item);
          }
        }}
        onTouchStart={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          onNavigationTooltipClicked(item);
        }}
      >
        <UspSpotTooltip
          source="polygon"
          uspSpotLocation={uspTooltipLocation}
          uspSpotAbsoluteLocation={tooltipAbsoluteLocation}
          givenDirection={uspTooltipDirection}
          onSizeComputed={(size: any) => {
            const direction = getDisplayDirection(
              tooltipAbsoluteLocation,
              size.width,
              size.height,
              window.innerWidth
            );

            const uspTooltipFinalPosition =
              direction.y === 'top' ? tooltipLocation : tooltipLocationHigh;
            setUspTooltipDirection(direction);
            setUspTooltipLocation(uspTooltipFinalPosition);
          }}
          spotObject={uspSpotObject}
        />
      </PreviewWrapper>
    );
  }

  if (
    item.assignedItem.navigationItemType === 'unitSpot' &&
    unitTooltipLocation &&
    item.unitItem
  ) {
    content = (
      <PreviewWrapper
        cornerPos={tooltipCornerPosition}
        pos={unitTooltipLocation}
        key={`unitTooltip${item.assignedItem.objectId}`}
        ref={unitSpotTooltipRef}
        scale={1 / zoomScale}
      >
        <UnitSpotTooltip
          origin="polygon"
          shouldShow={!!hoveredLocation || polygonTooltipHovered ? true : false}
          onMouseEnter={() => {
            setPolygonTooltipHovered(item?.assignedItem?.objectId);
          }}
          onMouseLeave={() => {
            setPolygonTooltipHovered('');
          }}
          unitSpotLocation={unitTooltipLocation}
          unitSpotAbsoluteLocation={tooltipAbsoluteLocation}
          unit={item.unitItem}
          onClick={() => {
            if (!isMobile) {
              onUnitTooltipClicked(item, parentMediaViewId);
            }
          }}
          onTouchStart={() => {
            onUnitTooltipClicked(item, parentMediaViewId);
          }}
          is360Viewer={is360Viewer}
          parentId={parentId}
          currentRotation={currentRotation}
          onSizeComputed={onTooltipSizeComputed}
          withDottedLine={false}
          imperativePosition={nestedTooltipPosition}
          hidePrices={hidePrices}
        />
      </PreviewWrapper>
    );
  }

  return content;
};

export default PolygonSpotTooltip;
