import React, { ReactNode, useEffect, useRef } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'src/localization/localizer';
import { isMobileOnly } from 'react-device-detect';

// Styling
import styled, { StyledProps } from 'styled-components';

const CloseIcon = styled(FontAwesomeIcon)<StyledProps<{ lightMode: boolean }>>`
  width: 20px !important;
  color: ${({ lightMode, theme }) => (lightMode ? theme.white : theme.gray50)};
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 12px;
`;

const SearchIcon = styled(FontAwesomeIcon)<StyledProps<{ lightMode: boolean }>>`
  width: 20px !important;
  color: ${({ lightMode, theme }) => (lightMode ? theme.white : theme.gray50)};
  cursor: pointer;
  position: absolute;
  top: 12px;
  left: ${isMobileOnly ? 8 : 6}px;
  z-index: 500;
`;

const InputWrapper = styled.div<
  StyledProps<{ mobileView: boolean; lightMode: boolean }>
>`
  position: relative;
  width: 100%;
  max-width: 272px;
  height: 40px;
`;

const StyledInput = styled.input<StyledProps<{ lightMode: boolean }>>`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  background: ${({ lightMode, theme }) =>
    lightMode ? 'rgba(0,0,0,0.3)' : theme.white};
  outline: transparent;
  padding: 0 8px 0 36px;
  color: ${({ lightMode, theme }) => (lightMode ? theme.white : theme.black)};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};

  &::placeholder {
    color: ${({ lightMode, theme }) =>
      lightMode ? theme.white : theme.gray80};
  }
`;

const StyledLoadingIcon = styled(FontAwesomeIcon)<
  StyledProps<{ lightMode: boolean }>
>`
  color: ${({ lightMode, theme }) => (lightMode ? theme.white : theme.gray60)};
  font-size: 0.75rem;
  position: absolute;
  top: 16px;
  right: 12px;
`;

interface SearchInputProps {
  setSearchValue: (value: string) => void;
  setHideResults: (value: boolean) => void;
  searchValue: string;
  mobileView: boolean;
  isMenuOpened: boolean;
  isLoading: boolean;
  isHomePage?: boolean;
}

const SearchInput = ({
  setSearchValue,
  searchValue,
  mobileView,
  isMenuOpened,
  setHideResults,
  isLoading,
  isHomePage = false
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchValue('');
  }, [isMenuOpened]);

  useEffect(() => {
    // hide results list and lose the focus by scroll
    const handleScroll = () => {
      setHideResults(true);
      inputRef.current?.blur();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const loadingIcon = isLoading ? (
    <StyledLoadingIcon
      icon={['far', 'spinner']}
      size="1x"
      spin={true}
      lightMode={isHomePage}
    />
  ) : null;

  const closeIcon =
    searchValue?.length && !isLoading ? (
      <CloseIcon
        lightMode={isHomePage}
        icon={['far', 'times-circle']}
        size="1x"
        onClick={() => {
          setSearchValue('');
          inputRef.current?.focus();
        }}
      />
    ) : null;

  return (
    <InputWrapper mobileView={mobileView} lightMode={isHomePage}>
      <SearchIcon
        lightMode={isHomePage}
        icon={['far', 'search']}
        size="1x"
        onClick={() => {
          setTimeout(() => {
            inputRef.current?.focus();
            setHideResults(false);
          }, 300);
        }}
      />
      <StyledInput
        lightMode={isHomePage}
        type="text"
        value={searchValue}
        onChange={(e: any) => {
          setSearchValue(e.target.value);
          setHideResults(false);
        }}
        ref={inputRef}
        placeholder={localizer.searchProject}
        onBlur={() => {
          setTimeout(() => {
            setHideResults(true);
          }, 300);
        }}
        onFocus={() => setHideResults(false)}
      />
      {closeIcon as ReactNode}
      {loadingIcon as ReactNode}
    </InputWrapper>
  );
};

export default SearchInput;
