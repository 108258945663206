import { useCallback, useState, useEffect } from 'react';

// Helpers
import { isMobile } from 'react-device-detect';

// Styling
import styled, {
  keyframes,
  css,
  StyledProps,
  withTheme
} from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Circle = styled.div<
  StyledProps<{
    size: number;
    isSpotHovered?: boolean;
    disabled?: boolean;
    animationEnabled?: boolean;
  }>
>`
  width: ${({ size }) => size + 'px' ?? '20px'};
  height: ${({ size }) => size + 'px' ?? '20px'};
  display: flex;
  justify-content: center;
  transition: 0.4s;
  align-items: center;
  background-color: ${({ isSpotHovered, theme }) =>
    isSpotHovered ? theme.white : theme.black};
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.disabled ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.5)'};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  ${({ animationEnabled }) =>
    css`
      animation: ${pulse} 2s linear ${animationEnabled ? 'infinite' : ''};
    `}
`;

const InnerCircle = styled.div<
  StyledProps<{ size: number; disabled?: boolean; color?: string }>
>`
  width: ${({ size }) => size * 0.6 + 'px' ?? '12px'};
  height: ${({ size }) => size * 0.6 + 'px' ?? '12px'};
  transition: 0.4s;
  background-color: ${(props) => props.color};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  border-radius: 50%;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.35);
  }
  28% {
    transform: scale(0.95);
  }
  46% {
    transform: scale(1.26);
  }
  64% {
    transform: scale(1);
  }
`;

// Types
interface UnitSpotProps {
  spotObject: any;
  spotLocation: any;
  isSpotHovered: boolean;
  onSpotClicked: any;
  onSpotTouched?: any;
  disabled: boolean;
  spotAbsoluteLocation: any;
  // Enable or disable the infinite dot animation (the first animation always appears)
  animationEnabled: boolean;
  theme: any;
  highlightedUnitSpotParams: any;
  setHighlightedUnitSpotParams: any;
}

const UnitSpot = ({
  spotObject,
  spotLocation,
  isSpotHovered,
  onSpotClicked,
  onSpotTouched,
  disabled,
  spotAbsoluteLocation,
  animationEnabled,
  theme,
  highlightedUnitSpotParams,
  setHighlightedUnitSpotParams
}: UnitSpotProps) => {
  const showUnitState = true;
  const [hovered, setHovered] = useState(false);
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    setHighlighted(
      spotObject?.objectId === highlightedUnitSpotParams?.objectId
    );
  }, [highlightedUnitSpotParams, spotObject]);

  useEffect(() => {
    if (animationEnabled) {
      const animation = document
        .getElementById(`circle${spotObject.objectId}`)
        ?.getAnimations()[0];
      if (animation) {
        animation.startTime = 0;
      }
    }
  }, [animationEnabled]);

  let color;
  if (!showUnitState) {
    color = theme?.unitDefault;
  } else {
    spotObject?.unitItem?.state === 'AVAILABLE'
      ? (color = theme?.unitStatus?.available)
      : spotObject?.unitItem?.state === 'SOLD'
      ? (color = theme?.unitStatus?.sold)
      : spotObject?.unitItem?.state === 'IN_OPTION'
      ? (color = theme?.unitStatus?.option)
      : (color = theme?.unitStatus?.draft);
  }

  const onUnitSpotHover = useCallback(() => {
    if (!hovered) {
      setHovered(true);
      setHighlightedUnitSpotParams({
        showUnitState,
        spotLocation,
        spotAbsoluteLocation: spotAbsoluteLocation,
        objectId: spotObject?.objectId
      });
    }
  }, [hovered, showUnitState, spotLocation, spotAbsoluteLocation, spotObject]);

  return (
    <Wrapper>
      <Circle
        id={`circle${spotObject.objectId}`}
        isSpotHovered={highlighted || (isSpotHovered && hovered)}
        disabled={disabled}
        size={spotObject?.size ?? 20}
        color={color}
        onClick={() => {
          if (isMobile || highlighted) {
            onSpotClicked(spotObject);
          }
        }}
        onMouseOver={() => {
          if (!isMobile) {
            onUnitSpotHover();
            setHovered(false);
          }
        }}
        animationEnabled={animationEnabled}
      >
        <InnerCircle
          disabled={disabled}
          size={spotObject?.size ?? 20}
          color={color}
        />
      </Circle>
    </Wrapper>
  );
};

export default withTheme<any>(UnitSpot);
