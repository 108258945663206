export const theme = {
  yellowBright: '#ffc720',
  yellowDark: '#e2b01c',
  yellowGold: '#dfb643',
  yellowLight: '#fff8e3',
  blueBright: '#4e75ff',
  blueDark: '#4236a1',
  blueLight: '#e6e5fc',
  greenBright: '#00bd8f',
  greenDark: '#00895c',
  greenCTA: '#007b6f',
  greenLight: '#cce7de',
  redBright: '#ff3455',
  redDark: '#c9002a',
  redLight: '#f9e5e9',
  white: '#ffffff',
  black: '#000000',
  orange: '#D16D3A',
  gray5: '#f8f8fa',
  gray10: '#f1f2f3',
  gray20: '#e3e5e8',
  gray30: '#c7cad1',
  gray40: '#abb0ba',
  gray50: '#8f95a3',
  gray60: '#737b8c',
  gray70: '#5c6270',
  gray75: '#4D4D4D',
  gray80: '#454a53',
  gray90: '#2e3138',
  gray95: '#1b1d21',
  gray100: '#f3f2f4',
  gray300: '#e7e6ea',
  gray400: '#c3c2ca',
  gold: '#bfa666',
  gold60: '#b0955c',
  beigeBg10: '#f5f5f2',
  beigeBg20: '#e9e8e4',
  beigeBg25: '#dcd9d0',
  beigeBg30: '#cdcabd',
  primary100: '#9f9caa',
  primary300: '#574f6b',
  fadeWhite: 'rgba(255, 255, 255, 0.6)',
  fadeLightGray: 'rgba(144, 144, 144, 0.4)',
  defaultBrandPrimaryColor: '#403957',
  unitStatus: {
    sold: '#C4C4C4',
    option: '#D16D3A',
    available: '#4CA760',
    draft: '#26C6DA'
  },
  clusterBgColor: '#cdcabd',
  clusterTextColor: '#000000',

  // spacing
  spacing: {
    xxxs: '0.5rem',
    xxs: '0.75rem',
    xs: '1rem',
    s: '1.5rem',
    m: '2rem',
    l: '3rem',
    xl: '4rem',
    xxl: '5rem',
    xxl2: '6rem',
    xxl3: '8rem',
    xxl4: '16rem'
  },

  // fonts
  fonts: {
    DMSans: "'DM Sans', sans-serif"
  }
};
