import {
  performGetCall,
  performPostCall,
  performPutCall
} from '../api-helpers';

export const enum TargetType {
  contentItem = 'contentItem',
  contentCollection = 'contentCollection',
  navigationItem = 'navigationItem',
  showcaseItem = 'showcaseItem'
}

export interface ContactCreateParams {
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  language?: string;
  vaultObjectId: string;
}
export interface ShareItemCreateParams {
  stringTargetType?: TargetType;
  unitObjectId?: string;
  navigationItemObjectId?: string;
  vaultObjectId?: string;
  projectObjectId?: string;
  showcaseConfigurationObjectId?: string;
  contactCreateParams?: ContactCreateParams;
  note?: string;
  archived?: boolean;
  expiresAt?: number;
  customerName?: string;
  tour360ObjectId?: string;
}

export interface GetAllShareItemsParams {
  vaultObjectId: string;
  projectObjectId: string;
  targetType: TargetType;
  createdByUserObjectId?: string;
}

export const ShareItem = {
  /**
   * Create share item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_2
   * @param {Object} params
   * @param {string} sessionToken
   */
  create: (params: ShareItemCreateParams, sessionToken: string) =>
    performPostCall(`shareitems`, params, sessionToken),

  /**
   * Get single shareitem by its id
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_2
   * @param {string} shareItemId
   */
  get: (shareItemId: string) =>
    performGetCall(`shareitems/${shareItemId}?loadCustomerInfo=true`),

  /**
   * Get all ShareItems
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall
   */
  getAll: (params: GetAllShareItemsParams, sessionToken?: string) =>
    performGetCall(`shareitems`, params, sessionToken),

  /** Get share item by code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbycode_1
   */
  getByCode: (code: string, sessionToken?: string) =>
    performGetCall(`shareitems/getByCode/${code}`, {}, sessionToken),

  /**
   * Update a share item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_2
   */
  update: (
    shareItemId: string,
    params: ShareItemCreateParams,
    sessionToken: string
  ) => performPutCall(`shareitems/${shareItemId}`, params, sessionToken),

  mockGetAll: (shareItemsToReturn: any, options: any = {}) => {
    //@ts-ignore
    ShareItem.getAll.mockReset();
    //@ts-ignore
    return ShareItem.getAll.mockImplementation(() => {
      if (options?.error) {
        const errorInfo = options.error;
        return Promise.reject({
          status: errorInfo.httpStatus,
          data: { code: errorInfo.errorCode, message: errorInfo.errorMessage }
        });
      } else {
        return Promise.resolve({ data: { shareItemList: shareItemsToReturn } });
      }
    });
  },

  mockUpdate: (shouldSucceed = true) => {
    //@ts-ignore
    ShareItem.update.mockReset();
    //@ts-ignore
    return ShareItem.update.mockImplementation(
      (shareItemObjectId: string, body: any, sessionToken: string) => {
        if (shouldSucceed) {
          return Promise.resolve({
            status: 200,
            data: {
              shareItem: { objectId: shareItemObjectId, ...body }
            }
          });
        } else {
          return Promise.reject({ status: 404 });
        }
      }
    );
  }
};
