import { useEffect, useState } from 'react';

// Helpers
import { fetchSettingsFromURL } from 'src/helpers/utils';
import { useNavigate } from 'react-router-dom';
import { useContactUsState } from 'src/store/ContactUsStore';
import env from 'src/environment';
import { BoardTicket } from '@/libs/prompto-api/src';
import localizer, { setLanguage } from "src/localization/localizer";


const LeadOptInPage = () => {

  const {  ContactUsStateDispatch } = useContactUsState();

  const queryParams = fetchSettingsFromURL();
  const navigate = useNavigate();

  useEffect(() => {

    const {boardTicketId, vaultId, projectId, firstName, lastName, email, phone} = queryParams;

    if(queryParams.language){
      setLanguage(queryParams.language as string)
    }
    
    // Handle board ticket
    if(boardTicketId){
      const moveParams: any = {
        ticketIdList: [boardTicketId],
        sourceColumnId: "uyVS4dOzPm", // Source and target are hardcoded columns from the admin lead management board
        targetColumnId: "lzmkUP8ajB"
      }

      BoardTicket.move(moveParams, env().integrationToken)
    }
    
    // Handle form params
    const contactUpdateParams : any= {};
    if(firstName) contactUpdateParams.firstName = firstName;
    if(lastName) contactUpdateParams.lastName = lastName;
    if(email) contactUpdateParams.email = email;
    if(phone) contactUpdateParams.phoneNumber = phone.toString();

    ContactUsStateDispatch({
      type: 'setContactDetails',
      payload: contactUpdateParams
    });

    // Handle redirect to project
    if(vaultId && projectId){
      navigate(
        `/vault/${vaultId}/project/${projectId}${window.location.search}`
      );
    }else{
      navigate(
        `/projects`
      );
    }
  }, [queryParams]);

  return (
    <>
    </>
  );
};

export default LeadOptInPage;
