import { useReducer, createContext, useContext } from 'react';

export const CacheStoreContext = createContext({
  searchProjects: null
});

const CacheStore = (props) => {
  const [CacheState, CacheStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'setCachedSearchedProjects':
          return {
            ...state,
            searchProjects: state.searchProjects.set(action.payload.searchValue, action.payload.projects)
          };
        default:
          return state;
      }
    },
    {
      searchProjects: new Map()
    }
  );

  return (
    <CacheStoreContext.Provider value={{ CacheState, CacheStateDispatch }}>
      {props.children}
    </CacheStoreContext.Provider>
  );
};

export const useCacheState = () => useContext(CacheStoreContext);

export default CacheStore;
