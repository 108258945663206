import { useState, useEffect, useCallback, useRef } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'src/components/Loader';

// Helpers
import localizer from 'src/localization/localizer';
import { motion, AnimatePresence } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';
import { useAppState } from 'src/store/AppStore';

// Styling
import styled, { StyledProps, css } from 'styled-components';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const SuggestedLocation = styled.div`
  width: 100%;
  height: 36px;
  padding: 5px 16px;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 26px;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.gray5};
  }
`;

const LoaderWrapper = styled.div<StyledProps<{ fixed?: boolean }>>`
  height: ${isMobileOnly ? 48 : 60}px;
  margin: 0 auto;
  ${({ fixed }) =>
    fixed &&
    css`
      position: absolute;
      z-index: 100;
      top: 0;
      right: 12px;
    `}
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.redDark};
  font-size: 0.875rem;
  padding: 12px;
  span {
    margin-left: 8px;
  }
`;

const StyledInput = styled.input<
  StyledProps<{ fixedDropdown?: boolean; styles?: string }>
>`
  width: 100%;
  height: ${isMobileOnly ? 48 : 60}px;
  padding: 5px 35px;
  box-sizing: border-box;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.white};
  outline: none;
  font-size: ${({ fixedDropdown }) => (fixedDropdown ? 1.125 : 0.875)}rem;
  text-overflow: ellipsis;
  ${({ styles }) => styles}
`;

const ContentWrapper = styled(motion.div)<
  StyledProps<{ fixed?: boolean; styles?: string }>
>`
  width: calc(100% + 32px);
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 12px -16px 0;
  background-color: ${({ theme }) => theme.white};
  ${({ fixed, theme }) =>
    fixed &&
    css`
      position: absolute;
      z-index: 100;
      top: ${isMobileOnly ? 38 : 50}px;
      left: 16px;
      width: 100%;
      border: 1px solid ${theme.gray20};
      border-radius: 4px;
      box-sizing: border-box;
    `}
  ${({ styles }) => styles}
`;

const SearchIcon = styled(FontAwesomeIcon)<StyledProps<{ styles?: string }>>`
  margin: auto;
  color: ${({ theme }) => theme.gray50};
  ${({ styles }) => styles}
`;

const ResultWrapper = styled.div`
  padding: 12px;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: ${isMobileOnly ? 48 : 60}px;
  width: 40px;
  display: flex;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  z-index: 110;
  top: 0;
  right: 0;
  height: ${isMobileOnly ? 48 : 60}px;
  width: 40px;
  display: flex;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  margin: auto;
  width: 20px !important;
  color: ${({ theme }) => theme.gray50};
  cursor: pointer;
  flex-shrink: 0;
`;

const SEARCH_RADIUS = 900000;
const NUMBER_OF_SHOWN_RESULTS = 4;
const SEARCH_DELAY_MS = 250;

interface LocationInputProps {
  value: string;
  onSelect: (location: any) => void;
  fixedDropdown?: boolean;
  onClose: (() => void) | null;
  styles?: {
    input?: string;
    iconSize?: SizeProp;
    dropdown?: string;
    searchIcon?: string;
  };
  onUpdateLocation?: (locations: any[]) => void;
}

const LocationInput = ({
  value,
  onSelect,
  fixedDropdown = false,
  onClose,
  styles = {},
  onUpdateLocation
}: LocationInputProps) => {
  const [searchValue, setSearchValue] = useState(value);
  const [canSearchLocations, setCanSearchLocations] = useState(false);

  const [lastRequest, setLastRequest] = useState('');
  const [placesService, setPlacesService] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);

  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // App state
  const { AppState } = useAppState();
  const { googleApiLoaded } = AppState;

  // REFS
  const startSearchRef = useRef<any>();

  const locationsToShow = locations.slice(0, NUMBER_OF_SHOWN_RESULTS);

  const onLocationClick = useCallback(
    (location: any) => {
      if (placesService && location) {
        //@ts-ignore
        placesService.getDetails(
          { placeId: location.place_id },
          (place: any, status: any) => {
            if (
              // @ts-ignore
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              place
            ) {
              onSelect(place);
              setLocations([]);
              setSearchValue(location.formatted_address);
              setCanSearchLocations(false);
            }
          }
        );
      }
    },
    [onSelect, placesService]
  );

  // To avoid searching for locations on every key stroke
  useEffect(() => {
    if (startSearchRef.current) {
      clearTimeout(startSearchRef.current);
    }

    if (searchValue) {
      startSearchRef.current = setTimeout(() => {
        clearTimeout(startSearchRef.current);
        setCanSearchLocations(true);
      }, SEARCH_DELAY_MS);
    }
  }, [searchValue]);

  // Reset states when search value is empty
  useEffect(() => {
    if (!searchValue) {
      setLocations([]);
      setLastRequest('');
      setLoading(false);
    }
  }, [searchValue]);

  // Pick the first suggested location on Entrer press
  useEffect(() => {
    const handlePressEnter = (event: any) => {
      if (event.key === 'Enter' && locationsToShow[0]) {
        onLocationClick(locationsToShow[0]);
      }
    };

    window.addEventListener('keydown', handlePressEnter);
    return () => {
      window.removeEventListener('keydown', handlePressEnter);
    };
  }, [locationsToShow, onLocationClick]);

  // Set Google places service instance
  useEffect(() => {
    // @ts-ignore
    if (googleApiLoaded && window.google.maps && !autocompleteService) {
      const timer = setTimeout(() => {
        clearTimeout(timer);

        // @ts-ignore
        const center = new window.google.maps.LatLng(
          51.050125202328346,
          3.729438531523031
        ); // Ghent

        setMapCenter(center);

        setAutocompleteService(
          // @ts-ignore
          new window.google.maps.places.AutocompleteService()
        );

        // @ts-ignore
        const map = new window.google.maps.Map(
          document.getElementById('hidden-map-container'),
          { center }
        );
        setPlacesService(
          // @ts-ignore
          new window.google.maps.places.PlacesService(map)
        );
      }, 100);
    }
  }, [autocompleteService, googleApiLoaded]);

  useEffect(() => {
    setError(false);
    if (!searchValue) return;
    if (searchValue === value) return;
    if (!canSearchLocations) return;

    setCanSearchLocations(false);

    if (autocompleteService && searchValue !== lastRequest) {
      setLastRequest(searchValue);

      setLoading(true);

      const request = {
        input: searchValue,
        language: localizer.getLanguage(),
        types: ['(regions)'],
        componentRestrictions: { country: 'be' }
      };

      // @ts-ignore
      autocompleteService.getPlacePredictions(
        request,
        (predictions: any, status: any) => {
          if (
            // @ts-ignore
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setLoading(false);
            setLocations(predictions);
            onUpdateLocation && onUpdateLocation(predictions);
          } else {
            setError(true);
          }
        }
      );
    }
  }, [
    searchValue,
    lastRequest,
    autocompleteService,
    mapCenter,
    canSearchLocations,
    value,
    onUpdateLocation
  ]);

  const content = loading ? (
    <LoaderWrapper fixed={fixedDropdown}>
      <Loader />
    </LoaderWrapper>
  ) : locationsToShow.length > 0 ? (
    <ContentWrapper
      initial={{ opacity: 0, x: -15 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 15 }}
      fixed={fixedDropdown}
      styles={styles.dropdown}
    >
      {locationsToShow.map((loc: any, i) => (
        <SuggestedLocation key={i} onClick={() => onLocationClick(loc)}>
          {loc.description}
        </SuggestedLocation>
      ))}
    </ContentWrapper>
  ) : null;

  return (
    <Wrapper>
      <SearchIconWrapper>
        <SearchIcon
          icon={['far', 'search']}
          size={styles.iconSize ?? 'sm'}
          styles={styles.searchIcon}
        />
      </SearchIconWrapper>
      <StyledInput
        value={searchValue}
        onChange={({ target }) => setSearchValue(target.value)}
        placeholder={value || localizer.projectsFilters.findLocation}
        fixedDropdown={fixedDropdown}
        styles={styles.input}
      />
      {value && onClose && (
        <CloseIconWrapper>
          <CloseIcon
            icon={['fas', 'times-circle']}
            size="1x"
            onClick={() => {
              setLocations([]);
              setLastRequest('');
              setSearchValue('');
              setLoading(false);
              onClose();
            }}
          />
        </CloseIconWrapper>
      )}
      {error ? (
        <ErrorMessage>
          <FontAwesomeIcon icon={['fal', 'info-circle']} size="1x" />
          <span>{localizer.projectsFilters.cantFindLocation}</span>
        </ErrorMessage>
      ) : (
        <ResultWrapper>
          <AnimatePresence>{content}</AnimatePresence>
        </ResultWrapper>
      )}

      <div id="hidden-map-container" />
    </Wrapper>
  );
};

export default LocationInput;
