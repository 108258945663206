import * as Sentry from '@sentry/react';

// Helpers
import getCurrentEnvironment from 'src/environment';
import { getPackageVersion } from './utils';

export default () => {
  const { env } = getCurrentEnvironment();
  const isProd = env === 'production';

  const initObject = {
    dsn: 'https://49933bbd18b42330131fa1a9db5cc480@o23101.ingest.sentry.io/4506217702490112',
    release: getPackageVersion(),
    debug: !isProd,
    environment: env,

    integrations: [],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  };

  if (window?.location?.hostname !== 'localhost') {
    Sentry.init(initObject);
  }
};
