import { memo } from 'react';

// Components
import Album360Content from './types/album360/Album360Content';
import DocumentContent from './types/document/DocumentContent';
import ImageContent from './types/image/ImageContent';
import VideoContent from './types/video/VideoContent';
import ThreeSixtyImageContent from '../ThreeSixtyImageContent';

// Helpers
import fileCategories from 'src/configs/SupportedFileCategories.json';
import { isMobileOnly } from 'react-device-detect';
import env from 'src/environment';

// Styles
import styled, { StyledProps } from 'styled-components';

const Wrapper = styled.div<StyledProps<{ isMobileOnly: boolean }>>`
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: ${({ isMobileOnly }) => (isMobileOnly ? 'absolute' : 'relative')};
  ${({ isMobileOnly }) => {
    if (isMobileOnly)
      return `
  top: 0;
  left: 0;
  z-index: 56;
  overflow: hidden;
`;
  }}

  .react-transform-component {
    width: 100% !important;
    height: 100% !important;
  }

  .react-transform-element {
    width: 100% !important;
    height: 100% !important;
  }
`;

export interface MobilePageProps {
  data: any;
  videoFallbackThumbnail: string;
  setIsDragEnabled: (isEnabled: boolean) => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onClose: () => void;
  isDragEnabled: boolean;
  CustomNavigation?: any;
  pageIndex: number;
  allPages: any;
  autoplay: boolean;
  showGrid?: boolean;
}

const MobilePage = memo((props: MobilePageProps) => {
  const { data, videoFallbackThumbnail, autoplay, showGrid } = props;
  const { CustomNavigation, ...otherProps } = props;

  const sharedProps = {
    ...otherProps,
    key: data.objectId
  };

  // Content
  const createPageContent = () => {
    switch (data.contentItemType) {
      case fileCategories.image: {
        return <ImageContent {...sharedProps} />;
      }
      case fileCategories.video: {
        return (
          <VideoContent
            autoplay={autoplay}
            data={data}
            showGrid={showGrid}
            fallbackThumbnail={videoFallbackThumbnail}
            transitioning={false}
          />
        );
      }
      case fileCategories.document:
      case fileCategories.floorplan: {
        return <DocumentContent {...sharedProps} />;
      }
      case fileCategories.album360: {
        return <Album360Content {...sharedProps} />;
      }
      case fileCategories.image360: {
        return (
          <ThreeSixtyImageContent
            {...sharedProps}
            contentUri={`${env().baseImageUrl}/o=true/${data?.contentUri}`}
            onLoad={() => {
              console.log('360 content loaded');
            }}
          />
        );
      }
      case fileCategories.turntable: {
        return null;
      }
      default: {
        return <ImageContent {...sharedProps} />;
      }
    }
  };

  const content = createPageContent();

  return (
    <Wrapper isMobileOnly={isMobileOnly} key={data.name}>
      {content}
    </Wrapper>
  );
});

export default MobilePage;
