import { useState, useEffect, RefObject } from 'react';

interface IObserverConfig {
  root?: HTMLElement;
  rootMargin?: string;
  threshold?: number | number[];
  minIntersectionRatio?: number;
}

const useIntersectionObserver = (
  ref: RefObject<HTMLElement>,
  config: IObserverConfig = {}
) => {
  const [gotVisible, setGotVisible] = useState(false);

  useEffect(() => {
    if (ref.current) {
      if (typeof window !== 'undefined') {
        const threshold = 0;
        const observerConfig = {
          rootMargin: '-15%',
          threshold,
          ...config
        };
        const handleIntersection = function (
          entries: { isIntersecting: boolean; intersectionRatio: number }[]
        ) {
          entries.forEach(
            (entry: { isIntersecting: boolean; intersectionRatio: number }) => {
              if (observerConfig.minIntersectionRatio) {
                setGotVisible(
                  entry.intersectionRatio > observerConfig.minIntersectionRatio
                );
              } else if (entry.isIntersecting) {
                setGotVisible(true);
              }
            }
          );
        };
        const observer = new IntersectionObserver(
          handleIntersection,
          observerConfig
        );
        observer.observe(ref.current);

        return () => observer.disconnect();
      } else {
        setGotVisible(true);
      }
    }
  }, [ref, config]);

  return { gotVisible };
};

export default useIntersectionObserver;
