import { useRef } from 'react';

import CreateAccountImage from 'src/resources/images/create-account-image.png';

// Helpers
import { motion } from 'framer-motion';
import useIntersectionObserver from 'src/hooks/use-intersection-observer';
import { useNavigate } from 'react-router-dom';
import localizer from 'src/localization/localizer';
import { useAuthState } from 'src/store/AuthStore';

// Components
import Button from 'src/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import styled, { withTheme } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';
import { Text, SmallText } from './sharedStyledComponents';

const Wrapper = styled(motion.div)`
  width: 100%;
  background: ${({ theme }) => theme.white};
  position: relative;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const Users = styled.div`
  margin-bottom: 32px;
  display: flex;
`;

const UserIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  margin-left: 8px;
`;

const CreateAccountImg = styled.img`
  height: 60px;
  width: auto;
  ${forBiggerThan.mobile`
  height: 70px;
`}
  ${forBiggerThan.tablet`
  height: 80px;
`}
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
  margin-right: 8px;
`;

const SmallText2 = styled(SmallText)`
  margin-bottom: 8px;
`;

const CreateAccountBlock = ({
  theme
}: {
  theme: { [key: string]: string };
}) => {
  const ref = useRef(null);
  const { gotVisible } = useIntersectionObserver(ref);

  const navigate = useNavigate();

  const { AuthState } = useAuthState();
  const { user } = AuthState;

  if (user) return <></>;

  return (
    <Wrapper
      ref={ref}
      animate={{ opacity: gotVisible ? 1 : 0, y: gotVisible ? 0 : 100 }}
      transition={{ ease: 'linear', duration: 0.3 }}
    >
      <Users>
        <CreateAccountImg src={CreateAccountImage} />
      </Users>

      <Text>
        {localizer.createAccountBlock.title1}
        <span>{localizer.createAccountBlock.title2}</span>
      </Text>
      <SmallText2>{localizer.createAccountBlock.description1}</SmallText2>
      <SmallText>{localizer.createAccountBlock.description2}</SmallText>
      <Button
        onClick={() => {
          navigate(`/register${window.location.search}`);
        }}
        styles={`background: ${theme.black}; min-width: 270px; height: 60px;`}
      >
        <UserIcon icon={['fal', 'user-circle']} size="sm" />

        {localizer.createAccountBlock.createAccount}

        <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
      </Button>
    </Wrapper>
  );
};

export default withTheme(CreateAccountBlock);
