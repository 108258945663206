import React, { useEffect, useState } from 'react';

// Component
import Button from 'src/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';

// Helpers
import localizer from 'src/localization/localizer';
import copy from 'copy-to-clipboard';
import { isMobileOnly } from 'react-device-detect';
import qs from 'query-string';

// Styling
import styled, { css } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

export interface IShareData {
  title: string;
  text: string;
  url: string;
}

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.black};
  font-size: 0.875rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  background: ${({ theme }) => theme.beigeBg20};
  transition: all 0.3s;
  height: 48px;
  min-width: 48px;
  width: fit-content;
  padding: 0 20px;
  column-gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0 0 0 auto;
  position: relative;

  &:hover {
    background: ${({ theme }) => theme.beigeBg30};
  }

  ${forBiggerThan.tablet`
    width: 120px;
    min-width: 120px;
  `}

  ${isMobileOnly &&
  css`
    border: solid 1px ${({ theme }) => theme.gray20};
    &:hover {
      background-color: ${({ theme }) => theme.beigeBg10};
    }
  `}
`;

const Toast = styled(motion.div)`
  background: ${({ theme }) => theme.beigeBg20};
  border-radius: 8px;
  position: absolute;
  min-width: 150px;
  padding: 10px 20px;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    background: ${({ theme }) => theme.beigeBg20};
    width: 10px;
    height: 10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: 1;
  }
`;

const ShareIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
`;

interface ShareButtonProps {
  projectTitle: string;
  unitTitle?: string;
  className?: string;
}

const ShareButton = ({
  projectTitle,
  unitTitle,
  className
}: ShareButtonProps) => {
  const [showToast, setShowToast] = useState(false);
  const [shareData, setShareData] = useState<IShareData | null>(null);

  useEffect(() => {
    if (projectTitle) {
      const { origin, pathname } = window.location;

      const parsed: any = qs.parse(window.location.search);

      [
        { utm_source: 'marketplace' },
        { utm_campaign: unitTitle ? 'unitShare' : 'projectShare' }
      ].forEach((param) => {
        const [key, value] = Object.entries(param)[0];
        parsed[key] = value;
      });

      const url = `${origin}${pathname}?${qs.stringify(parsed)}`;
      setShareData({
        title: unitTitle
          ? localizer.formatString(
              localizer.sharing.unitInProject,
              unitTitle,
              projectTitle
            )
          : projectTitle,
        text: localizer.formatString(
          localizer.sharing[
            unitTitle ? 'checkoutTheUnit' : 'checkoutTheProject'
          ],
          projectTitle || ''
        ),
        url
      } as IShareData);
    }
  }, [projectTitle, unitTitle, window.location.href]);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 1500);
    }
  }, [showToast]);

  return (
    <StyledButton
      className={className}
      type="button"
      doNotScaleOnHover
      onClick={() => {
        if (navigator.share) {
          navigator.share(shareData as IShareData);
        } else {
          setShowToast(true);
          copy(shareData?.url as string);
        }
      }}
    >
      <ShareIcon icon={['far', 'share-alt']} size="1x" />
      {localizer.sharing.share}
      <AnimatePresence>
        {showToast ? (
          <Toast
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {localizer.sharing.copiedToClipboard}
          </Toast>
        ) : (
          (null as React.ReactNode)
        )}
      </AnimatePresence>
    </StyledButton>
  );
};

export default ShareButton;
