import React, { useEffect } from 'react';

// Components
import LanguageSwitcher from 'src/pages/landingPage/LanguageSwitcher';
import ProjectSearch from 'src/components/projectSearch/ProjectSearch';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'src/store/AuthStore';
// Helpers
import env from 'src/environment';
import localizer from 'src/localization/localizer';

// Styling
import styled, { StyledProps } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfilePicture from './ProfilePicture';

const Wrapper = styled.div<StyledProps<{ isMenuOpened: boolean }>>`
  position: fixed;
  height: 100dvh;
  width: 100vw;
  left: 0;
  top: 0;
  padding: 88px 32px 14px 32px;
  display: ${({ isMenuOpened }) => (isMenuOpened ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.beigeBg10};
  z-index: 3003; // 3002 - z-index for 'contact us' button
`;

const BottomSection = styled.div`
  width: 100%;
  z-index: 0;
  justify-content: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 10px;
`;

const MenuItem = styled.div`
  width: 100%;
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.gray80};
  user-select: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.beigeBg20};
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.gray300};
  align-self: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const AccountLabel = styled.div`
  width: 100%;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.gray60};
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`;

const LoginButton = styled.div`
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  margin-top: 12px;
`;

const LoginIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 8px;
  color: #8f95a3;
`;

const ProfilePicWrapper = styled.div`
  width: 100%;
`;

interface MainMenuMobileProps {
  onLanguageSwitched: (value: string) => void;
  isMenuOpened: boolean;
  setIsMenuOpened: (value: boolean) => void;
}

const MainMenuMobile = ({
  onLanguageSwitched,
  isMenuOpened,
  setIsMenuOpened
}: MainMenuMobileProps) => {
  const navigate = useNavigate();

  const { AuthState } = useAuthState();
  const { user } = AuthState;

  useEffect(() => {
    if (isMenuOpened) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [isMenuOpened]);

  let userButton = (
    <LoginButton
      onClick={() => {
        navigate(`/login${window.location.search}`);
      }}
    >
      <LoginIcon icon={['fas', 'sign-in']} size="sm" /> Login to Prompto Account
    </LoginButton>
  );

  if (user && user !== undefined) {
    userButton = (
      <ProfilePicWrapper>
        <ProfilePicture
          onClick={() => {
            navigate(`/account${window.location.search}`);
          }}
          enableDropdown={true}
          size={60}
          showName={true}
        />
      </ProfilePicWrapper>
    );
  }

  return (
    <Wrapper isMenuOpened={isMenuOpened}>
      <BottomSection>
        <MenuItem
          onClick={() => {
            navigate(`/projects` + window.location.search);
          }}
        >
          {localizer.header.menu.offer}
          <ArrowIcon icon={['far', 'chevron-right']} size="sm" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`/developers` + window.location.search);
          }}
        >
          {localizer.header.menu.developers}
          <ArrowIcon icon={['far', 'chevron-right']} size="sm" />
        </MenuItem>
        <MenuItem
          onClick={() => window.open(env().leadGenerationUrl, '_blank')}
        >
          {localizer.header.menu.advertiseHere}
          <ArrowIcon icon={['far', 'chevron-right']} size="sm" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(`${env().contactUs}?utm_source=marketplace`, '_blank');
          }}
        >
          {localizer.header.menu.contactUs}
          <ArrowIcon icon={['far', 'chevron-right']} size="sm" />
        </MenuItem>
        <Divider />
        <AccountLabel>Account</AccountLabel>
        {userButton}
        <Divider />
        <MenuItem>
          <LanguageSwitcher
            mobileView
            onLanguageSwitched={onLanguageSwitched}
          />
        </MenuItem>
      </BottomSection>
    </Wrapper>
  );
};

export default MainMenuMobile;
