import { useEffect, useState, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';

// Styling
import styled, { StyledProps } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdealImage } from '@prompto-ui';
import { RichTextRenderer } from '@prompto-ui';
import StyledScrollbarsWrapper from 'src/components/StyledScrollbarWrapper';
import Loader from 'src/components/Loader';

// Helpers
import env from 'src/environment';

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.white};
  background-color: rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(1px);
  display: flex;
  transition: all 150ms ease;
`;

const LoaderWrapper = styled(Wrapper)`
  z-index: 1999;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(Wrapper)`
  z-index: 1999;
  background-color: rgba(0, 0, 0, 0.4);
`;

const UspContent = styled(motion.div)<
  StyledProps<{ size: any; showInfo: boolean }>
>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90dvw;
  max-height: 90dvh;
  width: ${({ size }) => size?.width + 'px'};
  min-width: ${({ showInfo }) => (showInfo ? 330 : 0)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 2px;
  overflow: hidden;
`;

const Media = styled.div<StyledProps<{ size: any }>>`
  height: ${({ size }) => size?.height}px;
  flex-grow: 1;
`;

const MainInfoWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black};
  font-family: ${({ theme }) => theme.fonts.DMSans};
`;

const Headline = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin: 0;
  margin-bottom: 10px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const MediaWrapper = styled.div<
  StyledProps<{ isTransparentBackground: boolean }>
>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme, isTransparentBackground }) =>
    isTransparentBackground ? 'transparent' : theme.white};
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.gray10};
  position: absolute;
  z-index: 1;
`;

const StyledVideo = styled.video<StyledProps<{ shouldFit: boolean }>>`
  position: absolute;
  top: 0;
  left: 0;
  object-position: center;
  object-fit: ${({ shouldFit }) => (shouldFit ? 'contain' : 'cover')};
  width: 100%;
  height: 100%;
`;

const Button = styled.button`
  position: absolute;
  z-index: 4;
  right: 1rem;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 1rem;
`;

const ToggleVolumeButton = styled(Button)`
  bottom: 1rem;
`;

const CloseUspButton = styled(Button)`
  top: 1rem;
`;

const defaultContainerSize = {
  width: 960,
  height: 660
};

// Types
interface EnlargedUspProps {
  enlargedUsp: any;
  closeEnlargedUsp: () => void;
}

const EnlargedUsp = (props: EnlargedUspProps) => {
  const { enlargedUsp, closeEnlargedUsp } = props;

  const [show, setShow] = useState(false);
  const [usp, setUsp] = useState<any>({});

  const [imageLoaded, setImageLoaded] = useState(false);

  const [isVideoMuted, setVideoMuted] = useState(true);
  const [enterTransition, setEnterTransition] = useState({
    x: 0,
    y: 0
  });
  const [exitTransition, setExitTransition] = useState({
    x: 0,
    y: 0
  });
  const [transitionCompleted, setTransitionCompleted] = useState(false);
  const [containerSize, setContainerSize] = useState(defaultContainerSize);
  const [imageSizeCalculated, setImageSizeCalculated] = useState(false);

  const [imageSize, setImageSize] = useState<any>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (enlargedUsp) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    }
  }, [enlargedUsp]);

  useEffect(() => {
    const width =
      usp?.size?.width && usp?.size?.width !== 0
        ? usp?.size?.width
        : wrapperRef.current?.offsetWidth;
    const height =
      usp?.size?.height && usp?.size?.height !== 0
        ? usp?.size?.height
        : wrapperRef.current?.offsetHeight;
    setImageSize({ width, height });
  }, [usp]);

  useEffect(() => {
    if (enlargedUsp === null) {
      setShow(false);
    } else {
      setUsp(enlargedUsp.data);
      setShow(true);
      setVideoMuted(!!enlargedUsp.data?.isVideoMuted);
      setEnterTransition({
        x: enlargedUsp.origin.x - window.innerWidth / 2,
        y: enlargedUsp.origin.y - window.innerHeight / 2
      });
      setExitTransition({
        x: enlargedUsp.spotLocation.x - window.innerWidth / 2,
        y: enlargedUsp.spotLocation.y - window.innerHeight / 2
      });
    }
    setShow(!(enlargedUsp === null));
  }, [enlargedUsp]);

  // recalculate container size for pinned usps
  useEffect(() => {
    if (!usp || !usp.isPinned || !usp.ratio) return;
    const infoHeight = infoRef.current?.offsetHeight ?? 0;
    const maxMediaHeight = window.innerHeight * 0.9 - infoHeight;
    const maxMediaWidth = window.innerWidth * 0.9;
    let width = 0;
    let height = 0;
    if (usp.ratio >= 1) {
      height = maxMediaHeight;
      // limit min width by 350 to handle extremely narrow images
      width = Math.max(maxMediaHeight / usp.ratio, 350);
    } else {
      width = maxMediaWidth;
      height = maxMediaWidth * usp.ratio;
    }
    if (width && height) {
      setContainerSize({
        width,
        height: height + infoHeight
      });
    }
  }, [usp]);

  const onCloseEnlargedUsp = useCallback(() => {
    document.body.style.overflow = 'auto';
    document.body.style.height = 'auto';
    closeEnlargedUsp();
    setContainerSize(defaultContainerSize);
    setImageSizeCalculated(false);
  }, [closeEnlargedUsp]);

  let mustFillParent = false;
  if(imageSize?.height >= window?.innerHeight){
    mustFillParent  = imageSize?.height >= window?.innerHeight;
  }
  const mediaContent = (
    <MediaWrapper
      ref={wrapperRef}
      isTransparentBackground={
        usp?.isPinned &&
        !usp?.visibilitySettings?.showHeadline &&
        !usp?.visibilitySettings?.showDescription
      }
    >
      {usp?.mediaType === 'image' && (
        <>
          {!imageLoaded && <ImagePlaceholder />}
          <IdealImage
            key={`enlargedUspImage`}
            contentUri={usp?.mediaContent}
            fallbackUri={usp?.mediaContent}
            imageSize={{
              width: imageSize.width ?? 0,
              height: imageSize.height ?? 0
            }}
            containerSize={containerSize}
            mustFillParent={mustFillParent}
            onLoad={(iWidth: number, iHeight: number, target: any, isPlaceholder: boolean) => {
              if (isPlaceholder || imageSizeCalculated) return;
              setImageSizeCalculated(true);
              setImageLoaded(true);
              // adopt media content size
              const imageWidth = target.naturalWidth ?? imageSize.width;
              const imageHeight = target.naturalHeight ?? imageSize.height;

              setImageSize({ width: imageWidth, height: imageHeight });

              const iRatio = target.naturalHeight / target.naturalWidth;

              // vertical image
              if (iRatio > 1) {
                const height = Math.min(window.innerHeight * 0.9, imageHeight);
                const width = height / iRatio;
                setContainerSize({ width, height });
              } else {
                const width = Math.min(window.innerWidth * 0.9, imageWidth);
                const height = width * iRatio;
                setContainerSize({ width, height });
              }
            }}
            shouldFit={true}
            imageFitFillType={'fit'}
            baseImageUrl={env().baseImageUrl}
          />
        </>
      )}

      {usp?.mediaType === 'video' && (
        <>
          <StyledVideo
            src={usp?.mediaContent}
            autoPlay={true}
            controls={false}
            preload="auto"
            playsInline={true}
            shouldFit={usp?.shouldFit}
            muted={isVideoMuted}
          />
          <ToggleVolumeButton
            onClick={(e) => {
              e.stopPropagation();
              setVideoMuted(!isVideoMuted);
            }}
          >
            <FontAwesomeIcon
              icon={['fal', isVideoMuted ? 'volume-slash' : 'volume-up']}
              size="1x"
            />
          </ToggleVolumeButton>
        </>
      )}
    </MediaWrapper>
  );

  const shouldShowContent =
    (usp?.mediaContent &&
      ((usp?.mediaType === 'image' && imageSizeCalculated) ||
        usp?.mediaType === 'video')) ||
    !usp?.mediaContent;

  return createPortal(
    <AnimatePresence>
      {show && enlargedUsp && (
        <>
          <Backdrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          />
          <Wrapper
            id="enlarged-usp-container"
            initial={{
              opacity: 0,
              scale: 0,
              ...enterTransition
            }}
            animate={{
              opacity: shouldShowContent ? 1 : 0,
              scale: 1,
              x: 0,
              y: 0
            }}
            exit={{
              opacity: 0.1,
              scale: 0.05,
              ...exitTransition
            }}
            transition={{ duration: 0.25, ease: 'easeOut' }}
            onClick={() => {
              if (transitionCompleted) {
                document.body.style.overflow = 'auto';
                document.body.style.height = 'auto';
                closeEnlargedUsp();
              }
            }}
            onAnimationStart={() => setTransitionCompleted(false)}
            onAnimationComplete={() => setTransitionCompleted(true)}
          >
            <UspContent
              onClick={(e) => e.stopPropagation()}
              size={containerSize}
              showInfo={
                usp?.visibilitySettings?.showHeadline ||
                usp?.visibilitySettings?.showDescription
              }
            >
              <CloseUspButton
                onClick={(e) => {
                  e.stopPropagation();
                  onCloseEnlargedUsp();
                }}
              >
                <FontAwesomeIcon icon={['fal', 'compress']} size="1x" />
              </CloseUspButton>
              <Media size={containerSize}>{mediaContent}</Media>
              {[
                usp?.visibilitySettings?.showHeadline,
                usp?.visibilitySettings?.showDescription
              ].some((x) => x !== false) && (
                <MainInfoWrapper ref={infoRef}>
                  {usp?.visibilitySettings?.showHeadline !== false && (
                    <Headline>{usp?.headline}</Headline>
                  )}
                  {usp?.visibilitySettings?.showDescription !== false && (
                    <StyledScrollbarsWrapper size={'20vh'}>
                      <RichTextRenderer
                        richText={usp?.richTextDescription}
                        fallbackValue={usp?.description}
                        fontSize={`0.75rem`}
                      />
                    </StyledScrollbarsWrapper>
                  )}
                </MainInfoWrapper>
              )}
            </UspContent>
          </Wrapper>
          <LoaderWrapper
            animate={{
              opacity:
                (usp?.mediaContent && imageSizeCalculated) || !usp?.mediaContent
                  ? 0
                  : 1
            }}
            transition={{ duration: 0 }}
          >
            <Loader color={'gray'} />
          </LoaderWrapper>
        </>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default EnlargedUsp;
