import { FlattenSimpleInterpolation, css } from 'styled-components';

interface TemplateStringsArray extends ReadonlyArray<string> {
  readonly raw: ReadonlyArray<string>;
}

enum BreakpointType {
  mobile = 'mobile',
  tablet = 'tablet',
  laptop = 'laptop',
  desktop = 'desktop',
  largeDesktop = 'largeDesktop',
}

type Breakpoints = {
  [key in BreakpointType | string]: string;
};

type BreakpointRules = {
  [key in BreakpointType | string]: (
    args_0: TemplateStringsArray,
    replacement?: any
  ) => FlattenSimpleInterpolation;
};

export const breakpoints: Breakpoints = {
  mobile: '576px',
  tablet: '768px',
  laptop: '992px',
  desktop: '1200px',
  largeDesktop: '1440px'
};

export const forBiggerThan = Object.keys(breakpoints).reduce<BreakpointRules>(
  (
    accumulator,
    label: keyof typeof BreakpointType | string
  ): BreakpointRules => {
    accumulator[label] = (...args: [TemplateStringsArray]) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);
