import { useCallback, useState } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import ThumbnailComponent from 'src/components/Thumbnail';

// Helpers
import { buildAddress } from 'src/helpers/vmProjectSection';
import { useNavigate } from 'react-router-dom';
import { IProject } from '@/libs/prompto-api/src';
import localizer, { getValueFromLocalized } from 'src/localization/localizer';

// Styling
import styled, { StyledProps } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const Card = styled.div<
  StyledProps<{
    gridArea: string | number;
    fixedHeight?: string;
  }>
>`
  width: 100%;
  height: ${({ fixedHeight }) => (fixedHeight ? fixedHeight : '100%')};
  box-sizing: border-box;
  padding: 32px;
  position: relative;
  overflow: hidden;

  grid-area: ${({ gridArea }) => gridArea};
  cursor: pointer;
  transition: all 150ms ease;
`;

const ShowProjectButton = styled(motion.div)`
  height: 60px;
  min-width: 200px;
  padding: 5px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 2px solid ${({ theme }) => theme.white};
  font-size: 1.125rem;
  color: ${({ theme }) => theme.white};
  position: absolute;
  z-index: 7;
  right: 16px;
  top: calc(50% - 30px);
  backdrop-filter: blur(10px);
`;

const ProjectData = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 32px;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
`;

const Logo = styled.img`
  position: absolute;
  top: 16px;
  left: 16px;
  width: auto;
  height: 45px;
`;

const FavoriteToggle = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 7;
  top: 16px;
  right: 16px;
  border: none;
  border-radius: 4px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
`;

const FavoriteIcon = styled(FontAwesomeIcon)`
  margin: auto;
  color: ${({ theme }) => theme.white};
`;

const LocationIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75rem;
  color: ${({ theme }) => theme.gray50};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.beigeBg10};
  font-size: 1.5rem;
  line-height: 30px;
  margin: 0 0 5px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
  ${forBiggerThan.mobile`
    font-size: 1.875rem;
    line-height: 40px;
    margin: 0 0 8px;
  `}
`;

const Address = styled(Title)`
  color: ${({ theme }) => theme.white};
  font-size: 0.875rem;
  line-height: 20px;
  margin: 0;
  padding-left: 20px;
  position: relative;
  flex-shrink: 0;
  ${forBiggerThan.mobile`
    font-size: 1rem;
  `}
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

const TotalUnitsIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 0.875rem;
`;

const BeTheFirstIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 0.875rem;
`;

const PillWrapper = styled.div`
  display: flex;
  flex-flow: row;
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 5;
  column-gap: 8px;
`;

const InfoPill = styled.div<StyledProps<{ isHighlighted?: boolean }>>`
  height: 32px;
  border-radius: 18px;
  backdrop-filter: blur(10px);
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? 'rgba(209, 109, 58, 0.5)' : 'rgba(0, 0, 0, 0.07)'};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0 16px;
`;

const AccentPill = styled(InfoPill)`
  background-color: rgba(0, 123, 111, 0.5);
`;

const FavouriteButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  z-index: 5;
`;

const FavouriteIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  height: 20px !important;
  color: white;
`;

interface ProjectCardProps {
  project: IProject;
  idx: number;
  size: number;
  fixedHeight?: string;
  hideBadge?: boolean;
  isDragging?: boolean;
}

const ProjectCard = ({
  project,
  idx,
  size,
  hideBadge,
  isDragging,
  fixedHeight
}: ProjectCardProps) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (isDragging) return;
    navigate(
      `/vault/${project.vaultId}/project/${project.projectId}${window.location.search}`
    );
  }, [isDragging]);

  const projectTitle = getValueFromLocalized(
    project?.localizedTitle,
    project?.title ?? ''
  );

  const unitsCount =
    (project?.unitCount ?? project?.unitsWithSpecificStatus) || 0;

  return (
    <Card
      key={project.projectId}
      gridArea={`p-${idx}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
      fixedHeight={fixedHeight}
    >
      <PillWrapper>
        {!!unitsCount && (
          <InfoPill isHighlighted={!!project.unitsWithSpecificStatus}>
            <TotalUnitsIcon icon={['far', 'building']} size="sm" />
            {unitsCount}
          </InfoPill>
        )}
        {idx === 0 && !hideBadge && (
          <AccentPill>
            <BeTheFirstIcon icon={['far', 'eye']} size="sm" />
            {localizer.projectSlider.beTheFirst}
          </AccentPill>
        )}
      </PillWrapper>
      <FavouriteButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <FavouriteIcon icon={[false ? 'fas' : 'far', 'heart']} size="sm" />
      </FavouriteButton>
      <ThumbnailComponent
        uri={project.projectThumbnailUri as string}
        height={Math.round(size)}
        hovered={hovered}
      />

      {/*
        <FavoriteToggle>
          <FavoriteIcon icon={['fal', 'heart']} size="lg" />
        </FavoriteToggle>
      */}

      <ShowProjectButton
        initial={{ opacity: 0, x: -40 }}
        animate={{ opacity: hovered ? 1 : 0, x: hovered ? 0 : -40 }}
        transition={{ ease: 'linear', duration: 0.12 }}
      >
        {localizer.projectSlider.showProject}
        <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
      </ShowProjectButton>

      <ProjectData>
        <Title>{projectTitle}</Title>
        <Address>
          <LocationIcon icon={['fas', 'map-marker-alt']} size="1x" />
          {buildAddress(project?.address)}
        </Address>
      </ProjectData>
    </Card>
  );
};

export default ProjectCard;
