import { useRef, useState, useCallback, useEffect } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import {
  getCurrentLanguage,
  setLanguage,
  supportedLanguages
} from 'src/localization/localizer';

// Styling
import styled, { css, StyledProps } from 'styled-components';
import { motion } from 'framer-motion';

const MainWrapper = styled.div<
  StyledProps<{
    mobileView?: boolean;
    className?: string;
  }>
>`
  position: relative;
  ${({ mobileView }) =>
    mobileView &&
    css`
      width: 100%;
    `};
`;

const ButtonWrapper = styled(motion.div)<
  StyledProps<{
    mobileView?: boolean;
    languageMenuOpened: boolean;
  }>
>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.beigeBg10};
  cursor: pointer;
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  transition: all 0.3s;
  ${({ mobileView, languageMenuOpened, theme }) =>
    mobileView &&
    css`
      width: 100%;
      column-gap: 12px;
      padding: 0 12px;
      border: solid 1px transparent;
      ${languageMenuOpened ? `background-color: ${theme.beigeBg20};` : ''};
    `};
`;

const LanguageIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  margin-left: auto;
`;

const LanguageMenuList = styled(motion.ul)<
  StyledProps<{ mobileView?: boolean }>
>`
  position: absolute;
  transform: translateX(calc(-100% + 46px));
  border-radius: 4px;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.gray20};
  list-style-type: none;
  list-style-position: outside;
  z-index: 3007;
  padding: 0;
  ${({ mobileView }) =>
    mobileView &&
    css`
      position: relative;
      transform: none;
      border: 1px solid transparent;
      background: transparent;
    `};
`;

const SelectedIcon = styled(FontAwesomeIcon)<
  StyledProps<{ selected?: boolean }>
>`
  color: ${({ theme }) => theme.gray50};
  margin-right: 12px;
  opacity: ${({ selected }) => (selected ? 1 : 0)};
`;

const LanguageItem = styled(motion.li)<
  StyledProps<{
    selected: boolean;
    hovered: boolean;
    mobileView?: boolean;
  }>
>`
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 8px 16px;
  text-align: center;
  background: ${({ selected, hovered, theme }) =>
    selected || hovered ? theme.beigeBg10 : theme.white};
  color: ${({ theme }) => theme.black};
  user-select: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};

  ${({ mobileView, selected, theme }) =>
    mobileView &&
    css`
      background: ${selected && mobileView ? theme.beigeBg20 : theme.beigeBg10};
    `};
`;

export interface LanguageSwitcherProps {
  onLanguageSwitched: (language: string) => void;
  className?: string;
  mobileView?: boolean;
}

interface ILanguage {
  short: string;
  long: string;
}

const LanguageSwitcher = ({
  onLanguageSwitched,
  className,
  mobileView
}: LanguageSwitcherProps) => {
  const [languageMenuOpened, setLanguageMenuOpened] = useState<boolean>(false);
  const [hoveredLanguage, setHoveredLanguage] = useState<string>('');
  const [currentLanguage, setCurrentLanguage] = useState('');

  useEffect(() => {
    setCurrentLanguage(() => {
      const findCurrentLang = (lang: ILanguage) =>
        lang.short === getCurrentLanguage();
      return (supportedLanguages?.find(findCurrentLang)?.long as string) || '';
    });
  }, []);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const checkOutsideClick = useCallback(
    (event: any) => {
      if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
        setLanguageMenuOpened(false);
      }
    },
    [languageMenuOpened]
  );

  useEffect(() => {
    window.addEventListener('click', checkOutsideClick);
    return () => window.removeEventListener('click', checkOutsideClick);
  }, [checkOutsideClick]);

  let unhoverTimer = useRef<number>(0);
  return (
    <MainWrapper className={className} mobileView={mobileView} ref={wrapperRef}>
      <ButtonWrapper
        onClick={() => {
          setLanguageMenuOpened((current) => !current);
        }}
        onHoverStart={() => {
          clearTimeout(unhoverTimer.current);
        }}
        onHoverEnd={() => {
          unhoverTimer.current = window.setTimeout(() => {
            setLanguageMenuOpened(false);
          }, 500);
        }}
        mobileView={mobileView}
        languageMenuOpened={languageMenuOpened}
      >
        <div>{getCurrentLanguage().toUpperCase()}</div>
        {mobileView ? currentLanguage : ''}
        {mobileView ? (
          <DropdownIcon
            icon={['far', languageMenuOpened ? 'chevron-down' : 'chevron-up']}
            size="1x"
          />
        ) : null}
      </ButtonWrapper>
      {languageMenuOpened && (
        <LanguageMenuList
          onHoverStart={() => {
            clearTimeout(unhoverTimer.current);
          }}
          onHoverEnd={() => {
            unhoverTimer.current = window.setTimeout(() => {
              setLanguageMenuOpened(false);
              setHoveredLanguage('');
            }, 500);
          }}
          mobileView={mobileView}
        >
          {supportedLanguages.map((language: any) => {
            const isSelected = getCurrentLanguage() === language.short;
            return (
              <LanguageItem
                onHoverStart={() => {
                  setHoveredLanguage(language.short);
                }}
                mobileView={mobileView}
                key={language.short}
                selected={isSelected}
                hovered={hoveredLanguage === language.short}
                onClick={() => {
                  setLanguageMenuOpened(false);
                  setHoveredLanguage('');
                  setLanguage(language.short);
                  setCurrentLanguage(language.long);
                  onLanguageSwitched(language.short);
                }}
              >
                <SelectedIcon
                  icon={['far', 'check']}
                  size="sm"
                  selected={isSelected}
                />

                {language.long}
              </LanguageItem>
            );
          })}
        </LanguageMenuList>
      )}
    </MainWrapper>
  );
};

export default LanguageSwitcher;
