import { useEffect, useState } from 'react';

// Resources
import PromptoLogo from 'src/resources/images/prompto-logo.png';
import PromptoLogoIcon from 'src/resources/images/prompto-logo-icon.png';
import MainBackground from 'src/resources/images/login-page-background.png';
import GoogleLogo from 'src/resources/images/logos/google.png';
import MicrosoftLogo from 'src/resources/images/logos/microsoft.png';

// Helpers
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'src/store/AuthStore';
import localizer from 'src/localization/localizer';

// Components
import Button from 'src/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import { motion, AnimatePresence } from 'framer-motion';
import { forBiggerThan } from 'src/helpers/ui';
import styled, { keyframes, StyledProps } from 'styled-components';
import { User } from '@prompto-api';
import useSingleSignOn from 'src/hooks/use-single-sign-on';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.beigeBg10};
  display: flex;
  flex-flow: row;
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 50px;
  margin-top: 24px;

  ${forBiggerThan.tablet`
  display: block;
  margin-left: 200px;
  margin-top: 72px;
  width: 30%;
`}
`;

const LeftSideContent = styled.div`
  max-width: 420px;
`;

const RightSide = styled.div`
  display: none;

  ${forBiggerThan.tablet`
  display:block;
  width: 60%;
  padding: 50px;
`}
`;

const AnimatedBackground = styled(motion.div)`
  width: 100%;
  position: relative;
`;

const ImageSlider = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  border-radius: 0 8px 0 8px;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  max-width: 200px;
  position: relative;
  z-index: 3004; // for 1 bigger than for mobile menu

  ${forBiggerThan.laptop`
  height: 60px;
  width: auto;
  max-width: 500px;
  `}
`;
const ErrorMessage = styled.div`
  margin-top: 5px;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.redBright};
`;

const LogoContainer = styled.div`
  cursor: pointer;
`;

const Logo = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  ${forBiggerThan.laptop`
  height: 60px;
  width: auto;
  max-width: 500px;  `}
`;

const SmallTitle = styled.div`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.black};
  margin-top: 48px;

  ${forBiggerThan.tablet`
  margin-top: 100px;
`}
`;

const Title = styled.div`
  font-size: 1.875rem;
  color: ${({ theme }) => theme.black};
  margin-top: 16px;
`;

const Accent = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-style: italic;
  font-weight: bold;
  display: inline-block;
`;

const ClickableAccent = styled(Accent)`
  cursor: pointer;
`;

const AlternativeSignInBlock = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.gray300};
  padding-bottom: 16px;
  margin-top: 48px;
`;

const AuthProviders = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

const AuthProviderButton = styled.button`
  height: 48px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary300};
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;

  span {
    font-size: 0.75rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;
    word-break: break-word;
    line-height: 1;
  }
`;

const AuthProviderLogo = styled.img`
  width: 24px;
  height: 24px;
  object-position: center;
  object-fit: contain;
  margin-right: 10px;
`;

const Label = styled.div`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.black};
  margin-top: 24px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  background: ${({ theme }) => theme.white};
  outline: transparent;
  padding: 0 12px 0 12px;
  color: ${({ theme }) => theme.gray60};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  margin-top: 16px;

  &::placeholder {
    color: ${({ theme }) => theme.gray60};
  }

  ${forBiggerThan.tablet`
  padding: 0 44px 0 36px;
`}
`;

const glareAnimation = keyframes`
  0%, 100% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0%);
  }
`;

const StyledButton = styled(Button)<StyledProps<{ disabled: boolean }>>`
  height: 48px;
  min-width: 250px;
  grid-area: button;
  justify-self: center;
  position: relative;
  overflow: hidden;
  background: ${({ theme, disabled }) =>
    disabled ? theme.gray20 : theme.gold60};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  margin-top: 16px;
  width: 100%;
  border-radius: 8px;

  ${forBiggerThan.laptop`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(0%);
    animation: ${glareAnimation} 10s linear infinite;
  }

  &:hover:before {
    animation: none;
  }
`}
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

const AlreadyHaveAccount = styled.div`
  border-top: 1px solid ${({ theme }) => theme.gray300};
  color: ${({ theme }) => theme.black};
  font-size: 1.125rem;
  margin-top: 24px;
  padding-top: 24px;

  div {
    font-weight: bold;
  }
`;

const LoginPage = () => {
  const navigate = useNavigate();

  const [imageExpanded, setImageExpanded] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>();
  const [password, setPassword] = useState<string>();

  const [isLoginPending, setIsLoginPending] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>();

  const { AuthStateDispatch } = useAuthState();

  const { signInWithAltAuthProvider, currentUser } = useSingleSignOn();

  const loginOAuth = (firebaseToken: string) => {
    User.loginOAuth({
      firebaseToken,
      entityId: 'aroundmedia',
      promptoSessionType: 'marketplace'
    })
      .then((result) => {
        setIsLoginPending(false);

        const { data } = result;
        const { sessionObjectId, sessionToken, user } = data;

        if (sessionToken && user) {
          const { vaultLabelSettings } = user;
          const vaultForMP =
            vaultLabelSettings?.defaultVaultForVaultLabel[
              'promptoMarketplace'
            ] ??
            vaultLabelSettings?.allVaultsForVaultLabel[
              'promptoMarketplace'
            ][0] ??
            null;

          if (vaultForMP) {
            AuthStateDispatch({
              type: 'login',
              payload: {
                user,
                sessionToken,
                vaultObjectId: vaultForMP
              }
            });

            navigate(`/account${window.location.search}`);
          }
        }
      })
      .catch((e) => {
        if (e.response.data) {
          setLoginError(e.response.data.message);
        }
      });
  };

  const onAltSignInSuccess = (token: string) => loginOAuth(token);

  const onAltSignInError = () => {};

  useEffect(() => {
    setTimeout(() => {
      setImageExpanded(true);
    }, 200);
  }, []);

  const onLogoClick = () => {
    navigate(`/${window.location.search}`);
  };

  const altSignInButtons = [
    {
      label: 'Google',
      logo: GoogleLogo,
      providerId: 'google.com'
    },
    {
      label: 'Microsoft',
      logo: MicrosoftLogo,
      providerId: 'microsoft.com'
    }
  ];

  const onLogin = () => {
    setIsLoginPending(true);
    setLoginError('');

    User.login(null, {
      entityObjectId: 'aroundmedia',
      usernameOrEmail: emailAddress,
      promptoSessionType: 'marketplace',
      password
    })
      .then((result) => {
        setIsLoginPending(false);

        const { data } = result;
        const { sessionObjectId, sessionToken, user } = data;

        if (sessionToken && user) {
          const { vaultLabelSettings } = user;
          const vaultForMP =
            vaultLabelSettings?.defaultVaultForVaultLabel[
              'promptoMarketplace'
            ] ??
            vaultLabelSettings?.allVaultsForVaultLabel['promptoMarketplace'][0];

          if (vaultForMP) {
            AuthStateDispatch({
              type: 'login',
              payload: {
                user,
                sessionToken,
                vaultObjectId: vaultForMP
              }
            });

            navigate(`/account${window.location.search}`);
          } else {
            setLoginError('Dit is geen Prompto Marketplace account');
          }
        }
      })
      .catch((e) => {
        if (e.response.data) {
          setLoginError(e.response.data.message);
          setIsLoginPending(false);
        }
      });
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === 'Enter') {
        onLogin();
      }
    };

    if (emailAddress && password) {
      window.addEventListener('keypress', handleKeyPress);
    } else {
      window.removeEventListener('keypress', handleKeyPress);
    }

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [emailAddress, password]);

  return (
    <>
      <Wrapper>
        <LeftSide>
          <LeftSideContent>
            <LogoWrapper>
              <LogoContainer onClick={onLogoClick}>
                <Logo src={PromptoLogo} alt="Prompto logo" />
              </LogoContainer>
            </LogoWrapper>

            <SmallTitle>{localizer.loginPage.greeting}</SmallTitle>
            <Title>
              <Accent>{localizer.loginPage.login}</Accent>
              {localizer.loginPage.toYourAccount}
            </Title>

            <AlternativeSignInBlock>
              <AuthProviders>
                {altSignInButtons.map((item) => {
                  return (
                    <AuthProviderButton
                      key={item.providerId}
                      onClick={() =>
                        signInWithAltAuthProvider(
                          item.providerId,
                          onAltSignInSuccess,
                          onAltSignInError
                        )
                      }
                    >
                      <AuthProviderLogo src={item.logo} /> {item.label}
                    </AuthProviderButton>
                  );
                })}
              </AuthProviders>
            </AlternativeSignInBlock>
            <Label>{localizer.loginPage.alternativeLabel}</Label>
            <StyledInput
              id="email"
              type="text"
              value={emailAddress}
              onChange={(e: any) => {
                setEmailAddress(e.target.value);
              }}
              placeholder={localizer.loginPage.yourEmail}
            />
            <StyledInput
              id="password"
              type="password"
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              placeholder={localizer.loginPage.yourPassword}
            />
            <StyledButton
              doNotScaleOnHover={true}
              onClick={onLogin}
              disabled={!emailAddress || !password || isLoginPending}
            >
              {isLoginPending
                ? localizer.loginPage.processing
                : localizer.loginPage.login}
              {!isLoginPending && (
                <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
              )}
            </StyledButton>
            {loginError && <ErrorMessage>{loginError}</ErrorMessage>}
            <AlreadyHaveAccount>
              {localizer.loginPage.noAccount}{' '}
              <ClickableAccent
                onClick={() => {
                  navigate(`/register${window.location.search}`);
                }}
              >
                {localizer.loginPage.signUp}
              </ClickableAccent>
            </AlreadyHaveAccount>
          </LeftSideContent>
        </LeftSide>
        <RightSide>
          <AnimatedBackground
            initial={{ width: 0, height: 0, left: '50%', top: '50%' }}
            animate={{
              width: imageExpanded ? '100%' : 0,
              height: imageExpanded ? '100%' : 0,
              left: imageExpanded ? 0 : '50%',
              top: imageExpanded ? 0 : '50%'
            }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <ImageSlider>
              <Image src={MainBackground} />
            </ImageSlider>
          </AnimatedBackground>
        </RightSide>
      </Wrapper>
    </>
  );
};

export default LoginPage;
