import { useState, useEffect } from 'react';

// Components
import RangeFilter from 'src/components/unitList/unitFilter/RangeFilter';
import LocationInput from 'src/components/locationInput/LocationInput';
import MultiSelectFilter from './MultiSelectFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as SimpleCheckbox } from 'react-simple-checkbox';

// Helpers
import localizer from 'src/localization/localizer';
import {
  useProjectsFiltersState,
  provinceIdToLabelMap
} from 'src/store/ProjectsFiltersStore';
import { useDataState } from 'src/store/DataStore';
import { isMobileOnly } from 'react-device-detect';
import isEqual from 'lodash.isequal';

// Types
import { ProjectsFilterKeyType } from '../ProjectsFilters';

// Styling
import styled, { StyledProps, withTheme } from 'styled-components';

const ContentWrapper = styled.div`
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
`;

const ContentTitle = styled.div``;

const Wrapper = styled.div<
  StyledProps<{ displayMode: 'single' | 'column' | 'wizard' }>
>`
  width: 100%;
  position: relative;
  border-bottom: ${({ displayMode, theme }) =>
    displayMode === 'column' ? `1px solid ${theme.gray20}` : 'none'};
  margin-bottom: ${({ displayMode }) => (displayMode === 'column' ? 12 : 0)}px;

  ${ContentWrapper} {
    padding-top: ${({ displayMode }) => (displayMode === 'column' ? 0 : 16)}px;
  }

  ${ContentTitle} {
    min-height: 40px;
    display: flex;
    align-items: center;
    padding-right: ${({ displayMode }) =>
      displayMode === 'column' ? 20 : 0}px;
    line-height: ${({ displayMode }) =>
      displayMode === 'column' ? '1.2' : '40px'};
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 8px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  color: ${({ theme }) => theme.gray50};
  margin: 0 5px 0 auto;
  cursor: pointer;
`;

const LocationSearchRadiusRangeWrapper = styled.div`
  margin-top: 25px;
`;

const LocationFilterTypeTabs = styled.div`
  display: flex;
  align-items: center;
  margin: -6px -16px 20px;
  box-shadow: ${({ theme }) => `inset 0 -1px ${theme.gray10}`};
  padding: 0 16px;
  box-sizing: border-box;
`;

const LocationFilterTab = styled.div<StyledProps<{ active: boolean }>>`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 50% 0 0;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  box-shadow: ${({ theme, active }) =>
    active ? `inset 0 -2px ${theme.black}` : 'none'};
`;

const ProjectsCount = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.gray60};
`;

const Option = styled.div<StyledProps<{ checked: boolean; wizard: boolean }>>`
  flex: ${({ wizard }) => (wizard ? '50%' : '100%')} 0 0;
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0 ${({ wizard }) => (wizard ? 12 : 16)}px;
  margin: 0 ${({ wizard }) => (wizard ? 0 : -16)}px;
  background-color: ${({ checked, theme, wizard }) => {
    if (wizard) return 'transparent';
    if (isMobileOnly) {
      return checked ? theme.beigeBg20 : theme.beigeBg10;
    } else {
      return checked ? theme.gray5 : theme.white;
    }
  }};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) =>
      isMobileOnly ? theme.beigeBg10 : theme.gray5};
  }

  ${ProjectsCount} {
    margin-left: auto;
  }
`;

const StyledSimpleCheckbox = styled(SimpleCheckbox)`
  width: 20px !important;
  height: 20px !important;
  position: inherit !important;
  border-radius: 2px;
  margin-right: 2px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin: 0;
  margin-right: 12px;

  &:focus {
    outline: none;
  }

  * {
    width: 20px;
    height: 20px;
  }
`;

const ProvincesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LocationFilterItemWrapper = styled.div``;

const FilterMenuContent = ({
  filterKey,
  displayMode = 'single',
  removeFilter,
  withLabel = true,
  theme
}: {
  filterKey: ProjectsFilterKeyType;
  displayMode?: 'single' | 'column' | 'wizard';
  removeFilter?: (filterKey: ProjectsFilterKeyType) => void;
  withLabel?: boolean;
  theme: { gray50: string };
}) => {
  const { DataState } = useDataState();
  const { projectsPerProvince } = DataState;

  const { ProjectsFiltersState, ProjectsFiltersDispatch } =
    useProjectsFiltersState();
  const { filterValues, temporaryFilterValues } = ProjectsFiltersState;

  const filterObject = isMobileOnly
    ? temporaryFilterValues[filterKey]
    : filterValues[filterKey];

  const [filterObjectValues, setFilterObjectValues] = useState(
    isMobileOnly ? temporaryFilterValues[filterKey] : filterValues[filterKey]
  );

  useEffect(() => {
    if (!isEqual(filterObject, filterObjectValues)) {
      const updatedValues =
        filterKey === 'location'
          ? { provinceValues: filterObjectValues }
          : { values: filterObjectValues };
      ProjectsFiltersDispatch({
        type: updateFiltersAction,
        payload: {
          filterKey: filterKey,
          filterObject: {
            ...filterObject,
            ...updatedValues
          }
        }
      });
    }
  }, [filterObjectValues]);

  const setSearchByProvince = (flag: boolean) =>
    ProjectsFiltersDispatch({
      type: updateFiltersAction,
      payload: {
        filterKey: filterKey,
        filterObject: {
          ...filterObject,
          searchByProvince: flag
        }
      }
    });

  const localized = localizer.projectsFilters as any;

  const updateFiltersAction =
    isMobileOnly && displayMode !== 'wizard'
      ? 'onUpdateTemporaryFilterValues'
      : 'onUpdateFilterValues';

  let content;

  // range filters
  switch (filterKey) {
    case 'price':
    case 'surface':
      content = (
        <ContentWrapper>
          {withLabel && (
            <ContentTitle>{localized.filterMenuTitle[filterKey]}</ContentTitle>
          )}
          <RangeFilter
            filterKey={filterKey}
            filterObject={filterObject}
            onUpdateValues={(values: { min: number; max: number }) => {
              ProjectsFiltersDispatch({
                type: updateFiltersAction,
                payload: {
                  filterKey: filterKey,
                  filterObject: {
                    ...filterObject,
                    values
                  }
                }
              });
            }}
            allowManualRange={true}
            enableMobileView={true}
            displayMode={displayMode}
          />
        </ContentWrapper>
      );
      break;

    // multiselect filters
    case 'numberOfBedrooms':
    case 'numberOfBathrooms':
      content = (
        <ContentWrapper>
          {withLabel && (
            <ContentTitle>{localized.filterMenuTitle[filterKey]}</ContentTitle>
          )}
          <MultiSelectFilter
            filter={filterObject}
            displayMax={filterObject.displayMax}
            displayMode={displayMode}
            setFilter={setFilterObjectValues}
          />
        </ContentWrapper>
      );
      break;

    // location filter
    case 'location':
      content = (
        <ContentWrapper>
          {displayMode !== 'wizard' && (
            <LocationFilterTypeTabs>
              <LocationFilterTab
                onClick={() => setSearchByProvince(true)}
                active={filterObject.searchByProvince === true}
              >
                {localizer.projectsFilters.provinces}
              </LocationFilterTab>
              <LocationFilterTab
                onClick={() => setSearchByProvince(false)}
                active={filterObject.searchByProvince !== true}
              >
                {localizer.projectsFilters.exactLocation}
              </LocationFilterTab>
            </LocationFilterTypeTabs>
          )}

          {filterObject.searchByProvince ? (
            <ProvincesWrapper>
              {filterObject.provinceOptions?.map(
                (option: string, idx: number) => {
                  const checked = filterObject.provinceValues.includes(option);

                  return (
                    <Option
                      checked={checked}
                      key={`${option}-${checked ? 1 : 0}`}
                      wizard={displayMode === 'wizard'}
                    >
                      <StyledSimpleCheckbox
                        id={`${filterObject.key}-${option}`}
                        checked={checked}
                        size={2}
                        color={{
                          uncheckedBorderColor: theme.gray50
                        }}
                        onChange={() => {
                          const values = checked
                            ? filterObject.provinceValues.filter(
                                (province: string) => province !== option
                              )
                            : [...filterObject.provinceValues, option];
                          setFilterObjectValues(values);
                        }}
                      />
                      <label htmlFor={`${filterObject.key}-${option}`}>
                        {
                          localizer.belgiumProvinces[
                            provinceIdToLabelMap[
                              option
                            ] as keyof typeof localizer.belgiumProvinces
                          ]
                        }
                      </label>
                      {projectsPerProvince?.[option] && (
                        <ProjectsCount>
                          {projectsPerProvince[option]}
                        </ProjectsCount>
                      )}
                    </Option>
                  );
                }
              )}
            </ProvincesWrapper>
          ) : (
            <LocationFilterItemWrapper>
              {withLabel && (
                <ContentTitle>
                  {localized.filterMenuTitle[filterKey]}
                </ContentTitle>
              )}

              <LocationInput
                value={filterObject.place}
                fixedDropdown={displayMode === 'wizard'}
                onClose={removeFilter ? () => removeFilter(filterKey) : null}
                onSelect={(location: any) => {
                  const loc = location.geometry.location;
                  const latitude = loc.lat();
                  const longitude = loc.lng();

                  const place = location.formatted_address;

                  ProjectsFiltersDispatch({
                    type: updateFiltersAction,
                    payload: {
                      filterKey: filterKey,
                      filterObject: {
                        ...filterObject,
                        place,
                        coords: { lat: latitude, lng: longitude }
                      }
                    }
                  });
                }}
              />

              {!!filterObject.place && (
                <LocationSearchRadiusRangeWrapper>
                  <RangeFilter
                    filterKey={filterKey}
                    filterObject={filterObject}
                    onUpdateValues={(values: { min: number; max: number }) => {
                      ProjectsFiltersDispatch({
                        type: updateFiltersAction,
                        payload: {
                          filterKey: filterKey,
                          filterObject: {
                            ...filterObject,
                            values
                          }
                        }
                      });
                    }}
                    allowManualRange={false}
                    enableMobileView={true}
                    isSingleValueRange={true}
                    displayMode={displayMode}
                    disabled={!filterObject.place}
                  />
                </LocationSearchRadiusRangeWrapper>
              )}
            </LocationFilterItemWrapper>
          )}
        </ContentWrapper>
      );
      break;

    default:
      content = null;
  }

  return (
    <Wrapper displayMode={displayMode}>
      {displayMode === 'column' && (
        <CloseIconWrapper>
          <CloseIcon
            icon={['fas', 'times-circle']}
            size="1x"
            onClick={() => removeFilter && removeFilter(filterKey)}
          />
        </CloseIconWrapper>
      )}
      {content}
    </Wrapper>
  );
};

export default withTheme(FilterMenuContent);
