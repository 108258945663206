// Components
import { Marker, MarkerClusterer } from '@react-google-maps/api';

// Helpers
import env from 'src/environment';
import { isMobileOnly } from 'react-device-detect';

// Types
import { IMapProject } from '../ProjectsPage';

// Styling
import { withTheme } from 'styled-components';

import NoThumbnailMarker from 'src/resources/images/unit-preview-placeholder.png';

const markerSize = isMobileOnly ? 40 : 50;

interface MarkersProps {
  projects: IMapProject[];
  onHoverMarker: (project: IMapProject) => void;
  onClickMarker: (project: IMapProject) => void;
  expandMap: boolean;
}

const Markers = ({
  projects,
  onHoverMarker,
  onClickMarker,
  expandMap
}: MarkersProps) => {
  return (
    <MarkerClusterer
      averageCenter
      clusterClass={
        isMobileOnly ? 'marketplaceMapCluster_mobile' : 'marketplaceMapCluster'
      }
      gridSize={markerSize}
      maxZoom={9}
    >
      {(clusterer) => {
        const styles = clusterer.getStyles();
        for (let i = 0; i < styles.length; i++) {
          styles[i].width = markerSize;
          styles[i].height = markerSize;
        }

        const projectSpots = projects.map((project: any, idx: number) => {
          if (!project.coords && !project.address.latitude) return null;

          let imageTag = '#custom-marker-image';

          if (!expandMap) imageTag += '_inactive';

          if (isMobileOnly) {
            imageTag += '_mobile';
          }

          const imageUrl = project?.projectThumbnailUri
            ? `${env().baseImageUrl}/h=${markerSize}/${
                project?.projectThumbnailUri
              }${imageTag}`
            : `${NoThumbnailMarker}${imageTag}`;

          const image = {
            url: imageUrl,
            // @ts-ignore
            size: new window.google.maps.Size(markerSize, markerSize),
            // @ts-ignore
            origin: new window.google.maps.Point(0, 0),
            // @ts-ignore
            anchor: new window.google.maps.Point(markerSize / 2, markerSize / 2)
          };

          const coords = project.coords ?? {
            lat: parseFloat(project.address.latitude),
            lng: parseFloat(project.address.longitude)
          };

          return (
            <Marker
              clusterer={clusterer}
              key={`${project.projectId}-${idx}-clustered`}
              position={coords}
              icon={image}
              onMouseOver={() => onHoverMarker(project)}
              onClick={() => onClickMarker(project)}
            />
          );
        });

        return projectSpots;
      }}
    </MarkerClusterer>
  );
};

export default withTheme<any>(Markers);
