import { performGetCall } from '../api-helpers';

export const UniqueSellingPoint = {
  /**
   * Get all unique selling points
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_31
   */
  getAll: (vaultObjectId: string, projectObjectId: string) =>
    performGetCall(
      `uniquesellingpoint?vaultObjectId=${vaultObjectId}&projectObjectId=${projectObjectId}`
    )
};
