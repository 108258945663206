import { useState, useEffect } from 'react';

// Components
import RangeFilter from './RangeFilter';

// Helpers
import { isMobileOnly } from 'react-device-detect';
import localizer from 'src/localization/localizer';
import MultiSelectFilter from 'src/pages/projectsPage/components/projectsFilters/components/MultiSelectFilter';
import isEqual from 'lodash.isequal';

// Styling
import styled, { StyledProps, css } from 'styled-components';
import { getLocalizedTextForUnitState } from '../unitListHelpers';

const Wrapper = styled.div<StyledProps<{ styles?: any }>>`
  display: flex;
  flex-flow: column;

  ${({ styles }) => styles};
  ${isMobileOnly &&
  css`
    padding: 16px 0;
    box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.gray20};
  `}
`;

const FilterUnitTitle = styled.p`
  display: flex;
  flex-flow: row;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.black};
  margin: 0 0 6px;
  ${isMobileOnly &&
  css`
    font-weight: bold;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.gray60};
    text-transform: uppercase;
  `}
`;

const FilterOptions = styled.div<StyledProps<{ styles?: any }>>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  ${({ styles }) => styles}
`;

const FilterChip = styled.div<StyledProps<{ chosen?: boolean; styles?: any }>>`
  height: 40px;
  min-width: ${isMobileOnly ? 60 : 40}px;
  width: fit-content;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: ${({ theme, chosen }) =>
    chosen ? theme.beigeBg20 : theme.white};
  font-size: 0.875rem;
  font-weight: ${({ chosen }) => (chosen ? '600' : '400')};
  color: ${({ theme, chosen }) => (chosen ? theme.black : theme.gray60)};
  cursor: pointer;
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
  }
  ${({ styles }) => styles}
`;

const NumberFilterChip = styled(FilterChip)`
  white-space: nowrap;
  span {
    display: inline;
  }
`;

const OverflowOptionsText = styled.span`
  margin: 0 0 0 6px !important;
`;

const NoData = styled.div`
  margin: 8px 0;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.gray200};
`;

// const MoreInfoTip = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-left: 8px;
//   color: ${({ theme }) => theme.gray60};
// `;

// const MoreInfoIcon = styled(FontAwesomeIcon)``;

interface FilterBlockProps {
  title: string;
  type?: string;
  name?: string;
  options?: any;
  setOptions?: any;
  rangeOptions?: any;
  styles?: any;
  displayMax?: any;
  overflowText?: any;
  hideTitle?: boolean;
  chipStyles?: any;
  optionsWrapperStyles?: any;
  optionToLabelMap?: any;
}

const FilterBlock = ({
  title,
  type,
  name,
  options,
  setOptions,
  rangeOptions,
  styles,
  displayMax,
  overflowText,
  hideTitle = false,
  chipStyles,
  optionsWrapperStyles,
  optionToLabelMap
}: FilterBlockProps) => {
  const onPillFilterOptionClick = (option: number, isCombined: boolean) => {
    const optionsToToggle = isCombined
      ? options.options.filter((x: number) => x >= option)
      : [option];
    let newSelected = [...options.values];
    optionsToToggle.forEach((option: any) => {
      if (newSelected.includes(option)) {
        newSelected = newSelected.filter((x) => x !== option);
      } else {
        newSelected = [...newSelected, option];
      }
    });
    setOptions(name, { ...options, values: newSelected });
  };

  const [filterObjectValues, setFilterObjectValues] = useState(options?.values);

  let limitedOptions = options?.options ?? [];
  if (displayMax && options) {
    limitedOptions = options.options.slice(0, displayMax);
  }

  const noData = options?.options?.length < 1;
  const noRangeData =
    rangeOptions?.limits?.min === Infinity ||
    rangeOptions?.limits?.max === -Infinity;

  useEffect(() => {
    if (!isEqual(options?.values, filterObjectValues)) {
      setOptions(name, { ...options, values: filterObjectValues });
    }
  }, [filterObjectValues]);

  let content;
  switch (name) {
    case 'price':
    case 'surface':
      content = noRangeData ? (
        <NoData>{localizer.unitListComponent.noData}</NoData>
      ) : (
        <RangeFilter
          filterKey={title}
          filterObject={rangeOptions}
          onUpdateValues={(values: any) => {
            setOptions(name, { ...rangeOptions, values });
          }}
          unitPosition={name === 'price' ? 'pre' : 'post'}
          allowManualRange={true}
        />
      );
      break;
    case 'numberOfBedrooms':
    case 'numberOfBathrooms':
      content = (
        <FilterOptions styles={optionsWrapperStyles}>
          {noData ? (
            <NoData>{localizer.unitListComponent.noData}</NoData>
          ) : (
            <MultiSelectFilter
              filter={options}
              displayMax={Math.min(displayMax, options.options.length)}
              displayMode={'wizard'}
              setFilter={setFilterObjectValues}
            />
          )}
        </FilterOptions>
      );
      break;
    case 'status':
    default:
      content = (
        <FilterOptions styles={optionsWrapperStyles}>
          {noData ? (
            <NoData>{localizer.unitListComponent.noData}</NoData>
          ) : (
            limitedOptions.map((option: any, i: number) => {
              const combinedOption = i >= displayMax - 1;
              const optionName =
                name === 'status'
                  ? getLocalizedTextForUnitState(option)
                  : option;

              return (
                <FilterChip
                  key={option}
                  chosen={options.values?.includes(option)}
                  onClick={() =>
                    onPillFilterOptionClick(option, combinedOption)
                  }
                  styles={chipStyles}
                >
                  <span>{optionName}</span>
                  {combinedOption && (
                    <OverflowOptionsText>{overflowText}</OverflowOptionsText>
                  )}
                </FilterChip>
              );
            })
          )}
        </FilterOptions>
      );
  }

  return (
    <Wrapper styles={styles}>
      {title && !hideTitle && (
        <FilterUnitTitle>
          {String(title)}
          {/* <MoreInfoTip title="filter on status">
            <MoreInfoIcon icon={['far', 'info-circle']} size="1x" />
          </MoreInfoTip> */}
        </FilterUnitTitle>
      )}
      {content}
    </Wrapper>
  );
};

export default FilterBlock;
