import { useState, useCallback, useEffect } from 'react';

// Components
import UspSpotTooltip from './UspSpotTooltip';
import { motion } from 'framer-motion';

// Helpers
import { isMobile } from 'react-device-detect';
import { zIndices } from '../projectTourHelpers';

// Styling
import styled, { css, StyledProps } from 'styled-components';

const HOME_BUTTON_HEIGHT = 55;

const Wrapper = styled(motion.div)<
  StyledProps<{
    position: any;
    offsets: any;
    zoomScale: number;
  }>
>`
  z-index: ${zIndices.cornerPinnedUsp};
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    z-index: ${zIndices.cornerPinnedUspHovered};
  }
  ${({ position, offsets, zoomScale }) => {
    let additionalIndent = 0;
    switch (position) {
      case 'TOP_LEFT':
        return css`
          position: absolute;
          top: ${offsets.top + HOME_BUTTON_HEIGHT / zoomScale}px;
          left: ${offsets.left}px;
          transform-origin: top left;
        `;
      case 'TOP_RIGHT':
        return css`
          position: absolute;
          top: ${offsets.top}px;
          right: ${offsets.right}px;
          transform-origin: top right;
        `;
      case 'BOTTOM_LEFT':
        return css`
          position: absolute;
          bottom: ${offsets.bottom}px;
          left: ${offsets.left}px;
          transform-origin: bottom left;
        `;
      case 'BOTTOM_RIGHT':
        additionalIndent = 97 / zoomScale; // 97px - width of zoom controls + right indent to it
        return css`
          position: absolute;
          bottom: ${offsets.bottom}px;
          right: ${offsets.right + additionalIndent}px;
          transform-origin: bottom right;
        `;
      default:
        return ``;
    }
  }}
`;

// Types
interface PinnedSpotProps {
  spot: any;
  moveForward: any;
  offsets: any;
  zoomScale: number;
  enlargeUsp: (event: any, uspId: string, data: any) => void;
}

const PinnedSpot = ({
  spot,
  moveForward,
  offsets,
  zoomScale,
  enlargeUsp
}: PinnedSpotProps) => {
  const [enlargedUspData, setEnlargedUspData] = useState<any>({});
  const [spotContent, setSpotContent] = useState<any>();

  const canBeEnlarged =
    spot?.usp?.uspVisibilitySettings?.showMedia !== false &&
    !isMobile &&
    ['image', 'video'].includes(enlargedUspData?.mediaType);

  const onEnlargeUsp = useCallback(
    (event: any) => {
      if (!canBeEnlarged) return;
      enlargeUsp(event, spot?.usp?.objectId, enlargedUspData);
    },
    [enlargedUspData, spot, canBeEnlarged, enlargeUsp]
  );

  useEffect(() => {
    if (spot?.navigationItemType && !spotContent) {
      let content = null;
      switch (spot.navigationItemType) {
        case 'uspSpot':
          content = (
            <UspSpotTooltip
              spotObject={spot}
              useInheritPosition={true}
              isPinned={true}
              enlargeUsp={onEnlargeUsp}
              setEnlargedUspData={setEnlargedUspData}
            />
          );
          break;
        default:
      }

      setSpotContent(content);
    }
  }, [spot, spotContent]);

  return (
    <Wrapper
      animate={{ scale: 1 / zoomScale }}
      transition={{ delay: 0, duration: 0 }}
      position={spot?.pinConfiguration?.position}
      zoomScale={zoomScale}
      onClick={moveForward}
      offsets={offsets}
    >
      {spotContent && spotContent}
    </Wrapper>
  );
};

export default PinnedSpot;
