import { useState, useEffect, useRef, useCallback } from 'react';

// Components
import ProjectsFilters from './projectsFilters/ProjectsFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterMenuContent from './projectsFilters/components/FilterMenuContent';
import Tippy from '@tippyjs/react';

// Helpers
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';
import localizer from 'src/localization/localizer';
import { AnimatePresence, motion } from 'framer-motion';
import useIntersectionObserver from 'src/hooks/use-intersection-observer';
import filtersConfig from 'src/configs/ProjectsFilters';
import { isMobileOnly } from 'react-device-detect';
import { normalizeFilters } from 'src/pages/projectsPage/ProjectsPageHelpers';

// Styling
import styled, { StyledProps, css } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const MainWrapper = styled(motion.div)`
  position: relative;
  background-color: ${({ theme }) => theme.beigeBg10};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  box-sizing: border-box;
  min-height: ${isMobileOnly ? 350 : 250}px;
  transform-origin: top;
  transition: all 120ms ease;

  ${forBiggerThan.mobile`
    padding: 16px 24px;
  `}

  ${forBiggerThan.largeDesktop`
    min-height: 350px;
    padding: 32px 64px;
  `}
`;

const Navigation = styled.div`
  position: relative;
  margin-top: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(motion.button)`
  padding: 12px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.beigeBg20};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 40px;
  height: 40px;
  ${isMobileOnly &&
  css`
    position: static;
    background-color: ${({ theme }) => theme.beigeBg10};
    border: none;
    width: 24px;
    height: 24px;
  `}
`;

const NavigationButton = styled(Button)<StyledProps<{ show: boolean }>>`
  min-width: ${isMobileOnly ? 48 : 200}px;
  height: 48px;
  font-weight: bold;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`;

const FinalPageButtons = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: ${isMobileOnly ? 16 : 32}px;
`;

const AllFiltersButton = styled(Button)`
  height: 48px;
  min-width: ${isMobileOnly ? 120 : 150}px;
  border: solid 1px ${({ theme }) => theme.beigeBg30};
  background-color: ${({ theme }) => theme.beigeBg10};
  color: ${({ theme }) => theme.black};
  font-weight: bold;
`;

const ViewProjectsButton = styled(Button)`
  height: 48px;
  min-width: ${isMobileOnly ? 120 : 200}px;
  border: solid 1px ${({ theme }) => theme.gold};
  background-color: ${({ theme }) => theme.gold};
  color: ${({ theme }) => theme.white};
  font-weight: bold;
`;

const WizardSteps = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${forBiggerThan.mobile`
    display: flex;
  `}
`;

const Step = styled.div<StyledProps<{ active?: boolean }>>`
  width: ${({ active }) => (active ? 12 : 10)}px;
  height: ${({ active }) => (active ? 12 : 10)}px;
  border-radius: 50%;
  background-color: ${({ theme, active }) =>
    active ? theme.gold : theme.beigeBg30};
`;

const RightIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

const LeftIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const FilterContent = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${isMobileOnly ? 32 : 16}px;

  ${forBiggerThan.largeDesktop`
    margin-bottom: 32px;
  `}
`;

const FilterMenuWrapper = styled.div`
  width: 576px;
  max-width: calc(100vw - 32px);
  ${forBiggerThan.tablet`
    max-width: 576px;
  `}
`;

const FilterTitle = styled.div<StyledProps<{ color?: string }>>`
  font-size: 1.875rem;
  color: ${({ theme, color }) => color ?? theme.black};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  text-align: center;
  span {
    font-family: ${({ theme }) => theme.fonts.DMSans};
    font-style: italic;
    font-weight: bold;
  }
  ${forBiggerThan.tablet`
    font-size: 2rem;
  `}

  ${forBiggerThan.largeDesktop`
    font-size: 2.25rem;
  `}
`;

const FilterDescription = styled.p`
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  margin: 0 0 12px;
  color: ${({ theme }) => theme.gray60};

  ${forBiggerThan.largeDesktop`
    margin-bottom: 25px;
  `}
`;

const TippyContent = styled.div`
  word-wrap: normal;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.1;
  text-align: center;
  backdrop-filter: blur(10px);
  background-color: rgba(46, 49, 56, 0.9);
  color: ${({ theme }) => theme.white};
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  width: fit-content;
  border-radius: 2px;
`;

const MobileHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const WizardStepsCounter = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.gray60};
`;

const Container = styled.div`
  min-height: 80px;
`;

const SearchWizard = ({
  renderAdditionalUI,
  mapInstance,
  scrollToProjects,
  newInitialFilters
}: any) => {
  const [showWizard, setShowWizard] = useState(false);
  const [sliding, setSliding] = useState(false);

  const [cachedFiltersChecked, setCachedFiltersChecked] = useState(false);

  const { ProjectsFiltersState, ProjectsFiltersDispatch } =
    useProjectsFiltersState();
  const {
    allFilters,
    currentWizardStep,
    wizardCompleted,
    forceShowWizard,
    filterValues
  } = ProjectsFiltersState;

  const wizardRef = useRef(null);
  const { gotVisible } = useIntersectionObserver(wizardRef, {
    rootMargin: '0px',
    threshold: [0, 0.02, 0.1, 1],
    minIntersectionRatio: isMobileOnly ? 0.02 : 0.1
  });

  useEffect(() => {
    if (wizardCompleted) return;
  }, [gotVisible, wizardCompleted]);

  useEffect(() => {
    if (cachedFiltersChecked) {
      const shouldShowWizard = !wizardCompleted && forceShowWizard;
      setShowWizard(shouldShowWizard);
    }
  }, [wizardCompleted, cachedFiltersChecked, forceShowWizard]);

  const slide = (direction: number) => {
    setSliding(true);
    ProjectsFiltersDispatch({
      type: 'changeWizardPage',
      payload: currentWizardStep + direction
    });

    setTimeout(() => {
      setSliding(false);
    }, 100);
  };

  const localized = localizer.projectsFilters.wizard as any;

  const currentFilter = allFilters[currentWizardStep];

  const onFilterDeselected = useCallback(
    (filterKey: any) => {
      ProjectsFiltersDispatch({
        type: 'onFilterDeselected',
        payload: filterKey
      });
    },
    [ProjectsFiltersDispatch]
  );

  const page = (
    <FilterContent
      initial={{ scale: 0.85, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.85, opacity: 0 }}
      transition={{ ease: 'linear' }}
    >
      <FilterTitle>
        {localizer.formatString(
          localized.title[currentFilter.key],
          <span>{localized.titleBold[currentFilter.key]}</span>
        )}
      </FilterTitle>
      <FilterDescription>
        {
          localized.subtitle[
            currentFilter.key !== 'location'
              ? currentFilter.key
              : filterValues.location?.searchByProvince
              ? 'province'
              : 'location'
          ]
        }
      </FilterDescription>

      <FilterMenuWrapper>
        <FilterMenuContent
          filterKey={currentFilter.key}
          withLabel={false}
          displayMode="wizard"
          removeFilter={onFilterDeselected}
        />
      </FilterMenuWrapper>
    </FilterContent>
  );

  const showPrev = currentWizardStep !== 0;
  const showNext = currentWizardStep < allFilters.length - 1;
  const isLastPage = currentWizardStep === allFilters.length - 1;

  const onCloseWizard = () => {
    setShowWizard(false);
    ProjectsFiltersDispatch({ type: 'completeWizard' });
  };

  const header = isMobileOnly ? (
    <MobileHeader>
      <WizardStepsCounter>
        {currentWizardStep + 1} / {allFilters.length}
      </WizardStepsCounter>
      <CloseButton onClick={onCloseWizard}>
        <FontAwesomeIcon icon={['far', 'times']} size="lg" />
      </CloseButton>
    </MobileHeader>
  ) : (
    <Tippy
      key="wizard-tooltip"
      placement="bottom"
      arrow={false}
      zIndex={4000}
      content={
        <TippyContent>
          <div>{localized.skip}</div>
        </TippyContent>
      }
      hideOnClick={false}
      interactive={false}
      offset={[0, 10]}
      appendTo={() => document.body}
    >
      <CloseButton onClick={onCloseWizard} title={localized.skip}>
        <FontAwesomeIcon icon={['far', 'times']} size="lg" />
      </CloseButton>
    </Tippy>
  );

  const navigation = (
    <Navigation>
      <NavigationButton
        initial={{ opacity: 0, x: -15 }}
        animate={{ opacity: showPrev ? 1 : 0, x: showPrev ? 0 : -15 }}
        show={showPrev}
        onClick={() => slide(-1)}
      >
        <LeftIcon icon={['far', 'arrow-left']} size="sm" />
        {(!isMobileOnly || (isMobileOnly && !isLastPage)) &&
          localized[isMobileOnly ? 'previous' : 'prevQuestion']}
      </NavigationButton>

      <WizardSteps>
        {allFilters.map((filter: any, idx: number) => (
          <Step key={filter.key} active={idx === currentWizardStep} />
        ))}
      </WizardSteps>

      <NavigationButton
        initial={{ opacity: 0, x: 15 }}
        animate={{
          opacity: showNext ? 1 : 0,
          x: showNext ? 0 : 15
        }}
        show={showNext}
        onClick={() => slide(1)}
      >
        {localized[isMobileOnly ? 'next' : 'nextQuestion']}
        <RightIcon icon={['far', 'arrow-right']} size="sm" />
      </NavigationButton>

      {!showNext && (
        <FinalPageButtons>
          <AllFiltersButton onClick={onCloseWizard}>
            <LeftIcon icon={['far', 'filter']} size="sm" />
            {localized.allFilters}
          </AllFiltersButton>
          <ViewProjectsButton
            onClick={() => {
              onCloseWizard();
              const timer = setTimeout(() => {
                clearTimeout(timer);
                scrollToProjects();
              }, 450);
            }}
          >
            {localized.viewProjects}
            <RightIcon icon={['far', 'arrow-down']} size="sm" />
          </ViewProjectsButton>
        </FinalPageButtons>
      )}
    </Navigation>
  );

  return (
    <Container>
      <AnimatePresence>
        <ProjectsFilters
          renderAdditionalUI={renderAdditionalUI}
          mapLoaded={!!mapInstance}
        />
      </AnimatePresence>
    </Container>
  );
};

export default SearchWizard;
