const devenv = {
  productId: 'Prompto',
  productId424: 'PromptoV2',
  maxExporterProductId: 'PromptoExporter3dsMax',
  sketchupExporterProductId: 'PromptoExporterSketchup',
  revitExporterProductId: 'PromptoExporterRevit',
  env: 'development',
  baseUrl: 'https://devapiv2.prompto.com/v2',
  apiToken:
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI2ZTM0ZjliNC03NmUwLTRkYmMtYTBiYy1jODQ0YjBlMzY2NzUiLCJpYXQiOjE0ODczMTgyODEsImV4cCI6MjExODAzODI4MSwiYXVkIjoiYXBpIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY2FwaVwiXSJ9.uwAmQwF2XUa4VFnFwnpB2XRi1AWC0pGuy7uoFmGjXHXA5OFgG7ucMj_k9JV1KemK2Z8jFqRLz3yDcsY8ZTWI5w',
  baseImageUrl: 'https://devimagecdn.prompto.com',
  cdnUrl: '',
  entityObjectId: 'aroundmedia',
  braintreeTokenizationKey: 'sandbox_9dmbb642_g2wsxwd4pxj8mwnb',
  applicationDomain: '.prompto.com',
  applicationUrl: 'devapp.prompto.com',
  sessionTokenRenewTimeout: 60 * 120 * 1000,
  googleStorageBucketId: 'devassetsvxgi.vr-tual.media',
  googleMapsAPIKey: 'AIzaSyC__OLtuhUjX4al5F6humPLtvZxlnYiyoQ',
  trialSubscriptionPlanId: 'around-trial-dev-3',
  defaultAppExpiration: '20s',
  stripe: 'pk_test_IVEsmgKankfY2VwIkuLZLol1',
  zippopotamusAPI: 'https://api.zippopotam.us/',
  typeFormOrderProjectUrl: 'https://prompto.typeform.com/to/Y3aUSL',
  orderSceneDismissCookiePopupTimeout: 7 * 24 * 60 * 60 * 1000, //1 week
  orderSceneDismissCookieName: 'orderSceneMaybeLater',
  loggedOutCookieName: 'loggedOut',
  aroundMediaPortalUrl: 'https://360app-devv2.prompto.com',
  aroundMediaViewerlUrl: 'https://360-dev.prompto.com',
  hubspotPortalId: '6072613',
  upsell360FormId: 'c9f8688b-31d6-4597-916c-f92c09ebd60f',
  upsellSessionTokenRenewThreshold: 4,
  essentialPackageInfo: 'https://prompto.com/subscriptions-essential/',
  defaultMaxProjectCount: '1',
  defaultMaxUserCount: '2',
  showcaseUrl: 'https://devshowcase.prompto.com',
  supportBaseLink: 'https://support.prompto.com',
  supportBillingPerUnitLink: 'https://support.prompto.com/billing-per-unit',
  supportPromptoPortalLink: 'https://support.prompto.com/prompto-portal',
  base360ImageUrl: 'https://devimagecdn.around.media',
  base360Url: 'https://devapi.around.media/v1',
  termsOfService: 'https://prompto.com/terms-of-service/',
  privacyPolicy: 'https://prompto.com/privacy-policy-2/',
  apiToken360:
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIxNmMwNGU4MS1jYTdhLTQxNmItYmMyMC0yNWM2ZWI3MDUxODEiLCJpYXQiOjE0Njc3MjE0MzQsImV4cCI6MjA5ODQ0MTQzNCwiYXVkIjoidmlld2VyIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY3ZpZXdlclwiXSJ9.ZjB3XiiQEjcguILhBUB8LzQD2Dcd4ssakiEsHZ_ISByn-McCdVM8EFTluP4RUxLSgytgq3GrNC93PYX3q68oWQ',
  cloudinaryVideoBaseUrl:
    'https://res.cloudinary.com/prompto-dev/video/upload/',
  cloudinaryImageBaseUrl:
    'https://res.cloudinary.com/prompto-dev/image/upload/',
  cloudinaryCloudName: 'prompto-dev',
  contractTestSigningCookieName: 'contractTestSigningCookie',
  appcueFlowWelcomeToPrompto: '-M5gKZ74LwcJvNfA-RUV',
  appcueFlowFirstProjectCreation: '47f26f52-5f82-4d0c-bf77-39a53486eb8e',
  appcueFlowUnitTypes: '36874fad-1be5-4548-ac8c-c914b359b5bc',
  appcueFlowFieldBuilder: '784b606c-629a-49d5-b6d0-f6768963ee2c',
  productLedRegistrationHubspotPortalId: '4943441',
  productLedRegistrationHubspotFormId: '723ec46d-e074-41f1-9935-a9d4630540d0',
  howToSetupUnitTypes:
    'https://support.prompto.com/billing-per-unit#per-unit-billing-model',
  howToImportUnitsFromCSV:
    'https://support.prompto.com/how-to-import-units-to-the-showcase-from-csv-file'
};

export default devenv;
