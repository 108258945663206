import { useState, useEffect } from 'react';

// Helpers
import { removeQueryParams, fetchSettingsFromURL } from 'src/helpers/utils';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';
import { useProjectsMapState } from 'src/store/ProjectsMapStore';

const parseFilterValues = (filterValues: any) => {
  const parsed: any = {};
  const parsedLocationFilter: any = {};

  filterValues
    .split('<')
    .filter(Boolean)
    .forEach((value: any) => {
      const trimmedValue = value.replace('>', '');

      const [key, subvalue] = trimmedValue.split('~');

      switch (key) {
        case 'price':
        case 'surface':
          const [values, limits, unit] = subvalue.split('|');
          const [minValue, maxValue] = values.split('_');
          const [minLimit, maxLimit] = limits.split('_');

          parsed[key] = {
            values: { min: +minValue, max: +maxValue },
            limits: { min: +minLimit, max: +maxLimit },
            unit
          };
          break;

        case 'numberOfBathrooms':
        case 'numberOfBedrooms':
          parsed[key] = {
            values: subvalue.split(',').map((value: string) => +value)
          };
          break;

        case 'location':
          subvalue
            .split('|')
            .filter(Boolean)
            .forEach((locationFilter: any) => {
              const [locationFilterKey, locationFilterValue] =
                locationFilter.split('^');

              switch (locationFilterKey) {
                case 'coords':
                  const [lat, lng] = locationFilterValue.split('_');
                  parsedLocationFilter.coords =
                    locationFilterValue === 'none'
                      ? null
                      : { lat: +lat, lng: +lng };
                  break;
                case 'provinceValues':
                  parsedLocationFilter.provinceValues = locationFilterValue
                    ? locationFilterValue.split(',')
                    : [];
                  break;
                case 'values':
                  parsedLocationFilter[locationFilterKey] =
                    +locationFilterValue;
                  break;
                case 'limits':
                  parsedLocationFilter[locationFilterKey] = {
                    max: +locationFilterValue
                  };
                  break;
                case 'searchByProvince':
                  parsedLocationFilter.searchByProvince =
                    locationFilterValue === 'true';
                  break;
                default:
                  parsedLocationFilter[locationFilterKey] = locationFilterValue;
              }
            });

        default:
      }
    });

  parsed.locationFilter = parsedLocationFilter;

  return parsed;
};

const convertQueryStringToFiltersState = (queryParam: any) => {
  const sharedFiltersState: any = {};

  queryParam
    .split('[')
    .filter(Boolean)
    .forEach((param: any) => {
      const trimmedParam = param.replace(']', '');

      const [key, value] = trimmedParam.split('=');

      switch (key) {
        case 'wc':
          sharedFiltersState.wizardCompleted = value === 'true';
          break;
        case 'af':
          sharedFiltersState.availableFilters = value.split('|');
          break;
        case 'sf':
          sharedFiltersState.selectedFilters = value.split('|');
          break;
        case 'fv':
          sharedFiltersState.filterValues = parseFilterValues(value);
          break;
        default:
      }
    });

  return sharedFiltersState;
};

const useSharedFilterParams = () => {
  const [filterQueryParamsIsChecked, setFilterQueryParamsIsChecked] =
    useState(false);

  const { ProjectsFiltersDispatch } = useProjectsFiltersState();
  const { ProjectsMapDispatch } = useProjectsMapState();

  useEffect(() => {
    if (filterQueryParamsIsChecked) return;

    const { filter, highlightedProjectId, lat, lng, zoom, selectedProject } =
      fetchSettingsFromURL();

    if (filter) {
      const sharedFiltersState = convertQueryStringToFiltersState(filter);
      ProjectsFiltersDispatch({
        type: 'setSharedFiltersState',
        payload: sharedFiltersState
      });
      removeQueryParams([{ filter: '' }]);
    }

    if (highlightedProjectId) {
      ProjectsMapDispatch({
        type: 'updateProjectsMapState',
        payload: {
          hoveredProjectId: highlightedProjectId
        }
      });
      removeQueryParams([{ highlightedProjectId: '' }, { shareFilter: '' }]);
    }

    if (lat && lng) {
      ProjectsMapDispatch({
        type: 'updateProjectsMapState',
        payload: {
          initialCenter: { lat, lng }
        }
      });
      removeQueryParams([{ lat: '' }, { lng: '' }, { shareFilter: '' }]);
    }

    if (zoom) {
      ProjectsMapDispatch({
        type: 'updateProjectsMapState',
        payload: {
          initialZoom: zoom
        }
      });
      removeQueryParams([{ zoom: '' }, { shareFilter: '' }]);
    }

    if (selectedProject) {
      ProjectsMapDispatch({
        type: 'updateProjectsMapState',
        payload: {
          initialSelectedProject: selectedProject
        }
      });
      removeQueryParams([{ selectedProject: '' }, { shareFilter: '' }]);
    }

    setFilterQueryParamsIsChecked(true);
  }, [filterQueryParamsIsChecked]);
};

export default useSharedFilterParams;
