// Styling
import { useEffect } from 'react';

import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';
import { useAppState } from 'src/store/AppStore';
import { useAuthState } from 'src/store/AuthStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';
import styled from 'styled-components';
import { Marketplace } from '@/libs/prompto-api/src';
import { useNavigate } from 'react-router-dom';
import localizer from 'src/localization/localizer';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const Title = styled.div`
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-weight: bold;
  color: ${({ theme }) => theme.gray60};
  margin-bottom: 24px;
`;

const FilterItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #e7e6ea;
  height: 48px;
  width: fit-content;
  user-select: none;
  cursor: pointer;
  margin-bottom: 12px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.gray50};
  width: 24px;
  height: 24px;
  margin-left: 12px;
  margin-right: 12px;
`;

const DeleteIcon = styled(StyledIcon)`
  color: ${({ theme }) => theme.redDark};
  cursor: pointer;
`;

const SearchPreferences = () => {
  const { ProjectsFiltersState, ProjectsFiltersDispatch } =
    useProjectsFiltersState();
  const { userSearchPreferences } = ProjectsFiltersState;

  const { AuthState } = useAuthState();
  const { vaultObjectId, sessionToken } = AuthState;

  const { AppState } = useAppState();
  const { marketplaceId } = AppState;

  const navigate = useNavigate();

  // Get the filters if not present
  useEffect(() => {
    if (vaultObjectId && marketplaceId && sessionToken) {
      Marketplace.getAllMarketplaceUserSearchPreferences(
        {
          vaultId: vaultObjectId,
          marketplaceId
        },
        sessionToken
      ).then((result: any) => {
        const { data } = result;
        if (data?.preferenceList) {
          ProjectsFiltersDispatch({
            type: 'storeUserPreferenceList',
            payload: data.preferenceList
          });
        }
      });
    }
  }, [vaultObjectId, marketplaceId, sessionToken]);

  const onDeletePreference = (preference: any) => {
    ProjectsFiltersDispatch({
      type: 'storeUserPreferenceList',
      payload: userSearchPreferences.filter(
        (x: any) => x.objectId !== preference.objectId
      )
    });

    Marketplace.deleteMarketplaceUserSearchPreference(
      preference.objectId,
      sessionToken
    );
  };

  return (
    <Wrapper>
      {userSearchPreferences?.length > 0 && (
        <Title>{localizer.accountPage.previousFilters}</Title>
      )}
      {userSearchPreferences?.map((preference: any) => {
        const priceRange = `€ ${numeral(
          preference.minPrice
        ).format()} - ${numeral(preference.maxPrice).format()}`;

        const surfaceRange = `${preference.minSurface} - ${preference.maxSurface} m²`;

        const bedroomRange = `Bedrooms: ${preference.minBedrooms} - ${preference.maxBedrooms}`;
        const bathroomRange = `Bedrooms: ${preference.minBathrooms} - ${preference.maxBathrooms}`;

        let fullPreference = '';
        let somethingAdded = false;

        if (preference.maxPrice) {
          fullPreference += priceRange;
          somethingAdded = true;
        }
        if (preference.maxSurface) {
          if (somethingAdded) fullPreference += '  |  ';
          fullPreference += surfaceRange;
          somethingAdded = true;
        }
        if (preference.maxBedrooms) {
          if (somethingAdded) fullPreference += '  |  ';
          fullPreference += bedroomRange;
          somethingAdded = true;
        }
        if (preference.maxBathrooms) {
          if (somethingAdded) fullPreference += '  |  ';
          fullPreference += bathroomRange;
        }

        return (
          <FilterItem
            onClick={() => {
              ProjectsFiltersDispatch({
                type: 'setActiveUserSearchPreference',
                payload: preference
              });
              ProjectsFiltersDispatch({
                type: 'setFiltersFromSearchPreference',
                payload: preference
              });

              navigate(`/projects${window.location.search}`);
            }}
          >
            <StyledIcon icon={['fal', 'search']} size="sm" />
            {fullPreference}

            <DeleteIcon
              icon={['fal', 'times']}
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDeletePreference(preference);
              }}
            />
          </FilterItem>
        );
      })}
    </Wrapper>
  );
};

export default SearchPreferences;
