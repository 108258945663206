import React, { useReducer, createContext, useContext } from 'react';
export const DataStoreContext = createContext({
  projects: []
});

const DataStore = (props) => {
  const [DataState, DataStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'storeProjectData': {
          const matchingProject = state.projects.find((x) => {
            return x.objectId === action.payload.objectId;
          });
          // Update existing entry of project in store
          if (matchingProject) {
            return {
              ...state,
              projects: [
                ...state.projects.filter(
                  (x) => x.objectId !== action.payload.objectId
                ),
                { ...matchingProject, ...action.payload }
              ]
            };
          } else {
            // Add project as a new entry in the store
            return { ...state, projects: [...state.projects, action.payload] };
          }
        }
        case 'storeProjectContent': {
          const matchingProject = state.projects.find((x) => {
            return x.objectId === action.payload.projectObjectId;
          });
          // Update existing entry of project in store
          if (matchingProject) {
            return {
              ...state,
              projects: [
                ...state.projects.filter(
                  (x) => x.objectId !== action.payload.projectObjectId
                ),
                {
                  ...matchingProject,
                  vmContentCollection: action.payload.vmContentCollection
                }
              ]
            };
          } else {
            // Add project as a new entry in the store
            return {
              ...state,
              projects: [
                ...state.projects,
                {
                  objectId: action.payload.projectObjectId,
                  vmContentCollection: action.payload.vmContentCollection
                }
              ]
            };
          }
        }
        case 'storeProjectPreviewContent': {
          const matchingProject = state.projects.find((x) => {
            return x.objectId === action.payload.projectObjectId;
          });
          // Update existing entry of project in store
          if (matchingProject) {
            return {
              ...state,
              projects: [
                ...state.projects.filter(
                  (x) => x.objectId !== action.payload.projectObjectId
                ),
                {
                  ...matchingProject,
                  previewContent: action.payload.vmContentItemList
                }
              ]
            };
          } else {
            // Add project as a new entry in the store
            return {
              ...state,
              projects: [
                ...state.projects,
                {
                  objectId: action.payload.projectObjectId,
                  previewContent: action.payload.vmContentItemList
                }
              ]
            };
          }
        }
        case 'storeProjectUnits': {
          const matchingProject = state.projects.find((x) => {
            return x.objectId === action.payload.projectObjectId;
          });
          // Update existing entry of project in store
          if (matchingProject) {
            return {
              ...state,
              projects: [
                ...state.projects.filter(
                  (x) => x.objectId !== action.payload.projectObjectId
                ),
                {
                  ...matchingProject,
                  unitList: action.payload.unitList
                }
              ]
            };
          } else {
            // Add project as a new entry in the store
            return {
              ...state,
              projects: [
                ...state.projects,
                {
                  objectId: action.payload.projectObjectId,
                  unitList: action.payload.unitList
                }
              ]
            };
          }
        }
        case 'storeUnitContentCollection':
        case 'storeUnitLinkedContentCollection': {
          const matchingProject = state.projects.find((x) => {
            return x.objectId === action.payload.projectObjectId;
          });

          // By this point project should exist, but to be safe
          if (matchingProject) {
            const matchingUnit = matchingProject.unitList.find(
              (x) => x.objectId === action.payload.unit.objectId
            );

            return {
              ...state,
              projects: [
                ...state.projects.filter(
                  (x) => x.objectId !== action.payload.projectObjectId
                ),
                {
                  ...matchingProject,
                  unitList: [
                    ...matchingProject.unitList.filter(
                      (x) => x.objectId !== action.payload.unit.objectId
                    ),
                    { ...matchingUnit, ...action.payload.unit }
                  ]
                }
              ]
            };
          }

          return state;
        }

        case 'storeProjectSettings': {
          const matchingProject = state.projects.find((x) => {
            return x.objectId === action.payload.objectId;
          });
          // Update existing entry of project in store
          if (matchingProject) {
            return {
              ...state,
              projects: [
                ...state.projects.filter(
                  (x) => x.objectId !== action.payload.objectId
                ),
                { ...matchingProject, ...action.payload }
              ]
            };
          } else {
            // Add project as a new entry in the store
            return {
              ...state,
              projects: [...state.projects, ...action.payload]
            };
          }
        }

        case 'setFOMODataPerProject':
          return {
            ...state,
            fomo: {
              ...state.fomo,
              [action.payload.projectId]: action.payload.data
            }
          };

        case 'setProjectsPerProvince':
          return {
            ...state,
            projectsPerProvince: action.payload
          };

        case 'setFetchedNavigationItems':
          return {
            ...state,
            fetchedNavigationItems: action.payload
          };

        case 'storeCurrentVaultId':
          return { ...state, currentVaultId: action.payload };
        case 'storeCurrentProjectId':
          return { ...state, currentProjectId: action.payload };
        case 'storeCurrentUnitId':
          return { ...state, currentUnitId: action.payload };
        case 'setScrollToTop':
          return { ...state, scrollToTop: action.payload };
        case 'setScrollToTopOfUnitPage':
          return { ...state, scrollToTopOfUnitPage: action.payload };
        case 'storeContentCollection':
          return {
            ...state,
            contentCollections: [
              ...state.contentCollections.filter(
                (x) => x.objectId !== action.payload.objectId
              ),
              action.payload
            ]
          };
        default:
          return state;
      }
    },
    {
      projects: [],
      currentVaultId: '',
      currentProjectId: '',
      currentUnitId: '',
      scrollToTop: false,
      scrollToTopOfUnitPage: false,
      fomo: {},
      projectsPerProvince: null,
      fetchedNavigationItems: [],
      contentCollections: []
    }
  );

  return (
    <DataStoreContext.Provider value={{ DataState, DataStateDispatch }}>
      {props.children}
    </DataStoreContext.Provider>
  );
};

export const useDataState = () => useContext(DataStoreContext);

export default DataStore;
