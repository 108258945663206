import { useCallback, useEffect, useState, useRef } from 'react';

// Components
import SearchWizard from './components/SearchWizard';
import ProjectCard from './components/ProjectCard';
import ProjectCardAlternative from '../landingPage/projectsBlock/ProjectCard';
import PaginationControls from 'src/components/unitList/PaginationControls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'src/components/Loader';
import Header from 'src/components/Header';

// Helpers
import { forBiggerThan } from 'src/helpers/ui';
import {
  initialFilters,
  useProjectsFiltersState
} from 'src/store/ProjectsFiltersStore';
import { useProjectsState } from 'src/store/ProjectsStore';
import { AnimatePresence, motion } from 'framer-motion';
import localizer, { setLanguage } from 'src/localization/localizer';
import { useAppState } from 'src/store/AppStore';
import { useProjectsMapState } from 'src/store/ProjectsMapStore';
import {
  getUpdatedInitialFilters,
  prepareSearchProjectsRequestBody,
  IInitialFilters,
  getFiltersOrLocationFromUrl,
  isValidProjectsPageUrl
} from './ProjectsPageHelpers';
import isEqual from 'lodash.isequal';
import { isMobile } from 'react-device-detect';
import Cookies from 'universal-cookie';
import { paginationOptions } from 'src/components/unitList/UnitList';
import qs from 'query-string';
import { Tracking, TrackMarketplaceActionParams } from '@prompto-api';
import { isMobileOnly } from 'react-device-detect';

// Styling
import styled, { StyledProps } from 'styled-components';

// Types
import { IProject, Marketplace } from '@/libs/prompto-api/src';
import { buildAssetURIWithOptions } from 'src/helpers/uri';
import { useParams, useLocation } from 'react-router-dom';
import { fetchSettingsFromURL } from 'src/helpers/utils';

const PageWrapper = styled.div<StyledProps<{ currentLanguage: string, hideMenu?: boolean }>>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  z-index: 100;
  min-height: 100dvh;
  height: ${({hideMenu}) => hideMenu ? '100dvh' : 'calc(100dvh - 72px)'};
  overflow: auto;
`;

const MainContent = styled.div<StyledProps<{ expandMap: boolean, hideMenu? :boolean }>>`
  position: relative;
  top: ${({ expandMap,hideMenu }) => (hideMenu ? 80 : isMobile ? (expandMap ? 60 : 140) : 152)}px;
  transition: all 180ms ease;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 24px 16px;
  flex-grow: 1;

  ${forBiggerThan.tablet`
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding: 0 64px 12px;
  `}
`;

const SearchResultCount = styled.div<StyledProps<{ expandMap: boolean }>>`
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  height: 60px;

  & > div {
    font-style: italic;
    span {
      font-weight: bold;
    }
  }

  ${forBiggerThan.mobile`
    flex-flow: row;
    align-items: center;
  `}
`;

const ProjectsOnMapCount = styled.div`
  margin: 0;
  font-style: italic;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.primary300};
  p {
    margin: 5px 0;
  }
  span {
    font-weight: bold;
    margin: 0;
  }
  ${forBiggerThan.mobile`
    margin-left: 16px;
  `}
`;

const COLUMN_GAP = 32;

const ProjectList = styled(motion.div)`
  display: flex;
  flex-flow: column;
  row-gap: 25px;
  column-gap: ${COLUMN_GAP}px;
  margin-bottom: 80px;

  ${forBiggerThan.tablet`
    flex-flow: row;
    flex-wrap: wrap;
  `}
`;

const GridWrapper = styled.div`
  grid-area: grid;
  max-height: ${isMobile ? 'auto' : 'auto'};
  position: relative;
`;

// const CreateAccountWrapper = styled.div`
//   margin-top: 80px;
//   margin-bottom: 32px;
// `;

const ProjectCardWrapper = styled(motion.div)`
  flex-basis: 100%;

  ${forBiggerThan.tablet`
    // We show 2 cards on laptop, so 50% per card.
    // There will be 1 columns which we will devide amonst the 2 cards, same logic follows for other screensizes

    flex-basis: calc(50% - ${COLUMN_GAP / 2}px);
  `};

  ${forBiggerThan.laptop`
    flex-basis: calc(33% - ${(COLUMN_GAP * 2) / 3}px);
  `};

  ${forBiggerThan.desktop`
    flex-basis: calc(25% - ${(COLUMN_GAP * 3) / 4}px);
  `};
`;

const ProjectCardExpandedMapWrapper = styled(motion.div)`
  flex-basis: 100%;

  ${forBiggerThan.desktop`
    flex-basis: calc(50% - ${COLUMN_GAP / 2}px);
  `};
`;

const PaginationControlsWrapper = styled.div`
  margin-top: 80px;
`;

const ToggleMapButton = styled(motion.button)`
  min-width: ${isMobile ? 150 : 176}px;
  height: 48px;
  padding: 5px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  border: none;
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
`;

const CloseButtonIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.white};
  margin-right: 10px;
`;

const Divider = styled.div`
  display: none;
  height: 32px;
  width: 1px;
  background-color: ${({ theme }) => theme.gray20};
  margin: 0 32px;

  ${forBiggerThan.mobile`
    display: block;
  `}
`;

const LoaderWrapper = styled(motion.div)`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.4);
`;

const StyledLoader = styled(Loader)`
  height: 40px;
`;

const GridAndMapWrapper = styled.main<StyledProps<{ expandMap: boolean }>>`
  display: grid;
  grid-template-columns: ${({ expandMap }) => {
    const mobile = '1fr';
    const desktop = expandMap ? '1fr 1fr' : '1fr';
    return isMobile ? mobile : desktop;
  }};
  grid-template-rows: ${isMobile ? '1fr' : 'auto'};
  grid-template-areas: ${({ expandMap }) => {
    const mobile = `'grid' 'grid'`;
    const desktop = expandMap ? `'grid map'` : `'grid'`;
    return isMobile ? mobile : desktop;
  }};
  grid-gap: ${({ expandMap }) => (expandMap ? 32 : 0)}px;
`;

const GridContent = styled.div`
  padding-right: ${isMobile ? 0 : 12}px;
`;

const NoProjectsMessage = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.primary300};
`;

const WizardWrapper = styled.div<StyledProps<{ appMenuOpened: boolean, hideMenu: boolean }>>`
  position: fixed;
  top:${({hideMenu}) => hideMenu ? 0 : isMobile ? 64 : 72}px;
  left: 0;
  right: 0;
  z-index: ${({ appMenuOpened }) =>
    isMobile ? (appMenuOpened ? 1000 : 1003) : 1001};
`;

const HeaderWrapper = styled.div<StyledProps<{ hide: boolean }>>`
  position: fixed;
  z-index: 3007;
  top: 0;
  left: 0;
  right: 0;
  display: ${({hide}) => hide ? 'none' :'unset'};
`;

const StyledImage = styled.img`
  height: 25px;
  object-fit: contain;
  margin-right: 15px;
  margin-bottom: 15px;
`;

const ShowAllprojectsButton = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0 8px;
  color: ${({ theme }) => theme.black};
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

// The amount of projects displayed in the main grid of the projects page
const MAIN_PROJECT_LIST_AMOUNT = 12;

const cookies = new Cookies();
const BIND_MAP_TO_LIST_COOKIE = 'isMarketplaceMapBoundToList';

interface IMarketplaceProject {
  address: any;
  localizedTitle: any;
  marketplaceProjectId: string;
  projectId: string;
  projectThumbnailUri?: string;
  vaultId: string;
}

export interface IMapProject extends IMarketplaceProject {
  coords?: {
    lat: number;
    lng: number;
  };
}

const ProjectsPage = () => {
  const params = qs.parse(document.location.search);
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(paginationOptions[0].value);
  const [withPagination, setWithPagination] = useState(false);

  const [appMenuOpened, setAppMenuOpened] = useState(false);

  const [currentLanguage, setCurrentLanguage] = useState<string>('');

  const { language } = useParams<string>();
  const location = useLocation();

  const {hideMenu} = fetchSettingsFromURL();


  // global filter values
  const { ProjectsFiltersState, ProjectsFiltersDispatch } =
    useProjectsFiltersState();
  const { filterValues, selectedFilters, vaultFilter } = ProjectsFiltersState;


  // Projects state
  const { ProjectsState, ProjectsStateDispatch } = useProjectsState();
  const {
    allProjects,
    displayedProjects,
    filteredProjects,
    totalCount,
    allVaultLogos,
    allProjectLogos,
    loading,
    error,
    projectsUnitCount
  } = ProjectsState;

  // App state
  const { AppState } = useAppState();
  const { marketplaceId, sessionId, visitorId, userLocation } = AppState;

  const { ProjectsMapState, ProjectsMapDispatch } = useProjectsMapState();
  const {
    projectsVisibleInMap,
    projectsForMap,
    isMapBoundToList,
    mapInstance,
    expandMap
  } = ProjectsMapState;

  // filter related
  const [newInitialFilters, setNewInitialFilters] = useState<IInitialFilters>();
  const [lastFilter, setLastFilter] = useState<any>();
  const [urlProcessed, setUrlProcessed] = useState<boolean>(false);

  // REFS
  const projectsGridRef = useRef<HTMLDivElement>(null);
  const projectsTitleRef = useRef<HTMLDivElement>(null);
  const delayTimerRef = useRef<any>();
  const unitCountHasFetched = useRef<any>(false);
  const prevPage = useRef<number>(0);

  useEffect(() => {
    if (
      isValidProjectsPageUrl(location.pathname) &&
      allVaultLogos &&
      !urlProcessed
    ) {
      setUrlProcessed(true);
      const filtersOrLocation: any = getFiltersOrLocationFromUrl(
        location.pathname
      );

      if (filtersOrLocation) {
        const updatedMapState: any = {};

        if (filtersOrLocation?.lat && filtersOrLocation?.lng) {
          updatedMapState.initialCenter = {
            lat: filtersOrLocation.lat,
            lng: filtersOrLocation.lng
          };
        }
        if (filtersOrLocation.zoom) {
          updatedMapState.initialZoom = filtersOrLocation.zoom;
        }

        if (Object.keys(updatedMapState).length > 0) {
          ProjectsMapDispatch({
            type: 'updateProjectsMapState',
            payload: updatedMapState
          });
        }

        if (filtersOrLocation.developer) {
          if (
            filtersOrLocation.developer &&
            allVaultLogos[filtersOrLocation.developer]
          ) {
            ProjectsFiltersDispatch({
              type: 'setVaultFilter',
              payload: {
                key: filtersOrLocation.developer,
                logo: allVaultLogos[filtersOrLocation.developer]
              }
            });
          }
        }
      }
    }
  }, [location, allVaultLogos, urlProcessed]);

  // Track Projects page visitor
  useEffect(() => {
    if (marketplaceId && sessionId && visitorId) {
      const trackingData = {
        actionName: 'trackMarketplaceProjectsVisitor',
        marketplaceId,
        visitorId,
        sessionId
      };
      Tracking.trackMarketplaceAction(trackingData).catch(() => {});
    }
  }, [marketplaceId, sessionId, visitorId]);

  // set default min and max values for price and surface of INITIAL filters that filters reducer use
  useEffect(() => {
    if (marketplaceId) {
      Marketplace.getMarketplaceFiltersEdges(marketplaceId)
        .then(({ data }) => {
          const updatedInitialFilters = getUpdatedInitialFilters(
            initialFilters,
            data
          );
          localStorage.setItem(
            'initialFilters',
            JSON.stringify(updatedInitialFilters)
          );
          setNewInitialFilters(updatedInitialFilters);
        })
        .catch(() => {});
    }
  }, [marketplaceId, setNewInitialFilters]);

  useEffect(() => {
    if (!marketplaceId) return;
    if (error) return;

    const projectsFilter = { ...lastFilter, limit: 200 };

    if (delayTimerRef.current) {
      clearTimeout(delayTimerRef.current);
    }

    delayTimerRef.current = setTimeout(() => {
      ProjectsStateDispatch({
        type: 'setProjectsData',
        payload: {
          loading: true,
          error: false
        }
      });
      Marketplace.searchProjects(marketplaceId, projectsFilter ?? {})
        .then((result) => {
          let projects = result.data?.projectList ?? [];

          if (vaultFilter) {
            projects = projects.filter(
              (x: any) => x.vaultId === vaultFilter.key
            );
          }

          ProjectsStateDispatch({
            type: 'setProjectsData',
            payload: {
              unalteredProjects: result.data?.projectList ?? [],
              allProjects: projects,
              totalCount: vaultFilter
                ? projects?.length
                : result.data?.totalCount ?? 0,
              allVaultLogos: result.data?.vaultIdToLogoUriMap ?? [],
              allProjectLogos: result.data?.projectIdToLogoUriMap ?? [],
              loading: false,
              error: false
            }
          });
        })
        .catch(() => {
          ProjectsStateDispatch({
            type: 'setProjectsData',
            payload: {
              loading: false,
              error: true
            }
          });
        });
    }, 250);
  }, [lastFilter, vaultFilter, marketplaceId, error, ProjectsStateDispatch]);

  useEffect(() => {
    if (allProjects.length > 0 && !unitCountHasFetched.current) {
      unitCountHasFetched.current = true;
      const projectsIDs = allProjects
        .filter((project: IMarketplaceProject) =>
          projectsUnitCount ? !projectsUnitCount[project.projectId] : true
        )
        .map((project: IMarketplaceProject) => project.projectId);

      if (projectsIDs.length > 0) {
        Marketplace.getUnitCount({
          marketplaceId,
          projectIdList: projectsIDs as string[],
          unitStateList: ['AVAILABLE', 'IN_OPTION']
        })
          .then((result) => {
            ProjectsStateDispatch({
              type: 'setProjectsData',
              payload: {
                projectsUnitCount: {
                  ...projectsUnitCount,
                  ...result.data?.projectsUnitsCountMap
                }
              }
            });
          })
          .catch(() => {});
      }
    }
  }, [allProjects, projectsUnitCount]);

  let totalUnitCount = 0;
  Object.keys(projectsUnitCount).forEach((key) => {
    if (
      allProjects.find((x: any) => {
        return x.projectId === key;
      })
    ) {
      totalUnitCount += projectsUnitCount[key];
    }
  });

  // display in the list only projects that are currently visible in the map
  useEffect(() => {
    if (!projectsVisibleInMap) {
      ProjectsStateDispatch({
        type: 'setProjectsData',
        payload: {
          filteredProjects: allProjects
        }
      });
      return;
    }

    setPage(0);
    if (isMapBoundToList && expandMap) {
      if (allProjects?.length > 0) {
        const filtered = allProjects.filter((project: IProject) =>
          projectsVisibleInMap.includes(project.projectId as string)
        );

        ProjectsStateDispatch({
          type: 'setProjectsData',
          payload: {
            filteredProjects: filtered
          }
        });
      } else {
        ProjectsStateDispatch({
          type: 'setProjectsData',
          payload: {
            filteredProjects: []
          }
        });
      }
    } else {
      ProjectsStateDispatch({
        type: 'setProjectsData',
        payload: {
          filteredProjects: allProjects
        }
      });
    }
  }, [projectsVisibleInMap, allProjects, isMapBoundToList, expandMap]);

  // display filtered projects according to current page
  useEffect(() => {
    const displayed = filteredProjects.slice(
      page * pageSize,
      page * pageSize + pageSize
    );

    ProjectsStateDispatch({
      type: 'setProjectsData',
      payload: {
        displayedProjects: displayed
      }
    });
  }, [page, pageSize, filteredProjects]);

  // filter projects when filter values get changed
  useEffect(() => {
    const searchProjectsRequestBody = prepareSearchProjectsRequestBody(
      page,
      pageSize,
      filterValues,
      selectedFilters,
      userLocation
    );

    console.log(searchProjectsRequestBody)
    console.log(filterValues)
    console.log(selectedFilters)


    if (!isEqual(lastFilter, searchProjectsRequestBody)) {
      // Check if location related values were changes
      const radiusChanged =
        lastFilter?.radius !== searchProjectsRequestBody.radius;
      const latitudeChanged =
        lastFilter?.latitude !== searchProjectsRequestBody.latitude;
      const longitudeChanged =
        lastFilter?.longitude !== searchProjectsRequestBody.longitude;
      const provinceValuesChanged = !isEqual(
        lastFilter?.provinceList,
        searchProjectsRequestBody.provinceList
      );

      if (
        radiusChanged ||
        latitudeChanged ||
        longitudeChanged ||
        provinceValuesChanged
      ) {
        if (marketplaceId && sessionId && visitorId) {
          const trackingData: TrackMarketplaceActionParams = {
            actionName: 'trackLocationFilterChange',
            marketplaceId,
            visitorId,
            sessionId
          };

          if (
            searchProjectsRequestBody.provinceList &&
            searchProjectsRequestBody.provinceList.length > 0
          ) {
            trackingData.filterProvinceList =
              searchProjectsRequestBody.provinceList;
          } else {
            trackingData.filterLongitude = searchProjectsRequestBody.longitude;
            trackingData.filterLatitude = searchProjectsRequestBody.latitude;
            trackingData.filterRadius = searchProjectsRequestBody.radius;
          }

          if (
            trackingData.filterProvinceList ||
            (trackingData.filterLongitude && trackingData.filterLatitude)
          ) {
            Tracking.trackMarketplaceAction(trackingData).catch(() => {});
          }
        }
      }

      unitCountHasFetched.current = false;
      setLastFilter(searchProjectsRequestBody);
      // if page wasn't changed - that means that only filters changed - then go to first page
      if (prevPage.current === page) {
        setPage(0);
      }
      prevPage.current = page;
    }
  }, [
    allProjects,
    filterValues,
    lastFilter,
    marketplaceId,
    sessionId,
    visitorId,
    selectedFilters,
    userLocation
  ]);

  // check if pagination is needed
  useEffect(() => {
    setWithPagination(filteredProjects.length > paginationOptions[0].value);
  }, [filteredProjects]);

  // Set language from url
  useEffect(() => {
    if (language) {
      setLanguage(language as string);
    }
  });

  const onProjectAddressClick = useCallback(
    (project: IMapProject) => {
      ProjectsMapDispatch({
        type: 'updateProjectsMapState',
        payload: {
          selectedProject: project,
          expandMap: true
        }
      });
    },
    [projectsForMap]
  );

  const renderFilterBardAdditionalUI = useCallback(
    () => (
      <>
        <Divider />
        <ToggleMapButton
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() =>
            ProjectsMapDispatch({
              type: 'updateProjectsMapState',
              payload: {
                expandMap: !expandMap
              }
            })
          }
        >
          <CloseButtonIcon
            icon={expandMap ? ['fas', 'list'] : ['far', 'map']}
            size="1x"
          />
          {!expandMap
            ? localizer.projectsMap.showMap
            : isMobile
            ? localizer.projectsMap.showList
            : localizer.projectsMap.hideMap}
        </ToggleMapButton>
      </>
    ),
    [expandMap]
  );

  const wizardComponent = (
    <WizardWrapper appMenuOpened={appMenuOpened} hideMenu={hideMenu as boolean}>
      <SearchWizard
        renderAdditionalUI={renderFilterBardAdditionalUI}
        mapInstance={mapInstance}
        scrollToProjects={() => {
          projectsGridRef.current &&
            projectsGridRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest'
            });
        }}
        expandMap={expandMap}
        newInitialFilters={newInitialFilters}
      />
    </WizardWrapper>
  );

  const enlistForm = (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSdV0g_9wl51r7RJudsp-y8s796rlbS5_p2V6SfdH__6iZVCFQ/viewform?embedded=true"
      width={isMobile ? '100%' : '600'}
      height="500"
      frameBorder="0"
    />
  );

  return (
    <>
      <HeaderWrapper hide={hideMenu as boolean}>
        <Header
          onLanguageSwitched={(language: string) => {
            setCurrentLanguage(language);
          }}
          isSticky={true}
          onToggleMenu={setAppMenuOpened}
        />
      </HeaderWrapper>
      {wizardComponent}

      <PageWrapper currentLanguage={currentLanguage} ref={projectsTitleRef} hideMenu={hideMenu as boolean}>
        <MainContent expandMap={expandMap} hideMenu={hideMenu as boolean }>
          {vaultFilter && isMobileOnly && (
            <HorizontalWrapper>
              <StyledImage
                src={buildAssetURIWithOptions('h=250', vaultFilter?.logo ?? '')}
              />
              <ShowAllprojectsButton
                onClick={() => {
                  ProjectsFiltersDispatch({ type: 'clearAllFilters' });
                }}
              >
                {localizer.homePage.viewAllprojects}
              </ShowAllprojectsButton>
            </HorizontalWrapper>
          )}

          <SearchResultCount expandMap={expandMap}>
            <div>
              {totalCount
                ? localizer.formatString(
                    totalCount === 1
                      ? localizer.projectDetailPage.projectFound
                      : localizer.projectDetailPage.projectsFound,
                    <span>{totalCount}</span>,
                    <span>{totalUnitCount}</span>
                  )
                : null}
            </div>
            {totalCount && expandMap && isMapBoundToList ? (
              <ProjectsOnMapCount>
                {projectsVisibleInMap && projectsVisibleInMap.length > 0 ? (
                  <p>
                    (<span>{projectsVisibleInMap.length}</span>{' '}
                    {localizer.projectsPage.onTheMap})
                  </p>
                ) : null}
              </ProjectsOnMapCount>
            ) : null}
          </SearchResultCount>

          <GridAndMapWrapper expandMap={expandMap}>
            <GridWrapper>
              <AnimatePresence>
                {loading && (
                  <LoaderWrapper
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                  >
                    <StyledLoader size="2x" />
                  </LoaderWrapper>
                )}
              </AnimatePresence>
              <GridContent>
                {displayedProjects.length === 0 && !loading ? (
                  <NoProjectsMessage>
                    {isMapBoundToList && expandMap ? (
                      <>
                        <p>
                          {localizer.projectsPage.noProjectsOnVisibleMapArea}
                        </p>
                        {enlistForm}
                      </>
                    ) : (
                      <>
                        <p>
                          {localizer.projectsPage.noProjectsMatchingFilters}
                        </p>
                        {enlistForm}
                      </>
                    )}
                  </NoProjectsMessage>
                ) : (
                  <>
                    {/* First we render an x amount of projects set in MAIN_PROJECT_LIST_AMOUNT */}
                    <ProjectList ref={projectsGridRef}>
                      {displayedProjects
                        .slice(0, MAIN_PROJECT_LIST_AMOUNT)
                        .map((project: any, i: number) => {
                          let unitCount = 0;
                          if (projectsUnitCount) {
                            unitCount =
                              projectsUnitCount[project?.projectId] || 0;
                          }
                          const card = params?.useAlternativeProjectCard ? (
                            <ProjectCardAlternative
                              idx={project?.projectId}
                              project={{
                                ...project,
                                unitCount
                              }}
                              fixedHeight={'250px'}
                              size={400}
                              hideBadge={true}
                              isDragging={false}
                            />
                          ) : (
                            <ProjectCard
                              key={project.projectId}
                              project={project}
                              projectForMap={projectsForMap.find(
                                (proj: IMapProject) =>
                                  proj.projectId === project.projectId
                              )}
                              onProjectAddressClick={onProjectAddressClick}
                              allVaultLogos={allVaultLogos}
                              allProjectLogos={allProjectLogos}
                              unitCount={unitCount}
                              onToggleHoveredState={(projectId) =>
                                ProjectsMapDispatch({
                                  type: 'updateProjectsMapState',
                                  payload: {
                                    hoveredProjectId: projectId
                                  }
                                })
                              }
                            />
                          );
                          return expandMap ? (
                            <ProjectCardExpandedMapWrapper
                              key={`projectWrapper-expanded${i}`}
                              {...projectCardTransitions}
                              transition={{ duration: 0.3, delay: i * 0.035 }}
                            >
                              {card}
                            </ProjectCardExpandedMapWrapper>
                          ) : (
                            <ProjectCardWrapper
                              key={`projectWrapper${i}`}
                              {...projectCardTransitions}
                              transition={{ duration: 0.3, delay: i * 0.035 }}
                            >
                              {card}
                            </ProjectCardWrapper>
                          );
                        })}
                    </ProjectList>
                    {/* <CreateAccountWrapper>
                <ProjectsCreateAccount />
              </CreateAccountWrapper> */}
                    <ProjectList>
                      {displayedProjects
                        .slice(MAIN_PROJECT_LIST_AMOUNT, pageSize)
                        .map((project: any, i: number) => {
                          let unitCount = 0;
                          if (projectsUnitCount) {
                            unitCount =
                              projectsUnitCount[project?.projectId] || 0;
                          }
                          const card = params?.useAlternativeProjectCard ? (
                            <ProjectCardAlternative
                              idx={project?.projectId}
                              project={{
                                ...project,
                                unitCount
                              }}
                              fixedHeight={'250px'}
                              size={400}
                              hideBadge={true}
                              isDragging={false}
                            />
                          ) : (
                            <ProjectCard
                              key={project.projectId}
                              project={project}
                              projectForMap={projectsForMap.find(
                                (proj: IMapProject) =>
                                  proj.projectId === project.projectId
                              )}
                              onProjectAddressClick={onProjectAddressClick}
                              allVaultLogos={allVaultLogos}
                              unitCount={unitCount}
                              onToggleHoveredState={(projectId) =>
                                ProjectsMapDispatch({
                                  type: 'updateProjectsMapState',
                                  payload: {
                                    hoveredProjectId: projectId
                                  }
                                })
                              }
                            />
                          );
                          return expandMap ? (
                            <ProjectCardExpandedMapWrapper
                              key={`projectWrapper-expanded${i}`}
                              {...projectCardTransitions}
                              transition={{ duration: 0.3, delay: i * 0.035 }}
                            >
                              {card}
                            </ProjectCardExpandedMapWrapper>
                          ) : (
                            <ProjectCardWrapper
                              key={`projectWrapper${i}`}
                              {...projectCardTransitions}
                              transition={{ duration: 0.3, delay: i * 0.035 }}
                            >
                              {card}
                            </ProjectCardWrapper>
                          );
                        })}
                    </ProjectList>
                  </>
                )}

                {withPagination && (
                  <PaginationControlsWrapper>
                    <PaginationControls
                      perPage={pageSize}
                      setPerPage={setPageSize}
                      currentPage={page}
                      setCurrentPage={(x) => {
                        setPage(x);
                        projectsTitleRef.current &&
                          projectsTitleRef.current.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                          });
                      }}
                      totalEntries={filteredProjects.length}
                      toPreviousPage={() => setPage((page) => page - 1)}
                      toNextPage={() => setPage((page) => page + 1)}
                      enableSizeChange={false}
                    />
                  </PaginationControlsWrapper>
                )}
              </GridContent>
            </GridWrapper>
          </GridAndMapWrapper>
        </MainContent>
      </PageWrapper>
    </>
  );
};

const projectCardTransitions = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 }
};

export default ProjectsPage;
