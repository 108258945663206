import { memo, useState, useEffect } from 'react';

// Components
import { default as SimpleCheckbox } from 'react-simple-checkbox';

// Helpers
import { isMobileOnly } from 'react-device-detect';

// Styling
import styled, { StyledProps, withTheme, css } from 'styled-components';

const Wrapper = styled.div<StyledProps<{ displayMode: any }>>`
  width: calc(100% + 32px);
  display: flex;
  flex-direction: column;
  margin: 0 -16px;

  ${({ displayMode }) => {
    if (displayMode === 'wizard') {
      return css`
        flex-direction: row;
        gap: 8px;
        justify-content: center;
      `;
    }
  }}
`;

const StyledSimpleCheckbox = styled(SimpleCheckbox)`
  width: 20px !important;
  height: 20px !important;
  position: inherit !important;
  border-radius: 2px;
  margin-right: 2px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin: 0;
  margin-right: 12px;

  &:focus {
    outline: none;
  }

  * {
    width: 20px;
    height: 20px;
  }
`;

const Option = styled.div<StyledProps<{ checked: boolean }>>`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0 16px;
  background-color: ${({ checked, theme }) => {
    if (isMobileOnly) {
      return checked ? theme.beigeBg20 : theme.beigeBg10;
    } else {
      return checked ? theme.gray5 : theme.white;
    }
  }};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) =>
      isMobileOnly ? theme.beigeBg10 : theme.gray5};
  }
`;

const WizardOption = styled.div<
  StyledProps<{ checked?: boolean; last?: boolean }>
>`
  position: relative;
  height: 40px;
  min-width: 40px;
  border-radius: 20px;
  background-color: ${({ checked, theme }) =>
    checked ? theme.beigeBg20 : theme.white};
  font-size 0.875rem;
  font-weight: ${({ checked }) => (checked ? 500 : 400)};
  color: ${({ checked, theme }) => (checked ? theme.black : theme.gray60)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ last, checked, theme }) => css`
    &::before {
      position: absolute;
      z-index: 2;
      content: '';
      width: 8px;
      height: 4px;
      top: 18px;
      left: -8px;
      background-color: ${checked ? 'transparent' : theme.beigeBg10};
    }

    ${!last &&
    css`
      &::after {
        position: absolute;
        z-index: 1;
        content: '';
        width: 8px;
        height: 4px;
        top: 18px;
        right: -8px;
        background-color: ${checked ? theme.beigeBg20 : 'transparent'};
      }
    `}
  `}
`;

const calculateMultiselectValues = (
  option: number,
  filterObject: any,
  displayMax: number
) => {
  let values;
  // If it is the first value, just add it
  if (filterObject.values.length === 0) {
    values = [option];
  } else {
    const optionShouldBeRemoved = filterObject.values.includes(option);

    if (optionShouldBeRemoved) {
      // If there are less smaller values then bigger ones, remove the former and vice versa
      const smallerValuesNumber = filterObject.values.filter(
        (value: number) => value < option
      ).length;
      const biggerValuesNumber = filterObject.values.filter(
        (value: number) => value > option && value <= displayMax
      ).length;

      if (biggerValuesNumber >= smallerValuesNumber) {
        values =
          option === displayMax
            ? []
            : filterObject.values.filter((value: number) => value > option);
      } else {
        values = filterObject.values.filter((value: number) => value < option);
      }
    } else {
      const smallestValue = Math.min(...filterObject.values);
      const biggestValue = Math.min(
        displayMax,
        Math.max(...filterObject.values)
      );

      // If selected value is less than the smallest selected value
      // add the selected value and all the values between newly selected one
      // and the smallest value out of previously selected
      if (option < smallestValue) {
        values = [
          ...filterObject.options.filter(
            (opt: number) => opt >= option && opt < smallestValue
          ),
          ...filterObject.values
        ];
        // Else if selected value is bigger than the biggest selected value
        // add the selected value and all the values between newly selected one
        // and the biggest value out of previously selected.
        // If selected value represents itself and all the bigger values, add them all as well
      } else if (option > biggestValue) {
        values = [
          ...filterObject.values,
          ...filterObject.options.filter((opt: number) =>
            option === displayMax
              ? opt > biggestValue
              : opt > biggestValue && opt <= option
          )
        ];
      }
    }
  }

  return values;
};

interface MultiSelectFilterProps {
  filter: any;
  displayMax?: number;
  displayMode?: 'single' | 'column' | 'wizard';
  theme: any;
  setFilter: any;
}

const MultiSelectFilter = ({
  filter,
  displayMax = 4,
  displayMode = 'single',
  theme,
  setFilter
}: MultiSelectFilterProps) => {
  const [checkedOptions, setCheckedOptions] = useState<number[]>([]);

  useEffect(() => {
    const checkedOptions = filter.options.filter((option: number) =>
      filter.values.includes(option)
    );
    setCheckedOptions(checkedOptions);
  }, [filter]);

  const onChangeEvent = (option: any) => {
    const values = calculateMultiselectValues(option, filter, displayMax);
    setFilter(values);
  };

  return (
    <Wrapper displayMode={displayMode}>
      {filter.options
        .slice(0, displayMax)
        .map((option: number, idx: number) => {
          const checked = checkedOptions.includes(option);

          let label = option.toString();
          let value = [option];

          return displayMode === 'wizard' ? (
            <WizardOption
              key={option}
              checked={checked}
              last={idx === displayMax - 1}
              onClick={() => onChangeEvent(option)}
            >
              {label}
            </WizardOption>
          ) : (
            <Option checked={checked} key={`${option}-${checked ? 1 : 0}`}>
              <StyledSimpleCheckbox
                id={`${filter.key}-${option}`}
                checked={checked}
                size={2}
                color={{
                  uncheckedBorderColor: theme.gray50
                }}
                onChange={() => {
                  onChangeEvent(option);
                }}
              />
              <label htmlFor={`${filter.key}-${option}`}>{label}</label>
            </Option>
          );
        })}
    </Wrapper>
  );
};
export default withTheme(MultiSelectFilter);
