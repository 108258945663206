import { useState, Fragment } from 'react';

// Helpers
import { motion } from 'framer-motion';
import env from 'src/environment';
import { IContentItem } from '@/libs/prompto-api/src';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { isMobileOnly } from 'react-device-detect';

// Styles
import styled from 'styled-components';

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  pointer-events: none;
`;

export interface ImageContentProps {
  data: IContentItem;
  pageIndex?: number;
  setIsDragEnabled?: any;
}

const ImageContent = ({
  data,
  pageIndex,
  setIsDragEnabled
}: ImageContentProps) => {
  const [hdLoaded, setHdLoaded] = useState(false);
  const [scale, setScale] = useState(1);

  // Transform
  const transform = 'q=80:h=1200';
  const placeHolderUri = `${env().baseImageUrl}/q=80:h=120/${data.contentUri}`;
  const imageUri = `${env().baseImageUrl}/${transform}/${data.contentUri}`;

  const onImageLoaded = () => {
    setHdLoaded(true);
  };

  return (
    <TransformWrapper
      options={{ maxScale: 10, centerContent: true }}
      onZoomChange={(data: any) => {
        if (isMobileOnly) return;
        setScale(data.scale);
        setIsDragEnabled(data.scale < 1.1 ? 'x' : false);
      }}
      pan={{
        lockAxisX: scale < 1.1 && !isMobileOnly
      }}
    >
      {/* @ts-ignore */}
      {() => (
        <TransformComponent>
          <ImageWrapper>
            {data.contentUri && (
              <Fragment>
                <StyledImage
                  alt={`low_res page ${pageIndex}`}
                  src={placeHolderUri}
                  draggable={false}
                />
                <motion.div
                  initial={'hidden'}
                  animate={hdLoaded ? 'visible' : 'hidden'}
                  variants={variants}
                >
                  <StyledImage
                    alt={`high_res page ${pageIndex}`}
                    src={imageUri}
                    onLoad={onImageLoaded}
                    draggable={false}
                  />
                </motion.div>
              </Fragment>
            )}
          </ImageWrapper>
        </TransformComponent>
      )}
    </TransformWrapper>
  );
};

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

export default ImageContent;
