import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

// Components
import LandingPage from 'src/pages/landingPage/LandingPage';
import Header from './components/Header';
import ProjectDetailPage from './pages/projectDetailPage/ProjectDetailPage';
import NotFound from './pages/NotFound';
import ProjectsPage from './pages/projectsPage/ProjectsPage';
import ProjectsMap from './pages/projectsPage/components/ProjectsMap';
import LeadOptOutPage from './leads/LeadOptOutPage';

// Helpers
import useManageMarketplaceSession from './hooks/use-manage-marketplace-session';
import { useAppState } from 'src/store/AppStore';
import { useAuthState } from 'src/store/AuthStore';
import {
  useProjectsFiltersState,
  BelgiumProvinces
} from 'src/store/ProjectsFiltersStore';
import { useProjectsState } from 'src/store/ProjectsStore';
import env from 'src/environment';
import Cookies from 'universal-cookie';
import { setLanguage } from 'src/localization/localizer';
import { useJsApiLoader } from '@react-google-maps/api';
import uniqid from 'uniqid';
import filtersConfig from 'src/configs/ProjectsFilters';
import { Marketplace } from '@prompto-api';
import { useDataState } from 'src/store/DataStore';
import { isMobile } from 'react-device-detect';
import { useProjectsMapState } from 'src/store/ProjectsMapStore';
import localizer from 'src/localization/localizer';
import { fetchSettingsFromURL } from './helpers/utils';
import { motion } from 'framer-motion';
import useSharedFilterParams from 'src/hooks/use-shared-filter-params';
import TempRedirectPage from './pages/TempRedirectPage';

// Styling
import { theme } from './theme';
import styled, {
  ThemeProvider,
  StyledProps,
  keyframes
} from 'styled-components';
import DevelopersPage from './pages/developersPage/DevelopersPage';
import Sitemap from './Sitemap';
import { isValidProjectsPageUrl } from './pages/projectsPage/ProjectsPageHelpers';
import LeadOptInPage from './leads/LeadOptInPage';
import RegistrationPage from './pages/registrationPage/RegistrationPage';
import LoginPage from './pages/loginPage/LoginPage';
import AccountPage from './pages/accountPage/AccountPage';
import useUserFavourites from './hooks/user-user-favourites';
import ProjectFormPage from './pages/projectFormPage/ProjectFormPage';

const StyledApp = styled.div<StyledProps<{ currentLanguage: string }>>`
  overflow: hidden;
  background-color: ${({ theme }) => theme.beigeBg10};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  min-width: 360px;

  a {
    text-decoration: none;
  }

  // Do not display a default blue border around the Google Map
  .gm-style iframe + div {
    border: none !important;
  }
`;

const MapWrapper = styled(motion.div)<StyledProps<{ expandMap: boolean, hideMenu?: boolean }>>`
  ${({ expandMap, theme, hideMenu }) => {
    if (!expandMap) return 'visibility: hidden';
    return isMobile
      ? ` position: fixed;
        z-index: ${expandMap ? 1000 : -1};
        left: 0;
        bottom: ${expandMap ? 0 : -60}px;
        width: 100vw;
        height: calc(100% - 144px);
        background: ${theme.white};
        padding: 16px;
        box-sizing: border-box;
        transition: all 180ms ease;
        border-radius: 20px 20px 0 0;
      `
      : `
        position: fixed;
        z-index: 1000;
        top: ${hideMenu ? '140px' : '212px'} ;
        min-height: 240px;
        right: 64px;
        width: calc(50dvw - 80px);
      `;
  }}
`;

const MapContent = styled.div<StyledProps<{ expandMap: boolean }>>`
  display: ${({ expandMap }) => (expandMap ? 'block' : 'none')};
  height: ${isMobile ? '100%' : 'calc(100vh - 234px)'};
  position: sticky;
  top: 152px;
`;

const glareAnimation = keyframes`
  0%, 100% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0%);
  }
`;

const MapPlaceholder = styled(motion.div)<StyledProps<{ expanded: boolean }>>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.beigeBg10};
  border-radius: 18px;
  overflow: hidden;
  transition: all 450ms ease;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 20%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.1) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(0%);
    animation: ${glareAnimation} 6s linear infinite;
  }
`;

const googleMapsLibraries = ['drawing', 'visualization', 'places'];

// The main app component, which is obvious right
export function App() {
  const [currentLanguage, setCurrentLanguage] = useState<string>('');
  const [cachedFiltersChecked, setCachedFiltersChecked] = useState(false);
  const [showSharedHeader, setShowSharedHeader] = useState(false);
  const [showSharedMap, setShowSharedMap] = useState(false);

  const { AppState, AppStateDispatch } = useAppState();
  const { marketplaceId } = AppState;

  const { DataState, DataStateDispatch } = useDataState();
  const { projectsPerProvince } = DataState;

  const { AuthState, AuthStateDispatch } = useAuthState();
  const { user } = AuthState;

  // Projects state
  const { ProjectsState } = useProjectsState();
  const { allProjects, allVaultLogos, projectsUnitCount } = ProjectsState;

  const { ProjectsFiltersDispatch } = useProjectsFiltersState();

  const { ProjectsMapState } = useProjectsMapState();
  const {
    expandMap,
    mapInstance,
    selectedProject,
    projectsVisibleInMap,
    projectsForMap,
    hoveredProjectId
  } = ProjectsMapState;

  const cookies = new Cookies();
  const queryParams = fetchSettingsFromURL();
  const { hideMenu } = queryParams;

  const { pathname } = useLocation();

  useEffect(() => {
    const user = cookies.get(`mp_user`);
    const sessionToken = cookies.get(`mp_sessionToken`);
    const vaultObjectId = cookies.get('mp_vaultObjectId');

    if (user && sessionToken && vaultObjectId) {
      AuthStateDispatch({
        type: 'login',
        payload: {
          user,
          sessionToken,
          vaultObjectId
        }
      });
    }
  }, []);

  // Show shared Header component in all pages except Projects page
  // We Show another instance of Header in there due to requirement to scroll behaviour
  useEffect(() => {
    const isProjectsPage = isValidProjectsPageUrl(pathname);
    const isRegisterPage = pathname.includes('register');
    const isLoginPage = pathname.includes('login');
    const isFormPage = pathname.includes('form');

    setShowSharedHeader(!isProjectsPage && !isRegisterPage && !isLoginPage && !isFormPage);
    setShowSharedMap(isProjectsPage);
  }, [pathname]);

  useManageMarketplaceSession(marketplaceId, user, pathname);

  useSharedFilterParams();

  useUserFavourites();

  useEffect(() => {
    if (!marketplaceId) {
      if (queryParams.marketplace) {
        AppStateDispatch({
          type: 'setMarketplaceId',
          payload: queryParams.marketplace
        });
      } else {
        AppStateDispatch({
          type: 'setMarketplaceId',
          payload: 'prompto'
        });
      }
    }
  }, [queryParams, marketplaceId]);

  // This useEffect hook is responsible for fetching the number of projects per province in Belgium.
  // When more countries are added, the useEffect might be moved to another comppnent
  useEffect(() => {
    if (!marketplaceId) return;
    if (projectsPerProvince) return;
    Marketplace.getProjectsPerProvince(marketplaceId, {
      country: 'Belgium',
      provinceList: BelgiumProvinces
    })
      .then((result) => {
        if (result.data?.projectCountByProvinceMap) {
          DataStateDispatch({
            type: 'setProjectsPerProvince',
            payload: result.data.projectCountByProvinceMap
          });
        } else {
          DataStateDispatch({
            type: 'setProjectsPerProvince',
            payload: {}
          });
        }
      })
      .catch(() => {
        DataStateDispatch({
          type: 'setProjectsPerProvince',
          payload: {}
        });
      });
  }, [projectsPerProvince, marketplaceId]);

  // Apply the language forced via url
  useEffect(() => {
    const { language } = fetchSettingsFromURL();
    if (language) {
      setLanguage(language as string);
    }
  }, []);

  // Load Google API
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: env().googleMapsApiKey,
    id: uniqid('loader-'),
    version: 'weekly',
    // if set to true it breaks the Emotion styles
    preventGoogleFontsLoading: false,
    libraries: googleMapsLibraries as (
      | 'drawing'
      | 'visualization'
      | 'places'
      | 'geometry'
      | 'localContext'
    )[]
  });

  useEffect(() => {
    if (isLoaded) {
      AppStateDispatch({ type: 'setGoogleApiLoaded', payload: true });
    }
  }, [isLoaded]);

  return (
    <ThemeProvider theme={theme}>
      <StyledApp currentLanguage={currentLanguage}>
        {showSharedHeader && !hideMenu && (
          <Header
            onLanguageSwitched={(language: string) => {
              setCurrentLanguage(language);
            }}
          />
        )}

        <Routes>
          <Route path="/interested" element={<LeadOptInPage />} />
          <Route path="/notinterested" element={<LeadOptOutPage />} />

          <Route path="/form/:code" element={<ProjectFormPage />} />

          <Route path="/:language?" element={<LandingPage />} />

          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/account" element={<AccountPage />} />

          {/* Project page */}
          <Route
            path="/vault/:vaultObjectId/project/:projectObjectId/*"
            element={<ProjectDetailPage />}
          />
          <Route path="/project/:code" element={<ProjectDetailPage />} />

          {/* Projects page */}
          <Route path="/projects/:language?" element={<ProjectsPage />} />
          <Route path="/nieuwbouw/:location?" element={<ProjectsPage />} />
          <Route path="/developer/:developer" element={<ProjectsPage />} />
          <Route path="/ontwikkelaar/:developer" element={<ProjectsPage />} />

          {/* Developers page */}
          <Route path="/developers" element={<DevelopersPage />} />

          <Route path="*" element={<NotFound />} />
        </Routes>

        <MapWrapper
          expandMap={showSharedMap && expandMap}
          initial={{ y: 50 }}
          animate={{ y: showSharedMap && expandMap ? 0 : 20 }}
          transition={{ ease: 'linear' }}
          hideMenu={hideMenu as boolean}
        >
          <MapContent expandMap={showSharedMap && expandMap}>
            <ProjectsMap
              expandMap={showSharedMap && expandMap}
              mapInstance={mapInstance}
              fullList={allProjects}
              toggleButtonTitle={localizer.projectsMap.viewOnMap}
              selectedProject={selectedProject}
              projectsForMap={projectsForMap}
              hoveredProjectId={hoveredProjectId}
              projectsVisibleInMap={projectsVisibleInMap}
              allVaultLogos={allVaultLogos}
              projectsUnitCount={projectsUnitCount}
            />
            <MapPlaceholder expanded={expandMap} />
          </MapContent>
        </MapWrapper>
      </StyledApp>
    </ThemeProvider>
  );
}

export default App;
