import { useReducer, createContext, useContext } from 'react';

export const AppStoreContext = createContext({
  marketplaceId: '',
  visitorId: '',
  sessionId: '',
  googleApiLoaded: false
});

const AppStore = (props) => {
  const [AppState, AppStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'setMarketplaceId':
          return {
            ...state,
            marketplaceId: action.payload
          };
        case 'setSessionData':
          return {
            ...state,
            ...action.payload
          };
        case 'setGoogleApiLoaded':
          return {
            ...state,
            googleApiLoaded: action.payload
          };
        default:
          return state;
      }
    },
    {
      marketplaceId: ''
    }
  );

  return (
    <AppStoreContext.Provider value={{ AppState, AppStateDispatch }}>
      {props.children}
    </AppStoreContext.Provider>
  );
};

export const useAppState = () => useContext(AppStoreContext);

export default AppStore;
