import { BelgiumProvinces } from 'src/store/ProjectsFiltersStore';

export const PROVINCES = BelgiumProvinces.map((province: string) => ({
  id: province
}));

// Province number offsets needed to position numbers closer to provinces' centers
const provinceNumberOffsets: any = {
  Hainaut: { x: 0.12, y: -0.07 },
  Liege: { x: 0, y: -0.07 },
  Namur: { x: 0, y: -0.07 },
  'Flemish Brabant': { x: 0, y: -0.03 },
  'Waloon Brabant': { x: 0, y: 0.02 }
};

export interface IPathTag {
  pathAsString: string;
  path: Path2D;
  id?: string;
}

export const convertSvgPath = (
  path: string
): [pathTag: IPathTag, province: { id?: string }] => {
  const startPath = path.indexOf('d=') + 3;
  const endPath = path.indexOf('"', startPath);
  const actualPath = path.substring(startPath, endPath);
  const p = new Path2D(actualPath);

  let id;
  const idAttributeStart = path.indexOf('id=');
  if (idAttributeStart > -1) {
    const startId = path.indexOf('id=') + 4;
    const endId = path.indexOf('"', startId);
    id = path.substring(startId, endId);
  }

  return [
    {
      pathAsString: actualPath,
      path: p,
      id
    },
    { id }
  ];
};

export interface IPolygonNumber {
  center: {
    y: number;
    x: number;
  };
  availableProjects: number;
  polygonId: string;
}

export const prepareSvgNumbers = (
  containerId: string,
  svgId: string
): IPolygonNumber[] => {
  const svgOverlay = document.querySelector(containerId);
  // @ts-ignore
  const svgOverlayCoords = svgOverlay.getBoundingClientRect();
  const svgElements = document.querySelectorAll(svgId);
  const polygonNumbers: any = [];
  svgElements.forEach((svg: any) => {
    const coords = svg.getBoundingClientRect();
    const center = {
      y: (coords.top + coords.bottom) / 2 - svgOverlayCoords.top,
      x: (coords.left + coords.right) / 2 - svgOverlayCoords.left
    };

    const offsets = provinceNumberOffsets[svg.dataset.polygonid];
    if (offsets) {
      center.y += center.y * offsets.y;
      center.x += center.x * offsets.x;
    }

    polygonNumbers.push({
      center,
      availableProjects: svg.dataset.polygonnumber,
      color: svg.dataset.polygoncolor,
      polygonId: svg.dataset.polygonid
    });
  });

  return polygonNumbers;
};

export const getOriginalSvgSize = (path: any) => {
  const startWidth = path.indexOf('width=') + 7;
  const endWidth = path.indexOf('"', startWidth);
  const width = path.substring(startWidth, endWidth);

  const startHeight = path.indexOf('height=') + 8;
  const endHeight = path.indexOf('"', startHeight);
  const height = path.substring(startHeight, endHeight);

  return { width, height };
};
