import { useEffect, useState } from "react";

// Helpers
import { fetchSettingsFromURL } from "src/helpers/utils";
import localizer, { setLanguage } from "src/localization/localizer";
import { useNavigate } from 'react-router-dom';
import env from "src/environment";

// Components
import Button from 'src/components/button/Button';

// Styling
import styled, {keyframes} from "styled-components"
import { forBiggerThan } from 'src/helpers/ui';
import { BoardTicket } from "@/libs/prompto-api/src";


const Wrapper = styled.div`
width: 100vw;
height: calc(100vh - 70px);
margin-top: 70px;
display: flex;
flex-flow: column;
align-items:center;
justify-content:center;
`

const Title = styled.div`
  font-size: 2rem;
  text-align:center;
`

const Email = styled.div`
font-size: 1.5rem;
margin-top: 15px;
font-style:italic;
`

const Description = styled.div`
font-size: 1rem;
margin-top: 50px;
`

const glareAnimation = keyframes`
  0%, 100% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0%);
  }
`;

const StyledButton = styled(Button)`
  height: 60px;
  min-width: 250px;
  grid-area: button;
  justify-self: center;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.gold};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  margin-top: 25px;

  ${forBiggerThan.laptop`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(0%);
    animation: ${glareAnimation} 10s linear infinite;
  }

  &:hover:before {
    animation: none;
  }
`}
`;

const LeadOptOutPage = () => {

  const queryParams = fetchSettingsFromURL();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>();

  useEffect(() => {

    const {boardTicketId} = queryParams;

    if(queryParams.language){
      setLanguage(queryParams.language as string)
    }
    
    if(queryParams.email){
      setEmail(queryParams.email as string)
    }

    // Handle board ticket
    if(boardTicketId){
      const moveParams: any = {
        ticketIdList: [boardTicketId],
        sourceColumnId: "uyVS4dOzPm",
        targetColumnId: "9vPLrT6PMo"
      }

      BoardTicket.move(moveParams, env().integrationToken)
    }
  }, [queryParams]);

return (
  <Wrapper>
  <Title>{localizer.optOutPage.title}</Title>
  <Email>{email}</Email>
  <Description>
    {localizer.optOutPage.description}
  </Description>
  <StyledButton
                  onClick={() => {

                      navigate(`/projects` + window.location.search);

                  }}
                >
                  {localizer.homePage.viewProjects}
                </StyledButton>
</Wrapper>
)
}


export default LeadOptOutPage;
