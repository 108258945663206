import React from 'react';

// Components
import {
  CloseButton,
  CloseIcon,
  IModalContent
} from 'src/components/getInTouchForm/GetInTouchFormModal';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Button from 'src/components/button/Button';
import { breakpoints } from 'src/helpers/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import styled, { StyledProps } from 'styled-components';

export const StatusIcon = styled(FontAwesomeIcon)<
  StyledProps<{ success: boolean }>
>`
  color: ${({ theme, success }) => (success ? theme.greenCTA : theme.redDark)};
  width: 80px;
  height: 80px;
  font-size: 60px;
`;

const ContentContainer = styled.div`
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 380px;
  margin: 0 auto;
`;

const Heading = styled.h3`
  padding: 0;
  margin: 24px 0;
  font-size: 1.5rem;
  text-align: center;
  font-weight: normal;
`;

const Paragraph = styled.p`
  padding: 0;
  margin: 16px 0;
  text-align: center;
  font-size: 1rem;
`;

const DoneButton = styled(Button)`
  font-size: 0.875rem;
  font-weight: bold;
  background: ${({ theme }) => theme.black};
  transition: all 0.3s;
  min-height: 48px;
  margin: 16px 0 32px;
  &:hover {
    opacity: 0.8;
  }
`;

const MailLink = styled.a`
  text-decoration: none;
  font-weight: 800;
  color: ${({ theme }) => theme.black};
`;

const StyledModal = styled.div`
  width: 90%;
  max-width: 480px;
  min-height: 400px;
  position: fixed;
  padding: 24px 32px 0 32px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background-color: white;
  z-index: 3100;
  max-height: calc(100vh - 100px);
  box-sizing: border-box;
`;

interface SubmitFormResultModalProps {
  onClose?(): void;
  modalContent: IModalContent;
}

const SubmitFormResultModal = ({
  onClose,
  modalContent
}: SubmitFormResultModalProps) => {
  const { buttonText, icon, header, paragraph1, paragraph2, success } =
    modalContent;
  return (
    <StyledModal>
      <CloseButton onClick={onClose ? onClose : () => null}>
        <CloseIcon icon={['fal', 'times']} size="sm" />
      </CloseButton>
      <ContentContainer>
        <StatusIcon
          success={success}
          icon={['fal', icon] as IconProp}
          size="sm"
        />
        <Heading>{header}</Heading>
        <Paragraph>{paragraph1}</Paragraph>
        <Paragraph>
          {paragraph2 ?? ''}
          {!success ? (
            <MailLink href="mailto:info@prompto.com" target="_blank">
              info@prompto.com
            </MailLink>
          ) : (
            ''
          )}
        </Paragraph>
        <DoneButton onClick={onClose ? onClose : () => null}>
          {buttonText}
        </DoneButton>
      </ContentContainer>
    </StyledModal>
  );
};

export default SubmitFormResultModal;
