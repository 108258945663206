import localizer from 'src/localization/localizer';
import numeral from 'numeral';
import { IUnit, GeneralFieldType } from '@prompto-api';
import defaultSortingOrder from '../configs/DefaultSortingOrder.js';

export const localizeUnitFieldValues = (unit: any) => {
  if (!unit) return;

  const language = localizer.getLanguage();
  const localizedUnit = { ...unit };

  const localizedTitle = localizedUnit.localizedTitle?.textMap?.[language];
  if (localizedTitle) localizedUnit.title = localizedTitle;

  const localizedDescription =
    localizedUnit.localizedDescription?.textMap?.[language];
  if (localizedDescription) localizedUnit.description = localizedDescription;

  const localizedReference =
    localizedUnit.unitMetadata?.localizedReference?.textMap?.[language];
  if (localizedReference) {
    localizedUnit.unitMetadata.reference = localizedReference;
  }

  const localizedExternalLink =
    localizedUnit.unitMetadata?.localizedExternalLink?.textMap?.[language];
  if (localizedExternalLink) {
    localizedUnit.unitMetadata.externalLink = localizedExternalLink;
  }

  return localizedUnit;
};

export const getUnitPrice = (unit: any) => {
  let unitPrice = `${numeral(unit?.unitPrice?.price).format()}`;

  if (unit?.unitFlow === 'rental') {
    unitPrice += localizer.projectTour.perMonth;
  }

  return unitPrice;
};

export const checkUnitFieldsVisibility = (
  unit?: IUnit,
  fieldsToCheck: GeneralFieldType[] = []
) => {
  if (unit) {
    return fieldsToCheck.map(
      (field: keyof typeof GeneralFieldType) =>
        unit?.unitType?.showGeneralFieldSettings?.[field] !== false
    );
  }

  return [];
};

export const sortBySortingOrders = (
  rawUnitContent: any[],
  customOrder: string[],
  defaultOrder = defaultSortingOrder
) => {
  if (!rawUnitContent || rawUnitContent.length === 0) return [];

  if (customOrder && customOrder.length > 0) {
    const sortedItems: any[] = [];
    customOrder.forEach((itemObjectId) => {
      const contentItem = rawUnitContent.find(
        (item) => item.objectId === itemObjectId
      );
      if (contentItem) {
        sortedItems.push(contentItem);
      }
    });
    return sortedItems;
  } else if (defaultOrder) {
    const sortedItemsObj = defaultOrder.reduce(
      (sorted: any, curr: string) => ({ ...sorted, [curr]: [] }),
      {}
    );
    rawUnitContent.forEach((item) => {
      let category;
      switch (item.contentItemType) {
        case 'image360':
          category = item.isLinked ? 'image360Linked' : 'image360';
          break;
        case 'image':
          category = item.isLinked ? 'imageLinked' : 'image';
          break;
        case 'video':
          category = item.isLinked ? 'videoLinked' : 'video';
          break;
        case 'document':
          category = item.isLinked ? 'documentLinked' : 'document';
          break;
        default:
          category = item.contentItemType;
      }
      if (sortedItemsObj[category]) {
        sortedItemsObj[category].push(item);
      }
    });
    const sortedItems = Object.values(sortedItemsObj).reduce(
      (res: any, curr: any) => res.concat(...curr),
      []
    );
    return sortedItems;
  } else {
    return rawUnitContent;
  }
};
