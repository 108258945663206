import urlJoin from 'url-join';
import env from 'src/environment';
import qs from 'query-string';
import axios from 'axios';

/**
 * Contruct the asset URI with the correct Bucket for the env
 * @param {string} res - the uri resource to load.
 * @returns {string|null} - the built string or null
 * if the base uri is undefined
 */
export const buildAssetURI = (res: string) =>
  res && urlJoin(env().baseImageUrl, res);

/**
 * Contruct the asset URI with the correct Bucket for the env
 * @param {string} options - documentation imageserver options: https://bitbucket.org/aroundmediagroup/virtual-media-image-server/src/master/
 * @param {string} res - the uri resource to load.
 * @returns {string|null} - the built string or null
 * if the base uri is undefined
 */
export const buildAssetURIWithOptions = (options: string, res: string) =>
  options &&
  res &&
  // NOTE: environment is set for producton since we use data from prod
  // to build marketplace UI
  urlJoin(env().baseImageUrl, options, res).split('?')[0];

/**
 * Add or edit params to the search string
 * @param {Array<Object>} arr - array of params to add
 */
export const addQueryParams = (arr: { [key: string]: string }[]) => {
  const parsed = qs.parse(window.location.search);

  arr.forEach((param: { [key: string]: string }) => {
    const key = Object.keys(param)[0];
    parsed[key] = param[key];
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${qs.stringify(parsed)}`
  );
};

export const sendLeadToMeta = async (email: string, phone: string) => {
  const encoder = new TextEncoder();

  const emailData = encoder.encode(email);
  const hashedEmail = await crypto.subtle.digest('SHA-256', emailData);

  const phoneData = encoder.encode(phone);
  const hashedPhone = await crypto.subtle.digest('SHA-256', phoneData);

  // convert ArrayBuffer to Array
  const hashArrayEmail = Array.from(new Uint8Array(hashedEmail));
  const hashArrayPhone = Array.from(new Uint8Array(hashedPhone));

  // convert bytes to hex string
  const hashHexEmail = hashArrayEmail
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  const hashHexPhone = hashArrayPhone
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

  axios.post(
    `https://graph.facebook.com/v20.0/1227714847864450/events?access_token=${
      env().facebookAccessToken
    }`,
    {
      data: [
        {
          action_source: 'website',
          event_name: 'Lead',
          event_time: Math.round(new Date().getTime() / 1000),
          user_data: {
            em: [hashHexEmail],
            ph: [hashHexPhone]
          }
        }
      ]
    },
    {}
  );
};
