import React, {Dispatch, SetStateAction} from 'react';

// Components
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Styling
import styled, {StyledProps} from 'styled-components';

export interface PhoneInputProps {
  value: string;
  onChange: Dispatch<SetStateAction<string>>;

  handleSubmit(): void;
  disabled?: boolean;
  text: string;
  currentLanguage?: string;
  placeholder: string;
  className?: string;
  wrapperProps?: any;
  width?: string;
  name: string;
  onBlur?: any;
}

const Label = styled.label<StyledProps<any>>`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.gray};
  font-size: 0.875em;
  position: relative;
  width: ${({width}) => width || '100%'};
`;

const StyledPhoneInput = styled.div<StyledProps<any>>`
  & .selected-flag {
    border-radius: 0;
  }

  & .flag-dropdown {
    transition: 0.3s;
    border: solid 1px ${({ theme }) => theme.gray300};
  }

  & .form-control {
    font-family: ${({ theme }) => theme.fonts.DMSans};
    border: solid 1px ${({ theme }) => theme.gray300};
    height: 45px;
    width: 100%;
    border-radius: 4px;
    transition: 0.3s;
  }
`;

export function PhoneInputField({
  className,
  disabled,
  onChange,
  placeholder,
  text,
  value,
  width,
  wrapperProps,
  currentLanguage,
  name,
  onBlur,
  handleSubmit,
  ...restProps
}: PhoneInputProps) {
  return (
    <Label {...wrapperProps} width={width}>
      {text}
      <StyledPhoneInput>
        <PhoneInput
          country={'be'}
          masks={{be: '... .. .. ...'}}
          placeholder={placeholder}
          disabled={disabled as boolean}
          value={value}
          onEnterKeyPress={(e) => {
            handleSubmit();
          }}
          onChange={(value) => {
            onChange(value)}
          }
          inputProps={{
            name: 'phoneNumber',
            required: true
          }}
          localization={{currentLanguage}}
          onBlur={onBlur}
          {...restProps}
        />
      </StyledPhoneInput>
    </Label>
  );
}

export default PhoneInputField;

PhoneInputField.defaultProps = {
  className: '',
  disabled: false,
  errors: {},
  info: '',
  onChange: () => undefined,
  handleSubmit: () => undefined,
  placeholder: '',
  success: '',
  text: '',
  value: '',
  width: '',
  wrapperProps: {}
};
