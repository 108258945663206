// Helpers
import { isMobileOnly } from 'react-device-detect';
import { forBiggerThan } from 'src/helpers/ui';

// Styling
import styled, { StyledProps } from 'styled-components';

const MainWrapper = styled.div<StyledProps<{ columnAmount: number }>>`
  column-count: ${({ columnAmount }) => columnAmount};
  column-fill: auto;
  max-height: 192px;
  width: auto;
  max-width: 880px;
  box-sizing: border-box;
  border: none;

  ${forBiggerThan.tablet`
  padding: 32px;
  padding-bottom: 16px;
  border-radius: 16px;
  border: solid 1px ${({ theme }: any) => theme.gray20};
    `}
`;

const LineItem = styled.div`
  display: flex;
  font-size: 1rem;
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
`;

const LineIcon = styled.div`
  margin-right: 12px;
  color: ${({ theme }) => theme.gray50};
`;

export interface LineItemProps {
  icon: JSX.Element;
  text: string;
}

export interface AdditionalInformationBlockProps {
  items: Array<LineItemProps>;
  className?: string;
  dataTestId?: string;
}

const AdditionalInformationBlock = ({
  items,
  className,
  dataTestId = 'addInfoBlock-list_item'
}: AdditionalInformationBlockProps) => {
  const maxItems = 12;
  const itemsPerColumn = 4;
  const limitedItems = items.slice(0, maxItems);

  const columnAmount = Math.ceil(limitedItems.length / itemsPerColumn);
  return (
    <MainWrapper columnAmount={isMobileOnly ? 1 : columnAmount} className={className}>
      {limitedItems.map((item: any, index: number) => {
        return (
          <LineItem data-testid={dataTestId} key={index}>
            <LineIcon>{item.icon}</LineIcon>
            {item.text}
          </LineItem>
        );
      })}
    </MainWrapper>
  );
};

export default AdditionalInformationBlock;
