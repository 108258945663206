import {
  MultiLanguageTextField,
  performPostCall,
  performGetCall,
  performPutCall
} from '../api-helpers';

export const enum NavigationItemType {
  explore = 'explore',
  navigationSpot = 'navigationSpot',
  infoSpot = 'infoSpot',
  mediaSpot = 'mediaSpot',
  polygonSpot = 'polygonSpot',
  mediaView = 'mediaView',
  mapView = 'mapView',
  unitSpot = 'unitSpot',
  line = 'line',
  uspSpot = 'uspSpot',
  turntableSpot = 'turntableSpot',
  album360Spot = 'album360Spot'
}

export const enum Position {
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT
}

export const enum LabelSize {
  small,
  medium,
  large,
  original
}

export interface Location {
  getxCoordinate?: string;
  getyCoordinate?: string;
}

export interface NavigationItemPinConfiguration {
  pinned?: boolean;
  position?: Position;
}

interface CustomLabelConfiguration {
  useCustomLabel?: boolean;
  customLabelObjectId?: string;
  customLabelSize?: LabelSize;
}

export interface NavigationItemCreateParams {
  navigationItemType: NavigationItemType;
  entityObjectId?: string;
  vaultObjectId?: string;
  projectObjectId?: string;
  title?: MultiLanguageTextField;
  description?: MultiLanguageTextField;
  thumbnailUri?: string;
  contentUri?: string;
  value?: string;
  nightValue?: string;
  getxCoordinate?: string;
  getyCoordinate?: string;
  location?: Location;
  offsetLocation?: Location;
  zoomLevel?: string;
  iconId?: string;
  rotationAngle?: string;
  color?: string;
  useUnitColor?: boolean;
  assignedNavigationItemObjectId?: string;
  pinConfiguration?: NavigationItemPinConfiguration;
  customLabelConfiguration?: CustomLabelConfiguration;
  size?: number;
  spotSize?: number;
  cameraStartPosition?: Location;
  borderRadius?: string;
  navigationObjectValue?: {
    navigationItemType: NavigationItemType;
  };
}

export interface INavigationItem {
  color?: string;
  description?: MultiLanguageTextField;
  location?: Location;
  nightValue?: string;
  xCoordinate?: string;
  yCoordinate?: string;
  iconId?: string;
  rotationAngle?: string;
  offsetLocation?: Location;
  zoomLevel?: string;
  customLabelConfiguration?: any;
  navigationObjectValue?: {
    navigationItemType: NavigationItemType;
  };
  pinConfiguration?: NavigationItemPinConfiguration;
  assignedNavigationItemObjectId?: string;
  spotSize?: number;
  cameraStartPosition?: Location;
  borderRadius?: string;
  useUnitColor?: boolean;
  title?: MultiLanguageTextField;
  navigationCollection?: any;
  thumbnailUri?: string;
  navigationItemType: NavigationItemType;
  contentUri?: string;
  value?: string;
  size?: number;
  objectId: string;
  loaded?: boolean;
}

export const NavigationItem = {
  /**
   * Create a navigation item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_20
   * @param {Object} createParams
   * @param {string} sessionToken
   */
  create: (createParams: NavigationItemCreateParams, sessionToken: string) => {
    return performPostCall('navigationitems', createParams, sessionToken);
  },

  /**
   * Update a navigation item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_22
   */
  update: (
    navigationItemObjectId: string,
    updateParams: NavigationItemCreateParams,
    sessionToken: string
  ) => {
    return performPutCall(
      `navigationitems/${navigationItemObjectId}`,
      updateParams,
      sessionToken
    );
  },

  /**
   * Get the navigationitem
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_23
   * @param {string} navigationItemObjectId
   */
  get: (navigationItemObjectId: string, sessionToken?: string) => {
    return performGetCall(
      `navigationitems/${navigationItemObjectId}`,
      {},
      sessionToken
    );
  },

  /**
   * Get by showcase code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbyshowcasecode
   */
  getByShowcaseCode: (code: string) =>
    performGetCall(`navigationitems/getByShowcaseCode/${code}`, {}),

  /**
   * Get by share item code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbycode_3
   */
  getByShareItemCode: (code: string) =>
    performGetCall(`navigationitems/getByCode/${code}`, {})
};
