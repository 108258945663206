import { memo, useRef, useState, useEffect } from 'react';

// Components
import Polygon from './Polygon';

// Helpers
import { stringToPolygonCoords } from './PolygonHelper';
import { getColorForUnitState } from 'src/components/unitList/unitListHelpers';
import { IUnit } from '@/libs/prompto-api/src';

// Styling
import { withTheme } from 'styled-components';

interface PolygonSpotProps {
  spot: any;
  imageSize: any;
  zoomScale: any;
  onHoverPolygon: any;
  isMobile: boolean;
  onSpotClicked: () => void;
  isPolygonTooltipHovered: boolean;
  theme: any;
  unitList: IUnit[];
  isIdle: boolean;
  highlighted?: boolean;
  isUnitTour?: boolean;
}

const PolygonSpot = memo(
  ({
    spot,
    imageSize,
    zoomScale,
    onHoverPolygon,
    isMobile,
    onSpotClicked,
    isPolygonTooltipHovered,
    theme,
    unitList,
    isIdle,
    highlighted,
    isUnitTour
  }: PolygonSpotProps) => {
    const coords = stringToPolygonCoords(spot?.value);
    const polygonShape = useRef<HTMLInputElement>(null);
    const [spotFill, setSpotFill] = useState('transparent');

    useEffect(() => {
      if (!spot?.assignedNavigationItemObjectId) {
        setSpotFill(spot?.color);
        return;
      }

      if (spot.assignedItem?.navigationItemType === 'unitSpot') {
        const assignedUnit = unitList?.find(
          (unit: IUnit) => unit.objectId === spot.assignedItem?.value
        );
        if (assignedUnit) {
          if (spot.useUnitColor) {
            const assignedUnitState = assignedUnit.state;
            if (assignedUnitState) {
              const unitColor = getColorForUnitState(theme, assignedUnitState);
              setSpotFill(unitColor);
            }
          } else {
            setSpotFill(spot.color);
          }
        } else {
          setSpotFill(spot.color);
        }
      } else {
        setSpotFill(spot.color);
      }
    }, [spot, theme, unitList]);

    const getIdealTooltipLocation = () => {
      // Some random logic to provide a decent location of the tooltip on the polygon spots
      // We take the average of the x position and the lowest y position
      let x = 0;
      let lowestX = 500000;
      let highestX = -500000;
      let lowestY = 500000;
      let highestY = -500000;
      coords.forEach((coord: any) => {
        x += coord.x;

        if (coord.y < lowestY) {
          lowestY = coord.y;
        }
        if (coord.y > highestY) {
          highestY = coord.y;
        }

        if (coord.x < lowestX) {
          lowestX = coord.x;
        }
        if (coord.x > highestX) {
          highestX = coord.x;
        }
      });
      x /= coords.length;

      return { x, yL: lowestY, yH: highestY, xL: lowestX, xH: highestX };
    };

    return (
      <Polygon
        ref={polygonShape}
        key={spot?.objectId}
        coords={coords}
        fill={spotFill}
        imageSize={imageSize}
        zoomScale={zoomScale}
        objectId={spot?.objectId}
        onMouseEnter={() => {
          if (isUnitTour) return;
          if (!isMobile) {
            const { x, yL, yH, xL, xH } = getIdealTooltipLocation();
            onHoverPolygon({
              spot: spot,
              pos: { x: x, yL, yH, xL, xH },
              size: {
                width: polygonShape.current?.getBoundingClientRect().width,
                height: polygonShape.current?.getBoundingClientRect().height
              }
            });
          }
        }}
        onMouseLeave={() => {
          if (isUnitTour) return;
          if (!isMobile) {
            onHoverPolygon({ spot: spot, pos: null });
          }
        }}
        onMouseDown={(e: any) => {
          if (isUnitTour) return;
          if (!isMobile) {
            onSpotClicked();
          }
        }}
        onTouchEnd={(e: any) => {
          if (isUnitTour) return;
          e.preventDefault();
          e.stopPropagation();
          onSpotClicked();
        }}
        isHovered={isPolygonTooltipHovered}
        isIdle={isIdle}
        highlighted={!!highlighted}
        defaultFillOpacity={isUnitTour ? 0 : 0.5}
        isUnitTour={isUnitTour}
      />
    );
  }
);

export default withTheme(PolygonSpot);
