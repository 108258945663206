import { useState, useRef, useEffect, useCallback } from 'react';

// Helpers
import localizer from 'src/localization/localizer';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import styled, { StyledProps } from 'styled-components';
import { motion } from 'framer-motion';
import { forBiggerThan } from 'src/helpers/ui';

const Wrapper = styled.div<StyledProps<{ withPagination: boolean }>>`
  height: 40px;
  min-width: 140px;
  width: fit-content;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.gray20};
  border-radius: 4px;
  background: ${({ theme }) => theme.beigeBg10};
  position: relative;
  flex-grow: 1;

  ${forBiggerThan.tablet`
    min-width: 200px;
  `}
`;

const ActiveSortOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SortBy = styled.span`
  font-size: 0.875rem;
  margin: 0 5px 0 0;
  color: ${(props) => props.theme.primary100};
`;

const CurrentFieldToSort = styled.div`
  font-size: 0.875rem;
  font-weight: normal;
  color: ${(props) => props.theme.showcaseBlack};
  margin-right: 5px;
  flex-grow: 1;
`;

const DropDownIcon = styled(FontAwesomeIcon)<StyledProps<{ show: any }>>`
  position: relative;
  top: -3px;
  cursor: pointer;
  color: ${(props) => props.theme.primary100};
  font-size: 14px;
  transition: all 150ms ease;
  transform: scaleY(${({ show }) => (show ? '-1' : '1')})
    translateY(${({ show }) => (show ? '9px' : '0')});
  transform-origin: center bottom;
`;

const CheckedItemIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: ${(props) => props.theme.primary300};
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(9px, 60%);
`;

const DropDown = styled(motion.div)`
  position: absolute;
  z-index: 15;
  background: ${({ theme }) => theme.beigeBg10};
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.gray20};
  border-radius: 0 0 4px 4px;
`;

const ItemsList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`;

const SubList = styled(ItemsList)``;

const SortingItem = styled.li<
  StyledProps<{ selected?: boolean; chosen?: boolean; height?: string }>
>`
  position: relative;
  font-size: 0.875rem;
  color: ${({ theme, selected }) => (selected ? theme.black : theme.gray50)};
  width: 100%;
  height: ${(props) => props.height};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px 5px 5px 30px;
  box-sizing: border-box;
  border-top: ${({ chosen, theme }) =>
    chosen ? `1px solid ${theme.gray200}` : 'none'};
  border-bottom: ${({ chosen, theme }) =>
    chosen ? `1px solid ${theme.gray200}` : 'none'};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.beigeBg20};
  }
`;

const SubItem = styled(SortingItem)`
  &:hover {
    background: none;
  }
`;

const getSortingOptionLabel = (field: string) => {
  //@ts-ignore
  return localizer.unitList.sortingOptions[field];
};

interface SortUnitsProps {
  fields: any[];
  currentFieldToSort: string;
  onSortByChanged: (field: string, isAsc: boolean) => void;
  isSortAscending: boolean;
  withPagination: boolean;
  showBathroomsInDetails: boolean;
  showBedroomsInDetails: boolean;
}

const SortUnits = ({
  fields,
  currentFieldToSort,
  onSortByChanged,
  isSortAscending,
  withPagination,
  showBathroomsInDetails,
  showBedroomsInDetails
}: SortUnitsProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [chosenFieldToSort, setChosenFieldToSort] =
    useState(currentFieldToSort);

  const sortingOptionsRef = useRef<HTMLDivElement>(null);

  const checkOutsideClick = useCallback(
    (event: any) => {
      if (!sortingOptionsRef.current?.contains(event.target)) {
        setShowOptions(false);
      }
    },
    [sortingOptionsRef]
  );

  const shouldRenderField = (field: string) => {
    switch (field) {
      case 'numberOfBedrooms':
        if (!showBedroomsInDetails) {
          return false;
        }
      case 'numberOfBathrooms':
        if (!showBathroomsInDetails) {
          return false;
        }
      default:
        return true;
    }
  };

  useEffect(() => {
    window.addEventListener('click', checkOutsideClick);
    return () => window.removeEventListener('click', checkOutsideClick);
  }, [checkOutsideClick]);

  return (
    <Wrapper ref={sortingOptionsRef} withPagination={withPagination}>
      <ActiveSortOptionWrapper onClick={() => setShowOptions((prev) => !prev)}>
        <SortBy>{localizer.unitListComponent.sortBy}</SortBy>
        <CurrentFieldToSort>
          {getSortingOptionLabel(currentFieldToSort)}
        </CurrentFieldToSort>
        <DropDownIcon show={showOptions ? 1 : 0} icon={['fas', 'sort-down']} />
      </ActiveSortOptionWrapper>

      {showOptions && (
        <DropDown
          initial={{ scaleY: 0, transformOrigin: 'top', opacity: 0.6 }}
          animate={{ scaleY: 1, opacity: 1 }}
          exit={{ scaleY: 0, opacity: 0.4 }}
        >
          <ItemsList>
            {fields.filter(shouldRenderField).map((field: any) => (
              <SortingItem
                key={field}
                selected={field === chosenFieldToSort}
                onClick={(e) => {
                  e.stopPropagation();
                  setChosenFieldToSort(field);
                }}
                height={field === chosenFieldToSort ? 'auto' : '30px'}
                chosen={field === chosenFieldToSort}
              >
                {field === chosenFieldToSort && (
                  <CheckedItemIcon icon={['far', 'check']} size="1x" />
                )}
                {getSortingOptionLabel(field)}
                {field === chosenFieldToSort && (
                  <SubList>
                    {[
                      {
                        label: localizer.unitListComponent.ascending,
                        isAsc: true
                      },
                      {
                        label: localizer.unitListComponent.descending,
                        isAsc: false
                      }
                    ].map((opt) => (
                      <SubItem
                        key={opt.label}
                        selected={
                          field === currentFieldToSort &&
                          isSortAscending === opt.isAsc
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          onSortByChanged(field, opt.isAsc);
                          setShowOptions(false);
                        }}
                        height={'25px'}
                      >
                        {field === currentFieldToSort &&
                          isSortAscending === opt.isAsc && (
                            <CheckedItemIcon
                              icon={['far', 'check']}
                              size="1x"
                            />
                          )}
                        {opt.label}
                      </SubItem>
                    ))}
                  </SubList>
                )}
              </SortingItem>
            ))}
          </ItemsList>
        </DropDown>
      )}
    </Wrapper>
  );
};

export default SortUnits;
