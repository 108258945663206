import {
  performGetCall,
  performPostCall,
  performPutCall
} from '../api-helpers';

export interface RenewSessionQueryParams {
  includeVaultStatus?: boolean;
  invalidateCurrentToken?: boolean;
  loadVaultDictionarySets?: boolean;
}

export interface UserPasswordUpdateParams {
  password?: string;
  newPassword?: string;
}

export interface UserRenewSessionParams {
  version?: string;
}

export interface UserUpdateParams {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  language?: string;
  username?: string;
  metadata?: any;
}

export interface LoginOAuthParams {
  firebaseToken: string;
  entityId: string;
  promptoSessionType: string;
}

export const User = {
  // User login
  login: (queryParams: any, body: any) => {
    let url = 'users/login';
    if (queryParams) {
      url = url += '?';
      const qs = Object.entries(queryParams).reduce((acc, [param, value]) => {
        //@ts-ignore
        acc.push(`${param}=${value}`);
        return acc;
      }, []);
      url += qs.join('&');
    }
    return performPostCall(url, body);
  },

  // User login OAuth
  loginOAuth: (body: LoginOAuthParams) =>
    performPostCall(`users/loginoauth`, body),

  /**
   * Logout user, Will invalidate the token.
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_logout
   */
  logout: (sessionToken: string) => {
    return performPostCall(`users/logout`, {}, sessionToken);
  },

  /**
   * User renew session token
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_renewsession
   * @param {object} queryParams
   * @param {object} body
   * @param {string} sessionToken
   */
  renewSessionToken: (
    queryParams: RenewSessionQueryParams,
    body: UserRenewSessionParams,
    sessionToken: string
  ) => {
    let url = 'users/renewsession';
    if (queryParams) {
      url = url += '?';
      const qs = Object.entries(queryParams).reduce((acc, [param, value]) => {
        // @ts-ignore
        acc.push(`${param}=${value}`);
        return acc;
      }, []);
      url += qs.join('&');
    }
    return performPostCall(url, body, sessionToken);
  },

  /**
   * Get user info
   * @param {string} sessionToken
   */
  getInfo: (sessionToken: string, params = {}) => {
    return performGetCall(`/users/info`, params, sessionToken);
  },

  /**
   * Update user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_47
   */
  update: (userId: string, params: UserUpdateParams, sessionToken: string) => {
    return performPutCall(`users/${userId}`, params, sessionToken);
  },

  /**
   * Update user password
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatepassword
   */
  updatePassword: (
    params: UserPasswordUpdateParams,
    userId: string,
    sessionToken: string
  ) => {
    return performPutCall(`users/${userId}/password`, params, sessionToken);
  },

  /**
   * Request to reset a password
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_requestresetpassword
   */
  requestResetPassword: (params: any) => {
    return performPostCall(`users/requestresetpassword`, params);
  },

  /**
   * reset a password
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_resetpassword
   */
  resetPassword: (params: any) => {
    return performPostCall(`users/resetpassword`, params);
  },

  /**
   *  Check the invitation of a user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_checkinvitation
   */
  checkInvitation: (params: any) => {
    return performPostCall(`users/checkinvitation`, params);
  },

  /**
   *  Check if a user exists
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_check
   */
  checkIfExists: (params: any) => {
    return performPostCall(`users/check`, params);
  },

  /**
   * create a new user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_50
   */
  create: (params: any) => {
    return performPostCall(`users`, params);
  },

  /**
   * Accept an invitation
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_acceptinvitation
   */
  acceptInvitation: (
    objectId: string,
    invitationUuid: string,
    sessionToken: string
  ) => {
    return performPostCall(
      `users/${objectId}/invitation/${invitationUuid}`,
      {},
      sessionToken
    );
  },

  /**
   * Set the default vault of a user
   * It sets the uer default vault. The first vault in the user vault list is the default vault. This endpoint changes the first vault in the user vault list
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_setdefaultvault
   */
  setDefaultVault: (
    userObjectId: string,
    params: any,
    sessionToken: string
  ) => {
    return performPutCall(
      `users/${userObjectId}/setdefaultvault`,
      params,
      sessionToken
    );
  }
};
