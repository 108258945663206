import { useState, useEffect, useCallback } from 'react';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged
} from 'firebase/auth';
import env from 'src/environment';

const prepareUser = (authUser: any) => {
  if (!authUser?.providerData) return null;

  const user = authUser.providerData.find(
    (data: any) => data.email === authUser.email
  );

  return user
    ? {
        ...user,
        accessToken: authUser.accessToken
      }
    : null;
};

const useSingleSignOn = () => {
  const [currentUser, setCurrentUser] = useState<any>(null);

  const {
    firebaseApiKey,
    firebaseAuthDomain,
    firebaseProjectId,
    firebaseStorageBucket,
    firebaseMessagingSenderId,
    firebaseAppId,
    firebaseMeasurementId
  } = env();

  // Firebase related
  const firebaseConfig: any = {
    apiKey: firebaseApiKey,
    authDomain: firebaseAuthDomain,
    projectId: firebaseProjectId,
    storageBucket: firebaseStorageBucket,
    messagingSenderId: firebaseMessagingSenderId,
    appId: firebaseAppId
  };

  if (firebaseMeasurementId) {
    firebaseConfig.measurementId = firebaseMeasurementId;
  }

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  // const { currentUser } = auth;

  const provider = new GoogleAuthProvider();
  const microsoftAuthProvider = new OAuthProvider('microsoft.com');

  // Check if user already exists
  // useEffect(() => {
  //   console.log(auth);
  //   onAuthStateChanged(auth, (user) => setCurrentUser(prepareUser(user)));
  // }, [auth]);

  const authProviders: any = {
    'google.com': provider,
    'microsoft.com': microsoftAuthProvider
  };

  const signInWithAltAuthProvider = useCallback(
    (providerId: string, onSuccess: any, onError: any) => {
      if (!providerId) return onError();

      if (
        currentUser &&
        currentUser.accessToken &&
        currentUser.providerId === providerId
      ) {
        return onSuccess(currentUser.accessToken);
      } else {
        return signInWithPopup(auth, authProviders[providerId])
          .then((result) => {
            const user = prepareUser(result.user);
            setCurrentUser(user);
            if (user?.accessToken) {
              onSuccess(user.accessToken);
            } else {
              onError();
            }
          })
          .catch(() => onError());
      }
    },
    [currentUser, auth, authProviders]
  );

  return {
    currentUser,
    signInWithAltAuthProvider
  };
};

export default useSingleSignOn;
