import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PromptoLogoIcon from 'src/resources/images/prompto-logo-icon.png';
import HomePageImageForSafari from 'src/resources/images/home_page_safari.png';
import EasterFinal from 'src/resources/images/EASTER_FINAL.jpg';
import MainBackground from 'src/resources/images/new-background.png';

// Components
import Button from 'src/components/button/Button';
import LocationInput from 'src/components/locationInput/LocationInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import localizer from 'src/localization/localizer';
import ReactResizeDetector from 'react-resize-detector';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';
import { isMobileOnly, isSafari } from 'react-device-detect';

// Styling
import styled, { StyledProps, keyframes, css } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';
import { getPackageVersion } from 'src/helpers/utils';

const Wrapper = styled.div`
  height: 70vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  ${forBiggerThan.mobile`
   height: 65vh;
`}
`;

const Title = styled.div`
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  margin-bottom: 15px;

  ${forBiggerThan.tablet`
    font-size: 3rem;
`}
`;

const Description = styled(Title)`
  font-size: 1rem;
font-weight: normal;
  ${forBiggerThan.tablet`
  font-size: 2em;
`}
`;

const OuterWrapper = styled(motion.div)``;

const AnimatedBackground = styled(motion.div)<StyledProps<{ bg?: string }>>`
  width: 100%;
  position: relative;
`;

const Content = styled(motion.div)`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  maring:auto;
  margin-top: 80px;
  justify-content: center;
  box-sizing: border-box;
  z-index: 5;

  
  ${forBiggerThan.mobile`
    width: 100%;
   margin-left: 168px;
   margin-top: 148px;
  `}
`;

const ImageSlider = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 60vh;
  //border-radius: 18px;
  overflow: hidden;

  ${forBiggerThan.mobile`
    top: 100px;
    left: 64px;
    height: 55%;
    width: calc(100% - 128px);
  `}
`;

const Motto = styled.div`
  display: block;
  position: relative;
  line-height: 1.15;
  font-size: 1.5rem;
  text-align: center;
  width: fit-content;
  margin: 0 0 ${({ theme }) => theme.spacing.xxl};

  padding: 3px 10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);

  ${forBiggerThan.tablet`
    font-size: 3rem;
    padding: 5px 25px;
`}
`;

const MottoFirstLine = styled(Motto)`
  margin-bottom: 10px;
  ${forBiggerThan.mobile`
    font-size: 2.5rem;
  `}
  ${forBiggerThan.desktop`
    font-size: 5rem;
    margin-bottom: 15px;
  `}
`;

const pixelSize = 40;
const Pixel = styled.div`
  position: absolute;
  left: -${pixelSize / 2.0}px;
  top: -${pixelSize / 2.0}px;
  width: ${pixelSize / 2.0}px;
  height: ${pixelSize / 2.0}px;
  background: black;

  ${forBiggerThan.tablet`
  left: -40px;
  top: -40px;
  width: 40px;
  height: 40px;
`}
`;

const pixelSizeSmall = 20;
const PixelSmall = styled.div`
  position: absolute;
  right: -${pixelSizeSmall / 2.0}px;
  bottom: -${pixelSizeSmall / 2.0}px;
  width: ${pixelSizeSmall / 2.0}px;
  height: ${pixelSizeSmall / 2.0}px;
  background: white;

  ${forBiggerThan.tablet`
  right: -20px;
  bottom: -20px;
  width: 20px;
  height: 20px;
`}
`;

const SearchBlock = styled.div`
  position: relative;
  z-index: 4;
  display: grid;
  grid-gap: 32px;
  grid-template-areas: 'input' 'button';
  width: 100%;
  
  grid-template-columns: 1fr;
   ${forBiggerThan.mobile`
    margin-top: 32px;
  `}
  ${forBiggerThan.laptop`
    grid-template-areas: 'input button';
    grid-template-columns: 1fr auto;
  `}
  ${forBiggerThan.desktop`
    width: 850px;
  `}
`;

const Accent = styled(motion.div)`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-style: italic;
  font-weight: bold;
  display: inline-block;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

const CustomCursorLayer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  cursor: pointer;
`;

const ScrollToAnchor = styled.div`
  position: relative;
  top: 50px;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: 0% 25%;
  object-fit: cover;
`;

const glareAnimation = keyframes`
  0%, 100% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0%);
  }
`;

const StyledButton = styled(Button)`
  height: 60px;
  min-width: 250px;
  grid-area: button;
  justify-self: center;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.yellowGold};
  font-family: ${({ theme }) => theme.fonts.DMSans};

  ${forBiggerThan.laptop`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(0%);
    animation: ${glareAnimation} 10s linear infinite;
  }

  &:hover:before {
    animation: none;
  }
`}
`;

const arrowDown = keyframes`
  0% {
    opacity:1;
    transform:translateY(0)
  }
  40% {
    transform:translateY(20px)
  }
  70% {
    opacity:1
  }
  90% {
    opacity:0
  }
  100% {
    opacity:0;
    transform:translateY(20px)
  }
`;

const ArrowContainer = styled.div`
  animation: ${arrowDown} 2s infinite;
  bottom: 2rem;
  color: #f7f4ec;
  height: 2.5rem;
  left: 0;
  margin: 0 auto;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: 2.5rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainBlock = () => {
  const [imageExpanded, setImageExpanded] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [showAccent, setShowAccent] = useState(false);

  const [locationValue, setLocationValue] = useState('');
  const [suggestedLocations, setSuggestedLocations] = useState<any[]>([]);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const isNarrowView = screenSize.width < 481;

  const { ProjectsFiltersDispatch } = useProjectsFiltersState();

  const navigate = useNavigate();

  getPackageVersion();

  useEffect(() => {
    // imageExpanded changes after image loaded right after page load
    // at this point of time height of page also changes. that reason scroll to top one more time
    window.scrollBy({
      top: -10000,
      behavior: 'auto' as ScrollBehavior
    });
  }, [imageExpanded]);

  useEffect(() => {
    if (!showAccent) {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setShowAccent(true);
      }, 200);
    }
  }, [showAccent]);

  const onSelectLocation = (location: any) => {
    if (location?.geometry?.location) {
      const loc = location.geometry.location;
      const latitude = loc.lat();
      const longitude = loc.lng();

      const place = location.formatted_address;

      ProjectsFiltersDispatch({
        type: 'setTargetLocation',
        payload: {
          place,
          coords: { lat: latitude, lng: longitude },
          searchByProvince: false
        }
      });
    }

    navigate(`/projects` + window.location.search);
  };

  const isMobileSafari = isMobileOnly && isSafari;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Wrapper>
      <ImageSlider>
        {MainBackground && <Image src={MainBackground} />}
      </ImageSlider>
      <Content
        initial={{ opacity: 0, y: -50 }}
        animate={{
          opacity: showContent ? 1 : 0,
          y: showContent ? 0 : -50
        }}
        onAnimationComplete={() => setShowAccent(true)}
        transition={{ duration: 0.4 }}
      >
        <Title>
          {localizer.homePage.motto}
        </Title>
        <Description>{localizer.homePage.motto2}</Description>

        <SearchBlock>
          <LocationInput
            value={locationValue}
            fixedDropdown={true}
            onClose={null}
            onSelect={onSelectLocation}
            onUpdateLocation={setSuggestedLocations}
            styles={{
              input: `
                      padding: 18px 18px 18px 40px;
                      border-radius: 8px;
                      backdrop-filter: blur(5px);
                      border: solid 1px rgba(255, 255, 255, 0.2);
                      background-color: rgba(0, 0, 0, 0.3);
                      color: white;
                      &::placeholder {
                        color: white;
                      }
                    `,
              iconSize: 'lg',
              dropdown: `
                      backdrop-filter: blur(10px);
                      border: solid 1px rgba(255, 255, 255, 0.2);
                      background-color: rgba(255, 255, 255, 0.5);
                    `,
              searchIcon: 'color: white'
            }}
          />

          <StyledButton
            doNotScaleOnHover={true}
            onClick={() => {
              if (suggestedLocations[0]) {
                onSelectLocation(suggestedLocations[0]);
              } else {
                ProjectsFiltersDispatch({
                  type: 'setTargetLocation'
                });
                navigate(`/projects` + window.location.search);
              }
            }}
          >
            {localizer.homePage.viewProjects}
            <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
          </StyledButton>
        </SearchBlock>
      </Content>
    </Wrapper>
  );
};

export default MainBlock;
