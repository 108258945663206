import {
  MultiLanguageTextField,
  performGetCall,
  performPostCall,
  performPutCall
} from '../api-helpers';
import { IContentItem } from '../prompto-objects';

export interface CreateContentCollectionBody {
  entityObjectId?: string;
  vaultObjectId?: string;
  projectObjectId?: string;
  unitObjectId?: string;
  linkedUnitObjectId?: string;
  title?: MultiLanguageTextField;
  description?: MultiLanguageTextField;
  contentItemIdList?: Array<string>;
  makePublic?: boolean;
}

export interface IContentCollection {
  objectId: string;
  vmContentItemList: Array<IContentItem>;
  loaded?: boolean;
}

export const ContentCollection = {
  /**
   * Create a content collection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create
   * @param {Object} params
   * @param {string} sessionToken
   */
  create: (params: CreateContentCollectionBody, sessionToken: string) =>
    performPostCall(`contentcollections`, params, sessionToken),

  /**
   * update a contentcollection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update
   */
  update: (
    contentCollectionObjectId: string,
    params: CreateContentCollectionBody,
    sessionToken: string
  ) => {
    return performPutCall(
      `contentcollections/${contentCollectionObjectId}`,
      params,
      sessionToken
    );
  },

  /**
   * Get a content collection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get
   */
  get: (
    contentCollectionObjectId: string,
    params?: any,
    sessionToken?: string
  ) =>
    performGetCall(
      `contentcollections/${contentCollectionObjectId}`,
      params,
      sessionToken
    ),

  /**
   * Get a content collection by share code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbycode
   */
  getByCode: (code: string, sessionToken?: string) =>
    performGetCall(`contentcollections/getByCode/${code}`, {}, sessionToken),

  /**
   * Make a content collection public
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_makepublic
   */
  makePublic: (contentCollectionObjectId: string, sessionToken: string) =>
    performPutCall(
      `contentcollections/makepublic`,
      { contentCollectionObjectId },
      sessionToken
    ),

  /**
   * Get first image and showcase configuration by code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbycodefirstimageandshowcaseconfiguration
   */
  getFirstImageAndShowcaseConfigurationByCode: (
    code: string,
    sessionToken?: string
  ) =>
    performGetCall(
      `contentcollections/getByCodeFirstImageAndShowcaseConfiguration/${code}`,
      {},
      sessionToken
    )
};
