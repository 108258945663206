import { performPutCall, performPostCall } from '../api-helpers';

export interface MoveBoardTicketParams {
  ticketIdList: string[];
  sourceColumnId: string;
  targetColumnId: string;
  sourceTicketOrderList: string[];
  targetTicketOrderList: string[];
}

export interface CreateBoardTicketParams {
  vaultObjectId?: string;
  projectObjectId?: string;
  boardId?: string;
  leadId?: string;
  state?: string;
  boardTicketType?: string;
  assignedUserId?: string;
  columnId?: string;
}

export const BoardTicket = {
  /**
   * Assign user to a board ticket
   *
   */
  assign: (
    boardTicketObjectId: string,
    params: Object,
    sessionToken: string
  ) => {
    return performPutCall(
      `boardticket/assign/${boardTicketObjectId}`,
      params,
      sessionToken
    );
  },

  /**
   * Move a board ticket from one column to another
   */
  move: (params: MoveBoardTicketParams, sessionToken: string) => {
    return performPutCall(
      `boardticket/move/moveBoardTicket`,
      params,
      sessionToken
    );
  },

  /**
   * Create a board ticket
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_createboardticket
   */
  create: (params: CreateBoardTicketParams, sessionToken: string) => {
    return performPostCall(`boardticket`, params, sessionToken);
  }
};
