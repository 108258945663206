// Import the envs to pick from
import devenv from './devenv';
import prodenv from './prodenv';
import qs from 'query-string';

const environment = (envQueryParam?: string) => {
  // Set default environment to dev if it is run on localhost. Otherwise set to production.
  const isLocalhost =
    window.location.host.match(/localhost/g) ||
    window.location.hostname.match(
      /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    );

  const { env } = qs.parse(window.location.search.substring(1));

  if (envQueryParam || env) {
    return envQueryParam === 'production' || env === 'production'
      ? prodenv
      : devenv;
  }

  if (isLocalhost) {
    return devenv;
  }

  if (import.meta.env.VITE_APP_PROD === 'true') {
    return prodenv;
  }

  return devenv;
};

export default environment;
