import { useState, useRef, useEffect, useCallback } from 'react';

// Helpers
import localizer from 'src/localization/localizer';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import styled, { StyledProps } from 'styled-components';
import { motion } from 'framer-motion';

const Wrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.DMSans};
`;

const SelectedPageSizeWrapper = styled.div`
  height: 40px;
  width: 100%;
  min-width: 80px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.gray20};
  border-radius: 4px;
  background: ${({ theme }) => theme.beigeBg10};
  position: relative;
  margin-left: 12px;
`;

const PageSizeValue = styled.div`
  font-size: 0.875rem;
  font-weight: normal;
  color: ${(props) => props.theme.showcaseBlack};
  margin-right: 5px;
  flex-grow: 1;
`;

const DropDownIcon = styled(FontAwesomeIcon)`
  position: relative;
  cursor: pointer;
  color: ${(props) => props.theme.primary100};
  font-size: 0.875rem;
  transition: all 150ms ease;
  transform-origin: center bottom;
`;

const CheckedItemIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: ${(props) => props.theme.primary300};
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(9px, 60%);
`;

const DropDown = styled(motion.div)`
  position: absolute;
  z-index: 15;
  background: ${({ theme }) => theme.beigeBg10};
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.gray20};
  border-radius: 0 0 4px 4px;
`;

const ItemsList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`;

const Item = styled.li<StyledProps<{ selected?: boolean; chosen?: boolean }>>`
  position: relative;
  font-size: 0.875rem;
  color: ${({ theme, selected }) => (selected ? theme.black : theme.gray50)};
  background-color: ${({ theme }) => theme.beigeBg10};
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 30px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.beigeBg20};
  }
`;

const OnPage = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.fonts.DMSans};
`;

type Option = {
  label: string;
  value: number;
};

interface PaginationSizeSelectProps {
  options: Option[];
  selectedOption: number;
  onSelect: (option: Option) => void;
}

const PaginationSizeSelect = ({
  options,
  selectedOption,
  onSelect
}: PaginationSizeSelectProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const optionsRef = useRef<HTMLDivElement>(null);

  const checkOutsideClick = useCallback(
    (event: any) => {
      if (!optionsRef.current?.contains(event.target)) {
        setShowOptions(false);
      }
    },
    [optionsRef]
  );

  useEffect(() => {
    window.addEventListener('click', checkOutsideClick);
    return () => window.removeEventListener('click', checkOutsideClick);
  }, [checkOutsideClick]);

  return (
    <Wrapper ref={optionsRef}>
      <OnPage>{localizer.unitListComponent.onPage}</OnPage>
      <SelectedPageSizeWrapper onClick={() => setShowOptions((prev) => !prev)}>
        <PageSizeValue>{selectedOption}</PageSizeValue>
        <DropDownIcon
          icon={['far', showOptions ? 'chevron-up' : 'chevron-down']}
        />

        {showOptions && (
          <DropDown
            initial={{ scaleY: 0, transformOrigin: 'top', opacity: 0.6 }}
            animate={{ scaleY: 1, opacity: 1 }}
            exit={{ scaleY: 0, opacity: 0.4 }}
          >
            <ItemsList>
              {options.map((option: any) => {
                const selected = option.value === selectedOption;

                return (
                  <Item
                    key={option.value}
                    selected={selected}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelect(option);
                      setShowOptions(false);
                    }}
                    chosen={selected}
                  >
                    {selected && (
                      <CheckedItemIcon icon={['far', 'check']} size="1x" />
                    )}
                    {option.label}
                  </Item>
                );
              })}
            </ItemsList>
          </DropDown>
        )}
      </SelectedPageSizeWrapper>
    </Wrapper>
  );
};

export default PaginationSizeSelect;
