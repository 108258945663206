import { performPostCall, performPutCall } from '../api-helpers';

export interface IStartSessionParams {
  visitorId?: string;
  marketplacePromptoSessionCreateParams?: any;
  showcasePromptoSessionCreateParams?: any;
  visitorCreateParams?: any;
  marketplaceId?: string;
}

export interface IUpdateSessionParams {
  sessionId: string;
}

export const Visitor = {
  /**
   * Create a new visitor and start session
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_createvisitorandstartsession
   */
  startSession: (params: IStartSessionParams, sessionToken?: string) => {
    return performPostCall(`visitor`, params, sessionToken);
  },

  /**
   * set end session timestamp
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updateendsessiontimestamp
   */
  updateSession: (params: IUpdateSessionParams, sessionToken?: string) => {
    return performPutCall(
      `promptosession/updateEndSessionTimestamp`,
      params,
      sessionToken
    );
  }
};
