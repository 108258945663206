import { useState } from 'react';
import localizer from 'src/localization/localizer';

// Stling
import styled, { StyledProps } from 'styled-components';

const Wrapper = styled.div`
  max-width: 880px;
  display: flex;
  flex-flow: row;
  border-bottom: 1px solid #e3e5e8;
  margin-bottom: 48px;
  gap: 20px;
`;

const TabItem = styled.div<StyledProps<{ isActive: boolean }>>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 25%;
  font-size: 1.25rem;
  color: ${({ theme, isActive }) => (isActive ? theme.black : theme.gray60)};
  cursor: pointer;
  user-select: none;
  padding-bottom: 22px;
  border-bottom: ${({ isActive }) => (isActive ? '1px solid #000000' : 'none')};
`;

const TabSwitcher = ({ activeTab, setActiveTab }: any) => {
  return (
    <Wrapper>
      <TabItem
        isActive={activeTab === 'profile'}
        onClick={() => {
          setActiveTab('profile');
        }}
      >
        {localizer.accountPage.profile}
      </TabItem>
      <TabItem
        isActive={activeTab === 'favourites'}
        onClick={() => {
          setActiveTab('favourites');
        }}
      >
        {localizer.accountPage.favourites}
      </TabItem>
    </Wrapper>
  );
};

export default TabSwitcher;
