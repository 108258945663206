import { useState, useEffect, useRef } from 'react';
import Cookies, { CookieSetOptions } from 'universal-cookie';
import { Visitor, IStartSessionParams } from '@prompto-api';
import { useAppState } from 'src/store/AppStore';
import { fetchSettingsFromURL } from 'src/helpers/utils';

const cookies = new Cookies();

// Types
interface ICreateVisitorResponse {
  data: {
    visitorObjectId: string;
    sessionObjectId: string;
  };
}

/*
 * get user data from ipapi.co api
 * */
export const getUserData = async () => {
  const response = await fetch(
    `https://ipapi.co/json/?key=${import.meta.env.VITE_IPAPI_SECRET_KEY}`
  );
  const userBrowserData = await response.json();

  const userAgentData = (navigator as any)?.userAgentData;
  const userDeviceData = {
    mobile: userAgentData?.mobile,
    platform: userAgentData?.platform
  };

  const visitorCreateParams = {
    ipAddress: userBrowserData?.ip,
    network: userBrowserData?.network,
    version: userBrowserData?.version,
    city: userBrowserData?.city,
    region: userBrowserData?.region,
    region_code: userBrowserData?.region_code,
    country: userBrowserData?.country,
    country_code: userBrowserData?.country_code,
    country_code_iso3: userBrowserData?.country_code_iso3,
    country_name: userBrowserData?.country_name,
    country_capital: userBrowserData?.country_capital,
    country_tld: userBrowserData?.country_tld,
    continent_code: userBrowserData?.continent_code,
    in_eu: userBrowserData?.in_eu,
    postal: userBrowserData?.postal,
    latitude: userBrowserData?.latitude,
    longitude: userBrowserData?.longitude,
    timezone: userBrowserData?.timezone,
    utc_offset: userBrowserData?.utc_offset,
    country_calling_code: userBrowserData?.country_calling_code,
    currency: userBrowserData?.currency,
    currency_name: userBrowserData?.currency_name,
    languages: userBrowserData?.languages,
    country_area: userBrowserData?.country_area,
    asn: userBrowserData?.asn,
    country_population: userBrowserData?.country_population,
    org: userBrowserData?.org,
    ...userDeviceData
  };

  return visitorCreateParams;
};

const visitorCookiesParams: CookieSetOptions = {
  maxAge: 315360000000, // 10 years,
  sameSite: 'none',
  secure: true
};

/**
 * The function `getUserLocation` retrieves the latitude and longitude of a user's location either from
 * provided parameters, cookies, or by calling an API endpoint.
 * @param {any} visitorCreateParams - The `visitorCreateParams` parameter is an object that contains
 * information about the visitor. It may include properties such as `latitude` and `longitude`, which
 * represent the visitor's location coordinates.
 * @param {string} visitorObjectId - The `visitorObjectId` parameter is a string that represents the
 * unique identifier of the visitor. It is used to retrieve the user's location from cookies.
 * @returns The function `getUserLocation` returns an object `userLocation` which contains the latitude
 * and longitude properties of the user's location.
 */
const getUserLocation = async (
  visitorCreateParams: any,
  visitorObjectId: string
) => {
  const userLocation: any = {};
  const queryParams = fetchSettingsFromURL();

  // Check if user location was provided in visitor create params.
  // Else check cookies.
  if (visitorCreateParams?.latitude && visitorCreateParams?.longitude) {
    userLocation.latitude = visitorCreateParams.latitude;
    userLocation.longitude = visitorCreateParams.longitude;
  } else {
    const userLatitude = cookies.get(
      `prompto_visitor_latitude_${visitorObjectId}`
    );
    const userLongitude = cookies.get(
      `prompto_visitor_longitude_${visitorObjectId}`
    );
    if (userLatitude && userLongitude) {
      userLocation.latitude = userLatitude;
      userLocation.longitude = userLongitude;
    }
  }

  // Else call ipapi endpoind to get user's location
  if (
    (!userLocation.latitude || !userLocation.longitude) &&
    (import.meta.env.VITE_APP_PROD === 'true' || !!queryParams.enableIpApi)
  ) {
    const { latitude, longitude } = await getUserData();
    if (latitude && longitude) {
      cookies.set(
        `prompto_visitor_latitude_${visitorObjectId}`,
        latitude,
        visitorCookiesParams
      );
      cookies.set(
        `prompto_visitor_longitude_${visitorObjectId}`,
        longitude,
        visitorCookiesParams
      );
      userLocation.latitude = latitude;
      userLocation.longitude = longitude;
    }
  }

  // If there is still no location, create a random location to use it during user's session
  // Latitude is limited by North semisphere and longitude is limited by European part of Eurasia
  if (!userLocation.latitude || !userLocation.longitude) {
    const getRandomNumber = (min: number, max: number) =>
      Math.round(min + Math.random() * (max - min));
    const randomLatitude = getRandomNumber(0, 80);
    const randomLongitude = getRandomNumber(-30, 60);
    userLocation.latitude = randomLatitude;
    userLocation.longitude = randomLongitude;
  }

  return userLocation;
};

const useManageMarketplaceSession = (
  marketplaceId: string,
  authenticated: boolean,
  pathname: string
) => {
  const [startCreateVisitorProcess, setStartCreateVisitorProcess] =
    useState(true);

  const createVisitorProcessStarted = useRef(false);
  const createVisitorProcessCounter = useRef(0);

  const { AppStateDispatch } = useAppState();
  const queryParams = fetchSettingsFromURL();

  useEffect(() => {
    const isRegisterPage = pathname.includes('register');
    const isLoginPage = pathname.includes('login');

    if (!marketplaceId) return;
    if (authenticated) return;
    if (isRegisterPage || isLoginPage) return;

    let sessionHeartInterval: any;

    const triggerStartSession = () => {
      createVisitorProcessStarted.current = false;
      setStartCreateVisitorProcess(true);
    };

    const createVisitorAndStartSession = async () => {
      if (startCreateVisitorProcess && !createVisitorProcessStarted.current) {
        createVisitorProcessStarted.current = true;
        setStartCreateVisitorProcess(false);

        createVisitorProcessCounter.current =
          createVisitorProcessCounter.current + 1;

        const marketplacePromptoSessionCreateParams = {
          sessionType: 'marketplace',
          marketplaceId: marketplaceId,
          utmCampaign: queryParams.utm_campaign ?? '',
          utmSource: queryParams.utm_source ?? ''
        };

        const handleStartSession = (
          response: ICreateVisitorResponse,
          userLocation: any
        ) => {
          const { visitorObjectId: visitorId, sessionObjectId: sessionId } =
            response.data;

          AppStateDispatch({
            type: 'setSessionData',
            payload: {
              visitorId,
              sessionId,
              userLocation,
              utmCampaign: marketplacePromptoSessionCreateParams.utmCampaign,
              utmSource: marketplacePromptoSessionCreateParams.utmSource
            }
          });

          sessionHeartInterval = setInterval(() => {
            Visitor.updateSession({ sessionId }).catch(() => {
              console.warn('Could not update the end session timestamp');
            });
          }, 10000);

          // showcase_visitor_id cookie is used in the Showcase
          // by using the same cookie in both Showcase and Marketplace we persist
          // visitor's identity across prompto applications
          cookies.set(`showcase_visitor_id`, visitorId, visitorCookiesParams);
        };

        const startPromptoSession = (params: IStartSessionParams) => {
          Visitor.startSession(params)
            .then(async (response: ICreateVisitorResponse) => {
              const userLocation = await getUserLocation(
                params.visitorCreateParams,
                response.data.visitorObjectId
              );
              return handleStartSession(response, userLocation);
            })
            .catch(() => {
              // do one more try
              if (createVisitorProcessCounter.current < 2) {
                triggerStartSession();
              }
            });
        };

        const visitorIdFromCookie = cookies.get(`showcase_visitor_id`);

        if (visitorIdFromCookie) {
          startPromptoSession({
            visitorId: visitorIdFromCookie,
            marketplacePromptoSessionCreateParams
          });
        } else {
          // Get user data only in production
          const visitorCreateParams =
            import.meta.env.VITE_APP_PROD === 'true' ? await getUserData() : {};
          startPromptoSession({
            marketplacePromptoSessionCreateParams,
            visitorCreateParams
          });
        }
      }
    };

    createVisitorAndStartSession();

    return () => {
      if (sessionHeartInterval) clearInterval(sessionHeartInterval);
    };
  }, [startCreateVisitorProcess, AppStateDispatch, queryParams]);
};

export default useManageMarketplaceSession;
