import styled, { StyledProps } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

export const Text = styled.div<StyledProps<{ color?: string }>>`
  font-size: 1.5rem;
  color: ${({ theme, color }) => color ?? theme.black};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  text-align: center;
  span {
    font-family: ${({ theme }) => theme.fonts.DMSans};
    font-style: italic;
    font-weight: bold;
  }
  ${forBiggerThan.tablet`
    font-size: 2.25rem;
  `}
`;

export const SmallText = styled(Text)`
  font-size: 1.125rem;
  margin-bottom: ${({ theme }) => theme.spacing.m};
  max-width: 80vw;
`;
