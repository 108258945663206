import { useCallback } from 'react';

// Component
import Button from 'src/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import {
  GetInTouchActionNameType,
  TrackGetInTouchBasicParams,
  Tracking
} from '@prompto-api';
import { useAppState } from 'src/store/AppStore';
import { useContactUsState } from 'src/store/ContactUsStore';
import localizer from 'src/localization/localizer';
import { isMobileOnly } from 'react-device-detect';

// Styling
import styled, { css, keyframes } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const glareAnimation = keyframes`
  0%, 100% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0%);
  }
`;

const ContactButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${({ theme }) => theme.greenCTA};
  min-width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  z-index: 1500;
  padding: 0 14px;

  ${forBiggerThan.laptop`
    min-width: 160px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%,
        rgba(255, 255, 255, 0) 100%
      );
      transform: translateX(0%);
      animation: ${glareAnimation} 10s linear infinite;
    }

    &:hover:before {
      animation: none;
    }
  `}

  ${isMobileOnly &&
  css`
    position: fixed;
    z-index: 3002;
    bottom: 10px;
    right: 15px;
    margin: 0;
  `}
`;

const MessageIcon = styled(FontAwesomeIcon)`
  ${forBiggerThan.laptop`
    margin-left: 8px;
  `}
`;

const Text = styled.div`
  display: none;

  ${forBiggerThan.laptop`
    display: block;
  `}
`;

interface ContactUsButtonProps {
  trackingData: TrackGetInTouchBasicParams;
  currentUnitId?: string;
  location: 'navigationBarUnitPage' | 'navigationBarProjectPage';
}

const ContactUsButton = ({
  trackingData,
  currentUnitId,
  location
}: ContactUsButtonProps) => {
  // App state
  const { AppState } = useAppState();
  const { ContactUsStateDispatch } = useContactUsState();
  const trackAction = useCallback(
    (actionName: GetInTouchActionNameType) => {
      if (trackingData) {
        Tracking.trackGetInTouchInteraction({
          ...trackingData,
          locationOnPage: location,
          actionName
        }).catch(() => {});
      }
    },
    [trackingData, location]
  );

  return (
    <ContactButton
      onClick={() => {
        trackAction('buttonClicked');

        ContactUsStateDispatch({
          type: 'setShowContactUsForm',
          payload: {
            showContactUsForm: true,
            unitObjectId: currentUnitId,
            location
          }
        });
      }}
    >
      <Text>{localizer.getInTouchCard.buttonText}</Text>
      <MessageIcon icon={['far', 'comment-alt-lines']} size="sm" />
    </ContactButton>
  );
};

export default ContactUsButton;
