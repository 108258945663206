import qs from 'query-string';
import axios from 'axios';
import env from 'src/environment';
import urlJoin from 'url-join';
import to from 'await-to-js';
import localizer from 'src/localization/localizer';

import { version } from '../../../../../package.json';

interface requestHeaders {
  'X-Around-Api-Token': string;
  'X-Around-Session-Token'?: string;
}

// Get the version defined in package.json, since this is the centralized point
export const getPackageVersion = () => {
  return version;
};

/**
 * Builds the headers we need for a 360 backend call
 * @param {string} sessionToken
 */
const build360Headers = (sessionToken?: string) => {
  const headers: requestHeaders = { 'X-Around-Api-Token': env().apiToken360 };
  if (sessionToken) {
    headers['X-Around-Session-Token'] = sessionToken;
  }
  return headers;
};

/**
 * Builds the headers we need for a call
 * @param {string} sessionToken
 */
const buildHeaders = (sessionToken?: string) => {
  const headers: requestHeaders = { 'X-Around-Api-Token': env().apiToken };
  if (sessionToken) {
    headers['X-Around-Session-Token'] = sessionToken;
  }
  return headers;
};

/**
 * Runs a querystring search on the url params, to fetch the data,
 * destructure it as you need params
 */
export const fetchSettingsFromURL = () => {
  return qs.parse(window.location.search, {
    parseBooleans: true,
    parseNumbers: true
  });
};

export function debounce<A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number
): (args: A) => Promise<R> {
  let timer: NodeJS.Timeout;

  return (args: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(args));
      }, ms);
    });
}

// capitalize the first letter of a string
export const capitalize = (string: string) =>
  string && `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

// calculate file extension
// https://github.com/goranmoomin/extname/blob/master/index.js
const CHAR_FORWARD_SLASH = 47; /* / */
const CHAR_DOT = 46; /* . */

export const extname = (path: string) => {
  if (typeof path !== 'string') {
    throw new TypeError(
      `The "path" argument must be of type string. Received type ${typeof path}`
    );
  }

  let startDot = -1;
  let startPart = 0;
  let end = -1;
  let matchedSlash = true;
  // Track the state of characters (if any) we see before our first dot and
  // after any path separator we find
  let preDotState = 0;
  for (let i = path.length - 1; i >= 0; --i) {
    let code = path.charCodeAt(i);
    if (code === CHAR_FORWARD_SLASH) {
      // If we reached a path separator that was not part of a set of path
      // separators at the end of the string, stop now
      if (!matchedSlash) {
        startPart = i + 1;
        break;
      }
      continue;
    }
    if (end === -1) {
      // We saw the first non-path separator, mark this as the end of our
      // extension
      matchedSlash = false;
      end = i + 1;
    }
    if (code === CHAR_DOT) {
      // If this is our first dot, mark it as the start of our extension
      if (startDot === -1) {
        startDot = i;
      } else if (preDotState !== 1) {
        preDotState = 1;
      }
    } else if (startDot !== -1) {
      // We saw a non-dot and non-path separator before our dot, so we should
      // have a good chance at having a non-empty extension
      preDotState = -1;
    }
  }

  if (
    startDot === -1 ||
    end === -1 ||
    // We saw a non-dot character immediately before the dot
    preDotState === 0 ||
    // The (right-most) trimmed path component is exactly '..'
    (preDotState === 1 && startDot === end - 1 && startDot === startPart + 1)
  ) {
    return '';
  }
  return path.slice(startDot, end);
};

/**
 * Compute cloudinary ID
 * @param {array} uri - uri
 * @param {array} bucketId - bucketId
 */
export const computeCloudinaryId = (uri: string, bucketId: string) => {
  const splitString = bucketId.concat('/o/');
  const tokens = uri.split(splitString);
  if (!tokens || tokens.length < 2) {
    return null;
  }

  const relativePath = tokens[1].split('?')[0];
  const fileExtension = extname(relativePath);
  const cloudinaryId = relativePath.replace(fileExtension, '');

  return cloudinaryId;
};

/**
 * Compute cloudinary Thumbnail
 */
export const computeCloudinaryThumbnail = (
  uri: string,
  bucketId: string,
  baseUrl: string,
  transform?: string
) => {
  if (uri && bucketId) {
    const cloudinaryId = computeCloudinaryId(uri, bucketId);
    const macMachine = navigator.userAgent
      .toString()
      .toLowerCase()
      .match(/mac/g);

    const extension = macMachine ? 'jpeg' : 'webp';
    let finalImageUri = `${baseUrl}`;
    if (transform) {
      finalImageUri += `/${transform}`;
    }
    finalImageUri += `/${cloudinaryId}.${extension}`;

    return finalImageUri;
  }
};

/**
 * Get a 360 album by code
 * @param {String} albumCode - code of album
 */
export const getAlbumByCode = (albumCode: string) => {
  if (!albumCode) {
    return Promise.reject(new Error('no album code given'));
  }
  const envData = env();
  const apiUrl = envData.base360Url; //'https://api.around.media/v1';

  return new Promise((resolver, rejecter) => {
    // first get api token
    axios
      .get(
        urlJoin(
          apiUrl,
          'albums',
          `code=${albumCode}/token`,
          `?_=${Date.now()}&apiToken=${envData.apiToken360}`
        ),
        {
          headers: build360Headers()
        }
      )
      .then(({ data }) => {
        //next get album cover image
        axios
          .get(
            urlJoin(
              apiUrl,
              'albums',
              `code=${albumCode}`,
              'coverimage',
              `?_=${Date.now()}&token=${data.token}&apiToken=${
                envData.apiToken360
              }`
            ),
            {
              headers: build360Headers()
            }
          )
          .then((result) => {
            resolver({ data: result.data });
          });
      })
      .catch((err) => {
        rejecter(err);
      });
  });
};

/**
 * Get the turntable for the showcase
 * @param {string} turntableObjectId
 */
export const getTurnTable = (turntableObjectId: string, params: any) => {
  return axios.get(
    urlJoin(env().baseUrl, 'turntable', turntableObjectId, `?_=${Date.now()}`),
    {
      headers: buildHeaders(),
      params
    }
  );
};

/**
 * Add or edit params to the search string
 * @param {Array<Object>} params - must be {key: value} objects
 */
export const addQueryParams = (params: any[]) => {
  const parsed = qs.parse(window.location.search);

  params.forEach((param) => {
    const key = Object.keys(param)[0];
    parsed[key] = param[key];
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${qs.stringify(parsed)}`
  );
};

/**
 * remove params to the search string
 * @param {Array<Object>} params - must be {key: value} objects
 */
export const removeQueryParams = (params: any[]) => {
  const parsed: any = qs.parse(window.location.search);

  params.forEach((param) => {
    const key = Object.keys(param)[0];
    parsed[key] = undefined;
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${qs.stringify(parsed)}`
  );
};

/**
 * It checks based on provided params is a specific cloudinary transformation is available
 *
 * @param {function} setTransformAvailable call back function
 * @param {string} uri the date uri stored on prompto backend
 * @param {string} bucketId the google bucket id
 * @param {string} baseUrl the base cloudinary image or video url
 * @param {string} transform optional cloudinary transformation
 * @param {string} extension file extension
 */
export const isCloudinaryTransformationAvailable = async (
  setTransformAvailable: (flag: boolean) => void,
  uri: string,
  bucketId: string,
  baseUrl: string,
  transform?: string,
  extension?: string
) => {
  const checkUrl = computeTransformUri(
    uri,
    bucketId,
    baseUrl,
    transform,
    extension
  );
  const [err, result] = await to(axios({ method: 'head', url: checkUrl }));

  if (err) {
    setTransformAvailable(false);
    return;
  }

  if (result?.status === 200) {
    setTransformAvailable(true);
  } else {
    setTransformAvailable(false);
  }
};

/**
 * It builds a complete cloudinary transform url
 *
 * @param {string} uri the data uri stored on prompto backend
 * @param {string} bucketId the google bucket id
 * @param {string} baseUrl the base cloudinary image or video url
 * @param {string} transform optional cloudinary transformation
 * @param {string} extension file extension
 */
export const computeTransformUri = (
  uri: string,
  bucketId: string,
  baseUrl: string,
  transform?: string,
  extension?: string
) => {
  if (uri && bucketId) {
    const cloudinaryId = computeCloudinaryId(uri, bucketId);
    let finalTransformUri = `${baseUrl}`;
    if (transform) {
      finalTransformUri += `/${transform}`;
    }
    finalTransformUri += `/${cloudinaryId}.${extension}`;
    return finalTransformUri;
  }
};

/**
 * Return the list of heights that are generated for each image by Cloudinary
 */
export const getBackendGeneratedHeights = () => {
  return [200, 250, 300, 400, 800, 1080, 1600, 2160];
};

/**
 * Display localized value
 * @param {Object} textMap
 * @returns {String} localized value
 */
export const displayLocalizedValue = (textMap: any) => {
  if (!textMap) return '';
  const lang = localizer.getLanguage();

  // 1. try to display a value in app language
  const value = textMap[lang];
  if (value) return value;

  // 2. fallback to value in other languages following the order [en, nl, fr, de]
  const fallbackLang = ['en', 'nl', 'fr', 'de'].find((lang) => textMap[lang]);
  return fallbackLang ? textMap[fallbackLang] : '';
};

/**
 * Check if the application is run in an iframe
 * @returns True if rendered in iframe
 */
export const checkIfRenderedInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const UNSORTED_FOLDER = 'unsorted-items-folder';

export const orderContentCollection = (list: any, folderStructure: any) => {
  if (!folderStructure || folderStructure.length === 0) {
    return list;
  }

  // reorder initial folder structure to make sure
  // that unsorted folder is in the end of the list
  let reorderedFolderStructure;
  const unsortedFolderIndex = folderStructure.findIndex(
    (f: any) => f.uuid === UNSORTED_FOLDER
  );

  // no need to reorder if the unsorted folder
  // does not exist or it is already in the last position
  if (
    unsortedFolderIndex > -1 &&
    unsortedFolderIndex !== folderStructure.length - 1
  ) {
    const unsortedFolder = folderStructure.find(
      (f: any) => f.uuid === UNSORTED_FOLDER
    );
    const otherFolders = folderStructure.filter(
      (f: any) => f.uuid !== UNSORTED_FOLDER
    );
    reorderedFolderStructure = [...otherFolders, unsortedFolder];
  } else {
    reorderedFolderStructure = folderStructure;
  }

  let startFolderItemIndex = 0;

  const ordered = reorderedFolderStructure.reduce(
    (acc: any, folder: any) => {
      const updAcc = { ...acc };

      const folderFiles = folder.projectFiles ?? [];
      const title =
        folder.name === UNSORTED_FOLDER ? 'Other resources' : folder.name;
      if (folderFiles.length > 0) {
        let addedFilesNumber = 0;

        const handleFolderFiles = (files: any) => {
          files.forEach((file: any) => {
            if (file.type === 'CONTENT_ITEM') {
              const fileInCollection = list.find(
                (x: any) => x.objectId === file.contentItemId
              );
              const fileWasAlreadyAdded = updAcc.contentItemlist.find(
                (x: any) => x.objectId === file.contentItemId
              );
              if (
                fileInCollection &&
                fileInCollection.contentItemState === 'published' &&
                !fileWasAlreadyAdded
              ) {
                updAcc.contentItemlist.push(fileInCollection);
                addedFilesNumber += 1;
              }
            } else {
              handleFolderFiles(file.projectFiles ?? []);
            }
          });
        };

        handleFolderFiles(folderFiles);

        if (
          addedFilesNumber > 0 &&
          (folder.name !== UNSORTED_FOLDER ||
            (folder.name === UNSORTED_FOLDER &&
              folder.uuid === UNSORTED_FOLDER))
        ) {
          updAcc.folders.push({
            title,
            uuid: folder.uuid,
            itemsRange: [
              startFolderItemIndex,
              startFolderItemIndex + addedFilesNumber
            ]
          });
          startFolderItemIndex += addedFilesNumber;
        }
      }

      return updAcc;
    },
    {
      contentItemlist: [],
      folders: [],
      foldersRefs: {}
    }
  );

  return ordered.contentItemlist;
};

export const abbreviateNumber = (price: number) => {
  const abbreviations = {
    B: 1000000000, // billion
    M: 1000000, // million
    K: 1000 // thousand
  };

  function abbreviate(price: number, range: number, letter: string) {
    const main = Math.floor(price / range);
    const reminder = ((price % range) / range).toFixed(2);

    return `${main + +reminder}${letter}`;
  }

  if (price > abbreviations.B) {
    return abbreviate(price, abbreviations.B, 'B');
  } else if (price > abbreviations.M) {
    return abbreviate(price, abbreviations.M, 'M');
  } else if (price > abbreviations.K) {
    return abbreviate(price, abbreviations.K, 'K');
  } else {
    return price;
  }
};

export const readAsDataUrl = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onabort = reject;
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
