// Helpers
import localizer from 'src/localization/localizer';
import { isMobile } from 'react-device-detect';

// Styling
import styled, { StyledProps } from 'styled-components';

const StyledToggle = styled.button`
  position: absolute;
  z-index: 10;
  bottom: 16px;
  left: 16px;
  min-width: 80px;
  height: ${isMobile ? 40 : 50}px;
  padding: ${isMobile ? 10 : 15}px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
`;

const InnerCircle = styled.div``;

const Switcher = styled.div<StyledProps<{ isInfoHidden: boolean }>>`
  width: 24px;
  height: 16px;
  border-radius: 8px;
  padding: 2px;
  box-sizing: border-box;
  background: ${({ theme, isInfoHidden }) =>
    isInfoHidden ? theme.gray10 : theme.gold};
  display: flex;
  align-items: center;
  justify-content: ${({ isInfoHidden }) =>
    isInfoHidden ? 'flex-start' : 'flex-end'};
  margin-right: 12px;
  transition: all 150ms ease;

  ${InnerCircle} {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(43, 51, 63, 0.7);
  }
`;

// Types
interface HideAdditionalInfoToggleProps {
  onToggle: () => void;
  isInfoHidden: boolean;
}

const HideAdditionalInfoToggle = ({
  onToggle,
  isInfoHidden
}: HideAdditionalInfoToggleProps) => {
  return (
    <StyledToggle onClick={onToggle}>
      <Switcher isInfoHidden={isInfoHidden}>
        <InnerCircle />
      </Switcher>
      <p>
        {isInfoHidden
          ? localizer.projectTour.showAdditionalInfo
          : localizer.projectTour.hideAdditionalInfo}
      </p>
    </StyledToggle>
  );
};

export default HideAdditionalInfoToggle;
