import React, { useReducer, createContext, useContext } from 'react';
import { isMobile } from 'react-device-detect';

import { addQueryParams, fetchSettingsFromURL } from 'src/helpers/utils';

export const ProjectsMapContext = createContext({
  bounds: null,
  center: null,
  selectedProject: null,
  expandMap: !isMobile,
  mapInstance: null,
  projectsVisibleInMap: [],
  projectsForMap: [],
  isMapBoundToList: true,
  hoveredProjectId: '',
  destroyMap: false
});

const ProjectsMapStore = (props) => {
  const [ProjectsMapState, ProjectsMapDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'updateProjectsMapState':
          const { shareFilter } = fetchSettingsFromURL();
          if (shareFilter) {
            const queryParams = [];
            if (!!action.payload.hoveredProjectId) {
              queryParams.push({
                highlightedProjectId: action.payload.hoveredProjectId
              });
            }
            if (!!action.payload.center) {
              queryParams.push({
                lat: action.payload.center.lat
              });
              queryParams.push({
                lng: action.payload.center.lng
              });
            }

            if (!!action.payload.zoom) {
              queryParams.push({
                zoom: action.payload.zoom
              });
            }

            if (!!action.payload.selectedProject) {
              queryParams.push({
                selectedProject: action.payload.selectedProject.projectId
              });
            }

            if (queryParams.length > 0) {
              addQueryParams(queryParams);
            }
          }

          return {
            ...state,
            ...action.payload
          };
        default:
          return state;
      }
    },
    {
      bounds: null,
      center: null,
      initialCenter: null,
      initialSelectedProject: null,
      selectedProject: null,
      expandMap: !isMobile,
      mapInstance: null,
      projectsVisibleInMap: [],
      projectsForMap: [],
      isMapBoundToList: true,
      hoveredProjectId: '',
      destroyMap: false
    }
  );

  return (
    <ProjectsMapContext.Provider
      value={{ ProjectsMapState, ProjectsMapDispatch }}
    >
      {props.children}
    </ProjectsMapContext.Provider>
  );
};

export const useProjectsMapState = () => useContext(ProjectsMapContext);

export default ProjectsMapStore;
