import {
  MultiLanguageTextField,
  performGetCall,
  performPostCall,
  performPutCall,
} from '../api-helpers';

export interface CreateNavigationCollectionBody {
  entityObjectId?: string;
  vaultObjectId?: string;
  projectObjectId?: string;
  title?: MultiLanguageTextField;
  description?: MultiLanguageTextField;
  navigationItemIdList?: Array<string>;
}

export const NavigationCollection = {
  /**
   * Create a navigation collection
   *https://devapiv2.vr-tual.media/asciidoc/index.html#_create_23
   */
  create: (params: CreateNavigationCollectionBody, sessionToken: string) =>
    performPostCall(`navigationcollections`, params, sessionToken),

  /**
   * update a navigation collection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_21
   */
  update: (
    navigationCollectionObjectId: string,
    params: CreateNavigationCollectionBody,
    sessionToken: string
  ) =>
    performPutCall(
      `navigationcollections/${navigationCollectionObjectId}`,
      params,
      sessionToken
    ),

  /**
   * Get a navigation collection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get
   */
  get: (
    navigationCollectionObjectId: string,
    loadNestedTree?: boolean,
    sessionToken?: string
  ) =>
    performGetCall(
      `navigationcollections/${navigationCollectionObjectId}`,
      { loadNestedTree },
      sessionToken
    ),
};
