import {
  MultiLanguageTextField,
  Textmap,
  performDeleteCall,
  performGetCall,
  performPostCall,
  performPutCall
} from '../api-helpers';
import { IContentCollection } from '../prompto-objects';

export interface GetAllUnitsQueryParams {
  projectSectionId: string;
  stateFilter?: string;
  includeContentCollection?: boolean;
  searchValue?: string;
  sortField?: any;
  sortAsc?: boolean;
  offset?: number;
  limit?: number;
}

export const enum UnitState {
  AVAILABLE = 'AVAILABLE',
  IN_OPTION = 'IN_OPTION',
  SOLD = 'SOLD',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT'
}

export const enum UnitFlow {
  rental,
  sale
}

export interface CreateUpdateUnitParams {
  state: UnitState;
  unitFlow?: UnitFlow;
  unitPrice?: any;
  unitMetadata?: any;
  localizedTitle?: MultiLanguageTextField;
  localizedDescription?: MultiLanguageTextField;
}

export interface UpdateUnitBulkParams {
  updateParamsList?: Array<CreateUpdateUnitParams>;
}

export interface CreateUnitBulkParams {
  unitParamsList?: Array<CreateUpdateUnitParams>;
  sessionObjectId?: string;
}

export const enum GeneralFieldType {
  price = 'price',
  surface = 'surface',
  numberOfBedrooms = 'numberOfBedrooms',
  numberOfBathrooms = 'numberOfBathrooms'
}

export interface IUnitType {
  title?: string;
  icon?: any;
  name?: Textmap;
  showGeneralFieldSettings?: {
    [key in GeneralFieldType]: boolean;
  };
}

export interface IUnit {
  objectId: string;
  state: UnitState;
  unitPrice: {
    price: number;
    currencyCode: string;
  };
  unitType?: IUnitType;
  unitMetadata: {
    numberOfBathrooms: number;
    numberOfBedrooms: number;
    surface: number;
  };
  localizedTitle: Textmap;
  title: string;
  vmContentCollection: IContentCollection;
  linkedContentCollection: IContentCollection;
  description: string;
  localizedDescription?: Textmap;
  loaded?: boolean;
  unitFlow?: string;
  contentItemCustomSortingValues?: string[];
}

export const Unit = {
  /**
   * Create a unit
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_40
   */
  create: (
    vaultObjectId: string,
    params: CreateUpdateUnitParams,
    sessionToken: string
  ) => performPostCall(`vaults/${vaultObjectId}/units`, params, sessionToken),

  /**
   * Create a unit bulk
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_createbulk_3
   */
  createBulk: (
    vaultObjectId: string,
    params: CreateUnitBulkParams,
    sessionToken: string
  ) =>
    performPostCall(`vaults/${vaultObjectId}/units/bulk`, params, sessionToken),

  /**
   * Get single unit
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_37
   */
  get: (sessionToken: string, vaultId: string, unitObjectId: string) =>
    performGetCall(`vaults/${vaultId}/units/${unitObjectId}`, {}, sessionToken),

  /**
   * update a unit
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_38
   */
  update: (
    objectId: string,
    vaultObjectId: string,
    params: CreateUpdateUnitParams,
    sessionToken: string
  ) => {
    return performPutCall(
      `vaults/${vaultObjectId}/units/${objectId}`,
      params,
      sessionToken
    );
  },

  /**
   * update multiple units
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatebulk_5
   */
  updateBulk: (
    vaultObjectId: string,
    params: UpdateUnitBulkParams,
    sessionToken: string
  ) => {
    return performPutCall(
      `vaults/${vaultObjectId}/units/bulk`,
      params,
      sessionToken
    );
  },

  /**
   * Delete a unit
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_delete_12
   */
  delete: (objectId: string, vaultObjectId: string, sessionToken: string) => {
    return performDeleteCall(
      `vaults/${vaultObjectId}/units/${objectId}`,
      sessionToken
    );
  },

  /**
   * Get all the units for a project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_23
   */
  getAll: (
    vaultId: string,
    params: GetAllUnitsQueryParams,
    sessionToken: string
  ) => performGetCall(`vaults/${vaultId}/units`, params, sessionToken)
};
