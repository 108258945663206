import {
  performDeleteCall,
  performGetCall,
  performPostCall,
  performPutCall
} from '../api-helpers';
import axios from 'axios';
import { Countries } from './countries';

export const enum VaultUserRoles {
  redOwner,
  redManager,
  redProjectExecutive,
  redProjectAgent
}

export interface VaultUpdateUserParams {
  permissionRoleObjectId?: VaultUserRoles;
  projectObjectIdList?: Array<string>;
}

export const Vault = {
  update: (vaultId: string, params: any, sessionToken: string) => {
    return performPutCall(`vaults/${vaultId}`, params, sessionToken);
  },

  updateSubscription: (vaultId: string, params: any, sessionToken: string) => {
    return performPostCall(
      `vaults/${vaultId}/updatesubscription`,
      params,
      sessionToken
    );
  },

  getInfo: (vaultId: string, sessionToken: string, params: any) => {
    return performGetCall(`vaults/${vaultId}/info`, params, sessionToken);
  },

  /**
   * Get vault user permissions
   * @param {string} vaultId
   * @param {string} userId
   * @param {string} sessionToken
   */
  getUserPermissions: (vaultId: string, userId: string, sessionToken: string) =>
    performGetCall(
      `vaults/${vaultId}/userPermissions/${userId}`,
      {},
      sessionToken
    ),

  /**
   * Get access token for storage
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getstorageaccesstoken
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  getUploadAccessToken: (vaultId: string, sessionToken: string) =>
    performGetCall(
      `vaults/${vaultId}/catalogue/storage/token`,
      {},
      sessionToken
    ),

  /**
   * Create a vault from Product-led registration page
   * Documentation is to be generated
   * @param {Object} params
   */
  createFromProductLed: (params: any) =>
    performPostCall(
      `entities/aroundmedia/vaults/createVaultFromProductLed`,
      params
    ),

  /**
   * Create a contact in Hubspot after product-led registration is done
   * @param {string} portalId
   * @param {string} formId
   * @param {Object} params
   */
  submitHubspotProductLedRegistration: (
    portalId: string,
    formId: string,
    params: any
  ) =>
    axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
      params
    ),

  /**
   * Get unit slots usage report for a vault
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getunitslotusagereport_1
   */
  getUnitSlotUsageReport: (vaultId: string, sessionToken: string) => {
    return performGetCall(
      `vaults/${vaultId}/getUnitSlotUsageReport`,
      {},
      sessionToken
    );
  },

  /**
   * Get Per Unit Plans
   * @param {string} sessionToken
   */
  getPerUnitPlans: (sessionToken: string) => {
    return performGetCall(
      `subscriptionplans/getPerUnitActivePlans`,
      {},
      sessionToken
    );
  },

  /**
   * Get payment sources
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getpaymentsources
   */
  getPaymentSources: (vaultId: string, sessionToken: string) => {
    return performGetCall(
      `vaults/${vaultId}/getPaymentSources`,
      {},
      sessionToken
    );
  },

  /**
   * Get payments history
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_paymenthistory
   */
  getPaymentsHistory: (vaultId: string, sessionToken: string) => {
    return performGetCall(
      `vaults/${vaultId}/getPaymentHistory`,
      {},
      sessionToken
    );
  },

  /**
   * Get ChargeBee invoice PDF
   * Documentation is to be added
   */
  getChargeBeeInvoicePdf: (
    vaultId: string,
    invoiceId: string,
    sessionToken: string
  ) => {
    return performGetCall(
      `vaults/${vaultId}/getInvoicePdfUrl/${invoiceId}`,
      {},
      sessionToken
    );
  },

  /**
   * Change subscription plan on next renewal date
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_changeplanonnextrenewaldate
   * @param {string} sessionToken
   * @param {Object} params
   */
  changePlanOnNextRenewalDate: (sessionToken: string, params: string) =>
    performPutCall(`vaults/changePlanOnNextRenewalDate`, params, sessionToken),

  /**
   * Get change plan on next renewal date info
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getchangeplanonnextrenewaldate
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  getPlanOnNextRenewalDate: (vaultId: string, sessionToken: string) => {
    return performGetCall(
      `vaults/changePlanOnNextRenewalDate/${vaultId}`,
      {},
      sessionToken
    );
  },

  /**
   * Get estimates for update vault subscription
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatesubscriptionestimates
   * @param {string} vaultId
   * @param {Object} body
   * @param {string} sessionToken
   */
  getEstimatesForUpdateVaultSubscription: (
    vaultId: string,
    body: any,
    sessionToken: string
  ) => {
    return performPostCall(
      `vaults/${vaultId}/updatesubscription/estimates`,
      body,
      sessionToken
    );
  },

  /**
   * delete queued change plan on next renewal date
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_cancelchangeplanonnextrenewal
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  cancelChangePlanOnNextRenewal: (vaultId: string, sessionToken: string) => {
    return performDeleteCall(
      `vaults/cancelChangePlanOnNextRenewal/${vaultId}`,
      sessionToken
    );
  },

  /**
   * Get estimates for upcoming invoice
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_upcominginvoiceestimates
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  getEstimatesForUpcomingInvoice: (vaultId: string, sessionToken: string) => {
    return performGetCall(
      `vaults/${vaultId}/upcominginvoiceestimate`,
      {},
      sessionToken
    );
  },

  /**
   * Verify email
   * Documentation is to be generated
   * @param {String} confirmationToken
   * @param {String} vaultObjectId
   */
  verifyEmail: (confirmationToken: string, vaultObjectId: string) =>
    performPostCall(`vaults/confirmEmailAddress`, {
      token: confirmationToken,
      vaultObjectId
    }),

  /**
   * Resend confirmation email
   * Documentation is to be generated
   * @param {String} vaultId
   * @param {String} sessionToken
   */
  resendConfirmationEmail: (vaultId: string, sessionToken: string) =>
    performPostCall(
      `vaults/${vaultId}/resendConfirmationEmail`,
      {},
      sessionToken
    ),

  /**
   * Request content
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_requestadditionalcontent
   * @param {string} vaultId
   * @param {string} projectId
   * @param {string} sessionToken
   */
  requestContent: (
    vaultId: string,
    projectId: string,
    sessionToken: string
  ) => {
    return performGetCall(
      `order/requestAdditionalContent?vaultObjectId=${vaultId}&projectObjectId=${projectId}`,
      {},
      sessionToken
    );
  },

  /**
   * Check vault subscription
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_checksubscription
   */
  checkSubscription: (vaultId: string, sessionToken: string) => {
    return performGetCall(
      `vaults/${vaultId}/checksubscription`,
      {},
      sessionToken
    );
  },

  /**
   * Get all users that can access given vault
   * @param vaultId
   * @param sessionToken
   * @returns
   */
  getAllUsersThatCanAccess: (vaultId: string, sessionToken: string) => {
    return performGetCall(`vaults/${vaultId}/users`, {}, sessionToken);
  },

  /**
   * Check the VAT
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_checkvatget
   */
  checkVat: (country: string, number: string, sessionToken: string) => {
    return performGetCall(
      `/vaults/checkvat?country=${country}&number=${number}`,
      {},
      sessionToken
    );
  },

  /**
   * Get the permissions of an invitation on the vault
   * https://devapiv2.vr-tual.media/asciidoc/?_=aabbcc#_getinvitationpermissions
   */
  getPermissionsOfInvitation: (
    vaultObjectId: string,
    objectId: string,
    sessionToken: string
  ) => {
    return performGetCall(
      `vaults/${vaultObjectId}/invitations/${objectId}`,
      {},
      sessionToken
    );
  },

  /**
   * Get the permissions of a user on the vault
   * https://devapiv2.vr-tual.media/asciidoc/?_=aabbcc#_getpermissions
   */
  getPermissionOfUser: (
    vaultObjectId: string,
    objectId: string,
    sessionToken: string
  ) => {
    return performGetCall(
      `vaults/${vaultObjectId}/users/${objectId}`,
      {},
      sessionToken
    );
  },

  /**
   * Get the allowed permission roles for the vault
   * https://devapiv2.vr-tual.media/asciidoc/?_=aabbcc#_getallowedpermissionroles_1
   */
  getAllowedPermissionRoles: (vaultObjectId: string, sessionToken: string) => {
    return performGetCall(
      `vaults/${vaultObjectId}/permissionroles`,
      {},
      sessionToken
    );
  },

  /**
   * DEPRECATED
   * Search data in catalogues
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_search_1
   */
  searchDataInCatalogues: (
    entityObjectId: string,
    params: any,
    sessionToken: string
  ) => {
    return performGetCall(
      `entities/${entityObjectId}/catalogues`,
      params,
      sessionToken
    );
  },

  /**
   * Invite user to the vault
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_inviteuser_2
   */
  inviteUser: (
    vaultId: string,
    params: any,
    sessionToken: string,
    skipInvitationMail: boolean = false
  ) =>
    performPostCall(
      `vaults/${vaultId}/users?skipInvitationMail=${skipInvitationMail}`,
      params,
      sessionToken
    ),

  /**
   * Invite multiple users to the vault
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_inviteuser_2
   */
  inviteUserBulk: (
    vaultId: string,
    params: any,
    sessionToken: string,
    skipInvitationMail: boolean = false
  ) =>
    performPostCall(
      `vaults/${vaultId}/users/bulk?skipInvitationMail=${skipInvitationMail}`,
      params,
      sessionToken
    ),

  /**
   * Kick/remove a user from a vault
   * https://devapiv2.vr-tual.media/asciidoc/?_=aabbcc#_kick
   */
  kickUser: (vaultObjectId: string, objectId: string, sessionToken: string) => {
    return performDeleteCall(
      `vaults/${vaultObjectId}/users/${objectId}`,
      sessionToken
    );
  },

  /**
   * Uninvite a user, remove the invitation
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_uninviteuser_1
   */
  uninviteUser: (vaultObjectId: string, uuid: string, sessionToken: string) => {
    return performDeleteCall(
      `vaults/${vaultObjectId}/users/uninvite?invitationUuid=${uuid}`,
      sessionToken
    );
  },

  /**
   * Update the permissions for a given user on a vault
   * https://devapiv2.vr-tual.media/asciidoc/#_updateuser_1
   */
  updateUserPermissions: (
    vaultObjectId: string,
    objectId: string,
    params: VaultUpdateUserParams,
    sessionToken: string
  ) => {
    return performPutCall(
      `vaults/${vaultObjectId}/users/${objectId}`,
      params,
      sessionToken
    );
  },

  /**
   * Get vault feature flags
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getvaultfeatureflaglist
   */
  getFeatureFlags: (vaultId: string) =>
    performGetCall(`vaults/vaultFeatureFlagList/${vaultId}`, {}),

  /**
   * Get vault contact person
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getcontactperson
   */
  getContactPerson: (vaultId: string) =>
    performGetCall(`vaults/getContactPerson/${vaultId}`, {}),

  /**
   * Get portfolio settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbyvaultid
   */
  getPortfolioSettings: (vaultId: string) => {
    if (!vaultId) {
      return Promise.reject(
        new Error(
          `Can't send getMarketplaceSettings call: invalid vaultId. (vaultId=${vaultId})`
        )
      );
    }

    return performGetCall(`marketplacesettings/getByVaultId/${vaultId}`, {
      vaultObjectId: vaultId
    });
  },

  fetchAvailableCountries: () => {
    return Promise.resolve(Countries.getFilteredCountryList());
  },

  mockgetAllUsersThatCanAccess: (users?: any, options: any = {}) => {
    //@ts-ignore
    Vault.getAllUsersThatCanAccess.mockReset();
    //@ts-ignore
    return Vault.getAllUsersThatCanAccess.mockImplementation(
      (vaultObjectId: string, sessionToken: string) => {
        const shouldRejectWithError = options?.error;
        const callResult = shouldRejectWithError
          ? {
              status: options.error.httpStatus,
              data: {
                code: options.error.errorCode,
                message: options.error.errorMessage
              }
            }
          : {
              status: 200,
              data: {
                userDictionary: {
                  // For now, no need to have users with different roles, just assume all users are redOwner
                  redOwner: users
                }
              }
            };

        if (options?.timeToCompleteCall) {
          return new Promise((resolve, reject) => {
            setTimeout(
              () =>
                shouldRejectWithError
                  ? reject(callResult)
                  : resolve(callResult),
              options.timeToCompleteCall
            );
          });
        } else {
          return shouldRejectWithError
            ? Promise.reject(callResult)
            : Promise.resolve(callResult);
        }
      }
    );
  }
};
