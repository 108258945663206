import { useState, useEffect } from 'react';

import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';
import { useAppState } from 'src/store/AppStore';
import { useAuthState } from 'src/store/AuthStore';
import { Marketplace } from '@/libs/prompto-api/src';

export const provinceIdToLabelMap = {
  'West-Vlaanderen': 'WestFlanders',
  'Oost-Vlaanderen': 'EastFlanders',
  Antwerpen: 'Antwerp',
  Limburg: 'Limburg',
  'Vlaams-Brabant': 'FlemishBrabant',
  Bruxelles: 'Brussels',
  'Brabant Wallon': 'WalloonBrabant',
  Hainaut: 'Hainaut',
  Namur: 'Namur',
  Liège: 'Liege',
  Luxembourg: 'Luxemburg'
};

const provinceMapToRegionAndCountry = [
  {province: "West-Vlaanderen", region: 'Vlaams Gewest', country: 'Belgium'},
  {province: "Oost-Vlaanderen", region: 'Vlaams Gewest', country: 'Belgium'},
  {province: "Antwerpen", region: 'Vlaams Gewest', country: 'Belgium'},
  {province: "Limburg", region: 'Vlaams Gewest', country: 'Belgium'},
  {province: "Vlaams-Brabant", region: 'Vlaams Gewest', country: 'Belgium'},
  {province: "Bruxelles", region: 'Bruxelles', country: 'Belgium'},
  {province: "Brabant Wallon", region: 'Région Wallonne', country: 'Belgium'},
  {province: "Hainaut", region: 'Région Wallonne', country: 'Belgium'},
  {province: "Namur", region: 'Région Wallonne', country: 'Belgium'},
  {province: "Liège", region: 'Région Wallonne', country: 'Belgium'},
  {province: "Luxembourg", region: 'Région Wallonne', country: 'Belgium'},
]

const buildLocationDetails = (provinces: any) => {
return provinces.map((province: string) => {
    return provinceMapToRegionAndCountry.find(x => {
      return x.province === province;
    })
})
}

const buildUserSearchPreferenceCreateUpdateBody = (
  filterValues: any,
  marketplaceId?: string,
  vaultId?: string,
  activeUserSearchPreference?: any
) => {
  const createUpdateParams: any = {
    marketplaceId,
    vaultId
  };

  Object.keys(filterValues).forEach((key) => {
    switch (key) {
      case 'price': {
        createUpdateParams.minPrice = filterValues[key].values.min;
        createUpdateParams.maxPrice = filterValues[key].values.max;
        break;
      }
      case 'surface': {
        createUpdateParams.minSurface = filterValues[key].values.min;
        createUpdateParams.maxSurface = filterValues[key].values.max;
        break;
      }
      case 'numberOfBedrooms': {
        const minValue = filterValues[key].values[0];
        const maxValue =
          filterValues[key].values.length > 1
            ? filterValues[key].values[filterValues[key].values.length - 1]
            : filterValues[key].values[0];

        createUpdateParams.minBedrooms = minValue;
        createUpdateParams.maxBedrooms = maxValue;
        break;
      }
      case 'numberOfBathrooms': {
        const minValue = filterValues[key].values[0];
        const maxValue =
          filterValues[key].values.length > 1
            ? filterValues[key].values[filterValues[key].values.length - 1]
            : filterValues[key].values[0];

        createUpdateParams.minBathrooms = minValue;
        createUpdateParams.maxBathrooms = maxValue;
        break;}
      case 'location': {
        const selectedProvinces = buildLocationDetails(filterValues[key].provinceValues)
        createUpdateParams.locationDetailsList = selectedProvinces;
      }
      
    }
  });

  // We need to actively set the removed filters to 0
  if (!createUpdateParams.maxPrice && activeUserSearchPreference?.maxPrice) {
    createUpdateParams.minPrice = 0;
    createUpdateParams.maxPrice = 0;
  }

  return createUpdateParams;
};

/**
 * If there is no current "active" search preference we create a new one and set it as active
 */
const createAndSetActiveSearchPreference = (
  selectedFilterValues: any,
  sessionToken: string,
  marketplaceId: string,
  vaultId: string,
  ProjectsFiltersDispatch: any
) => {
  const createParams = buildUserSearchPreferenceCreateUpdateBody(
    selectedFilterValues,
    marketplaceId,
    vaultId
  );

  Marketplace.createMarketplaceUserSearchPreference(
    createParams,
    sessionToken
  ).then((result: any) => {
    const { data } = result;

    ProjectsFiltersDispatch({
      type: 'setActiveUserSearchPreference',
      payload: data
    });
  });
};

const updateActiveSearchPreference = (
  activeUserSearchPreference: any,
  selectedFilterValues: any,
  sessionToken: string,
  ProjectsFiltersDispatch: any
) => {
  const updateParams = buildUserSearchPreferenceCreateUpdateBody(
    selectedFilterValues,
    '',
    '',
    activeUserSearchPreference
  );

  Marketplace.updateMarketplaceUserSearchPreference(
    activeUserSearchPreference?.objectId,
    updateParams,
    sessionToken
  ).then((result: any) => {
    const { data } = result;

    ProjectsFiltersDispatch({
      type: 'setActiveUserSearchPreference',
      payload: data
    });
  });
};

const storeUserPreference = (
  updatedFilterValues: any,
  selectedFilters: any,
  activeUserSearchPreference: any,
  sessionToken: string,
  marketplaceId: string,
  vaultId: string,
  ProjectsFiltersDispatch: any
) => {
  // Only store what the user selected
  const selectedFilterValues: any = {};
  selectedFilters.forEach((x: any) => {
    if (updatedFilterValues.hasOwnProperty(x.key)) {
      selectedFilterValues[x.key] = updatedFilterValues[x.key];
    }
  });

  console.log(selectedFilters);
  console.log(selectedFilterValues);
  // No active preference, create new
  if (!activeUserSearchPreference && selectedFilters?.length > 0) {
    createAndSetActiveSearchPreference(
      selectedFilterValues,
      sessionToken,
      marketplaceId,
      vaultId,
      ProjectsFiltersDispatch
    );
  } else {
    // Active preference, update
    updateActiveSearchPreference(
      activeUserSearchPreference,
      selectedFilterValues,
      sessionToken,
      ProjectsFiltersDispatch
    );
  }
};

const useUserSearchPreferences = () => {
  const { ProjectsFiltersState, ProjectsFiltersDispatch } =
    useProjectsFiltersState();

  const {
    filterValues,
    selectedFilters,
    activeUserSearchPreference,
    userSearchPreferences
  } = ProjectsFiltersState;

  const { AuthState } = useAuthState();
  const { vaultObjectId, sessionToken } = AuthState;

  const { AppState } = useAppState();
  const { marketplaceId } = AppState;

  /**
   * Get the user search preferences
   */
  useEffect(() => {
    if (
      !userSearchPreferences &&
      vaultObjectId &&
      marketplaceId &&
      sessionToken
    ) {
      Marketplace.getAllMarketplaceUserSearchPreferences(
        {
          vaultId: vaultObjectId,
          marketplaceId
        },
        sessionToken
      ).then((result: any) => {
        const { data } = result;
        if (data?.preferenceList) {
          ProjectsFiltersDispatch({
            type: 'storeUserPreferenceList',
            payload: data.preferenceList
          });
        }
      });
    }
  }, [userSearchPreferences, vaultObjectId, marketplaceId, sessionToken]);

  useEffect(() => {
    if (filterValues && marketplaceId && vaultObjectId) {
      storeUserPreference(
        filterValues,
        selectedFilters,
        activeUserSearchPreference,
        sessionToken,
        marketplaceId,
        vaultObjectId,
        ProjectsFiltersDispatch
      );
    }
  }, [filterValues, sessionToken, marketplaceId, vaultObjectId]);

  return {};
};

export default useUserSearchPreferences;
