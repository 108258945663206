import { useEffect, useState } from 'react';

// Resources
import PromptoLogo from 'src/resources/images/prompto-logo.png';
import PromptoLogoIcon from 'src/resources/images/prompto-logo-icon.png';
import MainBackground from 'src/resources/images/register-background.png';
import GoogleLogo from 'src/resources/images/logos/google.png';
import MicrosoftLogo from 'src/resources/images/logos/microsoft.png';
import { useAuthState } from 'src/store/AuthStore';

// Helpers
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

// Components
import Button from 'src/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import { motion, AnimatePresence } from 'framer-motion';
import { forBiggerThan } from 'src/helpers/ui';
import styled, { keyframes } from 'styled-components';
import { Marketplace } from '@prompto-api';
import useSingleSignOn from 'src/hooks/use-single-sign-on';
import localizer from 'src/localization/localizer';
import env from 'src/environment';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.beigeBg10};
  display: flex;
  flex-flow: row;
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 50px;
  margin-top: 24px;

  ${forBiggerThan.tablet`
  display: block;
  margin-left: 200px;
  margin-top: 72px;
  width: 30%;
`}
`;

const LeftSideContent = styled.div`
  max-width: 420px;
`;

const RightSide = styled.div`
  display: none;

  ${forBiggerThan.tablet`
  display:block;
  width: 60%;
  padding: 50px;
`}
`;

const AnimatedBackground = styled(motion.div)`
  width: 100%;
  position: relative;
`;

const ImageSlider = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  border-radius: 0 8px 0 8px;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  max-width: 200px;
  position: relative;
  z-index: 3004; // for 1 bigger than for mobile menu

  ${forBiggerThan.laptop`
  height: 60px;
  width: auto;
  max-width: 500px;
  `}
`;

const LogoContainer = styled.div`
  cursor: pointer;
`;

const Logo = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  ${forBiggerThan.laptop`
  height: 60px;
  width: auto;
  max-width: 500px;  `}
`;

const SmallTitle = styled.div`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.black};
  margin-top: 48px;

  ${forBiggerThan.tablet`
  margin-top: 100px;
`}
`;

const Spacer = styled.div`
  margin-top: 28px;

  ${forBiggerThan.tablet`
  margin-top: 108px;
`}
`;

const Title = styled.div`
  font-size: 1.875rem;
  color: ${({ theme }) => theme.black};
  margin-top: 16px;
`;

const Accent = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-style: italic;
  font-weight: bold;
  display: inline-block;
`;

const ClickableAccent = styled(Accent)`
  cursor: pointer;
`;

const AlternativeSignInBlock = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.gray300};
  padding-bottom: 16px;
  margin-top: 48px;
`;

const AuthProviders = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

const AuthProviderButton = styled.button`
  height: 48px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary300};
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;

  span {
    font-size: 0.75rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;
    word-break: break-word;
    line-height: 1;
  }
`;

const AuthProviderLogo = styled.img`
  width: 24px;
  height: 24px;
  object-position: center;
  object-fit: contain;
  margin-right: 10px;
`;

const Label = styled.div`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.black};
  margin-top: 24px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  background: ${({ theme }) => theme.white};
  outline: transparent;
  padding: 0 44px 0 36px;
  color: ${({ theme }) => theme.gray60};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};

  &::placeholder {
    color: ${({ theme }) => theme.gray60};
  }
`;

const glareAnimation = keyframes`
  0%, 100% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0%);
  }
`;

const StyledButton = styled(Button)`
  height: 48px;
  min-width: 250px;
  grid-area: button;
  justify-self: center;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.gold60};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  margin-top: 16px;
  width: 100%;
  border-radius: 8px;

  ${forBiggerThan.laptop`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(0%);
    animation: ${glareAnimation} 10s linear infinite;
  }

  &:hover:before {
    animation: none;
  }
`}
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const JoinButton = styled(StyledButton)`
  margin-top: 35px;
`;

const GoBackButton = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.redBright};
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

const FootNote = styled.div`
  color: ${({ theme }) => theme.gray60};
  font-size: 0.75rem;
  margin-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.gray300};

  span {
    font-weight: bold;
    cursor: pointer;
  }
`;

const AlreadyHaveAccount = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: 1.125rem;
  margin-top: 24px;

  div {
    font-weight: bold;
  }
`;

const VisibilityToggler = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const emailRegExp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

const RegistrationPage = () => {
  const { AuthStateDispatch } = useAuthState();
  const navigate = useNavigate();

  const [imageExpanded, setImageExpanded] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [emailValid, setEmailValid] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const [pass1, setPass1] = useState<string>('');
  const [pass2, setPass2] = useState<string>('');

  const [pass1Hidden, setPass1Hidden] = useState<boolean>(true);
  const [pass2Hidden, setPass2Hidden] = useState<boolean>(true);

  const [registerError, setRegisterError] = useState<string>('');

  const [page, setPage] = useState<number>(0);

  const registerOAuth = (firebaseToken: string) => {
    setIsProcessing(true);
    Marketplace.registerMarketplaceUserOAuth({ firebaseToken })
      .then((result) => {
        setIsProcessing(false);
        const { data } = result;
        const { sessionToken, user } = data.userLoginResponse;

        if (sessionToken && user) {
          const { vaultLabelSettings } = user;
          const vaultForMP =
            vaultLabelSettings.defaultVaultForVaultLabel[
              'promptoMarketplace'
            ] ??
            vaultLabelSettings.allVaultsForVaultLabel[
              'promptoMarketplace'
            ][0] ??
            null;

          if (vaultForMP) {
            AuthStateDispatch({
              type: 'login',
              payload: {
                user,
                sessionToken,
                vaultObjectId: vaultForMP
              }
            });

            navigate(`/account${window.location.search}`);
          }
        }
      })
      .catch((e) => {
        if (e.response.data) {
          setRegisterError(e.response.data.message);
        }
        setIsProcessing(false);
      });
  };

  const { signInWithAltAuthProvider, currentUser } = useSingleSignOn();

  const onAltSignInSuccess = (token: string) => registerOAuth(token);

  const onAltSignInError = () => {};

  useEffect(() => {
    setTimeout(() => {
      setImageExpanded(true);
    }, 200);
  }, []);

  const onLogoClick = () => {
    navigate(`/${window.location.search}`);
  };

  const altSignInButtons = [
    {
      label: 'Google',
      logo: GoogleLogo,
      providerId: 'google.com'
    },
    {
      label: 'Microsoft',
      logo: MicrosoftLogo,
      providerId: 'microsoft.com'
    }
  ];

  const onRegisterUser = () => {
    setIsProcessing(true);
    Marketplace.registerMarketplaceUser({
      email: emailAddress,
      password: pass1,
      firstName,
      lastName
    })
      .then((result: any) => {
        setIsProcessing(false);
        const { data } = result;
        const { sessionToken, user } = data.userLoginResponse;

        if (sessionToken && user) {
          const { vaultLabelSettings } = user;
          const vaultForMP =
            vaultLabelSettings.defaultVaultForVaultLabel[
              'promptoMarketplace'
            ] ??
            vaultLabelSettings.allVaultsForVaultLabel['promptoMarketplace'][0];

          if (vaultForMP) {
            AuthStateDispatch({
              type: 'login',
              payload: {
                user,
                sessionToken,
                vaultObjectId: vaultForMP
              }
            });

            navigate(`/account${window.location.search}`);
          }
        }
      })
      .catch((e) => {
        if (e.response.data) {
          setRegisterError(e.response.data.message);
        }
        setIsProcessing(false);
      });
  };

  let content = (
    <LeftSideContent>
      <LogoWrapper>
        <LogoContainer onClick={onLogoClick}>
          <Logo src={PromptoLogo} alt="Prompto logo" />
        </LogoContainer>
      </LogoWrapper>

      <SmallTitle>{localizer.registerPage.greeting}</SmallTitle>
      <Title>
        <Accent>{localizer.registerPage.create}</Accent>{' '}
        {localizer.registerPage.anAccount}
      </Title>

      <AlternativeSignInBlock>
        <AuthProviders>
          {altSignInButtons.map((item) => {
            return (
              <AuthProviderButton
                key={item.providerId}
                onClick={() =>
                  signInWithAltAuthProvider(
                    item.providerId,
                    onAltSignInSuccess,
                    onAltSignInError
                  )
                }
              >
                <AuthProviderLogo src={item.logo} /> {item.label}
              </AuthProviderButton>
            );
          })}
        </AuthProviders>
      </AlternativeSignInBlock>
      <Label> {localizer.registerPage.alternativeLabel}</Label>
      <ButtonWrapper>
        <StyledInput
          type="text"
          name={'email'}
          value={emailAddress}
          onChange={(e: any) => {
            setEmailValid(e.target.value.match(emailRegExp));
            setEmailAddress(e.target.value);
          }}
          placeholder={localizer.registerPage.yourEmail}
        />
      </ButtonWrapper>
      {!emailValid && emailAddress?.length > 0 && (
        <ErrorMessage> {localizer.registerPage.emailError}</ErrorMessage>
      )}
      <StyledButton
        doNotScaleOnHover={true}
        onClick={() => {
          setPage(1);
        }}
        disabled={!emailValid}
      >
        {localizer.registerPage.continue}
        <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
      </StyledButton>
      <FootNote>
        {localizer.formatString(
          localizer.registerPage.pptos,
          <span
            onClick={() => {
              window.open(env().privacyPolicy, '_blank');
            }}
          >
            {localizer.registerPage.pp}
          </span>,
          <span
            onClick={() => {
              window.open(env().termsOfService, '_blank');
            }}
          >
            {localizer.registerPage.tos}
          </span>
        )}
      </FootNote>
      <AlreadyHaveAccount>
        {localizer.registerPage.alreadyAnAccount}{' '}
        <ClickableAccent
          onClick={() => {
            navigate(`/login${window.location.search}`);
          }}
        >
          {localizer.registerPage.login}
        </ClickableAccent>
      </AlreadyHaveAccount>
    </LeftSideContent>
  );

  if (page === 1) {
    content = (
      <LeftSideContent>
        <LogoWrapper>
          <LogoContainer onClick={onLogoClick}>
            <Logo src={PromptoLogo} alt="Prompto logo" />
          </LogoContainer>
        </LogoWrapper>
        <Spacer />
        <Label>{localizer.registerPage.firstName}</Label>
        <ButtonWrapper>
          <StyledInput
            type="text"
            name={'firstName'}
            value={firstName}
            onChange={(e: any) => {
              setFirstName(e.target.value);
            }}
            placeholder={localizer.registerPage.yourFirstName}
          />
        </ButtonWrapper>
        <Label>{localizer.registerPage.lastName}</Label>
        <ButtonWrapper>
          <StyledInput
            type="text"
            name={'lastName'}
            value={lastName}
            onChange={(e: any) => {
              setLastName(e.target.value);
            }}
            placeholder={localizer.registerPage.yourLastName}
          />
        </ButtonWrapper>
        <Label>{localizer.registerPage.choosePassword}</Label>
        <ButtonWrapper>
          <StyledInput
            type={pass1Hidden ? 'password' : 'text'}
            name={'password1'}
            value={pass1}
            onChange={(e: any) => {
              setPass1(e.target.value);
            }}
            placeholder={''}
          />
          <VisibilityToggler onClick={() => setPass1Hidden((prev) => !prev)}>
            <FontAwesomeIcon
              icon={['fas', pass1Hidden ? 'eye' : 'eye-slash']}
              size={'1x'}
            />
          </VisibilityToggler>
        </ButtonWrapper>
        <Label>{localizer.registerPage.repeatPassword}</Label>
        <ButtonWrapper>
          <StyledInput
            type={pass2Hidden ? 'password' : 'text'}
            name={'password2'}
            value={pass2}
            onChange={(e: any) => {
              setPass2(e.target.value);
            }}
            placeholder={''}
          />

          <VisibilityToggler onClick={() => setPass2Hidden((prev) => !prev)}>
            <FontAwesomeIcon
              icon={['fas', pass2Hidden ? 'eye' : 'eye-slash']}
              size={'1x'}
            />
          </VisibilityToggler>
        </ButtonWrapper>

        <JoinButton
          doNotScaleOnHover={true}
          onClick={() => {
            setRegisterError('');
            onRegisterUser();
          }}
          disabled={!emailValid || isProcessing || !pass1 || !pass2}
        >
          {isProcessing ? 'Processing...' : localizer.registerPage.join}
          <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
        </JoinButton>

        {registerError && <ErrorMessage>{registerError}</ErrorMessage>}
        <GoBackButton>
          <ClickableAccent
            onClick={() => {
              setPage(0);
            }}
          >
            {localizer.registerPage.goBack}
          </ClickableAccent>
        </GoBackButton>
      </LeftSideContent>
    );
  }

  return (
    <>
      <Wrapper>
        <LeftSide>{content}</LeftSide>
        <RightSide>
          <AnimatedBackground
            initial={{ width: 0, height: 0, left: '50%', top: '50%' }}
            animate={{
              width: imageExpanded ? '100%' : 0,
              height: imageExpanded ? '100%' : 0,
              left: imageExpanded ? 0 : '50%',
              top: imageExpanded ? 0 : '50%'
            }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <ImageSlider>
              <Image src={MainBackground} />
            </ImageSlider>
          </AnimatedBackground>
        </RightSide>
      </Wrapper>
    </>
  );
};

export default RegistrationPage;
