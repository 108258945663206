import { useState, useEffect, useRef, useCallback, memo } from 'react';
import ReactResizeDetector from 'react-resize-detector';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { IdealImage } from '@prompto-ui';
import ZoomIndicator from './ZoomIndicator';
import DayNightToggle from './DayNightToggle';
import OutOfViewportSpotMarkers from './OutOfViewportSpotMarkers';
import Spots from './Spots';
import Spot from './Spot';
import PinnedSpot from './PinnedSpot';
import UnitSpotTooltip from './UnitSpotTooltip';
import HideAdditionalInfoToggle from './HideAdditionalInfoToggle';
import EnlargedUsp from './EnlargedUsp';
import MobileUnitPreview from './MobileUnitPreview';
import UspSpotMobileTooltip from './UspSpotMobileTooltip';
import MediaView360 from './MediaView360';
import PolygonSpot from './polygonSpot/PolygonSpot';
import PolygonSpotTooltip from './polygonSpot/PolygonSpotTooltip';
import Fullscreen360Viewer from 'src/components/resources/contentItems/types/album360/Fullscreen360Viewer';

import { IDimensions } from '../ProjectTour';
import { IUnit, INavigationItem, IContentItem } from '@prompto-api';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import {
  capitalize,
  checkIfRenderedInIframe,
  displayLocalizedValue
} from 'src/helpers/utils';
// import { usePrevious } from '@prompto-helpers';
// import { useMobileOrientation } from 'src/hooks/use-mobile-orientation';
import useKeyPress from 'src/hooks/use-key-press';
import { isMobile } from 'react-device-detect';
import isEqual from 'lodash.isequal';
import localizer from 'src/localization/localizer';
import env from 'src/environment';
import IdleTimer from 'react-idle-timer';
import { useDataState } from 'src/store/DataStore';

// Styling
import styled, { StyledProps } from 'styled-components';

const PreviewWrapper = styled.div``;

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

const NavigateToStartMediaViewButton = styled.button`
  position: absolute;
  z-index: 10;
  top: 16px;
  left: 16px;
  min-width: 96px;
  max-width: calc(100vw - 80px);
  overflow: hidden;
  height: ${isMobile ? 40 : 50}px;
  padding: ${isMobile ? 10 : 15}px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.gray40};
    margin-left: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
`;

const HomeIcom = styled(FontAwesomeIcon)`
  margin: 0 4px;
`;

const PolygonArea = styled.svg`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Instructions = styled.div<StyledProps<{ isMobile: boolean }>>`
  position: absolute;
  z-index: 1;
  width: 360px;
  left: 50%;
  bottom: ${({ isMobile }) => (isMobile ? '60px' : '0px')};
  transform: translateX(-50%);
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 1px;
  text-align: center;
`;

const FullscreenButton = styled.div<StyledProps<{ isFullscreen?: boolean }>>`
  position: absolute;
  right: 15px;
  height: 40px;
  width: ${isMobile ? '40px' : '80px'};
  bottom: ${isMobile ? '20px' : '80px'};
  border-radius: 3px;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.5);
  font-size: ${isMobile ? 0.875 : 1}rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  z-index: 500;
`;

const FullscreenIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.white};
  opacity: 1;
  font-size: 1.25rem;
  user-select: none;
  cursor: pointer;
`;

export const CLUSTER_CLICK_ZOOM_SCALE_STEP = 0.8;
const defaultSpotColor = '#AFAFAF';
const pinnedSpotPadding = 20;

// Types
export interface IRotation {
  pitch: number;
  yaw: number;
  hfov: number;
}

interface MediaViewProps {
  className?: string;
  spotList: INavigationItem[];
  onUpdateMediaView: (spotObject: any) => void;
  viewTransition?: any;
  imageTransition: any;
  mediaView: any;
  onOpenUnitPage: (unit: IUnit, parentMediaViewId?: string) => void;
  onStartImmersion: () => void;
  onStopImmersion: () => void;
  hideTourControls: boolean;
  unitList: IUnit[];
  navigateToStartView: () => void;
  isStartView: boolean;
  hideAdditionalInfoSwitcher: boolean;
  hideAdditionalInfo: boolean;
  setHideAdditionalInfo: any;
  contentCollection: any;
  uspList: any;
  allUnitList: IUnit[];
  highlightedPolygon?: string;
  isUnitTour?: boolean;
  hidePrices: boolean;
  onToggleFullscreen?: any;
  isFullscreen?: boolean;
}

const MediaView = memo(
  ({
    className,
    spotList,
    mediaView,
    onUpdateMediaView,
    onOpenUnitPage,
    onStartImmersion,
    onStopImmersion,
    viewTransition = { duration: 0.3 },
    hideTourControls = false,
    unitList = [],
    navigateToStartView,
    isStartView,
    hideAdditionalInfoSwitcher = false,
    hideAdditionalInfo,
    setHideAdditionalInfo,
    contentCollection,
    uspList,
    allUnitList,
    highlightedPolygon,
    isUnitTour = false,
    hidePrices,
    onToggleFullscreen,
    isFullscreen
  }: MediaViewProps) => {
    const wrapperRef = useRef(null);
    const pannellumRef = useRef(null);
    const imageCenterRef = useRef({ centerX: 0, centerY: 0 });

    const [highlightedSpot, setHighlightedSpot] = useState<any>(null);
    const [highlightedUnitSpotParams, setHighlightedUnitSpotParams] =
      useState<any>(null);

    const [minZoom, setMinZoom] = useState(0.5);
    const [imageDimensions, setImageDimensions] = useState<IDimensions>();

    const [imageLoaded, setImageLoaded] = useState(false);
    const [forceDeselectSpots, setForceDeselectSpots] = useState(false);
    const [forceSpotsRender, setForceSpotsRender] = useState(false);

    const [navigationSpotList, setNavigationSpotList] = useState<any[]>([]);
    const [unitSpotList, setUnitSpotList] = useState<any[]>([]);
    const [polygonSpotList, setPolygonSpotList] = useState<any[]>([]);
    const [pulseAnimationEnabled, setPulseAnimationEnabled] =
      useState<boolean>(true);

    const [canShowNightImage, setCanShowNightImage] = useState(false);
    const [isRenderedInIframe, setIsRenderedInIframe] = useState(false);

    const [contentItemToRender, setContentItemToRender] = useState<any>();
    const [hoveredPolygon, setHoveredPolygon] = useState<any>();
    const [polygonTooltipHovered, setPolygonTooltipHovered] = useState(false);

    const [show360, setShow360] = useState<any>(false);

    const [nightMode, setNightMode] = useState(false);

    const [firstLoad, setFirstLoad] = useState(false);

    // Unit control management
    const [isUnitMobilePreviewVisible, setUnitMobilePreviewVisible] =
      useState(false);
    const [unitClickedOnMobile, setUnitClickedOnMobile] = useState(null);

    // Pinned spots
    const [pinnedSpotList, setPinnedSpotList] = useState<any[]>([]);

    // 360 viewer related
    const [viewer360Dimensions, setViewer360Dimensions] = useState();
    const [isViewer360Loaded, setViewer360Loaded] = useState(false);
    const [currentRotation, setCurrentRotation] = useState<IRotation>();

    const [isIdle, setIsIdle] = useState(false);

    const [enlargedUsp, setEnlargedUsp] = useState<any>(null);

    const [mobileUspTooltipData, setMobileUspTooltipData] = useState<any>(null);

    // Data state
    const { DataState } = useDataState();
    const { fetchedNavigationItems } = DataState;

    const is360Viewer = mediaView.contentItem?.contentItemType === 'image360';

    const tooltipHovered = useRef(false);

    // screen orientation
    // const { isLandscape } = useMobileOrientation();
    // const prevOrientation = usePrevious(isLandscape);

    const ctrlPressed = useKeyPress('Control', false, false);

    const onSpotHover = useCallback(
      (spot: any) => {
        if (!spot) {
          setHighlightedUnitSpotParams(null);
        }

        if (!spot && highlightedSpot?.objectId) return;

        setHighlightedSpot(spot);
      },
      [highlightedSpot]
    );

    useEffect(() => {
      if (imageLoaded) {
        const timer = setTimeout(() => {
          clearTimeout(timer);
          setForceSpotsRender(true);
        }, 1500);
      }
    }, [imageLoaded]);

    useEffect(() => {
      setIsRenderedInIframe(checkIfRenderedInIframe());
    }, []);

    // if there is a nightContentItem, we display the DayNightToggle component
    useEffect(() => {
      setCanShowNightImage(mediaView?.nightContentItem);
    }, [mediaView]);

    // Create unit spot list
    useEffect(() => {
      if (!mediaView) return;
      if (spotList?.length > 0 && unitList?.length > 0) {
        const unitSpots: any[] = [];

        const filteredUnitIds = unitList.map((unit) => unit.objectId);

        spotList.forEach((spot: INavigationItem) => {
          const type = spot.navigationItemType;
          if (
            type === 'unitSpot' &&
            spot.value &&
            filteredUnitIds.includes(spot.value)
          ) {
            unitSpots.push(spot);
          }
        });

        setPulseAnimationEnabled(false);
        setTimeout(() => {
          setPulseAnimationEnabled(true);
        }, 500);

        setUnitSpotList(unitSpots);
      } else {
        setUnitSpotList([]);
      }
    }, [spotList, mediaView, unitList]);

    // Create nav spot list
    useEffect(() => {
      if (!mediaView) return;
      if (spotList?.length > 0) {
        // Separate the unit spots and the navigation spots
        const navSpots: any[] = [];

        spotList.forEach((spot: INavigationItem) => {
          const type = spot.navigationItemType;
          if (
            ['navigationSpot', 'uspSpot'].includes(type) &&
            !spot.pinConfiguration?.position
          ) {
            navSpots.push(spot);
          }
        });

        setNavigationSpotList(navSpots);
      }
    }, [spotList, mediaView]);

    // Create USP spot list
    useEffect(() => {
      if (!mediaView) return;
      if (spotList?.length > 0) {
        const pinnedSpots: any[] = [];

        spotList.forEach((spot: INavigationItem) => {
          if (!!spot.pinConfiguration?.position) {
            pinnedSpots.push(spot);
          }
        });

        setPinnedSpotList(pinnedSpots);
      }
    }, [spotList, mediaView]);

    useEffect(() => {
      if (nightMode && canShowNightImage) {
        setContentItemToRender(mediaView?.nightContentItem);
      } else {
        setContentItemToRender(mediaView?.contentItem);
      }
    }, [nightMode, canShowNightImage, mediaView]);

    // Calculate polygon spots and assigned items
    useEffect(() => {
      if (spotList.length > 0) {
        // Get the list of all polygon spots
        const polygonSpots = spotList.filter(
          (spot) => spot.navigationItemType === 'polygonSpot'
        );

        const contentItemList = contentCollection?.vmContentItemList;

        // The polygon spots have certain items assigned to them.
        // All the assigne navigation items are being fetched in the ProjectTour in advance

        // make sure polygon spots still belong to current media view
        const validPolygonSpots = polygonSpots.filter((spot) =>
          mediaView.navigationCollection?.vmNavigationItemList?.some(
            (x: any) => x.objectId === spot.objectId
          )
        );

        // Only polygons with assigned items are valid
        // If no items were fetched, do nothing
        if (fetchedNavigationItems.length > 0) {
          if (validPolygonSpots.length === 0) return;

          const polygonSpotsWithAssignedItems = validPolygonSpots
            .map((spot) => {
              if (spot.assignedNavigationItemObjectId) {
                return {
                  ...spot,
                  assignedItem: fetchedNavigationItems.filter(
                    (x: any) =>
                      x?.objectId === spot.assignedNavigationItemObjectId
                  )[0]
                };
              } else {
                return null;
              }
            })
            // Filter out the polygons that have nothing assigned
            .filter((x) => x !== null);

          // Now for each type of assigned item we attach as well what we need, be it a content item or a unit
          const preparedPolygonSpots = polygonSpotsWithAssignedItems.map(
            (x: any) => {
              if (
                x.assignedItem?.navigationItemType &&
                x.assignedItem.navigationItemType !== 'mediaView'
              ) {
                if (x.assignedItem?.navigationItemType === 'unitSpot') {
                  const unit = allUnitList?.find(
                    (y: IUnit) => y.objectId === x.assignedItem.value
                  );
                  // Only add spot if unit exists or is available
                  if (unit) {
                    return { ...x, unitItem: unit };
                  }
                } else if (x.assignedItem.navigationItemType === 'uspSpot') {
                  const uspForSpot = uspList.find(
                    (y: any) => y.objectId === x.assignedItem.value
                  );
                  return { ...x, usp: uspForSpot };
                } else if (
                  x.assignedItem.navigationItemType === 'album360Spot'
                ) {
                  const spotContentItem = contentItemList.find(
                    (y: IContentItem) => y.objectId === x.assignedItem.value
                  );
                  return { ...x, contentItemToRender: spotContentItem };
                } else if (
                  x.assignedItem.navigationItemType === 'album360Spot'
                ) {
                  const spotContentItem = contentItemList.find(
                    (y: IContentItem) => y.objectId === x.assignedItem.value
                  );
                  return { ...x, contentItemToRender: spotContentItem };
                } else {
                  return null;
                }
              } else {
                const mediaViewContentItem = contentItemList.find(
                  (y: IContentItem) => y.objectId === x.assignedItem?.value
                );
                return { ...x, contentItemToRender: mediaViewContentItem };
              }
            }
          );

          setPolygonSpotList(
            preparedPolygonSpots.filter(Boolean).map((x) => {
              if (x.objectId === highlightedPolygon) {
                return {
                  ...x,
                  highlighted: true
                };
              } else {
                return x;
              }
            })
          );
        }
      }
    }, [
      contentCollection,
      spotList,
      mediaView,
      allUnitList,
      uspList,
      highlightedPolygon,
      fetchedNavigationItems
    ]);

    const enlargeUsp = useCallback(
      (event: any, uspObjectId: string, enlargedUspData: any) => {
        setEnlargedUsp({
          data: enlargedUspData,
          origin: { x: event.clientX, y: event.clientY },
          spotLocation: { x: event.clientX, y: event.clientY }
        });
      },
      []
    );

    const handleNavigationSpotClick = useCallback(
      (spotObject: any) => {
        switch (spotObject.navigationItemType) {
          default:
          case 'navigationSpot':
            onUpdateMediaView(spotObject);
            break;
          case 'album360Spot':
            if (spotObject.hasMediaView) {
              onUpdateMediaView(spotObject);
            }
            break;
        }
      },
      [onUpdateMediaView]
    );

    const createSpots = useCallback(
      (
        navList: any,
        scale: number,
        containerSize: any,
        idealImageRelativePosition?: any
      ) => {
        const spots = navList.map((spot: any, index: any) => {
          let spotProps = {
            key: `navigationSpot${index}-${spot.objectId}`,
            parentDimensions: containerSize,
            imageDimensions: imageDimensions,
            spotObject: {
              ...spot,
              color: spot?.color || defaultSpotColor
            },
            forceDeselectSpots,
            zoomScale: scale,
            onSpotHover,
            idealImageRelativePosition,
            is360Viewer,
            // @ts-ignore
            canvas: pannellumRef.current?.getViewer(),
            currentRotation,
            nightMode,
            setMobileUspTooltipData,
            mobileUspTooltipData
          };
          return (
            <Spot
              {...spotProps}
              onSpotClicked={handleNavigationSpotClick}
              spotScale={1 / scale}
              enlargeUsp={enlargeUsp}
              isHighlighted={spot?.value === highlightedSpot?.value}
              forceRender={forceSpotsRender}
              onClusterSpotClicked={() => {}}
            />
          );
        });
        return spots;
      },
      [
        enlargeUsp,
        forceDeselectSpots,
        imageDimensions,
        onSpotHover,
        handleNavigationSpotClick,
        highlightedSpot,
        forceSpotsRender,
        is360Viewer,
        currentRotation
      ]
    );

    const showUnitDetails = useCallback(
      (spotObject: any, parentMediaViewId?: string) => {
        onOpenUnitPage(spotObject?.unitItem, parentMediaViewId);
      },
      [onOpenUnitPage]
    );

    const handleUnitSpotClick = useCallback(
      (spotObject: any) => {
        if (!hideTourControls) {
          showUnitDetails(spotObject, mediaView?.objectId);
        }
      },
      [showUnitDetails, hideTourControls]
    );

    const handleMobileUnitSpotClick = useCallback(
      (spotObject: any) => {
        if (!hideTourControls) {
          setUnitClickedOnMobile(spotObject?.unitItem);
          setUnitMobilePreviewVisible(true);
        }
      },
      [hideTourControls]
    );

    const handleUnitSpotHover = useCallback(
      (spot: any) => {
        if (!hideTourControls) {
          if (spot) {
            onSpotHover(spot);
            return;
          }

          const timer = setTimeout(() => {
            clearTimeout(timer);
            if (!tooltipHovered.current) {
              onSpotHover(spot);
            }
          }, 30);
        }
      },
      [onSpotHover]
    );

    if (!mediaView?.contentItem?.contentUri) {
      return null;
    }

    const polygonSpots = (parentDimensions: any, scale: any) => {
      return polygonSpotList.map((spot, idx) => {
        if (
          !isUnitTour ||
          (isUnitTour && highlightedPolygon === spot?.objectId) ||
          spot.highlighted
        ) {
          return (
            <PolygonSpot
              key={`polygonSpot${spot?.objectId ?? idx}`}
              spot={spot}
              zoomScale={scale}
              imageSize={parentDimensions}
              isMobile={isMobile}
              onHoverPolygon={setHoveredPolygon}
              isPolygonTooltipHovered={
                spot?.assignedItem?.objectId === polygonTooltipHovered
              }
              onSpotClicked={() => {
                switch (spot.assignedItem.navigationItemType) {
                  default:
                  case 'unitSpot':
                    if (isMobile) {
                      handleMobileUnitSpotClick(spot);
                    } else {
                      showUnitDetails(spot, mediaView?.objectId);
                    }
                    break;
                  case 'mediaView':
                    onUpdateMediaView({ value: spot.assignedItem.objectId });
                    break;
                  case 'uspSpot':
                    setMobileUspTooltipData({ spot, location: {} });
                    break;
                  case 'album360Spot':
                    setShow360(spot.contentItemToRender);
                    break;
                }
              }}
              unitList={allUnitList}
              isIdle={isIdle}
              highlighted={
                highlightedPolygon === spot?.objectId || spot.highlighted
              }
              isUnitTour={isUnitTour}
            />
          );
        } else {
          return null;
        }
      });
    };
    const disableClustering = true;

    console.log(mediaView)
    console.log(contentItemToRender)

    let content = (
      <TransformWrapper
        wheel={{
          step: 100,
          disabled: isRenderedInIframe || (!isMobile && !ctrlPressed),
          touchPadEnabled: true
        }}
        options={{
          minScale: minZoom,
          maxScale: 3
        }}
        pan={{
          paddingSize: 0
        }}
        zoomIn={{ step: 5 }}
        zoomOut={{ step: 5 }}
        doubleClick={{ disabled: true }}
      >
        {/* @ts-ignore */}
        {({
          zoomIn,
          zoomOut,
          scale,
          positionX,
          positionY,
          setTransform,
          resetTransform,
          pan
        }: any) => {
          pan.disabled = scale <= 1 && isMobile;

          const idealImageRelativePosition = {
            offsetX: positionX,
            offsetY: positionY
          };
          const container = document.getElementById('imageTour');
          const parentDimensions = {
            width: container?.getBoundingClientRect().width,
            height: container?.getBoundingClientRect().height
          };

          const tourContainer = document.getElementById('tourContainer');
          const containerDimensions = {
            width: tourContainer?.getBoundingClientRect().width ?? 0,
            height: tourContainer?.getBoundingClientRect().height ?? 0
          };

          // keep zoom scale lower than 3 to compliment TransformWrapper options
          const nextScaleLevel =
            scale + CLUSTER_CLICK_ZOOM_SCALE_STEP > 3
              ? 3
              : scale + CLUSTER_CLICK_ZOOM_SCALE_STEP;

          // calculated positions for USPs pinned to the screen corners
          const pinnedSpotTop =
            (idealImageRelativePosition.offsetY * -1 + pinnedSpotPadding) /
            scale;
          const pinnedSpotLeft =
            (idealImageRelativePosition.offsetX * -1 + pinnedSpotPadding) /
            scale;
          const pinnedSpotBottom =
            (parentDimensions.height +
              idealImageRelativePosition.offsetY -
              containerDimensions.height +
              pinnedSpotPadding) /
            scale;
          const pinnedSpotRight =
            (parentDimensions.width +
              idealImageRelativePosition.offsetX -
              containerDimensions.width +
              pinnedSpotPadding) /
            scale;

          const clusterSpotClickHandler = disableClustering
            ? undefined
            : (clusterX: number, clusterY: number) => {
                setTransform(clusterX, clusterY, nextScaleLevel, 500);
              };

          return (
            <>
              {!hideTourControls && (
                <ZoomIndicator
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                  scale={scale}
                  setTransform={setTransform}
                  onStartImmersion={onStartImmersion}
                  onStopImmersion={onStopImmersion}
                  centerPosition={imageCenterRef.current}
                  resetTransform={resetTransform}
                />
              )}

              {!hideTourControls && (
                <FullscreenButton
                  onClick={() => {
                    onToggleFullscreen();

                    setTransform(0, 0, 1);
                  }}
                  isFullscreen={isFullscreen}
                >
                  <FullscreenIcon
                    icon={['far', isFullscreen ? 'compress' : 'expand']}
                    size="1x"
                  />
                </FullscreenButton>
              )}

              <TransformComponent>
                <Wrapper
                  key="imageWrapper"
                  id="imageTour"
                  ref={wrapperRef}
                  className={className}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={viewTransition}
                  onClick={(e) => {
                    setForceDeselectSpots(true);
                  }}
                >
                  <ReactResizeDetector
                    handleHeight
                    handleWidth
                    skipOnMount
                    onResize={() => {
                      console.log('resized');
                      setTimeout(() => {
                        resetTransform();
                      }, 200);
                    }}
                  />
                  {contentItemToRender && (
                    <IdealImage
                      parentType={'imageTour'}
                      key={mediaView?.objectId}
                      contentUri={contentItemToRender?.contentUri}
                      fallbackUri={contentItemToRender?.contentUri}
                      imageSize={contentItemToRender?.originalImageSize}
                      containerSize={containerDimensions}
                      mustFillParent={false}
                      imageFitFillType="fit"
                      onLoad={(iWidth: number, iHeight: number) => {
                        if (!firstLoad && imageLoaded) {
                          setTransform(
                            imageCenterRef.current.centerX,
                            imageCenterRef.current.centerY,
                            1,
                            0
                          );
                          setFirstLoad(true);
                        }

                        if (iWidth && iHeight) {
                          setImageDimensions({
                            width: iWidth,
                            height: iHeight
                          });

                          const centerPosition = {
                            centerX: (iWidth - containerDimensions.width) / -2,
                            centerY: (iHeight - containerDimensions.height) / -2
                          };
                          imageCenterRef.current = centerPosition;

                          setMinZoom(
                            Math.min(
                              containerDimensions.width / iWidth,
                              containerDimensions.height / iHeight
                            )
                          );
                        }

                        const timer = setTimeout(() => {
                          clearTimeout(timer);
                          setImageLoaded(true);
                        }, 250);

                        // if (
                        //   prevOrientation &&
                        //   isLandscape === prevOrientation
                        // ) {
                        //   setTimeout(() => {
                        //     setTransform(
                        //       imageCenter.centerX,
                        //       imageCenter.centerY,
                        //       1
                        //     );
                        //   }, 2000);
                        // }
                      }}
                      forceOriginalSize={scale > 1.5}
                      baseImageUrl={env().baseImageUrl}
                    />
                  )}
                  {parentDimensions && imageLoaded && (
                    <PolygonArea key={`polygonArea`}>
                      {polygonSpots(parentDimensions, scale)}
                    </PolygonArea>
                  )}

                  <AnimatePresence>
                    {/* RENDER NAVIGATION SPOTS */}
                    {parentDimensions &&
                      imageLoaded &&
                      !hideAdditionalInfoSwitcher &&
                      createSpots(
                        navigationSpotList.filter(
                          (x: any) => x.navigationItemType === 'navigationSpot'
                        ),
                        scale,
                        parentDimensions,
                        idealImageRelativePosition
                      )}

                    {/* RENDER USP SPOTS */}
                    {!hideAdditionalInfo &&
                      parentDimensions &&
                      imageLoaded &&
                      createSpots(
                        navigationSpotList.filter(
                          (x: any) => x.navigationItemType === 'uspSpot'
                        ),
                        scale,
                        parentDimensions,
                        idealImageRelativePosition
                      )}

                    {/* RENDER UNIT SPOTS */}
                    {unitSpotList.length > 0 &&
                      parentDimensions &&
                      imageLoaded && (
                        <Spots
                          filteredUnitSpotList={unitSpotList}
                          zoomScale={scale}
                          containerSize={parentDimensions}
                          containerDimensions={containerDimensions}
                          clusterSpotClickHandler={clusterSpotClickHandler}
                          imageDimensions={imageDimensions}
                          forceDeselectSpots={forceDeselectSpots}
                          onSpotHover={(spot: any) => {
                            if (!hideTourControls) {
                              handleUnitSpotHover(spot);
                            }
                          }}
                          handleMobileUnitSpotClick={handleMobileUnitSpotClick}
                          handleUnitSpotClick={handleUnitSpotClick}
                          idealImageRelativePosition={
                            idealImageRelativePosition
                          }
                          disableClustering={true}
                          highlightedSpot={highlightedSpot}
                          highlightedUnitSpotParams={highlightedUnitSpotParams}
                          setHighlightedUnitSpotParams={
                            setHighlightedUnitSpotParams
                          }
                          setHighlightedSpot={(spot: any) => {
                            if (!hideTourControls) {
                              setHighlightedSpot(spot);
                            }
                          }}
                          nightMode={nightMode}
                          setMobileUspTooltipData={setMobileUspTooltipData}
                          mobileUspTooltipData={mobileUspTooltipData}
                          animationEnabled={pulseAnimationEnabled}
                        />
                      )}
                  </AnimatePresence>

                  {!hideAdditionalInfo &&
                    pinnedSpotList.map((spot) => (
                      <PinnedSpot
                        key={spot.objectId}
                        spot={spot}
                        moveForward={() => {
                          setPinnedSpotList((prev) => [
                            ...prev.filter((x) => x.objectId !== spot.objectId),
                            spot
                          ]);
                        }}
                        offsets={{
                          top: Math.floor(pinnedSpotTop),
                          left: Math.floor(pinnedSpotLeft),
                          bottom: Math.floor(pinnedSpotBottom),
                          right: Math.floor(pinnedSpotRight)
                        }}
                        zoomScale={scale}
                        enlargeUsp={enlargeUsp}
                      />
                    ))}

                  {hoveredPolygon && (
                    <PolygonSpotTooltip
                      item={hoveredPolygon.spot}
                      isMobile={isMobile}
                      onUnitTooltipClicked={showUnitDetails}
                      onNavigationTooltipClicked={(spot: any) => {
                        switch (spot.assignedItem.navigationItemType) {
                          default:
                          case 'mediaView':
                            onUpdateMediaView({
                              value: spot.assignedItem.objectId
                            });
                            break;
                          case 'album360Spot':
                            setShow360(spot.contentItemToRender);
                            break;
                        }
                      }}
                      hoveredLocation={hoveredPolygon.pos}
                      parentDimensions={parentDimensions}
                      zoomScale={scale}
                      idealImageRelativePosition={idealImageRelativePosition}
                      polygonTooltipHovered={polygonTooltipHovered}
                      setPolygonTooltipHovered={setPolygonTooltipHovered}
                      is360Viewer={is360Viewer}
                      parentId={highlightedUnitSpotParams?.objectId}
                      currentRotation={currentRotation}
                      containerDimensions={containerDimensions}
                      parentMediaViewId={mediaView?.objectId}
                      hidePrices={hidePrices}
                    />
                  )}
                </Wrapper>
              </TransformComponent>

              {unitSpotList.length > 0 && parentDimensions && imageLoaded && (
                <OutOfViewportSpotMarkers
                  filteredUnitSpotList={unitSpotList}
                  scale={scale}
                  parentDimensions={parentDimensions}
                  idealImageRelativePosition={idealImageRelativePosition}
                  containerDimensions={containerDimensions}
                />
              )}
            </>
          );
        }}
      </TransformWrapper>
    );

    if (is360Viewer)
      content = (
        <MediaView360
          ref={pannellumRef}
          imageSrc={`${env().baseImageUrl}/o=true/${
            contentItemToRender?.contentUri
          }`}
          setViewer360Dimensions={setViewer360Dimensions}
          setViewer360Loaded={setViewer360Loaded}
          setCurrentRotation={setCurrentRotation}
        >
          <AnimatePresence>
            {viewer360Dimensions &&
              isViewer360Loaded &&
              createSpots(navigationSpotList, 1, viewer360Dimensions)}

            {unitSpotList.length > 0 &&
              viewer360Dimensions &&
              isViewer360Loaded && (
                <Spots
                  filteredUnitSpotList={unitSpotList}
                  zoomScale={1}
                  containerSize={viewer360Dimensions}
                  containerDimensions={viewer360Dimensions}
                  clusterSpotClickHandler={() => {}}
                  imageDimensions={viewer360Dimensions}
                  forceDeselectSpots={forceDeselectSpots}
                  onSpotHover={handleUnitSpotHover}
                  handleMobileUnitSpotClick={handleMobileUnitSpotClick}
                  handleUnitSpotClick={handleUnitSpotClick}
                  disableClustering={true}
                  highlightedSpot={highlightedSpot}
                  highlightedUnitSpotParams={highlightedUnitSpotParams}
                  is360Viewer={true}
                  currentRotation={currentRotation}
                  // @ts-ignore
                  canvas={pannellumRef.current?.getViewer()}
                  setHighlightedUnitSpotParams={setHighlightedUnitSpotParams}
                  setHighlightedSpot={setHighlightedSpot}
                  nightMode={nightMode}
                  setMobileUspTooltipData={setMobileUspTooltipData}
                  mobileUspTooltipData={mobileUspTooltipData}
                />
              )}
          </AnimatePresence>
        </MediaView360>
      );

    return (
      <>
        <IdleTimer
          timeout={1500} // 1.5 seconds
          onActive={() => {
            setIsIdle(false);
          }}
          onIdle={() => {
            setIsIdle(true);
          }}
        />
        {content}

        {show360 && (
          <Fullscreen360Viewer
            image360ContentItem={show360}
            onExit={() => {
              setShow360(null);
            }}
            hideBackButton={false}
            isEmbedded={true}
          />
        )}

        {polygonSpotList?.length > 0 && (
          <Instructions isMobile={isMobile}>
            {isMobile
              ? localizer.projectTour.polygonMobileTip
              : localizer.projectTour.polygonPCTip}
          </Instructions>
        )}

        {canShowNightImage && (
          <DayNightToggle
            initialState={nightMode}
            onToggle={(isNight) => {
              setContentItemToRender(null);
              setNightMode(isNight);
            }}
          />
        )}

        {isUnitMobilePreviewVisible && (
          <MobileUnitPreview
            unit={unitClickedOnMobile}
            onClose={() => {
              setUnitClickedOnMobile(null);
              setUnitMobilePreviewVisible(false);
              //   onSpotHover(null);
            }}
            onExploreDetailsClick={(spot) => {
              if (isFullscreen) {
                onToggleFullscreen();
              }
              showUnitDetails(spot, mediaView?.objectId);
            }}
            hidePrices={hidePrices}
          />
        )}

        <UspSpotMobileTooltip
          tooltipData={mobileUspTooltipData}
          onClose={() => setMobileUspTooltipData(null)}
        />

        {highlightedSpot?.unitItem && highlightedUnitSpotParams && (
          <PreviewWrapper>
            <UnitSpotTooltip
              shouldShow={!!(highlightedSpot && highlightedUnitSpotParams)}
              unitSpotLocation={highlightedUnitSpotParams?.spotLocation}
              unit={highlightedSpot?.unitItem}
              onClick={() => {
                if (isFullscreen) {
                  onToggleFullscreen();
                }
                setHighlightedSpot(null);
                return isMobile
                  ? handleMobileUnitSpotClick(highlightedSpot)
                  : handleUnitSpotClick(highlightedSpot);
              }}
              onTouchStart={() => {
                if (isFullscreen) {
                  onToggleFullscreen();
                }
                handleMobileUnitSpotClick(highlightedSpot);
              }}
              unitSpotAbsoluteLocation={
                highlightedUnitSpotParams?.spotAbsoluteLocation
              }
              isSharedTooltip={true}
              parentId={highlightedUnitSpotParams?.objectId}
              onMouseEnter={() => {
                tooltipHovered.current = true;
              }}
              onMouseLeave={(parentId: string) => {
                if (parentId === highlightedUnitSpotParams?.objectId) {
                  setHighlightedUnitSpotParams(null);
                }
                tooltipHovered.current = false;
              }}
              is360Viewer={is360Viewer}
              // @ts-ignore
              canvas={pannellumRef.current?.getViewer()}
              currentRotation={currentRotation}
              origin="unit"
              hidePrices={hidePrices}
            />
          </PreviewWrapper>
        )}

        {!hideAdditionalInfoSwitcher && (
          <HideAdditionalInfoToggle
            isInfoHidden={hideAdditionalInfo}
            onToggle={() => setHideAdditionalInfo(!hideAdditionalInfo)}
          />
        )}

        {!hideTourControls && (
          <NavigateToStartMediaViewButton
            onClick={() => {
              if (isStartView) return;
              navigateToStartView();
            }}
          >
            <HomeIcom icon={['fas', 'home']} size="1x" />
            {capitalize(localizer.projectTour.home)}
            {!isStartView && (
              <span>
                /{' '}
                {displayLocalizedValue(
                  mediaView.contentItem?.displayName
                    ? mediaView.contentItem.displayName.textMap
                    : mediaView.contentItem?.title?.textMap
                )}
              </span>
            )}
          </NavigateToStartMediaViewButton>
        )}

        <EnlargedUsp
          enlargedUsp={enlargedUsp}
          closeEnlargedUsp={() => setEnlargedUsp(null)}
        />
      </>
    );
  },
  (prevProps: any, nextProps: any) => {
    let noUpdate = true;

    Object.entries(prevProps).forEach(([key, value]) => {
      if (key === 'spotList') {
        if (!isEqual(prevProps[key], nextProps[key])) {
          noUpdate = false;
        }
      } else if (prevProps[key] !== nextProps[key]) {
        noUpdate = false;
      }
    });

    return noUpdate;
  }
);

export default MediaView;
