import { useProjectsState } from 'src/store/ProjectsStore';
import { useAuthState } from 'src/store/AuthStore';
import { useAppState } from 'src/store/AppStore';
import { useDataState } from 'src/store/DataStore';

import { forBiggerThan } from 'src/helpers/ui';
import { useProjectsMapState } from 'src/store/ProjectsMapStore';
import ProjectCard from 'src/pages/projectsPage/components/ProjectCard';
// Styling
import styled, { keyframes } from 'styled-components';
import ProjectsMap from 'src/pages/projectsPage/components/ProjectsMap';
import { useEffect, useState } from 'react';
import { Marketplace } from '@/libs/prompto-api/src';
import UnitCard from 'src/components/unitList/UnitCard';
import localizer from 'src/localization/localizer';
import Button from 'src/components/button/Button';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  height: 100%;
  max-width: 800px;

  ${forBiggerThan.tablet`
 padding-bottom: 100px;
`}
`;
const Title = styled.div`
  color: ${({ theme }) => theme.gray60};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  margin-bottom: 24px;
`;
const COLUMN_GAP = 32;

const FavouriteProjectWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 25px;
  column-gap: ${COLUMN_GAP}px;
  margin-bottom: 80px;

  ${forBiggerThan.tablet`
 flex-flow: row;
    flex-wrap: wrap;
`}
`;

const FavouriteProjectCard = styled.div`
  flex-basis: 45%;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const FavouriteUnitWrapper = styled.div`
  display: flex;
  flex-flow: column;

  column-gap: ${COLUMN_GAP}px;
  margin-bottom: 80px;

  ${forBiggerThan.tablet`
  flex-flow: row;
  flex-wrap: wrap;
`}
`;

const glareAnimation = keyframes`
  0%, 100% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0%);
  }
`;

const StyledButton = styled(Button)`
  height: 48px;
  max-width: 250px;
  grid-area: button;
  justify-self: center;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.gold60};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  width: 100%;
  border-radius: 8px;
  margin-top: 12px;

  ${forBiggerThan.laptop`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(0%);
    animation: ${glareAnimation} 10s linear infinite;
  }

  &:hover:before {
    animation: none;
  }
`}
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

const ProjectCardWrapper = styled(motion.div)`
  flex-basis: 100%;
  flex-grow: 1;

  ${forBiggerThan.tablet`
    // We show 2 cards on laptop, so 50% per card.
    // There will be 1 columns which we will devide amonst the 2 cards, same logic follows for other screensizes

    flex-basis: calc(50% - ${COLUMN_GAP / 2}px);
  `};
`;

const FavouritesBlock = () => {
  const { AuthState, AuthStateDispatch } = useAuthState();
  const {
    vaultObjectId,
    favouriteProjects,
    favouriteUnits,
    sessionToken,
    tempFavouriteProjects,
    tempFavouriteUnits
  } = AuthState;

  const { ProjectsState } = useProjectsState();
  const { allProjects, allVaultLogos, allProjectLogos } = ProjectsState;

  const { AppState } = useAppState();
  const { marketplaceId } = AppState;

  const { DataState } = useDataState();
  const { projects } = DataState;

  const [localLoadedProjects, setLocalLoadedProjects] = useState<any>([]);
  const [localLoadedUnits, setLocalLoadedUnits] = useState<any>([]);

  const navigate = useNavigate();

  // Get the favourites
  useEffect(() => {
    if (vaultObjectId && marketplaceId) {
      Marketplace.getAllMarketplaceProjectFavourites(
        vaultObjectId,
        marketplaceId,
        sessionToken
      ).then((result: any) => {
        if (result.data) {
          AuthStateDispatch({
            type: 'setFavouriteProjects',
            payload: result.data.projectFavoriteList
          });

          setLocalLoadedProjects(result.data.projectList);
        }
      });

      Marketplace.getAllMarketplaceUnitFavourites(
        vaultObjectId,
        marketplaceId,
        sessionToken
      ).then((result: any) => {
        if (result.data) {
          AuthStateDispatch({
            type: 'setFavouriteUnits',
            payload: result.data.unitFavoriteList
          });

          setLocalLoadedUnits(result.data.unitList);
        }
      });
    }
  }, [vaultObjectId, marketplaceId, sessionToken]);

  const onBrowseProjects = () => {
    navigate(`/projects${window.location.search}`);
  };

  const allFavouriteProjects = [...tempFavouriteProjects, ...favouriteProjects];
  const allFavouriteUnits = [...tempFavouriteUnits, ...favouriteUnits];

  return (
    <Wrapper>
      <VerticalWrapper>
        <Title>{localizer.accountPage.favouriteProjects.toUpperCase()}</Title>
        <FavouriteProjectWrapper>
          {allFavouriteProjects?.length === 0 && (
            <div>
              <div>{localizer.accountPage.noFavouriteProjects}</div>
              <StyledButton doNotScaleOnHover={true} onClick={onBrowseProjects}>
                {localizer.accountPage.browseProjects}

                <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
              </StyledButton>
            </div>
          )}
          {allFavouriteProjects?.map((project: any) => {
            let fullProject = localLoadedProjects.find(
              (x: any) => x.objectId === project.favoriteProjectId
            );

            if (!fullProject) {
              fullProject = allProjects.find(
                (x: any) => x.projectId === project.favoriteProjectId
              );
            } else {
              fullProject = {
                ...fullProject,
                projectId: fullProject.objectId,
                vaultId: fullProject.vault.objectId
              };
            }

            if (!fullProject) return;

            return (
              <ProjectCardWrapper>
                <ProjectCard
                  key={project.favoriteProjectId}
                  project={{
                    ...fullProject
                  }}
                  allVaultLogos={allVaultLogos}
                  allProjectLogos={allProjectLogos}
                />
              </ProjectCardWrapper>
            );
          })}
        </FavouriteProjectWrapper>
        <Title>{localizer.accountPage.favouriteUnits.toUpperCase()}</Title>
        <FavouriteUnitWrapper>
          {allFavouriteUnits?.length === 0 && (
            <div>
              <div>{localizer.accountPage.noFavouriteUnits}</div>
              <StyledButton doNotScaleOnHover={true} onClick={onBrowseProjects}>
                {localizer.accountPage.browseProjects}

                <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
              </StyledButton>
            </div>
          )}
          {allFavouriteUnits?.map((unit: any) => {
            const matchedUnit = localLoadedUnits.find(
              (x: any) => x.objectId === unit.favoriteUnitId
            );

            if (!matchedUnit) return;

            const fullUri = `/vault/${matchedUnit.vault.objectId}/project/${matchedUnit.parentProjectSection.objectId}/unit/${matchedUnit.objectId}${window.location.search}`;

            return (
              <UnitCard
                key={unit.objectId}
                onCardClick={() => {
                  navigate(fullUri);
                }}
                unit={matchedUnit}
                showcaseConfiguration={null}
                hidePrices={true}
              />
            );
          })}
        </FavouriteUnitWrapper>
      </VerticalWrapper>
    </Wrapper>
  );
};

export default FavouritesBlock;
