import { useState, useEffect, useCallback, useRef } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import ReactSlider from 'react-slider';
import { AnimatePresence, motion } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';
import isEqual from 'lodash.isequal';
import numeral from 'numeral';
import localizer from 'src/localization/localizer';

import { IRangeFilter } from '../UnitList';

// Styling
import styled, { StyledProps, css } from 'styled-components';

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: ${isMobileOnly ? 'flex-start' : 'center'};
  position: relative;
  z-index: 2;
  gap: ${isMobileOnly ? 0 : 12}px;
`;

const SliderWrapper = styled(motion.div)<
  StyledProps<{ allowManualRange?: boolean }>
>`
  position: absolute;
  z-index: 100;
  left: 0;
  top: 50px;
  width: 100%;

  ${({ allowManualRange, theme }) =>
    allowManualRange &&
    css`
      width: 300px;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px ${theme.gray20};
      background-color: ${theme.white};
    `}
`;

const StyledSlider = styled(ReactSlider)<StyledProps<{ isMobile: boolean }>>`
  height: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
  ${({ isMobile }) => {
    return isMobile ? 'margin: 0 20px 20px 20px;' : '';
  }}
`;

const StyledSingleValueSlider = styled(StyledSlider)`
  margin: 0;
  ${({ isMobile }) => {
    return isMobile ? 'margin: 0 20px 20px 20px;' : '';
  }}
`;

const StyledThumb = styled.div`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px ${({ theme }) => theme.gray30};
  background-color: ${({ theme }) => theme.white};
  height: 18px;
  width: 18px;
  border-radius: 50%;
  outline: none;
  cursor: grab;
`;

const StyledTrack = styled.div<
  StyledProps<{
    index?: number;
    allowManualRange?: boolean;
    isSingleValueRange?: boolean;
    displayMode?: 'single' | 'column' | 'wizard';
    disabled?: boolean;
  }>
>`
  height: 6px;
  border-radius: 4px;
  background: ${({
    index,
    theme,
    isSingleValueRange,
    displayMode,
    disabled
  }) => {
    if (disabled) return theme.gray10;

    const bg = displayMode === 'wizard' ? theme.white : theme.gray10;
    const bgForSingleValueRange = index === 1 ? bg : theme.gold;
    const bgForDoubleValueRange = index === 1 ? theme.gold : bg;

    return isSingleValueRange ? bgForSingleValueRange : bgForDoubleValueRange;
  }};
`;

const InputFieldWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  label {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.gray60};
    margin: 0 0 4px;
  }
`;

const StyledInput = styled.input<
  StyledProps<{ displayMode?: 'single' | 'column' | 'wizard' }>
>`
  height: ${({ displayMode }) => {
    let height;
    if (displayMode === 'wizard') {
      height = isMobileOnly ? 48 : 60;
    } else {
      height = isMobileOnly ? 50 : 40;
    }
    return `${height}px`;
  }};
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.gray20};
  border-radius: ${({ displayMode }) => (displayMode === 'wizard' ? 8 : 4)}px;
  font-family: ${({ theme }) => theme.fonts.DMSans} !important;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.black};
  outline: none;
  &:focus {
    border-color: #b6a36c;
  }
`;

const ValuesDivider = styled.div<
  StyledProps<{ displayMode?: 'single' | 'column' | 'wizard' }>
>`
  margin: 0 6px;
  color: ${({ theme }) => (isMobileOnly ? theme.black : theme.gray60)};
  height: ${({ displayMode }) => {
    let height;
    if (displayMode === 'wizard') {
      height = isMobileOnly ? 48 : 60;
    } else {
      height = isMobileOnly ? 50 : 40;
    }
    return `${height}px`;
  }};
  align-self: flex-end;
  display: flex;
  align-items: center;
`;

const Error = styled.span`
  margin: 5px 0 0;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.errorColor};
`;

const ValueContainer = styled.div`
  height: 40px;
  min-width: 150px;
  width: fit-content;
  padding: 0 10px;
  border-radius: 20px;
  background: ${({ theme }) => theme.beigeBg20};
  color: ${({ theme }) => theme.black};
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const RangeDisplayed = styled.div`
  display: flex;
  align-items: center;
  margin: 0 6px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0 0 0 6px;
  color: ${({ theme }) => theme.black};
  font-size: 0.75rem;
`;

const SingleValueLabel = styled.div<StyledProps<{ disabled?: boolean }>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.DMSans};

  label {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.gray60};
  }

  span {
    font-size: 0.875rem;
    color: ${({ theme, disabled }) => (disabled ? theme.gray60 : 'inherit')};
  }
`;

const formatValue = (val: any) => {
  return `${numeral(val).format()}`;
};

const InputField = ({
  label,
  value,
  updateValue,
  range,
  fieldName,
  displayMode
}: any) => {
  const [localValue, setLocalValue] = useState(formatValue(value));

  useEffect(() => {
    setLocalValue(formatValue(value));
  }, [value]);

  const validateAndUpdate = (value: any) => {
    if (!value) return;
    const deformatedValue = Number(String(value).replace(/[.,]/g, ''));
    const clampedValue = Math.max(
      Math.min(deformatedValue, range.max),
      range.min
    );
    setLocalValue(formatValue(clampedValue));
    updateValue(fieldName, clampedValue);
  };

  return (
    <InputFieldWrapper>
      {label && <label id={label}>{label}</label>}
      <StyledInput
        aria-labelledby={label}
        value={localValue}
        onChange={({ target }) => {
          setLocalValue(target.value);
        }}
        onFocus={() => {
          setLocalValue(value);
        }}
        onBlur={() => validateAndUpdate(localValue)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            validateAndUpdate(localValue);
          } else if (
            ![
              '0',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '0',
              '.'
            ].includes(event.key)
          ) {
            event.preventDefault();
          }
        }}
        displayMode={displayMode}
      />
    </InputFieldWrapper>
  );
};

// Types

interface RangeFilterProps {
  filterKey: string;
  filterObject: IRangeFilter;
  onUpdateValues: (values: { min: number; max: number }) => void;
  unitPosition?: 'pre' | 'post';
  allowManualRange?: boolean;
  enableMobileView?: boolean;
  isSingleValueRange?: boolean;
  displayMode?: 'single' | 'column' | 'wizard';
  disabled?: boolean;
}

const RangeFilter = ({
  filterKey,
  filterObject,
  onUpdateValues,
  unitPosition = 'pre',
  allowManualRange = false,
  enableMobileView = false,
  isSingleValueRange = false,
  displayMode = 'single',
  disabled = false
}: RangeFilterProps) => {
  const [localValues, setLocalValues] = useState(
    filterObject.values ?? filterObject.limits
  );

  const [showSlider, setShowSlider] = useState(false);

  const mobileView = enableMobileView || isMobileOnly;

  const showBothMinMaxValues = localValues.min !== localValues.max;

  useEffect(() => {
    setLocalValues(filterObject.values);
  }, [filterObject]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const checkOutsideClick = useCallback(
    (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current?.contains(event.target) &&
        showSlider
      ) {
        setShowSlider(false);
      }
    },
    [showSlider]
  );

  useEffect(() => {
    window.addEventListener('click', checkOutsideClick);
    return () => window.removeEventListener('click', checkOutsideClick);
  }, [checkOutsideClick]);

  // If Thumb is left occasionally, we check if local filter values
  // differ from initially given ones
  const onThumbLeave = () => {
    if (!isEqual(localValues, filterObject.values)) {
      onUpdateValues(localValues);
    }
  };

  const rangeInputs = (
    <ValueWrapper>
      <InputField
        label={
          mobileView
            ? `Min ${String(filterKey).toLowerCase()}, ${filterObject.unit}`
            : ''
        }
        fieldName={'min'}
        value={localValues.min}
        updateValue={(fieldName: string, value: any) => {
          const newValues = { ...localValues, [fieldName]: value };
          onUpdateValues(newValues);
          setLocalValues(newValues);
        }}
        range={filterObject.limits}
        displayMode={displayMode}
      />
      <ValuesDivider displayMode={displayMode}>-</ValuesDivider>
      <InputField
        label={
          mobileView
            ? `Max ${String(filterKey).toLowerCase()}, ${filterObject.unit}`
            : ''
        }
        fieldName={'max'}
        value={localValues.max}
        updateValue={(fieldName: string, value: any) => {
          const newValues = { ...localValues, [fieldName]: value };
          onUpdateValues(newValues);
          setLocalValues(newValues);
        }}
        range={filterObject.limits}
        displayMode={displayMode}
      />
    </ValueWrapper>
  );

  const slider = !isSingleValueRange ? (
    <StyledSlider
      isMobile={isMobileOnly}
      min={filterObject.limits.min}
      max={filterObject.limits.max}
      step={Math.ceil(
        (filterObject.limits.max - filterObject.limits.min) / 100
      )}
      value={[localValues.min, localValues.max]}
      onChange={(values: any) => {
        const max =
          values[1] > filterObject.limits.max
            ? filterObject.limits.max
            : Math.max(values[1], filterObject.limits.min);
        const min =
          values[0] < filterObject.limits.min
            ? filterObject.limits.min
            : Math.min(filterObject.limits.max, values[0]);

        setLocalValues({ min, max });
      }}
      disabled={disabled}
      renderTrack={(props: any, state: any) => {
        return (
          <StyledTrack
            {...props}
            index={state.index}
            allowManualRange={allowManualRange}
            displayMode={displayMode}
            disabled={disabled}
          >
            {state.valueNow}
          </StyledTrack>
        );
      }}
      renderThumb={(props: any) => {
        return (
          <StyledThumb
            {...props}
            onMouseUp={() => {
              console.log('on change 2');
              onUpdateValues(localValues);
            }}
            onTouchEnd={() => {
              console.log('on change 2');
              onUpdateValues(localValues);
            }}
            onMouseLeave={onThumbLeave}
          />
        );
      }}
    />
  ) : (
    <StyledSingleValueSlider
      isMobile={isMobileOnly}
      min={0}
      max={filterObject.limits.max}
      step={Math.ceil(filterObject.limits.max / 100)}
      value={localValues}
      onChange={(value: any) => {
        onUpdateValues(value);
        setLocalValues(value);
      }}
      disabled={disabled}
      renderTrack={(props: any, state: any) => {
        return (
          <StyledTrack
            {...props}
            index={state.index}
            allowManualRange={allowManualRange}
            isSingleValueRange={true}
            displayMode={displayMode}
            disabled={disabled}
          >
            {state.valueNow}
          </StyledTrack>
        );
      }}
      renderThumb={(props: any) => {
        return (
          <StyledThumb
            {...props}
            onMouseUp={() => onUpdateValues(localValues)}
            onMouseLeave={onThumbLeave}
          />
        );
      }}
    />
  );

  const singleValueLabel = !isSingleValueRange ? null : (
    <SingleValueLabel disabled={disabled}>
      <label>{localizer.projectsFilters.locationSearchRadius}</label>
      <span>
        {localValues.toString()} {filterObject.unit}
      </span>
    </SingleValueLabel>
  );

  if (mobileView) {
    return (
      <VerticalWrapper data-testid={`filterSlider-${String(filterKey)}`}>
        {singleValueLabel}
        {slider}
        {allowManualRange && rangeInputs}
      </VerticalWrapper>
    );
  }

  return (
    <VerticalWrapper
      data-testid={`filterSlider-${String(filterKey)}`}
      ref={wrapperRef}
    >
      <ValueWrapper>
        <ValueContainer
          onClick={() => {
            if (showBothMinMaxValues) {
              setShowSlider((show) => !show);
            }
          }}
        >
          {unitPosition === 'pre' && filterObject.unit}
          <RangeDisplayed>
            {showBothMinMaxValues ? (
              <>
                {`${numeral(localValues.min).format()}`}
                <ValuesDivider displayMode={displayMode}>-</ValuesDivider>
                {`${numeral(localValues.max).format()}`}
              </>
            ) : (
              <>{`${numeral(localValues.min).format()}`}</>
            )}
          </RangeDisplayed>
          {unitPosition === 'post' && filterObject.unit}
          {showBothMinMaxValues && (
            <StyledIcon
              icon={['far', showSlider ? 'chevron-up' : 'chevron-down']}
              size="1x"
            />
          )}
        </ValueContainer>
      </ValueWrapper>

      <AnimatePresence>
        {showSlider && (
          <SliderWrapper
            allowManualRange={allowManualRange}
            initial={{ y: -15, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -15, opacity: 0 }}
            transition={{ ease: 'linear', duration: 0.1 }}
          >
            {singleValueLabel}
            {slider}
            {allowManualRange && rangeInputs}
          </SliderWrapper>
        )}
      </AnimatePresence>
    </VerticalWrapper>
  );
};

export default RangeFilter;
