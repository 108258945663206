import { useState, useEffect, useRef } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandDirections, getDisplayDirection } from './Spot';
import UnitPreview from 'src/components/unitList/UnitPreview';
import UnitStateBadge from './UnitStateBadge';

// Helpers
import { motion } from 'framer-motion';
import { displayLocalizedValue } from 'src/helpers/utils';
import { checkUnitFieldsVisibility, getUnitPrice } from 'src/helpers/vmUnit';
import currencySymbolMap from 'currency-symbol-map';
import { zIndices } from '../projectTourHelpers';
import { getCurrentLanguage } from 'src/localization/localizer';

// Styling
import styled, { StyledProps } from 'styled-components';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { GeneralFieldType, IUnit } from '@prompto-api';

const maxTooltipWidth = 370;
const dottedLineHeight = 30;

const getOffsetInX = (displayDirectionInX: string) => {
  let horizontalOffset = '-50%';
  if (displayDirectionInX === ExpandDirections.left) {
    horizontalOffset = '-100%';
  } else if (displayDirectionInX === ExpandDirections.right) {
    horizontalOffset = '0%';
  }
  return horizontalOffset;
};

const UnitInfoPreviewWrapper = styled(motion.div).attrs(
  ({ navTransform }: any) => ({
    style: {
      top: `${navTransform?.y ?? 0}px`,
      left: `${navTransform?.x ?? 0}px`
    }
  })
)<
  StyledProps<{
    navTransform: any;
    isShown: boolean;
    tooltipWidth: number;
    isSharedTooltip: boolean;
    initialPosition: any;
    verticalExpandDirection: any;
    tooltipHeight: number;
    imperativePosition?: string;
  }>
>`
  width: ${(props) => props.tooltipWidth}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: ${(props) => (props.isShown ? 'initial' : 'none')};
  cursor: ${(props) => (props.isShown ? 'pointer' : 'default')};
  z-index: ${(props) => (props.isShown ? zIndices.spotTooltip : '1')};

  ${({
    isSharedTooltip,
    initialPosition,
    verticalExpandDirection,
    tooltipHeight,
    navTransform,
    imperativePosition
  }) => {
    if (navTransform) return '';
    if (isSharedTooltip) {
      const x = initialPosition.x;
      let y = initialPosition.y;
      if (verticalExpandDirection === ExpandDirections.top) {
        y -= tooltipHeight + 20;
      }

      return `
      left: ${x}px !important;
      top: ${y}px !important;
    `;
    } else if (!imperativePosition) {
      return verticalExpandDirection === ExpandDirections.top
        ? {
            bottom: '20px !important'
          }
        : {
            top: '20px !important'
          };
    }
  }}

  ${({ imperativePosition }) => imperativePosition}
`;

const DottedLineWrapper = styled.div<
  StyledProps<{
    withLineHoverArea: boolean;
    verticalExpandDirection: any;
    origin: string;
    horizontalExpandDirection: any;
  }>
>`
  width: ${({ withLineHoverArea }) => (!withLineHoverArea ? '3px' : '100%')};
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  border-radius: 20px;

  ${({ verticalExpandDirection, origin }) =>
    verticalExpandDirection === ExpandDirections.bottom &&
    `bottom: ${origin === 'unit' ? 30 : 50}px`};
  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.top && 'top: 40px'};

  ${(props) =>
    props.horizontalExpandDirection === ExpandDirections.right && {
      width: !props.withLineHoverArea ? '3px' : '130px',
      left: '-20px',
      paddingLeft: '20px',
      justifyContent: 'flex-start'
    }};
  ${(props) =>
    props.horizontalExpandDirection === ExpandDirections.left && {
      width: !props.withLineHoverArea ? '3px' : '130px',
      right: '-20px',
      paddingRight: '20px',
      justifyContent: 'flex-end'
    }}};
`;

const DottedLineHoverArea = styled.div<
  StyledProps<{ verticalExpandDirection: any }>
>`
  position: absolute;
  width: 150px;
  height: 75%;

  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.bottom && 'bottom: 0'}
  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.top && 'top: 0'}
`;

const DottedLineHoverAreaTwo = styled.div<
  StyledProps<{ verticalExpandDirection: any }>
>`
  position: absolute;
  width: 250px;
  height: 65%;

  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.bottom && 'bottom: 0'}
  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.top && 'top: 0'};
`;

const DottedLine = styled(motion.div)<
  StyledProps<{ verticalExpandDirection: any; yPositionCorrection: number }>
>`
  width: 2px;
  height: calc(
    100% - 10px - ${({ yPositionCorrection }) => yPositionCorrection}px
  );
  background-image: linear-gradient(
    to top,
    white 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: right;
  background-size: 2px 10px;
  background-repeat: repeat-y;

  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.bottom &&
    'margin-top: 20px'}
  ${(props) =>
    props.verticalExpandDirection === ExpandDirections.top &&
    'margin-bottom: 20px'};
`;

const UnitInfoWrapper = styled(motion.div)`
  width: 100%;
  position: relative;
  cursor: pointer;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`;

const UnitCard = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.gray20};
  background-color: ${(props) => props.theme.white};
`;

const UnitInfo = styled.div`
  font-size: 0.875rem;
  color: black;
`;

const UnitTitle = styled(UnitInfo)`
  font-size: 1rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const UnitPriceStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UnitPrice = styled(UnitInfo)`
  font-weight: 600;
  color: ${(props) => props.theme.gray50};
  background: ${(props) => props.theme.beigeBg20};
  height: 28px;
  padding: 4px 12px;
  box-sizing: border-box;
  border-radius: 14px;
  display: flex;
  align-items: center;
`;

const Price = styled(UnitInfo)`
  font-weight: 700;
  color: ${(props) => props.theme.black};
`;

const BlockWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
`;

const MainInfoBlockWrapper = styled(BlockWrapper)`
  padding-right: 0;
`;

const UnitMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  margin-left: 12px;
  gap: 2px;
`;

const UnitTypeAndPrice = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnitType = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.showcaseBlack};
  margin-bottom: 5px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const StyledPriceIcon = styled(FontAwesomeIcon)`
  font-size: 0.8rem;
  margin-right: 5px;
  color: ${(props) => props.theme.gray50};
`;

const StyledUnitTypeIcon = styled(StyledPriceIcon)``;

const StyledArrowIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  color: ${(props) => props.theme.gray30};
`;

const ArrowIconWrapper = styled.div`
  margin-left: auto;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.gray20};
  background-color: ${(props) => props.theme.white};
  cursor: pointer;
  align-self: center;
  flex-shrink: 0;
`;

const GeneralFieldsBlockWrapper = styled(BlockWrapper)`
  justify-content: center;
  padding: 0;
`;

const UnitMetaInfoWrapper = styled.div<StyledProps<{ flexBasis: number }>>`
  display: flex;
  align-items: center;
  flex-basis: ${(props) => props.flexBasis}%;
`;

const UnitMetaInfo = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: auto;
  white-space: nowrap;
`;

const UnitMetaIcon = styled(FontAwesomeIcon)`
  font-size: 0.8rem;
  margin-right: 8px;
  color: ${(props) => props.theme.gray50};
`;

const Divider = styled.div`
  width: 1px;
  height: 12px;
  margin: 0 8px;
  background-color: ${(props) => props.theme.gray20};
  flex-shrink: 0;
`;

const UnitStateTag = styled(UnitStateBadge)`
  position: absolute;
  right: 12px;
  top: -15px;
  font-size: 0.75rem;
  font-weight: bold;
  color: ${(props) => props.theme.white};
  border-radius: 20px;
  min-width: 5rem;
  height: 26px;
`;

// Types
interface UnitSpotTooltipProps {
  unitSpotLocation: any;
  unit: IUnit;
  shouldShow: boolean;
  onClick: any;
  onTouchStart: any;
  onMouseEnter: any;
  onMouseLeave: any;
  unitSpotAbsoluteLocation: any;
  isSharedTooltip?: boolean;
  parentId: string;
  withLineHoverArea?: boolean;
  origin: string;
  is360Viewer: boolean;
  canvas?: any;
  currentRotation: any;
  onSizeComputed?: (size: any) => void;
  withDottedLine?: boolean;
  imperativePosition?: string;
  hidePrices: boolean;
}

/**
 * Tooltip to display next to a unit spot containing information on the unit.
 * Depending on where this spot is on the screen, the tooltip will position itself to fit.
 */
const UnitSpotTooltip = ({
  unitSpotLocation,
  unit,
  shouldShow,
  onClick,
  onTouchStart,
  onMouseEnter,
  onMouseLeave = () => {},
  unitSpotAbsoluteLocation,
  isSharedTooltip = false,
  parentId,
  withLineHoverArea = true,
  origin = 'unit',
  is360Viewer = false,
  canvas = null,
  currentRotation,
  onSizeComputed,
  withDottedLine = true,
  imperativePosition,
  hidePrices
}: UnitSpotTooltipProps) => {
  const [tooltipHeight] = useState(115);
  const [displayDirection, setDisplayDirection] = useState({
    x: null,
    y: null
  });
  const [offsetInX, setOffsetInX] = useState<string | number>('-50%');
  const [yPositionCorrection, setYPositionCorrection] = useState(0);

  // animation related
  const [showArrow, setShowArrow] = useState(false);

  const currencySymbol = currencySymbolMap('EUR');

  let unitPrice = `${currencySymbol} ${getUnitPrice(unit)}`;

  const containerRef = useRef<HTMLDivElement>(null);

  // panellum related
  const [container, setContainer] = useState<any>();
  const [navTransform, setNavTransform] = useState<any>();

  useEffect(() => {
    if (container && is360Viewer) {
      const transform = window.getComputedStyle(container).transform;
      const splitted = transform.split(',');

      const x = splitted[12];
      const y = splitted[13];
      const z = splitted[14];

      setNavTransform({ x, y, z });
    } else {
      const base = window.document.getElementsByClassName(`spot${parentId}`);

      if (base[0]) {
        setContainer(base[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRotation, container, canvas, parentId, is360Viewer]);

  useEffect(() => {
    const displayDirection = getDisplayDirection(
      unitSpotAbsoluteLocation,
      maxTooltipWidth,
      tooltipHeight + 55, // 55 pixels for he dotted line and status badge
      window.innerWidth,
      'tourContainer'
    );

    if (displayDirection.y === 'top') {
      const tooltipIsFullyVisible =
        unitSpotAbsoluteLocation.y - dottedLineHeight - tooltipHeight > 0;
      if (!tooltipIsFullyVisible) {
        // 5px is to add a visible gap between a tooltip and a tour image border
        setYPositionCorrection(
          tooltipHeight - unitSpotAbsoluteLocation.y + dottedLineHeight + 5
        );
        // since we move the tooltip down we need to place it next to the spot
        // here we calculate a proper side position, 12 - is a radius of the spot
        displayDirection.x =
          unitSpotAbsoluteLocation.x + maxTooltipWidth / 2 + 12 >
          window.innerWidth
            ? 'left'
            : 'right';
      } else {
        setYPositionCorrection(0);
      }
    } else {
      setYPositionCorrection(0);
    }

    setDisplayDirection(displayDirection);
  }, [tooltipHeight, unitSpotLocation, unitSpotAbsoluteLocation]);

  useEffect(() => {
    setOffsetInX(getOffsetInX(displayDirection.x as unknown as string));
  }, [displayDirection]);

  useEffect(() => {
    if (!onSizeComputed) return;
    onSizeComputed({
      height: containerRef.current?.offsetHeight,
      width: maxTooltipWidth
    });
  }, [containerRef, shouldShow, onSizeComputed]);

  const [showPrice, showBedrooms, showBathrooms, showSurface] = checkUnitFieldsVisibility(
    unit,
    [
      GeneralFieldType.price,
      GeneralFieldType.numberOfBedrooms,
      GeneralFieldType.numberOfBathrooms,
      GeneralFieldType.surface
    ]
  );

  const renderMetaInfo = () => {


    const metaInfo: { icon: [IconPrefix, IconName]; info: any }[] = [
    ];

    if(showSurface){
      metaInfo.push(  {
        icon: ['far', 'vector-square'],
        info: unit.unitMetadata?.surface
          ? `${unit.unitMetadata?.surface} m²`
          : '-'
      })
    }
    if(showBedrooms){
      metaInfo.push({
        icon: ['far', 'bed'],
        info: unit.unitMetadata?.numberOfBedrooms ?? '-'
      })
    }
    if(showBathrooms){
      metaInfo.push(  {
        icon: ['far', 'bath'],
        info: unit.unitMetadata?.numberOfBathrooms ?? '-'
      })
    }

    const flexBasis = 100 / metaInfo.length;
    return metaInfo.map((item, idx) => {
      const needDivider = metaInfo.length > 1 && idx !== 0;
      return (
        <UnitMetaInfoWrapper key={idx} flexBasis={flexBasis}>
          {needDivider && <Divider />}
          <UnitMetaInfo>
            <UnitMetaIcon icon={item.icon} size="1x" />
            <UnitInfo>{item.info}</UnitInfo>
          </UnitMetaInfo>
        </UnitMetaInfoWrapper>
      );
    });
  };

  const unitTitle =
    unit?.localizedTitle?.textMap[getCurrentLanguage()] ?? unit?.title;

  return (
    <UnitInfoPreviewWrapper
      ref={containerRef}
      tooltipWidth={maxTooltipWidth}
      tooltipHeight={tooltipHeight}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => onMouseLeave(parentId)}
      initial={{
        opacity: 0,
        x: offsetInX,
        y:
          displayDirection.y === ExpandDirections.bottom
            ? 20
            : -20 + yPositionCorrection
      }}
      animate={
        shouldShow
          ? {
              opacity: 1.0,
              x: offsetInX,
              y:
                displayDirection.y === ExpandDirections.bottom
                  ? 20
                  : -20 + yPositionCorrection
            }
          : {
              opacity: 0,
              x: offsetInX,
              y:
                displayDirection.y === ExpandDirections.bottom
                  ? 20
                  : -20 + yPositionCorrection
            }
      }
      transition={{ duration: 0, type: 'tween' }}
      onClick={() => {
        onClick();
      }}
      onTouchStart={onTouchStart}
      isShown={shouldShow}
      verticalExpandDirection={displayDirection.y}
      initialPosition={unitSpotAbsoluteLocation}
      isSharedTooltip={isSharedTooltip}
      navTransform={navTransform}
      imperativePosition={imperativePosition}
    >
      {withDottedLine && (
        <DottedLineWrapper
          horizontalExpandDirection={displayDirection.x}
          verticalExpandDirection={displayDirection.y}
          withLineHoverArea={withLineHoverArea}
          origin={origin}
        >
          <DottedLine
            initial={{ opacity: 0 }}
            animate={{ opacity: showArrow ? 1 : 0 }}
            transition={{ duration: 0.1 }}
            verticalExpandDirection={displayDirection.y}
            yPositionCorrection={yPositionCorrection}
          />

          {withLineHoverArea && (
            <>
              <DottedLineHoverArea
                verticalExpandDirection={displayDirection.y}
              />
              <DottedLineHoverAreaTwo
                verticalExpandDirection={displayDirection.y}
              />
            </>
          )}
        </DottedLineWrapper>
      )}

      <UnitInfoWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        onAnimationComplete={() => setShowArrow(true)}
      >
        <UnitCard>
          <UnitStateTag unit={unit} />
          <MainInfoBlockWrapper>
            <UnitPreview unit={unit} height={92} />

            <UnitMainInfo>
              <UnitTitle>{unitTitle}</UnitTitle>
              <UnitTypeAndPrice>
                {unit?.unitType && (
                  <UnitType>
                    {unit?.unitType?.icon && (
                      <StyledUnitTypeIcon
                        icon={['far', unit.unitType.icon]}
                        size="1x"
                      />
                    )}
                    {displayLocalizedValue(unit?.unitType?.name?.textMap)}
                  </UnitType>
                )}

                {!hidePrices && unit.state !== 'SOLD' && showPrice && (
                  <UnitPriceStatus>
                    <UnitPrice>
                      {/* <StyledPriceIcon icon={['far', 'tag']} size="1x" /> */}
                      <Price>{unitPrice}</Price>
                    </UnitPrice>
                  </UnitPriceStatus>
                )}
              </UnitTypeAndPrice>

              <GeneralFieldsBlockWrapper>
                {renderMetaInfo()}
              </GeneralFieldsBlockWrapper>
            </UnitMainInfo>

            <ArrowIconWrapper>
              <StyledArrowIcon icon={['far', 'arrow-right']} size="1x" />
            </ArrowIconWrapper>
          </MainInfoBlockWrapper>
        </UnitCard>
      </UnitInfoWrapper>
    </UnitInfoPreviewWrapper>
  );
};

export default UnitSpotTooltip;
