// Styling
import styled, { StyledProps } from 'styled-components';
import { getColorForUnitState } from '../unitList/unitListHelpers';

const MainWrapper = styled.div<
  StyledProps<{
    color?: BadgeColor;
    fontSize: number;
    useUnitStateColor?: boolean;
    state?: string;
  }>
>`
  display: flex;
  align-items: center;
  width: max-content;
  height: 48px;
  padding: 17px 32px;
  border-radius: 24px;
  box-sizing: border-box;
  text-align: center;
  font-size: ${({ fontSize }) => `${fontSize}rem`};
  font-weight: bold;
  font-style: italic;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme, color }) => {
    switch (color) {
      default:
      case 'gold':
      case 'orange':
      case 'green':
        return theme.white;
      case 'beige':
      case 'gray':
        return theme.black;
    }
  }};
  background-color: ${({ color, theme, useUnitStateColor, state }) => {
    if (useUnitStateColor && state) {
      return getColorForUnitState(theme, state);
    }
    switch (color) {
      default:
      case 'gold':
        return theme.gold;
      case 'orange':
        return theme.orange;
      case 'gray':
        return theme.gray40;
      case 'green':
        return theme.unitStatus.available;
      case 'beige':
        return theme.beigeBg10;
    }
  }};
`;

export const SmallText = styled.div``;

export const enum BadgeColor {
  green = 'green',
  orange = 'orange',
  gray = 'gray',
  gold = 'gold',
  beige = 'beige'
}

export interface InformationBadgeProps {
  information: string;
  color?: BadgeColor;
  useUnitStateColor?: boolean;
  state?: string;
  fontSize?: number;
  className?: string;
  onClick?: () => void;
  dataTestId?: string;
}

const InformationBadge = ({
  information,
  color,
  useUnitStateColor,
  state,
  fontSize = 1,
  className,
  onClick,
  dataTestId = 'informationBadge'
}: InformationBadgeProps) => {
  return (
    <MainWrapper
      color={color}
      className={className}
      fontSize={fontSize}
      useUnitStateColor={useUnitStateColor}
      state={state}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {information}
      <SmallText></SmallText>
    </MainWrapper>
  );
};

export default InformationBadge;
