import React from 'react';
import { number, shape } from 'prop-types';

// Helpers
import { MotionValue, motion, useTransform } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';

// Styles
import styled, { StyledProps } from 'styled-components';

const Wrapper = styled(motion.div)<
  StyledProps<{ itemWidth: number; gap: number }>
>`
  width: ${({ itemWidth }) => itemWidth}px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  &:not(:last-child) {
    margin-right: ${({ gap }) => gap}px;
  }
`;

const EmptyWrapper = styled(Wrapper)`
  height: 85%;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.7);
  filter: blur(2px);
`;

const Container = styled(motion.div)`
  width: 100%;
  height: ${isMobileOnly ? '100%' : '85%'};
  max-height: ${isMobileOnly ? '100%' : '85%'};
  position: relative;
`;

interface MotionSliderItemProps {
  children: [];
  itemWidth: number;
  containerWidth: number;
  gap: number;
  index: number;
  offset: MotionValue<number>;
  visible: boolean;
}

const Item = ({
  children,
  itemWidth,
  containerWidth,
  gap,
  index,
  offset,
  visible
}: MotionSliderItemProps) => {
  const activeStartPosition = index * -(itemWidth + gap);
  const activeEndPosition = activeStartPosition + containerWidth;
  const opacity = useTransform(
    offset,
    [
      activeStartPosition - itemWidth,
      activeStartPosition,
      activeEndPosition - itemWidth,
      activeEndPosition
    ],
    [0.8, 1, 1, 0.8]
  );

  const scale = useTransform(
    offset,
    [
      activeStartPosition - itemWidth,
      activeStartPosition,
      activeEndPosition - itemWidth,
      activeEndPosition
    ],
    [1, 1, 1, 1]
  );

  const titleOpacity = useTransform(
    offset,
    [
      activeStartPosition - itemWidth,
      activeStartPosition,
      activeEndPosition - itemWidth,
      activeEndPosition
    ],
    [0, 1, 1, 0]
  );

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      titleOpacity
    })
  );

  if (!visible) {
    return <EmptyWrapper itemWidth={itemWidth} gap={gap} />;
  }

  return (
    <Wrapper itemWidth={itemWidth} gap={gap} style={{ opacity }}>
      <Container style={{ scale }}>{childrenWithProps}</Container>
    </Wrapper>
  );
};

Item.propTypes = {
  itemWidth: number,
  containerWidth: number,
  gap: number,
  index: number,
  offset: shape({})
};

Item.defaultProps = {
  itemWidth: 0,
  containerWidth: 0,
  gap: 0,
  index: 0,
  offset: null
};

export default Item;
