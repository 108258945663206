import { motion } from 'framer-motion';
import { ReactElement, ReactFragment } from 'react';
import styled, { StyledProps } from 'styled-components';

const StyledButton = styled(motion.button)<StyledProps<{ styles?: string }>>`
  min-width: 160px;
  width: fit-content;
  height: 48px;
  margin: 0;
  padding: 6px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.gold};
  color: ${({ theme }) => theme.white};
  font-family: inherit;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

type ButtonType = 'button' | 'reset' | 'submit' | undefined;

export interface ButtonProps {
  className?: string;
  children: ReactElement | ReactFragment | string;
  onClick?: () => void;
  type?: ButtonType;
  disabled?: boolean;
  styles?: string;
  initial?: any;
  animate?: any;
  doNotScaleOnHover?: any;
}

const Button = ({
  className,
  onClick,
  type = 'button',
  children,
  disabled,
  initial,
  animate,
  doNotScaleOnHover
}: ButtonProps) => (
  <StyledButton
    className={className}
    onClick={onClick}
    type={type}
    disabled={disabled}
    whileHover={doNotScaleOnHover ? {} : { scale: 1.05 }}
    initial={initial}
    animate={animate}
  >
    {children}
  </StyledButton>
);

export default Button;
