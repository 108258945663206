import * as React from 'react';

function SvgPlusIcon(props: any) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M16.5 7.636h-6.136V1.5a.682.682 0 00-.682-.682H8.318a.682.682 0 00-.682.682v6.136H1.5a.682.682 0 00-.682.682v1.364c0 .376.305.681.682.681h6.136V16.5c0 .376.306.681.682.681h1.364a.682.682 0 00.682-.681v-6.137H16.5a.682.682 0 00.682-.681V8.318a.682.682 0 00-.682-.682z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgPlusIcon;
