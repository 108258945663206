import {
  MultiLanguageTextField,
  performGetCall,
  performPostCall,
  performPutCall
} from '../api-helpers';

export const enum ContentItemType {
  image,
  gif,
  video,
  pdf,
  document,
  text,
  mapLocation,
  metadata,
  album360,
  floorplan,
  website,
  url,
  social,
  iFrame,
  viewItScene,
  scan,
  clean3dmodel,
  image360,
  bimModel,
  turntable
}

export const enum ContentItemState {
  published,
  unpublished,
  archived
}

export interface OriginalImageSize {
  imageWidth?: number;
  imageHeight?: number;
  width?: number;
  height?: number;
}
export interface ContentItemCreateUpdateParams {
  contentItemType: ContentItemType;
  title?: MultiLanguageTextField;
  displayName?: MultiLanguageTextField;
  description?: MultiLanguageTextField;
  thumbnailUri?: string;
  contentUri?: string;
  tagList?: Array<string>;
  uploaded?: boolean;
  contentItemState?: ContentItemState;
  originalImageSize?: OriginalImageSize;
  value?: string;
  contentCollectionIdList?: Array<string>;
}
export interface ContentItemUpdateBulkParams {
  updateParamsList?: Array<ContentItemCreateUpdateParams>;
}

export interface IContentItem {
  type: string;
  objectId: string;
  loaded: boolean;
  name: string;
  createdAt: number;
  lastModifiedAt: number;
  accessControlList: any[];
  contentItemType: string;
  pageIndex?: number;
  title: {
    textMap: {
      en?: string;
      nl?: string;
      de?: string;
      fr?: string;
    };
  };
  contentUri: string;
  tagList: string[];
  uploaded: boolean;
  contentItemState: string;
  contentCollectionList: string[];
  thumbnailUri: string;
  originalImageSize: {
    width: number;
    height: number;
  };
}

export const ContentItem = {
  /**
   * Create a content item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_1
   */
  create: (
    createParams: ContentItemCreateUpdateParams,
    sessionToken: string
  ) => {
    return performPostCall('contentitems', createParams, sessionToken);
  },

  /**
   * update a content item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_1
   */
  update: (
    contentItemObjectId: string,
    params: ContentItemCreateUpdateParams,
    sessionToken: string
  ) => {
    return performPutCall(
      `contentitems/${contentItemObjectId}`,
      params,
      sessionToken
    );
  },

  /**
   * Content item bulk update
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatebulk
   * @param {Object} params
   * @param {string} sessionToken
   */
  updateBulk: (params: ContentItemUpdateBulkParams, sessionToken: string) => {
    return performPutCall(`contentitems/bulk`, params, sessionToken);
  },

  /**
   * Get content item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_1
   */
  get: (contentItemObjectId: string, sessionToken?: string) => {
    return performGetCall(
      `contentitems/${contentItemObjectId}`,
      {},
      sessionToken
    );
  },
  /**
   * Get the history of versions of a content item
   * @param {string} contentItemId
   */
  getVersionHistory: (contentItemId: string, sessionToken: string) => {
    return performGetCall(
      `contentitemversions/getByContentItem/${contentItemId}`,
      {},
      sessionToken
    );
  },

  /**
   * Get the history of downloads of a content item
   * @param {string} contentItemId
   */
  getDownloadHistory: (contentItemId: string, sessionToken: string) => {
    return performGetCall(
      `contentitems/getHistoryOfDownloads/${contentItemId}`,
      {},
      sessionToken
    );
  }
};
