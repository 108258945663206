import React, { useReducer, createContext, useContext } from 'react';

export const ProjectsContext = createContext({
  allProjects: [],
  displayedProjects: [],
  filteredProjects: [],
  totalCount: 0,
  allVaultLogos: null,
  allProjectLogos: null,
  loading: true,
  error: false,
  projectsUnitCount: {}
});

const ProjectsStore = (props) => {
  const [ProjectsState, ProjectsStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'setProjectsData':
          return {
            ...state,
            ...action.payload
          };
        default:
          return state;
      }
    },
    {
      allProjects: [],
      displayedProjects: [],
      filteredProjects: [],
      totalCount: 0,
      allVaultLogos: null,
      allProjectLogos: null,
      loading: true,
      error: false,
      projectsUnitCount: {}
    }
  );

  return (
    <ProjectsContext.Provider value={{ ProjectsState, ProjectsStateDispatch }}>
      {props.children}
    </ProjectsContext.Provider>
  );
};

export const useProjectsState = () => useContext(ProjectsContext);

export default ProjectsStore;
