import { useEffect, useState } from 'react';

// Components
import { GoogleMap, OverlayView } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import uniqid from 'uniqid';
import { forBiggerThan } from 'src/helpers/ui';
import { useAppState } from 'src/store/AppStore';

// Styling
import mapStyling from './marketplaceMapStyling.js';
import styled, { StyledProps } from 'styled-components';
import { motion } from 'framer-motion';
import { Address } from '@/libs/prompto-api/src/index.js';

const BackdropOverlay = styled(motion.div)<
  StyledProps<{ isMapOpened: boolean }>
>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(46, 49, 56, 0.3);
  z-index: 2000;
  pointer-events: ${({ isMapOpened }) => (isMapOpened ? 'all' : 'none')};
`;

const Modal = styled(motion.div)<StyledProps<{ isMapOpened: boolean }>>`
  position: fixed;
  width: 90%;
  height: 90%;
  max-height: 720px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background-color: white;
  z-index: 2000;
  pointer-events: ${({ isMapOpened }) => (isMapOpened ? 'all' : 'none')};

  ${forBiggerThan.laptop`
  width: 960px;
    `}
`;

const MarkerBody = styled.div`
  z-index: 2;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const markerSize = 60;

const Thumbnail = styled.img`
  position: absolute;
  width: ${markerSize - 4}px;
  height: ${markerSize - 4}px;
  border: 2px solid ${({ theme }) => theme.white};
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
  z-index: 2;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 200;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
`;

const ToggleMapTypeButton = styled(motion.button)`
  position: absolute;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.gray60};
  border: 0px;
  margin: 0px;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  right: 10px;
  bottom: 185px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;
`;
const ToggleMapTypeIcon = styled(FontAwesomeIcon)`
  pointer-events: none;
  font-size: 1.125rem;
`;

export interface MapModalProps {
  onClose: () => void;
  address?: Address;
  isMapOpened: boolean;
  projectThumbnail: string;
}

const mapTypes = ['roadmap', 'satellite'];

const MapModal = ({
  onClose,
  address,
  isMapOpened,
  projectThumbnail
}: MapModalProps) => {
  const [mapInstance, setMapInstance] = useState<any>();
  const [mapTypeId, setMapTypeId] = useState(mapTypes[0]);
  const [spotLocation, setSpotLocation] = useState<any>();
  const [mapId] = useState(uniqid('map-'));

  // App state
  const { AppState } = useAppState();
  const { googleApiLoaded } = AppState;

  const defaultZoomLevel = 12;
  const minZoomLevel = 6;

  useEffect(() => {
    if (address && isMapOpened) {
      setSpotLocation({
        lat: Number(address?.latitude),
        lng: Number(address?.longitude)
      });
    }
  }, [address, isMapOpened]);

  const onMapLoad = (instance: any) => {
    instance.streetView.setVisible(false);
    setMapInstance(instance);
  };

  // todo add sattelite/street view
  const mapOptions = {
    fullscreenControl: false,
    clickableIcons: false,
    disableDefaultUI: false,
    styles: mapStyling,
    mapTypeControl: false,
    controlSize: 40,
    draggableCursor: 'default',
    gestureHandling: 'greedy',
    keyboardShortcuts: false,
    minZoom: minZoomLevel
  };

  return (
    <>
      <BackdropOverlay
        onClick={onClose}
        initial={{ opacity: 0 }}
        animate={isMapOpened ? { opacity: 1 } : { opacity: 0 }}
        isMapOpened={isMapOpened}
      />
      <Modal
        initial={{ opacity: 0 }}
        animate={isMapOpened ? { opacity: 1 } : { opacity: 0 }}
        isMapOpened={isMapOpened}
      >
        <CloseButton onClick={onClose}>
          <CloseIcon icon={['fal', 'times']} size="sm" />
        </CloseButton>
        <ToggleMapTypeButton
          onClick={() => {
            const currMapType = mapInstance.getMapTypeId();
            const nextMapTypeIdx =
              (mapTypes.findIndex((x) => x === currMapType) + 1) %
              mapTypes.length;
            const nextMapType = mapTypes[nextMapTypeIdx];
            setMapTypeId(nextMapType);
            mapInstance.setMapTypeId(nextMapType);
            setMapInstance(mapInstance);
          }}
        >
          <ToggleMapTypeIcon icon={['fas', 'layer-group']} size="1x" />
        </ToggleMapTypeButton>
        {googleApiLoaded && (
          //@ts-ignore
          <GoogleMap
            id={mapId}
            zoom={defaultZoomLevel}
            options={mapOptions}
            center={spotLocation}
            onLoad={onMapLoad}
            mapContainerStyle={{
              height: '100%',
              width: '100%',
              borderRadius: '8px'
            }}
          >
            {/* @ts-ignore */}
            <OverlayView
              position={spotLocation}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <MarkerBody>
                <Thumbnail src={projectThumbnail} />
              </MarkerBody>
            </OverlayView>
          </GoogleMap>
        )}
      </Modal>
    </>
  );
};

export default MapModal;
