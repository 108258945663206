import {
  performGetCall,
  performPutCall,
  performDeleteCall,
  performPostCall
} from '../api-helpers';

export interface GetBulkUnitsForLeadsParams {
  unitIdList: string[];
}

export interface GetSessionsGroupedByLeadIdsParams {
  vaultId: string;
  leadIdList: string[];
}

export interface ShareItemCodesByLeadIdListParams {
  leadIdList: string[];
}

export const Lead = {
  /**
   * Get all leads
   * Documentation is to be generated
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  getAll: (vaultId: string, sessionToken: string) => {
    return performGetCall(
      `lead/${vaultId}`,
      { limit: 2500, state: 'active' },
      sessionToken
    );
  },

  /**
   * Update lead
   * Documentation is to be generated
   * @param {Object} params
   * @param {string} sessionToken
   */
  update: (params: any, sessionToken: string) =>
    performPutCall('lead', params, sessionToken),

  /**
   * Archive lead
   */
  archive: (leadId: string, sessionToken: string) =>
    performDeleteCall(`lead/${leadId}`, sessionToken),

  /**
   * Create lead
   */
  create: (createParams: {
    leadCreateMarketplaceParams?: any;
    leadCreateExternalParams?: any;
    leadCreateShowcaseParams?: any;
  }) => performPostCall('lead', createParams),

  /**
   * Get bulk units for leads
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getbulkunitsforleads
   */
  getBulkUnitsForLeads: (
    vaultObjectId: string,
    sessionToken: string,
    params: GetBulkUnitsForLeadsParams
  ) =>
    performPutCall(
      `vaults/${vaultObjectId}/units/getBulkUnitsForLeads`,
      params,
      sessionToken
    ),

  /**
   * Get prompto sessions related to specified leads
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getsessionsgroupedbyleadids
   */
  getLeadsRelatedSessions: (
    sessionToken: string,
    params: GetSessionsGroupedByLeadIdsParams
  ) =>
    performPutCall(
      'promptosession/getSessionsGroupedByLeadIds',
      params,
      sessionToken
    ),

  /**
   * Get share item codes related to specified leads
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getshareitemcodesbyleadidlist
   */
  getLeadsShareItemCodes: (
    vaultObjectId: string,
    sessionToken: string,
    params: ShareItemCodesByLeadIdListParams
  ) =>
    performPutCall(
      `shareitems/getShareItemCodesByLeadIdList/${vaultObjectId}`,
      params,
      sessionToken
    )
};
