import { useState } from 'react';

import { useAuthState } from 'src/store/AuthStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import styled from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';
import { User, Vault } from '@/libs/prompto-api/src';

const Center = styled.div`
  position: fixed;
  height: 100dvh;
  width: 100dvw;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3008;
`;

export const Modal = styled.div`
  position: relative;
  padding: 24px 32px 32px 32px;
  padding-bottom: 12px;

  background-color: white;
  z-index: 2000;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;

  ${forBiggerThan.tablet`
  max-height: 520px;
  max-width: 1128px;
  border-radius: 8px;
  `}

  ${forBiggerThan.laptop`
  max-height: 820px;
  max-width: 1128px;
  border-radius: 8px;
  `}
`;

const BackdropOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(46, 49, 56, 0.3);
  z-index: 3008;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  margin-bottom: 24px;
`;

const Label = styled.div`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.gray60};
  margin-bottom: 4px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: solid 1px #e3e5e8;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  background: ${({ theme }) => theme.white};
  outline: transparent;
  padding: 14px 12px;

  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  margin-bottom: 24px;

  &::placeholder {
    color: ${({ theme }) => theme.gray60};
  }
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 24px;
`;

const CloseButton = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 12px;
  right: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  font-size: 1.5rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row;
  padding: 16px 16px 16px 25px;
  box-shadow: inset 0 1px 0 0 #e3e5e8;
  justify-content: space-between;
`;

const CancelButton = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
  user-select: none;
  cursor: pointer;
`;

const SaveButton = styled.div`
  border-radius: 8px;
  background-color: #000;
  color: white;
  width: 160px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;

//const emailRegExp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

const EditProfileModal = ({ onClose }: any) => {
  const { AuthState, AuthStateDispatch } = useAuthState();
  const { user, vault, sessionToken } = AuthState;

  const [localFirstName, setLocalFirstName] = useState<string>(user?.firstName);
  const [localLastName, setLocalLastName] = useState<string>(user?.lastName);
  //const [localEmail, setLocalEmail] = useState<string>(user?.email);
  const [localZipcode, setLocalZipcode] = useState<string>(
    vault?.billingAddress?.zipCode
  );
  const [localCity, setLocalCity] = useState<string>(
    vault?.billingAddress?.city
  );
  const [localPhone, setLocalPhone] = useState<string>(user?.phoneNumber);
  //const [emailValid, setEmailValid] = useState<boolean>(false);

  const onSaveProfile = () => {
    const userUpdateParams: any = {};
    if (localFirstName !== user.firstName) {
      userUpdateParams.firstName = localFirstName;
    }
    if (localLastName !== user.lastName) {
      userUpdateParams.lastName = localLastName;
    }
    if (localPhone !== user.phoneNumber) {
      userUpdateParams.phoneNumber = localPhone;
    }

    User.update(user.objectId, userUpdateParams, sessionToken).then(
      (result: any) => {
        AuthStateDispatch({
          type: 'setUser',
          payload: result.data.user
        });

        onClose();
      }
    );

    const vaultUpdateParams: any = { account: { email: user.email } };
    if (localZipcode) {
      vaultUpdateParams.account.zipCode = localZipcode;
    }
    if (localCity) {
      vaultUpdateParams.account.city = localCity;
    }

    if (vaultUpdateParams) {
      Vault.update(vault.objectId, vaultUpdateParams, sessionToken).then(
        (result: any) => {
          if (result.data) {
            AuthStateDispatch({
              type: 'setVault',
              payload: result.data.vault
            });

            onClose();
          }
        }
      );
    }
  };

  return (
    <>
      <BackdropOverlay />
      <Center>
        <Modal>
          <CloseButton onClick={onClose}>
            <CloseIcon icon={['fal', 'times']} size="sm" />
          </CloseButton>
          <Title>Edit Profile</Title>
          <HorizontalWrapper>
            <VerticalWrapper>
              <Label>First name</Label>
              <StyledInput
                id="firstName"
                type="text"
                value={localFirstName}
                onChange={(e: any) => {
                  setLocalFirstName(e.target.value);
                }}
                placeholder={'First name'}
                maxLength={24}
                required={true}
              />
            </VerticalWrapper>

            <VerticalWrapper>
              <Label>Last name</Label>

              <StyledInput
                id="lastName"
                type="text"
                value={localLastName}
                onChange={(e: any) => {
                  setLocalLastName(e.target.value);
                }}
                placeholder={'Last name'}
                maxLength={24}
                required={true}
              />
            </VerticalWrapper>
          </HorizontalWrapper>
          <HorizontalWrapper>
            <VerticalWrapper>
              <Label>Zipcode</Label>
              <StyledInput
                id="zipCode"
                type="text"
                value={localZipcode}
                onChange={(e: any) => {
                  setLocalZipcode(e.target.value);
                }}
                placeholder={'Zipcode'}
                maxLength={12}
              />
            </VerticalWrapper>

            <VerticalWrapper>
              <Label>City</Label>
              <StyledInput
                id="city"
                type="text"
                value={localCity}
                onChange={(e: any) => {
                  setLocalCity(e.target.value);
                }}
                placeholder={'City'}
                maxLength={12}
              />
            </VerticalWrapper>
          </HorizontalWrapper>
          {/* <Label>Email</Label>
          <StyledInput
            id="email"
            type="text"
            value={localEmail}
            onChange={(e: any) => {
              setEmailValid(e.target.value.match(emailRegExp));
              setLocalEmail(e.target.value);
            }}
            placeholder={'Your email address'}
            maxLength={64}
          /> */}

          <Label>Phone number</Label>
          <StyledInput
            id="phone"
            type="tel"
            value={localPhone}
            onChange={(e: any) => {
              setLocalPhone(e.target.value);
            }}
            placeholder={'Phone number'}
            maxLength={16}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            required
          />
          <ButtonWrapper>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SaveButton onClick={onSaveProfile}>Save</SaveButton>
          </ButtonWrapper>
        </Modal>
      </Center>
    </>
  );
};

export default EditProfileModal;
