import { useState, useEffect, forwardRef } from 'react';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledPolygon = styled(motion.polygon)`
  cursor: pointer;
`;

const convertRelativeLocation = (
  x: number,
  y: number,
  parentDimensions: any,
  zoomScale: number
) => {
  // Get parent image size and container size
  const parentWidth = parentDimensions?.width || 0;
  const parentHeight = parentDimensions?.height || 0;

  // Calculate the half size of the image and the offset of the marker according to the center of the image
  const parentHalfWidth = parentWidth / 2.0;
  const parentHalfHeight = parentHeight / 2.0;

  const centerOffsetX = parentHalfWidth * x;
  const centerOffsetY = parentHalfHeight * y;

  // Add everything together to get the spot location according to the top left corner of the screen
  let cornerOffsetX = (centerOffsetX + parentHalfWidth) / zoomScale;
  let cornerOffsetY = (centerOffsetY + parentHalfHeight) / zoomScale;

  cornerOffsetX = Math.ceil(cornerOffsetX);
  cornerOffsetY = Math.ceil(cornerOffsetY);

  return { x: cornerOffsetX, y: cornerOffsetY };
};

const drawPolygon = (coords: any, imageSize: any, zoomScale: number) => {
  if (coords.length === 0) return;
  let points = '';
  coords.forEach((coord: any, i: any) => {
    const { x, y } = convertRelativeLocation(
      coord.x,
      coord.y,
      imageSize,
      zoomScale
    );
    const stringifiedCoord = `${x} ${y}`;
    points += `${i > 0 ? ', ' : ''}${stringifiedCoord}`;
  });
  return points;
};

interface PolygonProps {
  coords: any;
  fill: string;
  imageSize: any;
  zoomScale: number;
  objectId: string;
  onMouseDown: any;
  onMouseEnter: any;
  onMouseLeave: any;
  onTouchEnd: any;
  isHovered: boolean;
  isIdle: boolean;
  highlighted: boolean;
  defaultFillOpacity: number;
  isUnitTour?: boolean;
}

const Polygon = forwardRef(
  (
    {
      coords,
      fill,
      imageSize,
      zoomScale,
      objectId,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onTouchEnd,
      isHovered,
      isIdle,
      highlighted,
      defaultFillOpacity = 0.5,
      isUnitTour = false
    }: PolygonProps,
    ref: any
  ) => {
    const [stringifiedCoords, setStringifiedCoords] = useState<any>('');
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
      setStringifiedCoords(drawPolygon(coords, imageSize, zoomScale));
    }, [coords, imageSize, zoomScale]);

    return (
      <StyledPolygon
        initial={{ opacity: 0 }}
        animate={
          isUnitTour
            ? { opacity: 1 }
            : isIdle && !(hovered || isHovered)
            ? { opacity: defaultFillOpacity }
            : { opacity: 1 }
        }
        transition={!isUnitTour ? { duration: 2.5 } : { duration: 0.25 }}
        key={`polygon${objectId}`}
        xmlns="http://www.w3.org/2000/svg"
        points={stringifiedCoords}
        stroke={fill}
        strokeWidth={highlighted ? 2 : 1}
        strokeOpacity={highlighted ? 0.75 : defaultFillOpacity}
        fill={fill}
        fillOpacity={
          highlighted ? 0.75 : hovered || isHovered ? 0.75 : defaultFillOpacity
        }
        onMouseEnter={() => {
          if (isUnitTour) return;
          setHovered(true);
          onMouseEnter();
        }}
        onMouseLeave={() => {
          if (isUnitTour) return;
          setHovered(false);
          onMouseLeave();
        }}
        onMouseDown={onMouseDown}
        onTouchEnd={onTouchEnd}
        ref={ref}
      />
    );
  }
);

export default Polygon;
