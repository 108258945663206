import React from 'react';
import { useDropzone } from 'react-dropzone';
import localizer from 'src/localization/localizer';
import Button from 'src/components/button/Button';
import { motion } from 'framer-motion';

import styled, { StyledProps } from 'styled-components';

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: 50% 60%;
  object-fit: cover;
`;

const Text = styled.div`
  font-size: 0.875rem;
  padding: 0;
  margin: 0;
`;

const TextOr = styled(Text)`
  padding: 0;
`;

const BrowseOption = styled.div`
  display: flex;
`;

const ImageDropPlace = styled(motion.div)<
  StyledProps<{ active?: boolean; size?: number }>
>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const SelectedImage = styled(Image)``;

const DropZone = styled.div<StyledProps<{ active?: boolean }>>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.whiteOff};
  border-radius: 8px;
  cursor: pointer;
  flex-direction: column;
  position: absolute !important;
  top: 0;
`;

const DropZoneWithImage = styled(DropZone)`
  opacity: 0;
  &:hover {
    opacity: 1;
    background: ${({ theme }) => theme.fadeWhite};
  }
`;

export interface DropZoneComponentProps {
  size?: any;
  files: any;
  fileAsDataUrl?: string;
  onDrop: any;
  onCancel: any;
  accept: any;
  enabled?: boolean;
  onHoverStart?: any;
  onHoverEnd?: any;
  onClick?: any;
}

const DropZoneComponent = ({
  size,
  files,
  fileAsDataUrl,
  enabled = true,
  onHoverStart,
  onHoverEnd,
  onClick,
  ...otherProps
}: DropZoneComponentProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...otherProps
  });

  const text = () => {
    if (isDragActive) {
      return <Text>{'let go'}</Text>;
    }

    return (
      <>
        <Text>{'Drag & Drop'}</Text>
        <TextOr>{'Or'}</TextOr>
        <BrowseOption>Click to browse</BrowseOption>
      </>
    );
  };

  const dropZoneContent = (
    <>
      <input {...getInputProps()} />
      {text()}
    </>
  );

  const cardActive: boolean =
    (isDragActive || files.length > 0 || fileAsDataUrl !== undefined) ?? false;

  const dropzone = (
    <DropZoneWithImage {...getRootProps()} active={cardActive}>
      {dropZoneContent}
    </DropZoneWithImage>
  );

  return (
    <ImageDropPlace
      active={cardActive}
      size={size}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      onClick={onClick}
    >
      {(files.length > 0 || fileAsDataUrl) && (
        <SelectedImage src={fileAsDataUrl} />
      )}

      {enabled && dropzone}
    </ImageDropPlace>
  );
};

export default DropZoneComponent;
