export const zIndices = {
  regularSpot: 105,
  regularSpotHovered: 110,
  pinnedOrCustomImageSpot: 103,
  pinnedOrCustomImageSpotHovered: 104,
  cornerPinnedUsp: 105,
  cornerPinnedUspHovered: 110,
  spotEndOfLine: 100,
  spotTooltip: 105
};
