import env from 'src/environment';
import urlJoin from 'url-join';
import to from 'await-to-js';
import axios from 'axios';
import uuid from 'uuid';

/**
 * Uploads an image to a certain destination
 * @param {object} file - The uploaded file object
 * @param {string} bucketId - The Google Storage bucket's name
 * @param {string} bucketPath - The Google Storage bucket's path
 * @param {string} authToken - The Google Storage's auth token
 * @param {string} vaultId - The vault id
 *
 * @returns {Object} object with uploaded file data
 */

export default function uploadFileToBucketCallback(
  file,
  bucketId,
  bucketPath,
  authToken,
  vaultId,
  needPreview = false
) {
  const uploadURI = `https://www.googleapis.com/upload/storage/v1/b/${bucketId}/o?uploadType=multipart&predefinedAcl=publicRead`;
  const prepareURI = `${env().baseImageUrl}/prep=gallery-set`;

  // Create the config
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  const doPrepCall = async (name) => {
    const [prepError] = await to(axios.get(urlJoin(prepareURI, name)));
    return prepError;
  };

  const pushVideoToCloudinary = async (url) => {
    const [prepError] = await to(axios.get(url));
    return prepError;
  };

  const pushPdfToCloudinary = async (url) => {
    const [prepError] = await to(axios.get(url));
    return prepError;
  };

  const doCall = async () => {
    const contentType = file.type || 'application/octet-stream';
    const fileExtension = file.name.split('.').pop().toLowerCase();

    const fileName = `vaults/${vaultId}/${bucketPath}/${uuid.v4()}.${fileExtension}`;

    let data = new FormData();
    data.append(
      'metadata',
      new Blob(
        [
          JSON.stringify({
            'Content-Type': 'application/json',
            'Content-Disposition': `attachment; filename="${file.name}"`,
            name: fileName,
            mimeType: 'Document' //contentType
          })
        ],
        { type: 'application/json' }
      )
    );
    data.append('body', new Blob([file], { type: contentType }));

    const [error, result] = await to(axios.post(uploadURI, data, config));
    if (error) {
      return error;
    }
    if (result) {
      return result;
    }
  };

  return doCall();
}
