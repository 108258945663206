import { performPutCall } from '../api-helpers';
import { UnitState } from '../prompto-objects';

export interface TrackUserActivityParams {
  sessionId: string;
  action: string;
}

export interface TrackContentUploadParams {
  sessionId: string;
  projectObjectId?: string;
  vaultObjectId: string;
  unitObjectId?: string;
  contentItemIdList: string;
}

export interface TrackUnitStateUpdateParams {
  vaultId: string;
  projectId: string;
  unitId: string;
  oldValue: UnitState;
  newValue: UnitState;
  sessionObjectId: string;
}

export interface TrackUnitFloorplanUpdateParams {
  vaultId: string;
  projectId: string;
  unitId: string;
  floorPlanContentItemId: string;
  oldValue: string;
  newValue: string;
  sessionObjectId: string;
}

export interface TrackMarketplaceProjectVisitParams {
  visitorId: string;
  sessionId: string;
  projectObjectId: string;
  vaultObjectId: string;
  marketplaceId: string;
}

export interface TrackMarketplaceUnitVisitorParams {
  visitorId: string;
  sessionId: string;
  projectObjectId: string;
  vaultObjectId: string;
  unitObjectId?: string;
}

export interface TrackMarketplaceActionParams {
  visitorId: string;
  sessionId: string;
  marketplaceId: string;
  actionName: string;
  targetPage?: string;
  filterLongitude?: number;
  filterLatitude?: number;
  filterRadius?: number;
  filterProvinceList?: string[];
}

export type GetInTouchActionNameType =
  | 'buttonClicked'
  | 'formInteracted'
  | 'formClosed'
  | 'formSubmitted';

export type GetInTouchLocationOnPageType =
  | 'centerProjectPage'
  | 'bottomProjectPage'
  | 'navigationBarProjectPage'
  | 'navigationBarUnitPage'
  | 'centerUnitPage';

export interface TrackGetInTouchBasicParams {
  visitorId: string;
  sessionId: string;
  projectObjectId: string;
  vaultObjectId: string;
  unitObjectId?: string;
  marketplaceObjectId?: string;
}

interface TrackGetInTouchInteractionParams extends TrackGetInTouchBasicParams {
  actionName: GetInTouchActionNameType;
  locationOnPage: GetInTouchLocationOnPageType;
}

export const enum UnitActionName {
  unitTitleUpdate,
  unitPriceUpdate,
  unitNumberOfBedroomsUpdate,
  unitNumberOfBathroomsUpdate,
  unitDescriptionUpdate,
  unitSurfaceUpdate,
  unitReferenceUpdate,
  unitExternalLinkUpdate,
  unitCRMIdUpdate
}

export interface TrackUnitInfoUpdatePrams {
  actionName: UnitActionName;
  vaultId: string;
  projectId: string;
  unitId: string;
  oldValue: string;
  newValue: string;
  sessionObjectId: string;
}

export interface TrackProjectOpenParams {
  sessionId: string;
  projectObjectId: string;
  vaultObjectId: string;
}

export interface TrackMediaDownloadingParams {
  sessionId: string;
  action: string;
  vaultId: string;
  projectId?: string;
  contentItemIdList: Array<string>;
  zipFileSize?: string;
  sectionId?: string;
}

export interface TrackSharedCollectionPageOpened {
  sessionId: string;
  sharedCollectionId: string;
}

export interface TrackSingleItemFromSharedCollectionViewed {
  sessionId?: string;
  vaultId: string;
  contentItemId: string;
}

export interface TrackShowcaseUnitVisitor {
  actionName: string;
  visitorId?: string;
  vaultId?: string;
  projectId?: string;
  unitId?: string;
  shareCode?: string;
  sessionObjectId?: string;
}

export interface TrackFinishLoadingShowcaseParams {
  visitorId: string;
  sessionId: string;
  shareCode: string;
}

export const Tracking = {
  /**
   * User simple actions tracking
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackuseractivity
   * @param {string} sessionToken
   * @param {{sessionId: string, action: string}} params
   */
  trackUserSimpleAction: (
    params: TrackUserActivityParams,
    sessionToken: string
  ) => performPutCall(`track/trackUserActivity`, params, sessionToken),

  /**
   * track when user uploads files
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackcontentupload
   * @param {object} params
   * @param {string} body.sessionId
   * @param {string} body.projectObjectId
   * @param {string} body.vaultObjectId
   * @param {string=} body.unitObjectId
   * @param {string[]} body.contentItemIdList
   * @param {string} sessionToken
   */
  trackContentUpload: (
    params: TrackContentUploadParams,
    sessionToken: string
  ) => performPutCall(`track/trackContentUpload`, params, sessionToken),

  /**
   * track when user downloads files
   * TODO: add link to documentation
   * @param {object} params
   * @param {string} body.action
   * @param {string} body.sessionId
   * @param {string} body.vaultId
   * @param {string} body.projectId
   * @param {string[]=} body.contentItemIdList
   * @param {string=} body.zipFileSize
   * @param {string} sessionToken
   */
  trackContentDownload: (
    params: TrackMediaDownloadingParams,
    sessionToken: string
  ) =>
    performPutCall(
      `track/trackPortalMediaDownloading`,
      params,
      sessionToken
    ).catch(() => console.warn(`Failed to track ${params.action}`)),

  /**
   * Track unit state update
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackunitstateupdate
   * @param {string} sessionToken
   * @param {Object} params
   */
  trackUnitStateUpdate: (
    sessionToken: string,
    params: TrackUnitStateUpdateParams
  ) => performPutCall(`track/trackUnitStateUpdate`, params, sessionToken),

  /**
   * Track unit floorplan update
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackunitfloorplanupdate
   * @param {string} sessionToken
   * @param {Object} params
   */
  trackUnitFloorplanUpdate: (
    sessionToken: string,
    params: TrackUnitFloorplanUpdateParams
  ) => performPutCall(`track/trackUnitFloorplanUpdate`, params, sessionToken),

  /**
   * Track unit info update
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackunitinfoupdate
   * @param {string} sessionToken
   * @param {Object} params
   */
  trackUnitInfoUpdate: (
    sessionToken: string,
    params: TrackUnitInfoUpdatePrams
  ) => performPutCall(`track/trackUnitInfoUpdate`, params, sessionToken),

  /**
   * Track when user opens a project
   * TODO: add link to documentation
   * @param {{ sessionId: string, projectObjectId: string, vaultObjectId: string }} params
   */
  trackUserProjectOpen: (
    params: TrackProjectOpenParams,
    sessionToken: string
  ) => performPutCall(`track/trackProjectOpen`, params, sessionToken),

  /**
   * Track when user opens a project in Marketplace
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackmarketplaceprojectvisit
   */
  trackMarketplaceProjectVisit: (params: TrackMarketplaceProjectVisitParams) =>
    performPutCall(`track/trackMarketplaceProjectVisit`, params),

  /**
   * Track when user opens a unit page in Marketplace
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackmarketplaceunitvisitor
   */
  trackMarketplaceUnitVisitor: (params: TrackMarketplaceUnitVisitorParams) =>
    performPutCall(`track/trackMarketplaceUnitVisitor`, params),

  /**
   * Track when user interacts with the Get In Touch form
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackgetintouchinteraction
   */
  trackGetInTouchInteraction: (params: TrackGetInTouchInteractionParams) =>
    performPutCall(`track/trackGetInTouchInteraction`, params),

  /**
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_updateactionupdatedatfield
   */
  updateActionUpdateAtField: (params: { actionId: string }) =>
    performPutCall(`track/updateActionUpdatedAtField`, params),

  /**
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackvisitorunitactivity
   */
  trackShowcaseUnitVisitor: (params: TrackShowcaseUnitVisitor) =>
    performPutCall(`track/trackVisitorUnitActivity`, params),

  /**
   * Track Showcase loaded action
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackfinishloadingshowcase
   */
  trackShowcaseLoaded: (params: TrackFinishLoadingShowcaseParams) =>
    performPutCall(`track/trackFinishLoadingShowcase`, params),

  /**
   * Track when shared collection page was opened
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_tracksharedcontentcollectionviewed
   */
  trackSharedCollectionPageOpened: (params: TrackSharedCollectionPageOpened) =>
    performPutCall(`track/trackSharedContentCollectionViewed`, params),

  /**
   * Track content item from shared collection was opened
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackcontentitemviewed
   */
  trackSingleItemFromSharedCollectionViewed: (
    params: TrackSingleItemFromSharedCollectionViewed
  ) => performPutCall(`track/trackContentItemViewed`, params),

  /**
   * Track content item from shared collection was opened
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackcontentitemdownloaded
   */
  trackSharedCollectionContentDownload: (params: TrackMediaDownloadingParams) =>
    performPutCall(`track/trackContentItemDownloaded`, params),

  /**
   * Track marketplace action
   *
   */
  trackMarketplaceAction: (params: TrackMarketplaceActionParams) =>
    performPutCall(`track/trackMarketplaceAction`, params)
};
