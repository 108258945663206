import { useEffect, useRef, useState } from 'react';
import { useAuthState } from 'src/store/AuthStore';
import { useNavigate } from 'react-router-dom';

// Styling
import styled, { StyledProps } from 'styled-components';
import { motion } from 'framer-motion';
import DropZoneComponent from './upload/DropZoneComponent';
import { buildAssetURIWithOptions } from 'src/helpers/uri';
import { readAsDataUrl } from 'src/helpers/utils';
import { User, Vault } from '@/libs/prompto-api/src';
import uploadFileToBucketCallback from 'src/helpers/uploadFileToBucketCallback';
import env from 'src/environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const UserCircle = styled(motion.div)<StyledProps<{ size: number }>>`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => (size / 120) * 1.875}rem;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.gray30};
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
`;

const DropdownMenu = styled(motion.div)<StyledProps<{ size: number }>>`
  position: absolute;
  border-radius: 4px;
  border: solid 1px #e7e6ea;
  background: ${({ theme }) => theme.white};
  top: ${({ size }) => size + 12}px;
  left: 0px;
  padding: 8px;
  cursor: pointer;
`;

const Name = styled.div`
  margin-left: 12px;
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 1.25rem;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-left: auto;
  color: ${({ theme }) => theme.gray80};
`;

export interface ProfilePictureProps {
  isWhite?: boolean;
  onClick?: any;
  size?: number;
  enableDropdown?: boolean;
  showName?: boolean;
}

const ProfilePicture = ({
  isWhite,
  onClick,
  enableDropdown = false,
  size = 40,
  showName
}: ProfilePictureProps) => {
  const { AuthState, AuthStateDispatch } = useAuthState();
  const { user, vaultObjectId, sessionToken } = AuthState;

  const [fileAsDataUrl, setFileAsDataUrl] = useState<string>('');

  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  let initials = `${user?.firstName ? user?.firstName[0] : ''}${
    user?.lastName ? user?.lastName[0] : ''
  }`;

  const fullName = `${user?.firstName ? user?.firstName : ''} ${
    user?.lastName ? user?.lastName : ''
  }`;

  const removeTimer = useRef<any>();

  const onUserLogout = () => {
    AuthStateDispatch({ type: 'logout' });
    navigate(`/projects`);
  };

  const onDrop = async (files: any) => {
    const fileAsDataUrl = (await readAsDataUrl(files[0])) as string;

    setFileAsDataUrl(fileAsDataUrl);

    const uploadToken = await Vault.getUploadAccessToken(
      vaultObjectId,
      sessionToken
    );

    const uploadedFileData = await uploadFileToBucketCallback(
      files[0],
      env().googleStorageBucketId,
      `user/${user.objectId}/profilePicture`,
      uploadToken.data.token,
      vaultObjectId
    );

    User.update(
      user.objectId,
      {
        metadata: {
          profilePictureUri: uploadedFileData.data.name
        }
      },
      sessionToken
    ).then((result: any) => {
      AuthStateDispatch({
        type: 'setUser',
        payload: { ...result.data.user }
      });
    });
  };

  useEffect(() => {
    if (user?.metadata?.profilePictureUri) {
      setFileAsDataUrl(
        buildAssetURIWithOptions('mr=120,120', user.metadata.profilePictureUri)
      );
    }
  }, [user]);

  return (
    <Wrapper>
      <DropZoneComponent
        size={size}
        onDrop={onDrop}
        onCancel={() => {}}
        files={[]}
        onHoverStart={() => {
          clearTimeout(removeTimer.current);
          setShowDropdown(true);
        }}
        onHoverEnd={() => {
          removeTimer.current = setTimeout(() => {
            setShowDropdown(false);
          }, 500);
        }}
        enabled={!enableDropdown}
        fileAsDataUrl={fileAsDataUrl}
        accept={`image/png, image/jpg, image/jpeg`}
        onClick={onClick}
      />

      {!fileAsDataUrl && (
        <UserCircle
          size={size}
          onHoverStart={() => {
            clearTimeout(removeTimer.current);
            setShowDropdown(true);
          }}
          onHoverEnd={() => {
            removeTimer.current = setTimeout(() => {
              setShowDropdown(false);
            }, 500);
          }}
          onClick={onClick}
        >
          {initials}
        </UserCircle>
      )}

      {showName && (
        <>
          <Name onClick={onClick}>{fullName} </Name>
          <ArrowIcon
            icon={['far', 'chevron-right']}
            size="sm"
            onClick={onClick}
          />
        </>
      )}

      {enableDropdown && showDropdown && (
        <DropdownMenu
          size={size}
          onHoverStart={() => {
            clearTimeout(removeTimer.current);
          }}
          onHoverEnd={() => {
            removeTimer.current = setTimeout(() => {
              setShowDropdown(false);
            }, 500);
          }}
        >
          <div onClick={onUserLogout}>Logout</div>
        </DropdownMenu>
      )}
    </Wrapper>
  );
};

export default ProfilePicture;
