// @ts-nocheck
import React, { useReducer, createContext, useContext } from 'react';

const defaultStoreValue = {
  showContactUsForm: false,
  formLocation: '',
  unitObjectId: '',
  contactFormDetails: {
    firstName: '',
    lastName: '',
    email: '',
    postcode: '',
    reason: [],
    phoneNumber: '',
    yourMessage: '',
    visitorObjectId: '',
    vaultId: '',
    projectId: ''
  }
};

export const Context = createContext(defaultStoreValue);

const ContactUsStore = (props) => {
  const [ContactUsState, ContactUsStateDispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'setShowContactUsForm':
          if (action.payload.showContactUsForm) {
            document.body.style.overflow = 'hidden';
          }

          if (!action.payload.showContactUsForm) {
            if (!window.location.pathname.includes('unit')) {
              document.body.style.overflow = 'auto';
            }
          }
          return {
            ...state,
            showContactUsForm: action.payload.showContactUsForm,
            formLocation: action.payload.location,
            unitObjectId: action.payload.unitObjectId
          };
        case 'setContactDetails': {
          return {
            ...state,
            contactFormDetails: {
              ...state.contactFormDetails,
              ...action.payload
            }
          };
        }
        case 'resetContactForm': {
          return {
            ...state,
            contactFormDetails: {
              firstName: '',
              lastName: '',
              email: '',
              postcode: '',
              reason: [],
              phoneNumber: '',
              yourMessage: '',
              visitorObjectId: '',
              vaultId: '',
              projectId: ''
            }
          };
        }
        default:
          return state;
      }
    },
    {
      showContactUsForm: false,
      formLocation: '',
      unitObjectId: '',
      contactFormDetails: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        postcode: '',
        yourMessage: ''
      }
    }
  );

  return (
    <Context.Provider value={{ ContactUsState, ContactUsStateDispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useContactUsState = () => useContext(Context);

export default ContactUsStore;
