import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthState } from 'src/store/AuthStore';
import localizer from 'src/localization/localizer';
import { forBiggerThan } from 'src/helpers/ui';

// Styling
import styled, { StyledProps } from 'styled-components';

const Wrapper = styled.div`
  border-radius: 16px;
  border: solid 1px ${({ theme }) => theme.gray20};
  background: ${({ theme }) => theme.white};
  padding: 18px;
  max-width: 600px;
  margin-bottom: 48px;

  ${forBiggerThan.tablet`
   padding: 32px;
`}
`;

const Title = styled.div`
  color: ${({ theme }) => theme.gray60};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 36px;
`;

const StyledIcon = styled(FontAwesomeIcon)<
  StyledProps<{ isWhite?: boolean; size?: any }>
>`
  color: ${({ theme }) => theme.gray50};
  width: ${({ size }) => size}px !important;
  height: ${({ size }) => size}px !important;
  cursor: pointer;
  margin-right: 12px;
`;

const Info = styled.div<StyledProps<{ isActive: boolean }>>`
  color: ${({ theme, isActive }) => (isActive ? theme.black : theme.gray60)};
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 1.125rem;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 24px;
`;

const MainInfoBlock = () => {
  const { AuthState } = useAuthState();
  const { user, vault } = AuthState;

  const billingAddress = vault?.billingAddress;
  let addressString,
    city,
    zipCode = null;

  if (billingAddress?.zipCode || billingAddress?.city) {
    zipCode = billingAddress?.zipCode;
    city = billingAddress?.city;
    addressString = `${zipCode}${zipCode && city ? ', ' : ''}${city}`;
  }
  return (
    <Wrapper>
      <Title> {localizer.accountPage.account}</Title>
      <HorizontalWrapper>
        <StyledIcon icon={['fal', 'envelope']} size="sm" />{' '}
        <Info isActive={user?.email}>
          {user?.email ?? localizer.accountPage.email}
        </Info>
      </HorizontalWrapper>
      <HorizontalWrapper>
        <StyledIcon icon={['fal', 'phone-alt']} size="sm" />{' '}
        <Info isActive={user?.phoneNumber}>
          {user?.phoneNumber ?? localizer.accountPage.phone}
        </Info>
      </HorizontalWrapper>
      <HorizontalWrapper>
        <StyledIcon icon={['fas', 'map-marker-alt']} size="sm" />{' '}
        <Info isActive={zipCode || city}>
          {addressString ?? localizer.accountPage.address}
        </Info>
      </HorizontalWrapper>
    </Wrapper>
  );
};

export default MainInfoBlock;
