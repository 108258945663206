function PdfPlaceholder() {
  return (
    <svg
      width="300"
      height="300"
      viewBox="0 0 300 300"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M10 0h242l48 48v242c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0z"
          id="18obl2ljka"
        />
        <path d="m252 0 48 48h-38c-5.523 0-10-4.477-10-10V0z" id="be30rhx73b" />
        <mask
          id="f0abzr0iic"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="300"
          height="300"
          fill="#fff"
        >
          <use xlinkHref="#18obl2ljka" />
        </mask>
        <mask
          id="yyufn4mx3d"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="48"
          height="48"
          fill="#fff"
        >
          <use xlinkHref="#be30rhx73b" />
        </mask>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g
          stroke="#E7E6EA"
          stroke-dasharray="0,0"
          stroke-linejoin="round"
          stroke-width="4"
        >
          <use mask="url(#f0abzr0iic)" fill="#FFF" xlinkHref="#18obl2ljka" />
          <use mask="url(#yyufn4mx3d)" fill="#F9F7FA" xlinkHref="#be30rhx73b" />
        </g>
        <g fill="#F3F3F5">
          <path d="M251 236c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15h-5v10h-10v-30h10v-10h5zm25 30v10h-10v-10h10zm-30-20v10h10v-10h-10zM256 76v20h-20V76zM196 176v20h-20v-20zM236 196v40h-40v-40zM276 156v40h-40v-40zM236 96v60h-60V96z" />
        </g>
        <g fill="#C3C2CA" fill-rule="nonzero">
          <path d="M30 58V30h9.8c2.187 0 4 .367 5.44 1.1 1.44.733 2.513 1.733 3.22 3 .707 1.267 1.06 2.7 1.06 4.3 0 1.52-.34 2.913-1.02 4.18-.68 1.267-1.74 2.273-3.18 3.02-1.44.747-3.28 1.12-5.52 1.12h-5.56V58H30zm4.24-14.76h5.36c2 0 3.433-.433 4.3-1.3s1.3-2.047 1.3-3.54c0-1.547-.433-2.753-1.3-3.62s-2.3-1.3-4.3-1.3h-5.36v9.76zM53.72 58V30h9.08c3.28 0 5.98.573 8.1 1.72 2.12 1.147 3.693 2.767 4.72 4.86 1.027 2.093 1.54 4.58 1.54 7.46 0 2.853-.513 5.327-1.54 7.42-1.027 2.093-2.6 3.707-4.72 4.84-2.12 1.133-4.82 1.7-8.1 1.7h-9.08zm4.24-3.6h4.68c2.533 0 4.54-.413 6.02-1.24 1.48-.827 2.547-2.013 3.2-3.56.653-1.547.98-3.4.98-5.56 0-2.16-.327-4.027-.98-5.6a7.249 7.249 0 0 0-3.2-3.62c-1.48-.84-3.487-1.26-6.02-1.26h-4.68V54.4zM81.84 58V30H99.4v3.44H86.08v8.84h11.04v3.4H86.08V58h-4.24z" />
        </g>
      </g>
    </svg>
  );
}

export default PdfPlaceholder;
