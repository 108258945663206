import { useState, useEffect, useCallback, useRef, memo } from 'react';

// Components
import NavigationSpot from './NavigationSpot';
import UnitSpot from './UnitSpot';
// import ClusterSpot from './ClusterSpot';
import UspSpot from './UspSpot';
import LineTo from 'react-lineto';

// Helpers
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import isEqual from 'lodash.isequal';
import { addSpotOnCanvas } from 'src/helpers/panellumHelpers';
import { zIndices } from '../projectTourHelpers';

// Styling
import styled, { StyledProps } from 'styled-components';

const maxSpotPreviewWidth = 290;
const maxSpotPreviewHeight = isMobile ? 120 : 150;
const markerHeight = 40;

export const ExpandDirections = {
  right: 'right',
  left: 'left',
  top: 'top',
  bottom: 'bottom'
};

export const getDisplayDirection = (
  originLocation: any,
  width: number,
  height: number,
  maxInX: number,
  containerId?: string
) => {
  let maxWidth: number = 0;
  let maxHeight: number = 0;
  if (containerId) {
    const container = document.getElementById(containerId);
    maxWidth = container?.getBoundingClientRect().width ?? 0;
    maxHeight = container?.getBoundingClientRect().height ?? 0;
  } else {
    maxWidth = maxInX;
    maxHeight = window.innerHeight;
  }

  let finalDirections: any = { x: '', y: '' };
  const widthOriginToEdge = width / 2;
  if (originLocation.x - widthOriginToEdge < 0) {
    finalDirections.x = ExpandDirections.right;
  } else if (originLocation.x + widthOriginToEdge > maxWidth) {
    finalDirections.x = ExpandDirections.left;
  } else {
    finalDirections.x = '';
  }

  if (originLocation.y + height >= maxHeight) {
    finalDirections.y = ExpandDirections.top;
  } else if (originLocation.y - height < 0) {
    finalDirections.y = ExpandDirections.bottom;
  } else {
    finalDirections.y = ExpandDirections.top;
  }

  return finalDirections;
};

const Marker = styled(motion.div).attrs(
  ({
    location,
    disabled,
    isSpotHovered,
    isSpotPinned,
    isCustomLabelEnabled,
    navTransform
  }: any) => ({
    style: {
      width: `${markerHeight}px`,
      height: `${markerHeight}px`,
      top: `${
        navTransform?.y ? navTransform.y - markerHeight / 2 : location.y
      }px`,
      left: `${
        navTransform?.x ? navTransform.x - markerHeight / 2 : location.x
      }px`,
      cursor: `${disabled ? 'default' : 'pointer'}`,
      zIndex: `${
        isSpotHovered
          ? isSpotPinned || isCustomLabelEnabled
            ? zIndices.pinnedOrCustomImageSpotHovered
            : zIndices.regularSpotHovered
          : isSpotPinned || isCustomLabelEnabled
          ? zIndices.pinnedOrCustomImageSpot
          : zIndices.regularSpot
      }`
    }
  })
)<
  StyledProps<{
    location: any;
    isSpotHovered: boolean;
    isSpotPinned: boolean;
    isCustomLabelEnabled: boolean;
    disabled?: boolean;
    navTransform?: any;
  }>
>`
  position: absolute;
  z-index: 10000;
  overflow: visible;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EndOfLine = styled.div.attrs((props: any) => ({
  style: {
    top: props.location.y + 'px',
    width: 10,
    height: 10
  }
}))<StyledProps<{ location: any }>>`
  position: absolute;
  left: ${(props) => props.location.x}px;
  z-index: ${zIndices.spotEndOfLine};
`;

// Types
interface SpotProps {
  spotObject: any;
  parentDimensions: any;
  imageDimensions: any;
  onSpotClicked: any;
  onClusterSpotClicked: any;
  disabled?: boolean;
  forceDeselectSpots: any;
  zoomScale: number;
  onSpotHover: any;
  spotScale: number;
  idealImageRelativePosition: any;
  enlargeUsp: any;
  isHighlighted: boolean;
  forceRender: boolean;
  is360Viewer: boolean;
  canvas?: any;
  currentRotation: any;
  nightMode: boolean;
  highlightedUnitSpotParams?: any;
  setHighlightedUnitSpotParams?: any;
  mobileUspTooltipData: any;
  setMobileUspTooltipData: any;
  animationEnabled?: boolean;
}

const Spot = memo(
  ({
    spotObject,
    parentDimensions,
    imageDimensions,
    onSpotClicked,
    // onClusterSpotClicked,
    disabled,
    forceDeselectSpots,
    zoomScale,
    onSpotHover,
    spotScale,
    idealImageRelativePosition,
    enlargeUsp,
    isHighlighted,
    forceRender = false,
    is360Viewer = false,
    canvas = null,
    currentRotation,
    nightMode = false,
    highlightedUnitSpotParams,
    setHighlightedUnitSpotParams,
    mobileUspTooltipData,
    setMobileUspTooltipData,
    animationEnabled
  }: SpotProps) => {
    const [spotLocation, setSpotLocation] = useState<any>({ x: 0, y: 0 });
    const [lineLocation, setLineLocation] = useState<any>(null);
    const [spotAbsoluteLocation, setSpotAbsoluteLocation] = useState({
      x: 0,
      y: 0
    });

    const [isSpotHovered, setIsSpotHovered] = useState(false);
    const [isSpotHighlighted, setIsSpotHighlighted] = useState(isHighlighted);

    const [horizontalExpandDirection, setHorizontalExpandDirection] = useState(
      ExpandDirections.left
    );
    const [verticalExpandDirection, setVerticalExpandDirection] = useState(
      ExpandDirections.top
    );

    // show line when we know exactly its coordinates
    const [canCheckForLine, setCanCheckForLine] = useState(false);
    const [showLine, setShowLine] = useState(false);

    useEffect(() => {
      document.addEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    }, []);

    useEffect(() => {
      if (lineLocation && (spotLocation.x || spotLocation.y)) {
        setShowLine(true);
      }
    }, [lineLocation, spotLocation]);

    // panellum related
    const [container, setContainer] = useState<any>();
    const [startedAddingSpot, setStartedAddingSpot] = useState(false);
    const [navTransform, setNavTransform] = useState<any>();

    const {
      xCoordinate: spotXCoord,
      yCoordinate: spotYCoord,
      objectId: spotObjectId
    } = spotObject;

    useEffect(() => {
      if (is360Viewer && !startedAddingSpot && canvas && spotObject) {
        setStartedAddingSpot(true);
        addSpotOnCanvas(canvas, spotObject);
      }
    }, [startedAddingSpot, canvas, spotObject, is360Viewer]);

    useEffect(() => {
      if (container) {
        const transform = window.getComputedStyle(container).transform;
        const splitted = transform.split(',');

        const x = splitted[12];
        const y = splitted[13];
        const z = splitted[14];

        setNavTransform({ x, y, z });
      } else {
        const base = window.document.getElementsByClassName(
          `spot${spotObjectId}`
        );

        let container;
        if (base[0] && !!window.getComputedStyle(base[0]).transform) {
          container = base[0];
        } else if (base[1] && !!window.getComputedStyle(base[1]).transform) {
          container = base[1];
        }

        if (container) {
          setContainer(container);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      currentRotation,
      container,
      canvas,
      spotXCoord,
      spotYCoord,
      spotObjectId
    ]);

    const convertRelativeLocation = useCallback(
      (x: number, y: number) => {
        // Get parent image size and container size
        const parentWidth = parentDimensions?.width || 0;
        const parentHeight = parentDimensions?.height || 0;

        // Calculate the half size of the image and the offset of the marker according to the center of the image
        const parentHalfWidth = parentWidth / 2.0;
        const parentHalfHeight = parentHeight / 2.0;

        const centerOffsetX = parentHalfWidth * x;
        const centerOffsetY = parentHalfHeight * y;

        // Add everything together to get the spot location according to the top left corner of the screen
        const scale = 1.0 / zoomScale;
        const cornerOffsetX = (centerOffsetX + parentHalfWidth) * scale;
        const cornerOffsetY = Math.max(
          markerHeight / 2,
          centerOffsetY + parentHalfHeight
        );

        const yPos =
          (parentDimensions?.height -
            Math.max(
              markerHeight / 2,
              parentDimensions?.height - cornerOffsetY
            )) *
          scale;

        return { x: cornerOffsetX, y: yPos };
      },
      [parentDimensions, zoomScale]
    );

    // Calculate spot location
    useEffect(() => {
      const spotLoc = convertRelativeLocation(
        spotObject.xCoordinate,
        spotObject.yCoordinate
      );

      setSpotLocation({
        x: spotLoc.x - 20,
        y: spotLoc.y - 20
      });

      if (idealImageRelativePosition) {
        setSpotAbsoluteLocation({
          x: idealImageRelativePosition.offsetX + spotLoc.x * zoomScale,
          y: idealImageRelativePosition.offsetY + spotLoc.y * zoomScale
        });
      }

      // Too much to the left or right
      if (spotLoc.x - maxSpotPreviewWidth < 0) {
        setHorizontalExpandDirection(ExpandDirections.right);
      } else {
        setHorizontalExpandDirection(ExpandDirections.left);
      }

      // Too much to the top or bottom
      if (spotLoc.y - maxSpotPreviewHeight < 0) {
        setVerticalExpandDirection(ExpandDirections.bottom);
      } else {
        setVerticalExpandDirection(ExpandDirections.top);
      }
    }, [
      spotObject,
      parentDimensions,
      zoomScale,
      imageDimensions,
      convertRelativeLocation,
      idealImageRelativePosition
    ]);

    useEffect(() => {
      // Check for line object
      if (spotObject?.navigationCollection?.vmNavigationItemList?.length > 0) {
        const lineObject =
          spotObject.navigationCollection.vmNavigationItemList[0];

        const lineLoc = convertRelativeLocation(
          lineObject.xCoordinate,
          lineObject.yCoordinate
        );

        setLineLocation({
          x: lineLoc.x - 5, // 5 is half width of the invisible end of line spot
          y: lineLoc.y + (isMobile ? 3 : markerHeight / 2.0) + 5
        });
      } else {
        setShowLine(false);
      }
    }, [spotObject, convertRelativeLocation, forceRender, canCheckForLine]);

    useEffect(() => {
      if (forceDeselectSpots) {
        setIsSpotHovered(false);
      }
    }, [forceDeselectSpots, setIsSpotHovered]);

    // useEffect(() => {
    //   if (!activeUsp?.spot && isMobile) {
    //     setIsSpotHovered(false);
    //   }
    // }, [activeUsp]);

    const spotMarkerRef = useRef<HTMLDivElement>(null);

    const checkOutsideClick = useCallback(
      (event: any) => {
        if (!spotMarkerRef.current?.contains(event.target)) {
          setIsSpotHovered(false);
          setIsSpotHighlighted(false);
        }
      },
      [spotMarkerRef]
    );

    useEffect(() => {
      window.addEventListener('click', checkOutsideClick);
      return () => window.removeEventListener('click', checkOutsideClick);
    }, [checkOutsideClick]);

    let spotToDisplay;
    switch (spotObject.navigationItemType) {
      default:
      case 'navigationSpot':
      case 'turntableSpot':
      case 'album360Spot':
        spotToDisplay = (
          <NavigationSpot
            key={spotObject.objectId}
            spotObject={spotObject}
            spotLocation={spotLocation}
            spotAbsoluteLocation={spotAbsoluteLocation}
            isSpotHovered={isSpotHovered}
            horizontalExpandDirection={horizontalExpandDirection}
            verticalExpandDirection={verticalExpandDirection}
            onSpotClicked={onSpotClicked}
            parentDimensions={parentDimensions}
            nightMode={nightMode}
          />
        );
        null;

        break;
      case 'uspSpot':
        spotToDisplay = (
          <UspSpot
            key={spotObject.objectId}
            spotObject={spotObject}
            spotLocation={spotLocation}
            isSpotHovered={isSpotHovered || isSpotHighlighted}
            spotAbsoluteLocation={spotAbsoluteLocation}
            enlargeUsp={enlargeUsp}
            horizontalExpandDirection={horizontalExpandDirection}
            verticalExpandDirection={verticalExpandDirection}
            mobileUspTooltipData={mobileUspTooltipData}
            setMobileUspTooltipData={setMobileUspTooltipData}
          />
        );
        break;
      case 'unitSpot':
        spotToDisplay = (
          <UnitSpot
            key={spotObject.objectId}
            spotObject={spotObject}
            spotLocation={spotLocation}
            isSpotHovered={isSpotHovered || isSpotHighlighted}
            onSpotClicked={onSpotClicked}
            disabled={disabled ?? false}
            spotAbsoluteLocation={spotAbsoluteLocation}
            animationEnabled={animationEnabled}
            highlightedUnitSpotParams={highlightedUnitSpotParams}
            setHighlightedUnitSpotParams={setHighlightedUnitSpotParams}
          />
        );
        break;
      case 'cluster':
        spotToDisplay = null;
      //  (
      //   <ClusterSpot
      //     key={spotObject.objectId}
      //     spotObject={spotObject}
      //     onSpotClicked={onClusterSpotClicked}
      //   />
      // );
    }

    const markerAnimation =
      !!spotObject?.pinConfiguration?.pinned ||
      !!spotObject?.customLabelConfiguration?.useCustomLabel
        ? {
            initial: { scale: 1 }
          }
        : {
            initial: { scale: 0, opacity: 0 },
            animate: {
              scale: [0, spotScale * 1.5, spotScale],
              opacity: 1,
              transition: { delay: 0, duration: 0.4 }
            },
            exit: { opacity: 0 }
          };

    return (
      <>
        <Marker
          ref={spotMarkerRef}
          className={spotObject.objectId}
          style={
            {
              scale: spotScale
            } as unknown as any
          }
          location={spotLocation}
          isSpotHovered={isSpotHovered || isSpotHighlighted}
          isSpotPinned={!!spotObject?.pinConfiguration?.pinned}
          isCustomLabelEnabled={
            !!spotObject?.customLabelConfiguration?.useCustomLabel
          }
          disabled={disabled}
          onTouchEnd={(e) => {
            if (isSpotHovered) {
              setTimeout(() => {
                setIsSpotHovered(false);
                onSpotHover(null);
              }, 100);
            } else {
              setTimeout(() => {
                setIsSpotHovered(true);
                onSpotHover(spotObject);
              }, 100);
            }
          }}
          onHoverStart={() => {
            if (!isMobile) {
              setIsSpotHovered(true);
              onSpotHover(spotObject);
            }
          }}
          onHoverEnd={() => {
            if (isMobile) return;
            setIsSpotHovered(false);
            onSpotHover(null);
          }}
          onAnimationComplete={() => setCanCheckForLine(true)}
          navTransform={navTransform}
          {...markerAnimation}
        >
          {spotToDisplay}
        </Marker>
        {canCheckForLine && showLine && (
          <>
            <EndOfLine
              className={`${spotObject.objectId}line`}
              location={lineLocation}
            />
            <LineTo
              from={spotObject.objectId}
              to={`${spotObject.objectId}line`}
              fromAnchor={'center'}
              borderColor={'white'}
              borderStyle={'dashed'}
              borderWidth={1 * zoomScale}
              className="line-to-element"
              within={spotObject.objectId}
            />
          </>
        )}
      </>
    );
  },
  (prevProps: any, nextProps: any) => {
    let noUpdate = true;

    Object.entries(prevProps).forEach(([key, value]) => {
      if (['parentDimensions', 'imageDimensions', 'spotObject'].includes(key)) {
        if (!isEqual(prevProps[key], nextProps[key])) {
          noUpdate = false;
        }
      } else if (prevProps[key] !== nextProps[key]) {
        noUpdate = false;
      }
      return noUpdate;
    });

    return noUpdate;
  }
);

export default Spot;
