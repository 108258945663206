import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  ReactNode
} from 'react';
import { shape, number, bool, func } from 'prop-types';

// Components
import Item from './Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { motion, useMotionValue, useAnimation } from 'framer-motion';
import { debounce } from 'src/helpers/utils';
import useKeyPress from 'src/hooks/use-key-press';
import ReactResizeDetector from 'react-resize-detector';
import { isMobileOnly } from 'react-device-detect';

// Styles
import styled, { StyledProps, keyframes, css } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const Wrapper = styled(motion.div)<StyledProps<{ padding: number }>>`
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => `0 ${padding}px`};
  box-sizing: border-box;
  position: relative;
`;

const slide = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const TrackWrapper = styled(motion.div)`
  display: flex;
`;

const Track = styled(motion.div)<
  StyledProps<{ autoLoop?: boolean; gap: number }>
>`
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  min-width: min-content;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  ${({ autoLoop, gap }) => {
    if (autoLoop) {
      return css`
        padding-right: ${gap}px !important;
        animation: ${slide} linear infinite;
        animation-direction: normal;
      `;
    }
  }}
`;

const Dots = styled.div`
  width: 100%;
  padding: 25px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const Dot = styled.div<StyledProps<{ active: boolean }>>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme, active }) => (active ? theme.gold : theme.beigeBg30)};
  transform: scale(${({ active }) => (active ? 1.25 : 1)});
  transition: all 150ms linear;
`;

const ControlsSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 32px 0;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  ${forBiggerThan.tablet`
    padding: 64px 0;
    text-align: left;
  `}
`;

const SliderHeading = styled.h2`
  margin: 0 auto;
  font-weight: normal;
  font-size: 1.5rem;
  ${forBiggerThan.tablet`
    margin: 0;
    font-size: 2rem;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

const StyledButtonIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${({ theme }) => theme.black};
`;

const SliderButton = styled(motion.button)<StyledProps<{ disabled?: boolean }>>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 14px 15px;
  transition: all 0.3s;
  border: none;
  outline: transparent;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.beigeBg20};
  cursor: pointer;
  visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
  &:hover {
    background-color: ${({ theme }) => theme.black};
    ${StyledButtonIcon} {
      color: ${({ theme }) => theme.white};
    }
  }
`;

interface MotionSliderProps {
  children: any[];
  maxActiveItems: number;
  minActiveItems: number;
  minItemWidth: number;
  padding: number;
  rightPadding?: number;
  gap: number;
  velocity: number;
  disableScroll?: boolean;
  disableArrowKeys?: boolean;
  setIsDragging?: (value: ((prevState: boolean) => boolean) | boolean) => void;
  onSliderReachesEnd?: () => void;
  loadDataEndReached?: boolean;
  onDrag?: () => void;
  onScroll: () => void;
  enableLockedScroll?: boolean;
  onSliderLoaded?: () => void;
  heading?: ReactNode;
  autoLoop?: boolean;
  loopDuration?: number;
}

const MotionSlider = ({
  children,
  maxActiveItems,
  minActiveItems,
  minItemWidth,
  padding,
  rightPadding = 0,
  gap,
  velocity,
  disableArrowKeys,
  onSliderReachesEnd,
  loadDataEndReached,
  onDrag,
  onSliderLoaded,
  heading,
  setIsDragging: setIsDraggingParentState,
  autoLoop = false,
  loopDuration = 0
}: MotionSliderProps) => {
  const [activeItem, setActiveItem] = useState(0);
  const [offsets, setOffsets] = useState<number[]>([]);
  const [dragNotNeeded, setDragNotNeeded] = useState(false);

  const startTimeOffset = 500;
  const [hasLoaded, setHasLoaded] = useState(false);

  const trackRef = useRef<any>(null);
  const containerRef = useRef<any>(null);

  const [containerWidth, setContainerWidth] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [numActiveItems, setNumActiveItems] = useState(0);

  const [dragDisabled, setDragDisabled] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [previousSize, setPreviousSize] = useState(0);
  const [sliderEndReached, setSliderEndReached] = useState(false);

  // Auto loop
  const [looperInstances, setLooperInstances] = useState(2);
  const [trackWrapperHovered, setTrackWrapperHovered] = useState(false);

  // Keypress events
  const nextKeyPressed = useKeyPress('ArrowRight', true, false);
  const previousKeyPressed = useKeyPress('ArrowLeft', true, false);

  // Scrolling variables
  const x = useMotionValue(0);
  const controls = useAnimation();
  const [size, setSize] = useState(0);

  useEffect(() => {
    if (setIsDraggingParentState) {
      setIsDraggingParentState(isDragging);
    }
  }, [isDragging]);

  // Recalculate sizes of the items and container
  const calculateItemWidth = useCallback(
    (containerWidth: number, activeItems: number) =>
      (containerWidth - (activeItems - 1) * gap) / activeItems,
    [gap]
  );

  const updateSizes = useCallback(() => {
    if (children.length > 0 && containerRef.current) {
      const newContainerWidth =
        containerRef.current?.offsetWidth - 2 * padding - rightPadding;

      let newItemWidth = 0;
      let newNumActiveItems = 0;
      if (minActiveItems === maxActiveItems) {
        newNumActiveItems = minActiveItems;
        newItemWidth = calculateItemWidth(newContainerWidth, newNumActiveItems);
      } else {
        newNumActiveItems = Math.min(
          minActiveItems ? minActiveItems : children.length,
          maxActiveItems
        );
        newItemWidth = calculateItemWidth(newContainerWidth, newNumActiveItems);

        while (newItemWidth < minItemWidth && newNumActiveItems > 1) {
          --newNumActiveItems;
          newItemWidth = calculateItemWidth(
            newContainerWidth,
            newNumActiveItems
          );
        }
      }

      setNumActiveItems(newNumActiveItems);
      setContainerWidth(newContainerWidth);
      setItemWidth(newItemWidth);

      const newOffsets = children.map((child: number, index: number) => {
        return -index * (newItemWidth + gap);
      });
      setOffsets(newOffsets);
    }
  }, [
    children,
    gap,
    padding,
    rightPadding,
    maxActiveItems,
    minActiveItems,
    minItemWidth,
    calculateItemWidth
  ]);

  useEffect(() => {
    setTimeout(() => {
      updateSizes();
      setHasLoaded(true);
      onSliderLoaded && onSliderLoaded();
    }, startTimeOffset);
  }, [updateSizes, onSliderLoaded]);

  // If track has smaller width than screen, drag is not needed
  useEffect(() => {
    if (itemWidth) {
      const trackWidth = trackRef.current?.offsetWidth;
      setDragNotNeeded(trackWidth < window.innerWidth);
    }
  }, [trackRef, itemWidth]);

  // When the slider reaches the end
  useEffect(() => {
    if (
      !sliderEndReached &&
      !loadDataEndReached &&
      children.length > 0 &&
      activeItem > children.length - numActiveItems - 5
    ) {
      setSliderEndReached(true);
      onSliderReachesEnd && onSliderReachesEnd();
    }
  }, [
    activeItem,
    children.length,
    numActiveItems,
    onSliderReachesEnd,
    sliderEndReached,
    loadDataEndReached
  ]);

  useEffect(() => {
    setSliderEndReached(false);
  }, [children.length]);

  // Whenever an arrow button is clicked, disabled dragging for a small time to allow the animation to finish
  useEffect(() => {
    if (dragDisabled) {
      setTimeout(() => {
        setDragDisabled(false);
      }, 400);
    }
  }, [dragDisabled]);

  // On window resize, recalculate the width of the cards
  const onResize = debounce(() => {
    updateSizes();
  }, 150);

  useEffect(() => {
    if (size !== previousSize) {
      setPreviousSize(size);
      onResize(size);
    }
  }, [size, previousSize, onResize]);

  // Dragging animation
  const moveActiveItem = useCallback(
    (newActiveItem: number, velocity: number, enableDelay?: boolean) => {
      if (enableDelay) {
        setDragDisabled(true);
      }

      if (newActiveItem > children.length - numActiveItems) {
        newActiveItem = children.length - numActiveItems;
      }
      setActiveItem(newActiveItem);
      const nextPosition = offsets[newActiveItem];

      const xLimit =
        size -
        (trackRef.current?.offsetWidth ?? 0) +
        containerRef.current?.offsetWidth -
        (padding + padding);

      controls.start({
        x: xLimit < 0 ? Math.max(nextPosition, xLimit) : nextPosition,
        transition: {
          type: 'tween',
          velocity
        }
      });
    },
    [
      controls,
      offsets,
      padding,
      children.length,
      numActiveItems,
      size,
      rightPadding
    ]
  );

  // On next / previous events
  const onNext = useCallback(
    (offset: number, enableDelay: boolean) => {
      if (activeItem + numActiveItems > children.length - 1) return;

      let newActiveItem = activeItem + offset;
      if (newActiveItem > children.length - 1) {
        newActiveItem = children.length - 1;
      }

      moveActiveItem(newActiveItem, 1, enableDelay);
    },
    [activeItem, children.length, moveActiveItem, numActiveItems]
  );

  const onPrevious = useCallback(
    (offset: number, enableDelay: boolean) => {
      if (activeItem === 0) return;

      let newActiveItem = activeItem - offset;
      if (newActiveItem < 0) {
        newActiveItem = 0;
      }

      moveActiveItem(newActiveItem, 1, enableDelay);
    },
    [activeItem, moveActiveItem]
  );

  // Scroll binding
  // const handleScroll = useCallback(
  //   (e: any) => {
  //     if (enableLockedScroll && !trackRef.current?.contains(e.target)) return;
  //     e.preventDefault();
  //     e.stopPropagation();

  //     if (disableScroll) {
  //       return;
  //     }
  //     onScroll();
  //     const useDeltaX = Math.abs(e.deltaX) > Math.abs(e.deltaY);
  //     const delta = useDeltaX ? -e.deltaX : -e.deltaY;

  //     if (delta < -4 && !dragDisabled) {
  //       onNext(1, true);
  //     } else if (delta > 4 && !dragDisabled) {
  //       onPrevious(1, true);
  //     }
  //   },
  //   [
  //     dragDisabled,
  //     disableScroll,
  //     onNext,
  //     onPrevious,
  //     enableLockedScroll,
  //     onScroll
  //   ]
  // );

  // useEffect(() => {
  //   window.addEventListener('wheel', handleScroll, { passive: false });
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, [handleScroll]);

  // Arrow keys binding
  useEffect(() => {
    if (!disableArrowKeys && previousKeyPressed && !dragDisabled) {
      onPrevious(1, true);
    }
  }, [disableArrowKeys, previousKeyPressed, dragDisabled, onPrevious]);

  useEffect(() => {
    if (!disableArrowKeys && nextKeyPressed && !dragDisabled) {
      onNext(1, true);
    }
  }, [disableArrowKeys, nextKeyPressed, dragDisabled, onNext]);

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { isDragging })
  );

  // Calculate how many times slider should be repeated to cover the whole page width
  useEffect(() => {
    if (!trackRef?.current || !containerRef?.current) return;

    const { width } = trackRef.current.getBoundingClientRect();
    if (width) {
      const { width: parentWidth } =
        containerRef.current.getBoundingClientRect();

      if (width < parentWidth + itemWidth) {
        setLooperInstances(1 + Math.ceil(parentWidth / width));
      }
    }
  }, []);

  const onDragEnd = (event: any, info: any, isMobileOnly: boolean) => {
    const offset = info.offset.x;
    const correctedVelocity = info.velocity.x * velocity;
    const direction = correctedVelocity < 0 || offset < 0 ? 1 : -1;
    const startPosition = info.point.x - offset;

    const endOffset =
      direction === 1
        ? Math.min(correctedVelocity, offset)
        : Math.max(correctedVelocity, offset);
    const endPosition = startPosition + endOffset;

    const selectableOffsets = offsets.slice(
      0,
      offsets.length - (numActiveItems - 1)
    );
    if (selectableOffsets.length === 0) return;
    const closestPosition = selectableOffsets.reduce((prev, curr) =>
      Math.abs(curr - endPosition) < Math.abs(prev - endPosition) ? curr : prev
    );
    const activeSlide = selectableOffsets.indexOf(closestPosition);

    if (isMobileOnly) {
      // calculate next item for mobile (it should move only one slide per swipe)
      if (direction === 1) {
        // calculate that next active item not exceed max items length
        const activeItemsLimit = childrenWithProps?.length - 1 || 0;
        const nextActiveItem =
          activeItem + 1 > activeItemsLimit ? activeItem : activeItem + 1;
        moveActiveItem(nextActiveItem, 300);
      } else {
        // calculate that prev active item not lower than 0
        const prevActiveItem = activeItem - 1 < 0 ? 0 : activeItem - 1;
        moveActiveItem(prevActiveItem, 300);
      }
    } else {
      moveActiveItem(activeSlide, info.velocity.x);
    }
  };

  const onControlClick = (direction: number) =>
    moveActiveItem(activeItem + 1 * direction, 700);

  const sliderControls: {
    icon: IconName;
    direction: number;
    disabled: boolean;
  }[] = [
    {
      icon: 'arrow-left',
      direction: -1,
      disabled: activeItem === 0
    },
    {
      icon: 'arrow-right',
      direction: 1,
      disabled: activeItem + numActiveItems > children.length - 1
    }
  ];

  return (
    <>
      <Wrapper
        ref={containerRef}
        initial={{ opacity: 0 }}
        animate={hasLoaded ? { opacity: 1 } : { opacity: 0 }}
        padding={padding}
      >
        <ControlsSection>
          <SliderHeading>{heading}</SliderHeading>
          {(size || window.innerWidth) > 768 && !autoLoop && (
            <ButtonsWrapper>
              {sliderControls.map((control) => (
                <SliderButton
                  onClick={() => onControlClick(control.direction)}
                  key={`control-${control.icon}`}
                  disabled={control.disabled}
                  initial={{ opacity: 0, x: -10 * control.direction }}
                  animate={{ opacity: 1, x: 0 }}
                >
                  <StyledButtonIcon
                    icon={['far', `${control.icon}`]}
                    size="sm"
                  />
                </SliderButton>
              ))}
            </ButtonsWrapper>
          )}
        </ControlsSection>

        <TrackWrapper>
          {[...Array(autoLoop ? looperInstances : 1)].map((_, idx) => (
            <Track
              key={`track-${idx}`}
              ref={trackRef}
              style={{
                x,
                animationPlayState: trackWrapperHovered ? 'paused' : 'running',
                animationDuration: `${loopDuration}s`
              }}
              animate={controls}
              drag={!dragDisabled && !dragNotNeeded && 'x'}
              dragConstraints={{
                left:
                  size -
                  (trackRef.current?.offsetWidth ?? 0) -
                  (padding + padding),
                right: 0
              }}
              onDragStart={() => {
                onDrag && onDrag();
                setTimeout(() => {
                  setIsDragging(true);
                }, 10);
              }}
              onDragEnd={(event, info) => {
                onDragEnd(event, info, isMobileOnly);
                setTimeout(() => {
                  setIsDragging(false);
                }, 10);
              }}
              onPanStart={(e, pointInfo) => {
                if (pointInfo.delta.y > 5 || pointInfo.delta.y < -5) {
                  setDragDisabled(true);
                }
              }}
              onPanEnd={() => {
                setDragDisabled(false);
              }}
              autoLoop={autoLoop}
              gap={gap}
              onMouseOver={() => setTrackWrapperHovered(true)}
              onMouseLeave={() => setTrackWrapperHovered(false)}
            >
              {childrenWithProps.map((child: any, i: number) => {
                return (
                  <Item
                    key={i}
                    gap={gap}
                    containerWidth={containerWidth}
                    itemWidth={itemWidth}
                    index={i}
                    offset={x}
                    visible={
                      autoLoop
                        ? true
                        : i <= activeItem + numActiveItems + 2 &&
                          i >= activeItem - 2
                    }
                  >
                    {child}
                  </Item>
                );
              })}
            </Track>
          ))}
        </TrackWrapper>

        {(size || window.innerWidth) < 768 &&
          children.length > 1 &&
          !autoLoop && (
            <Dots>
              {[...Array(children.length)].map((_, i) => (
                <Dot
                  key={i}
                  active={activeItem === i}
                  onClick={() => moveActiveItem(i, 700)}
                />
              ))}
            </Dots>
          )}
      </Wrapper>

      <ReactResizeDetector
        handleHeight
        handleWidth
        skipOnMount
        onResize={() => {
          setSize(window.innerWidth);
          if (hasLoaded) {
            const trackWidth = trackRef.current?.offsetWidth;
            setDragNotNeeded(trackWidth < window.innerWidth);
          }
        }}
      />
    </>
  );
};

MotionSlider.propTypes = {
  maxActiveItems: number,
  minActiveItems: number,
  minItemWidth: number,
  padding: number,
  gap: number,
  velocity: number,
  transition: shape({}),
  disableScroll: bool,
  disableArrows: bool,
  disableArrowKeys: bool,
  arrowsOffset: number,
  onSliderReachesEnd: func,
  loadDataEndReached: bool,
  hideScrollbar: bool,
  onDrag: func,
  onScroll: func,
  enableLockedScroll: bool,
  onSliderLoaded: func
};

MotionSlider.defaultProps = {
  maxActiveItems: 3,
  minActiveItems: 0,
  minItemWidth: 300,
  padding: 100,
  gap: 10,
  velocity: 0.9,
  transition: { stiffness: 300, damping: 600, mass: 3 },
  disableScroll: false,
  disableArrows: false,
  disableArrowKeys: false,
  arrowsOffset: 50,
  loadDataEndReached: false,
  hideScrollbar: false,
  enableLockedScroll: false.valueOf
};

export default MotionSlider;
