import localizer from 'src/localization/localizer';

const projectsFiltersConfig = {
  // Every new filter should be added to this array
  // To change filters order in UI, change the order in this array
  allFilters: [
    {
      key: 'location',
      label: 'location',
      icon: 'map-marker-alt',
      placeholder: localizer.projectsFilters.selectLocation
    },
    {
      key: 'price',
      label: 'price',
      icon: 'tag'
    },
    {
      key: 'surface',
      label: 'surface',
      icon: 'vector-square'
    },
    {
      key: 'numberOfBedrooms',
      label: 'bedrooms',
      icon: 'bed'
    },
    {
      key: 'numberOfBathrooms',
      label: 'bathrooms',
      icon: 'bath'
    }
  ],

  // As we have a special logic of displaying filter items in filters container
  // or in AddFilterButton dropdown, we need to have some fixed sizes
  // to perform calculations
  uiSettings: {
    filterButtonWidth: 260,
    filtersContainerFlexGap: 16
  },

  // Session storage filter values key
  sessionStorageKey: 'marketplaceProjectsFilterValues'
};

export default projectsFiltersConfig;
