// Helpers
import { IUnit, UnitState } from '@/libs/prompto-api/src';
import {
  getColorForUnitState,
  getLocalizedTextForUnitState
} from 'src/components/unitList/unitListHelpers';

// Styling
import styled, { StyledProps } from 'styled-components';

const Badge = styled.div<StyledProps<{ unitState: UnitState }>>`
  width: 6rem;
  height: 2rem;
  padding: 3px 5px;

  font-size: 0.95rem;
  font-family: 'proxima-nova' sans-serif;
  color: ${(props) => props.theme.white};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;

  background-color: ${(props) =>
    getColorForUnitState(props.theme, props.unitState)};
`;

// Types
interface InitStateBadgeProps {
  className?: string;
  unit: IUnit;
}

const UnitStateBadge = ({ unit, className }: InitStateBadgeProps) => {
  return (
    <Badge unitState={unit?.state} className={className}>
      {getLocalizedTextForUnitState(unit?.state, unit?.unitFlow).toUpperCase()}
    </Badge>
  );
};

export default UnitStateBadge;
