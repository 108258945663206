import React, { useCallback, useEffect, useState } from 'react';

// Components
import ProjectCard from 'src/components/projectSearch/ProjectCard';
import StyledScrollbarWrapper from 'src/components/StyledScrollbarWrapper';
import SearchInput from 'src/components/projectSearch/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'src/localization/localizer';
import { IProject, Marketplace } from '@/libs/prompto-api/src';
import { useAppState } from 'src/store/AppStore';
import { useCacheState } from 'src/store/CacheStore';
import { debounce } from 'src/helpers/utils';
import { forBiggerThan } from 'src/helpers/ui';
// Styling
import styled, { css, StyledProps } from 'styled-components';

const Wrapper = styled.div`
  margin-right: 12px;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  position: relative;
  z-index: 1;

  ${forBiggerThan.tablet`
  margin-right: 20px;
`}
`;

const PlaceholderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 16px;
  color: ${({ theme }) => theme.gray40};
  font-size: 1rem;
`;

const PlaceholderIcon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  color: ${({ theme }) => theme.gray30};
`;

const ProjectsResultsPlaceholder = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primary300};
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
`;

const ProjectsFoundWrapper = styled.div<StyledProps<{ mobileView: boolean }>>`
  padding: 16px 16px 14px 16px;
  font-size: ${({ mobileView }) => (mobileView ? '0.75rem' : '0.875rem')};
  font-weight: bold;
  color: ${({ theme }) => theme.gray60};
  text-transform: uppercase;
`;

const ResultsList = styled.div<StyledProps<{ mobileView: boolean }>>`
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${({ theme }) => theme.gray20};
  margin-top: 8px;

  background-color: ${({ theme }) => theme.white};

  ${forBiggerThan.tablet`
  position: absolute;
  left: -50px;
  width: fit-content;
`}
`;

const ProjectsResultsWrapper = styled.div<StyledProps<{ mobileView: boolean }>>`
  box-sizing: border-box;
  width: ${({ mobileView }) => (mobileView ? '100%' : '380px')};
  padding-bottom: 8px;
`;

const ScrollbarWrapper = styled(StyledScrollbarWrapper)<
  StyledProps<{ mobileView: boolean }>
>`
  > div:first-child {
    max-height: ${({ mobileView }) =>
      mobileView
        ? 'calc(100vh - 200px) !important' // 200px - height of logo and search projects field
        : '424px !important'};
  }
  > div:nth-child(3) {
    right: 4px;
    width: 8px;
    > div {
      background: ${({ theme }) => theme.gray20};
      cursor: pointer;
    }
  }
`;

interface ProjectSearchProps {
  setIsMenuOpened: (value: boolean) => void;
  isMenuOpened: boolean;
  mobileView: boolean;
  isHomePage?: boolean;
}

type ProjectList = IProject[] | [];

const ProjectSearch = ({
  mobileView,
  isHomePage = false,
  isMenuOpened,
  setIsMenuOpened
}: ProjectSearchProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [hideResults, setHideResults] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [filteredProjectList, setFilteredProjectList] = useState<ProjectList>(
    []
  );

  const { AppState } = useAppState();
  const { marketplaceId } = AppState;

  const { CacheState, CacheStateDispatch } = useCacheState();
  const { searchProjects } = CacheState;

  const doSearch = useCallback(
    debounce((searchValue: string) => {
      if (searchProjects && searchProjects.has(searchValue)) {
        const projects = searchProjects.get(searchValue);
        setFilteredProjectList(projects);
        setLoadingProjects(false);
        return;
      }

      Marketplace.searchProjects(marketplaceId, { searchValue })
        .then((result) => {
          const projects = result.data?.projectList ?? [];
          CacheStateDispatch({
            type: 'setCachedSearchedProjects',
            payload: {
              searchValue,
              projects
            }
          });
          setFilteredProjectList(projects);
          setLoadingProjects(false);
        })
        .catch(() => {
          setFilteredProjectList([]);
          setLoadingProjects(false);
        });
    }, 300),
    [marketplaceId, searchProjects]
  );

  useEffect(() => {
    if (searchValue !== '') {
      setLoadingProjects(true);
      doSearch(searchValue);
    } else {
      setFilteredProjectList([]);
    }
  }, [searchValue]);

  const searchResultsProjectsList = filteredProjectList?.length
    ? filteredProjectList.map((project: IProject) => {
        return (
          <ProjectCard
            key={project?.projectId}
            project={project}
            setSearchValue={setSearchValue}
            setIsMenuOpened={setIsMenuOpened}
            mobileView={mobileView}
          />
        );
      })
    : !loadingProjects && (
        <ProjectsResultsPlaceholder>
          <PlaceholderContent>
            <PlaceholderIcon icon={['far', 'file-search']} />
            {localizer.noResults}
          </PlaceholderContent>
        </ProjectsResultsPlaceholder>
      );

  return (
    <Wrapper>
      <SearchInput
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        mobileView={mobileView}
        isMenuOpened={isMenuOpened}
        setHideResults={setHideResults}
        isLoading={loadingProjects}
        isHomePage={isHomePage}
      />
      {searchValue !== '' && !hideResults && searchResultsProjectsList && (
        <ResultsList mobileView={mobileView}>
          {!!filteredProjectList?.length && (
            <ProjectsFoundWrapper mobileView={mobileView}>
              {localizer.formatString(
                localizer.projectDetailPage.projectsFound,
                filteredProjectList?.length
              )}
            </ProjectsFoundWrapper>
          )}
          <ScrollbarWrapper
            style={{
              maxHeight: mobileView
                ? 'calc(100vh - 200px) !important'
                : '424px !important'
            }}
            mobileView={mobileView}
          >
            <ProjectsResultsWrapper mobileView={mobileView}>
              {searchResultsProjectsList}
            </ProjectsResultsWrapper>
          </ScrollbarWrapper>
        </ResultsList>
      )}
    </Wrapper>
  );
};

export default ProjectSearch;
