import { useState } from 'react';

// Helpers
import localizer from 'src/localization/localizer';
import {
  GetInTouchActionNameType,
  GetInTouchLocationOnPageType,
  TrackGetInTouchBasicParams,
  Tracking
} from '@/libs/prompto-api/src';
import { useAppState } from 'src/store/AppStore';
import { useContactUsState } from 'src/store/ContactUsStore';
import { forBiggerThan } from 'src/helpers/ui';

// Components
import Button from 'src/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messagesIcon from 'src/resources/images/get-in-touch.png';

// Styling
import styled, { keyframes, StyledProps } from 'styled-components';
import { buildAssetURIWithOptions } from 'src/helpers/uri';

const MainWrapper = styled.div`
  display: flex;
  max-width: 424px;
  min-width: 300px;
  max-height: 500px;
  height: fit-content;
  padding: 32px;
  box-sizing: border-box;
  align-items: center;
  flex-flow: column;
  background-color: ${({ theme }) => theme.beigeBg10};
  border-radius: 16px;
  width: calc(100vw - 64px);
`;

const StyledImage = styled.img`
  max-width: calc(100vw - 128px);
  width: 320px;
  height: 120px;
  object-fit: contain;
`;

const TinyLabel = styled.div`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.gray60};
  margin-bottom: 16px;
`;

const SoldByLabel = styled(TinyLabel)`
  margin-top: 25px;

  ${forBiggerThan.tablet`
  margin-top: 45px;
`}
`;

const glareAnimation = keyframes`
  0%, 100% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(0%);
  }
`;

const SubmitButton = styled(Button)`
  background: ${({ theme }) => theme.greenCTA};
  min-width: 270px;
  height: 60px;
  position: relative;
  margin-top: 32px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(0%);
    animation: ${glareAnimation} 10s linear infinite;
  }

  &:hover:before {
    animation: none;
  }
`;

const MessageIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

export interface GetInTouchCardProps {
  className?: string;
  logoUri?: string;
  unitObjectId?: string;
  trackingData?: TrackGetInTouchBasicParams;
  location: GetInTouchLocationOnPageType;
  projectSettings: any;
  projectId?: string;
  marketplaceId?: string;
}

const GetInTouchCard = ({
  className,
  logoUri,
  unitObjectId,
  trackingData,
  location,
  projectSettings,
  marketplaceId,
  projectId
}: GetInTouchCardProps) => {
  // App state
  const { AppState } = useAppState();

  // Get In Touch state
  const { ContactUsStateDispatch } = useContactUsState();

  const trackAction = (actionName: GetInTouchActionNameType) => {
    if (trackingData) {
      Tracking.trackGetInTouchInteraction({
        ...trackingData,
        locationOnPage: location,
        actionName
      }).catch(() => console.warn(`Could not track ${actionName}`));
    }
  };

  let logoToShow = '';
  if (logoUri) {
    logoToShow = buildAssetURIWithOptions('h=120', logoUri ?? '');
  }
  if (projectSettings.projectLogo) {
    logoToShow = buildAssetURIWithOptions('h=120', projectSettings.projectLogo);
  }

  let sellerLogo = '';
  if (projectSettings.sellerLogo) {
    sellerLogo = buildAssetURIWithOptions('h=120', projectSettings.sellerLogo);
  }

  return (
    <>
      <MainWrapper className={className as string}>
        <TinyLabel>{marketplaceId === "cofinimmo" ? "Managed by" :localizer.getInTouchCard.developedBy}</TinyLabel>

        <StyledImage src={logoToShow ? logoToShow : messagesIcon} />

        {sellerLogo && (
          <>
            <SoldByLabel>{localizer.getInTouchCard.soldBy}</SoldByLabel>
            <StyledImage src={sellerLogo} />
          </>
        )}
        <SubmitButton
          onClick={() => {
            ContactUsStateDispatch({
              type: 'setShowContactUsForm',
              payload: {
                showContactUsForm: true,
                location,
                unitObjectId
              }
            });
            trackAction('buttonClicked');
          }}
        >
          {localizer.getInTouchCard.buttonText}
          <MessageIcon icon={['far', 'comment-alt-lines']} size="sm" />
        </SubmitButton>
      </MainWrapper>
    </>
  );
};

export default GetInTouchCard;
