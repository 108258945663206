import { ReactNode } from 'react';

// Helpers
import localizer from 'src/localization/localizer';
import env from 'src/environment';

import FooterBg from 'src/resources/images/footer-bg.png';
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';

// Styling
import styled, { StyledProps } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.black};
  background-image: url(${FooterBg});
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 372px 192px;
  padding: 48px 24px 24px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  row-gap: 32px;
  ${forBiggerThan.mobile`
    padding: 64px 64px 36px;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  `}
  ${forBiggerThan.laptop`
    padding: 96px 64px 118px;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  `}
`;

const Link = styled.a<StyledProps<{ active?: boolean }>>`
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  color: ${({ theme, active }) => (active ? theme.gold : theme.white)};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.gold};
    cursor: pointer;
  }
  ${forBiggerThan.tablet`
    font-size: 1rem;
    margin-bottom: 1rem;
  `}
`;

const LinkContent = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div``;

const Title = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.gray40};
  margin-bottom: 0.75rem;
  ${forBiggerThan.tablet`
    font-size: 1.25rem;
    margin-bottom: 1rem;
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: 5px;
  color: ${({ theme }) => theme.gray50};
`;

const Footer = () => {
  const columns = [
    {
      title: localizer.homePage.about,
      content: [
        {
          label: localizer.homePage.aboutPrompto,
          link: env().aboutPrompto
        },
        {
          label: localizer.homePage.contactUs,
          link: env().contactUs
        }
      ]
    },
    {
      title: localizer.homePage.findUs,
      content: [
        {
          label: 'LinkedIn',
          icon: (
            <IconWrapper>
              <FaLinkedinIn />
            </IconWrapper>
          ),
          link: env().linkedinUrl
        },
        {
          label: 'Instagram',
          icon: (
            <IconWrapper>
              <FaInstagram />
            </IconWrapper>
          ),
          link: env().instagramUrl
        }
      ]
    },
    {
      title: localizer.homePage.resources,
      content: [
        {
          label: localizer.homePage.termsOfService,
          link: env().termsOfService
        },
        {
          label: localizer.homePage.privacyPolicy,
          link: env().privacyPolicy
        },
        {
          label: localizer.homePage.cookiePolicy,
          link: env().cookiePolicy
        }
      ]
    }
  ];

  return (
    <Wrapper>
      {columns.map((column) => (
        <Column key={column.title}>
          <Title>{column.title}</Title>
          {column.content.map(
            (item: {
              icon?: ReactNode;
              link: string;
              label: string;
              active?: boolean;
            }) => (
              <Link
                href={item.link}
                title={item.label}
                key={item.label}
                active={item.active}
              >
                <LinkContent>
                  {item.icon}
                  {item.label}
                </LinkContent>
              </Link>
            )
          )}
        </Column>
      ))}
    </Wrapper>
  );
};

export default Footer;
