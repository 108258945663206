import { Link } from 'react-router-dom';

import NotFound1 from 'src/resources/video/404-1.webm';
import NotFound2 from 'src/resources/images/404-2.png';
import NotFound3 from 'src/resources/images/404-3.png';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text, SmallText } from './landingPage/sharedStyledComponents';
import Footer from './landingPage/Footer';

// Helpers
import localizer from 'src/localization/localizer';

// Styling
import styled from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const Wrapper = styled.div`
  min-height: 100vh;
`;

const Block404 = styled.div`
  padding: 32px 14px;
  justify-content: space-around;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${forBiggerThan.tablet`
    padding: 0;
    min-height: calc(100vh - 72px);
    flex-direction: row;
  `}
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${forBiggerThan.tablet`
    width: 40%;
    align-items: flex-start;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${forBiggerThan.tablet`
    justify-content: flex-start;
  `}
`;
const NotFoundImage = styled.img`
  height: 288px;
  width: 288px;
  ${forBiggerThan.tablet`
    margin-right: 32px;
    height: 384px;
    width: 384px;
  `}

  ${forBiggerThan.desktop`
    height: 576px;
    width: 576px;
  `}
`;

const NotFoundVideo = styled.video`
  pointer-events: none;
  height: 288px;
  width: 288px;
  ${forBiggerThan.tablet`
    margin-right: 32px;
    height: 384px;
    width: 384px;
  `}
  ${forBiggerThan.desktop`
    height: 576px;
    width: 576px;
  `}
`;

const NotFoundHeadline = styled(Text)`
  ${forBiggerThan.tablet`
    text-align: left;
  `}
  text-align: center;
`;

const NotFoundText = styled(SmallText)`
  ${forBiggerThan.tablet`
    text-align: left;
  `}
  text-align: center;
`;

const HomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.white};
  margin-right: 8px;
`;

const BuildingIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  margin-right: 8px;
`;

const BackToHomepageButton = styled.button`
  position: relative;
  width: 272px;
  height: 60px;
  padding: 10px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin: auto;
  overflow: hidden;
  margin-bottom: 16px;
  ${forBiggerThan.mobile`
    margin-right: 32px;
  `}
`;

const ExploreProjectsButton = styled(BackToHomepageButton)`
  background-color: ${({ theme }) => theme.beigeBg20};
  color: ${({ theme }) => theme.black};
`;

const NotFoundImages = [NotFound1, NotFound2, NotFound3];

const randomImageSelector = Math.floor(Math.random() * NotFoundImages.length);

const NotFound = () => {
  return (
    <Wrapper>
      <Block404>
        {randomImageSelector === 0 ? (
          <NotFoundVideo muted autoPlay loop src={NotFound1} />
        ) : (
          <NotFoundImage src={NotFoundImages[randomImageSelector]} />
        )}
        <InfoBlock>
          <NotFoundHeadline>
            {localizer.formatString(
              localizer.notFoundPage.pageNotFound,
              <span>{localizer.notFoundPage.notFound}</span>
            )}
          </NotFoundHeadline>
          <NotFoundText>{localizer.notFoundPage.pageDoesntExist}</NotFoundText>
          <ButtonsWrapper>
            <Link to="/">
              <BackToHomepageButton>
                <HomeIcon icon={['far', 'home-lg']} size="1x" />
                {localizer.notFoundPage.backToHomepage}
              </BackToHomepageButton>
            </Link>
            <Link to="/projects">
              <ExploreProjectsButton>
                <BuildingIcon icon={['far', 'city']} size="1x" />
                {localizer.notFoundPage.exploreProjects}
              </ExploreProjectsButton>
            </Link>
          </ButtonsWrapper>
        </InfoBlock>
      </Block404>
      <Footer />
    </Wrapper>
  );
};

export default NotFound;
