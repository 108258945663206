// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { getFieldIcon } from './unitListHelpers';
import { GeneralFieldType } from '@prompto-api';
import { checkUnitFieldsVisibility } from 'src/helpers/vmUnit';
import { abbreviateNumber } from 'src/helpers/utils';

// Styling
import styled, { StyledProps } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const UnitGenericFieldsWrapper = styled.div`
  width: auto;
  height: 45px;
  display: flex;
  align-self: end;
  align-items: center;
  grid-area: details;
  margin: 0 -16px;
  border-top: ${({ theme }) => `1px solid ${theme.gray20}`};
  gap: 10px;

  ${forBiggerThan.desktop`
    width: none;
    height: 100%;
    align-self: center;
    margin: 0;
    border-top: none;
  `}
`;

const UnitMetaInfo = styled.div<
  StyledProps<{ flexBasis: number; index: number }>
>`
  flex-basis: ${(props) => props.flexBasis}%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  ${forBiggerThan.desktop`
    justify-content: flex-start;
    border-shadow: none;
  `}
`;

const UnitMetaIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 12px;
  color: ${({ theme }) => theme.gray50};
`;

const UnitInfo = styled.div`
  font-size: 14px;
  color: black;
  white-space: nowrap;
  padding-right: 10px;
  box-sizing: border-box;
  word-break: break-all;
`;

interface UnitGeneralFieldsProps {
  unit: any;
  showcaseConfiguration: any;
}

const UnitGeneralFields = (props: UnitGeneralFieldsProps) => {
  const { unit, showcaseConfiguration } = props;

  const [showBedrooms, showBathrooms, showSurface] = checkUnitFieldsVisibility(
    unit,
    [
      GeneralFieldType.numberOfBedrooms,
      GeneralFieldType.numberOfBathrooms,
      GeneralFieldType.surface
    ]
  );

  const metaInfo = [];

  if (showSurface) {
    metaInfo.push({
      icon: getFieldIcon(showcaseConfiguration, 'surface', 'far'),
      info: unit.unitMetadata?.surface
        ? `${abbreviateNumber(unit.unitMetadata.surface)} m²`
        : '-'
    });
  }

  if (showBedrooms) {
    metaInfo.push({
      icon: getFieldIcon(showcaseConfiguration, 'numberOfBedrooms', 'far'),
      info: unit.unitMetadata?.numberOfBedrooms ?? '-'
    });
  }

  if (showBathrooms) {
    metaInfo.push({
      icon: getFieldIcon(showcaseConfiguration, 'numberOfBathrooms', 'far'),
      info: unit.unitMetadata?.numberOfBathrooms ?? '-'
    });
  }

  const flexBasis = 100 / metaInfo.length;
  return (
    <UnitGenericFieldsWrapper>
      {metaInfo.map((item, idx) => (
        <UnitMetaInfo key={idx} flexBasis={flexBasis} index={idx}>
          {!!item.icon && <UnitMetaIcon icon={item.icon} size="1x" />}
          <UnitInfo>{item.info}</UnitInfo>
        </UnitMetaInfo>
      ))}
    </UnitGenericFieldsWrapper>
  );
};

export default UnitGeneralFields;
