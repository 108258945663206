import { useState, useEffect } from 'react';

// Types
import { ISvgPolygon } from './FindNearbyPropertyBlock';

// Styling
import styled from 'styled-components';

const StyledPolygonPath = styled.path`
  opacity: 0.75;
  cursor: pointer;
`;

interface ProvinceSvgMaskProps {
  onClick: (id: string) => void;
  maskData: ISvgPolygon;
  hoveredProvince: string;
  onHover: (id: string) => void;
  number: number;
}

const ProvinceSvgMask = ({
  onClick,
  maskData,
  hoveredProvince,
  onHover,
  number
}: ProvinceSvgMaskProps) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setIsHighlighted(isHovered || hoveredProvince === maskData?.id);
  }, [isHovered, hoveredProvince, maskData]);

  return (
    <StyledPolygonPath
      id="invisible-polygon"
      xmlns="http://www.w3.org/2000/svg"
      d={maskData.path}
      fill={isHighlighted ? '#bfa666' : '#454A53'}
      strokeWidth={0}
      stroke={isHighlighted ? '#bfa666' : '#454A53'}
      data-polygonnumber={number}
      data-polygonid={maskData.id}
      onClick={() => {
        if (maskData.id) onClick(maskData.id);
      }}
      onMouseEnter={() => {
        setIsHovered(true);
        if (maskData.id && hoveredProvince !== maskData.id) {
          onHover(maskData.id);
        }
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        onHover('');
      }}
    />
  );
};

export default ProvinceSvgMask;
