import LocalizedStrings from 'react-localization';

import enStrings from './en/en-US-strings.json';
import nlStrings from './nl/nl-NL-strings.json';
import frStrings from './fr/fr-FR-strings.json';
import deStrings from './de/de-DE-strings.json';
import esStrings from './es/es-ES-strings.json';
import ptStrings from './pt/pt-PT-strings.json';

import 'numeral/locales/nl-nl';
import 'numeral/locales/nl-be';
import 'numeral/locales/fr';
import 'numeral/locales/de';

// Merge two json files into a single strings file
const languagesFiles = {
  nl: { ...nlStrings },
  en: { ...enStrings },
  fr: { ...frStrings },
  de: { ...deStrings },
  es: { ...esStrings },
  pt: { ...ptStrings }
};

const strings = new LocalizedStrings(languagesFiles);

export const supportedLanguages = [
  {
    short: 'nl',
    long: 'Nederlands'
  },
  {
    short: 'en',
    long: 'English'
  },
  {
    short: 'fr',
    long: 'Français'
  },
  {
    short: 'de',
    long: 'Deutsch'
  },
  {
    short: 'es',
    long: 'Español'
  },
  {
    short: 'pt',
    long: 'Português'
  }
];

export const setLanguage = (lang: string) => {
  strings.setLanguage(lang);
};

export const getCurrentLanguage = () => {
  return strings.getLanguage() as string;
};

export const getValueFromLocalized = (
  localizedValues: any,
  defaultValue = ''
) => {
  const appLang = strings.getLanguage();
  const localizedValue = localizedValues?.textMap?.[appLang];
  if (!!localizedValue) return localizedValue;

  const fallbackLanguage = 'en';
  const fallbackValue = localizedValues?.textMap?.[fallbackLanguage];
  if (!!fallbackValue) return fallbackValue;

  const availableValues = supportedLanguages.reduce(
    (result: any[], lang: any) => {
      if (localizedValues?.textMap?.[lang.short]) {
        return [...result, localizedValues?.textMap?.[lang.short]];
      } else {
        return result;
      }
    },
    []
  );

  return availableValues[0] || defaultValue;
};

export default strings;
