function OutOfViewportLeftIcon(props: any) {
  return (
    <svg width={40} height={40} {...props}>
      <path
        d="m25.18 4.5-6.758.001c-3.766.025-5.342.406-7.07 1.276a8.592 8.592 0 0 0-3.575 3.575c-.834 1.559-1.265 3.11-1.277 7.55L2.78 20l3.72 3.101c-.005 4.115.375 5.757 1.277 7.547a8.592 8.592 0 0 0 3.575 3.575c1.48.792 2.953 1.221 6.694 1.272l.777.005h6.358c4.053 0 5.69-.382 7.467-1.277a8.592 8.592 0 0 0 3.575-3.575c.792-1.48 1.221-2.953 1.272-6.694l.005-.777V16.82c0-4.053-.382-5.69-1.277-7.467a8.592 8.592 0 0 0-3.575-3.575c-1.58-.845-3.151-1.277-7.467-1.277z"
        fill="#C3C2CA"
        stroke="#FFF"
        fillRule="evenodd"
        fillOpacity=".8"
        strokeOpacity=".3"
      />
    </svg>
  );
}

export default OutOfViewportLeftIcon;
