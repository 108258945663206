import { useEffect, useState } from 'react';

// Components
import { motion } from 'framer-motion';

// Helpers
import env from 'src/environment';

// Styling
import styled, { css, keyframes, StyledProps } from 'styled-components';

const sharedThumbnailStyle = `
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    background-color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const slidingFrontSunbeam = keyframes`
    0% { left: -80% }
    100% { left: 120% }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

const ThumbnailImageOverlay = styled.div<
  StyledProps<{
    hovered?: boolean;
    animate?: boolean;
    hideAnimation?: boolean;
    noGradient?: boolean;
  }>
>`
  ${sharedThumbnailStyle}

  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2;
  ${({ hovered, animate, hideAnimation, noGradient }) => {
    if (hideAnimation) return '';
    let styles = css`
      background-image: ${() => {
        return noGradient
          ? 'rgba(0, 0, 0, 0.15)'
          : `linear-gradient(
            to bottom,
            transparent ${hovered ? '0%' : '65%'},
            rgba(0, 0, 0, 0.8) 100%
          )`;
      }};
      transition: all 150ms ease;
    `;
    if (animate) {
      styles = css`
        ${styles}
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 80%;
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.25) 30%,
            rgba(255, 255, 255, 0.37) 54%,
            rgba(255, 255, 255, 0.25) 70%,
            rgba(255, 255, 255, 0)
          );
          transform: skew(-8deg);
          animation: ${slidingFrontSunbeam} 2s infinite ease-out;
        }
      `;
    }
    return styles;
  }}
`;

const ThumbnailImage = styled(motion.img)`
  ${sharedThumbnailStyle}
  object-fit: cover;
  z-index: 1;
  transition: 0.3s;
  pointer-events: none;
`;

const HdThumbnailImage = styled(ThumbnailImage)`
  z-index: 2;
`;

interface ThumbnailProps {
  uri: string;
  height: number;
  hovered?: boolean;
  hideAnimation?: boolean;
  noGradient?: boolean;
  onLoaded?: () => void;
}

const ThumbnailComponent = ({
  uri,
  height,
  hovered = false,
  hideAnimation,
  noGradient,
  onLoaded
}: ThumbnailProps) => {
  const [hdThumbnailLoaded, setHdThumbnailLoaded] = useState(false);
  const [lowQualityThumbnailLoaded, setLowQualityThumbnailLoaded] =
    useState(false);

  const [loadLowQualityError, setLoadLowQualityError] = useState(false);
  const [loadHdError, setLoadHdError] = useState(false);

  let thumbnail = <ThumbnailImageOverlay noGradient={noGradient} animate />;

  if (uri && height) {
    const hqImageHeight = Math.ceil(height) * 2;
    thumbnail = (
      <Wrapper>
        {lowQualityThumbnailLoaded && (
          <HdThumbnailImage
            initial={{ opacity: 0 }}
            animate={{
              opacity: hdThumbnailLoaded ? 1 : 0
            }}
            style={hovered ? { transform: 'scale(1.05)' } : {}}
            src={`${env().baseImageUrl}/q=100:h=${hqImageHeight}/${uri}`}
            alt={`Project HD thumbnail`}
            onLoad={() => {
              setHdThumbnailLoaded(true);
            }}
            onError={() => setLoadHdError(true)}
            whileHover={{ scale: 1.05 }}
          />
        )}

        <ThumbnailImage
          initial={{ opacity: 0 }}
          animate={{
            opacity: lowQualityThumbnailLoaded ? 1 : 0
          }}
          style={hovered ? { transform: 'scale(1.05)' } : {}}
          src={`${env().baseImageUrl}/q=30:h=${height}/${uri}`}
          alt={`Project thumbnail`}
          onLoad={() => {
            setLowQualityThumbnailLoaded(true);
            onLoaded && onLoaded();
          }}
          onError={() => setLoadLowQualityError(true)}
          whileHover={{ scale: 1.05 }}
        />

        {!lowQualityThumbnailLoaded && (
          <ThumbnailImageOverlay
            hideAnimation={hideAnimation}
            hovered={hovered}
            animate={
              !lowQualityThumbnailLoaded &&
              !(loadLowQualityError && loadHdError)
            }
          />
        )}
      </Wrapper>
    );
  }

  return thumbnail;
};

export default ThumbnailComponent;
