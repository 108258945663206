import { useEffect } from 'react';

// Components
import UspSpotTooltip from './UspSpotTooltip';

// Helpers
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

// Styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Backdrop = styled(motion.div)`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

const TooltipWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  max-width: 330px;
  max-height: calc(100% - 58px);
  border-radius: 4px;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  padding-top: 28px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${(props) => props.theme.gray50};
  padding: 0;
`;

const UspSpotMobileTooltip = ({
  tooltipData,
  onClose
}: {
  tooltipData: any;
  onClose: () => void;
}) => {
  if (!isMobile) return null;
  if (!tooltipData) return null;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';
  }, []);

  return (
    <Backdrop>
      <TooltipWrapper>
        <CloseButton
          onClick={() => {
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
            onClose();
          }}
        >
          <CloseIcon icon={['fal', 'times']} size="1x" />
        </CloseButton>
        <UspSpotTooltip
          spotObject={tooltipData?.spot}
          uspSpotLocation={tooltipData.location}
          uspSpotAbsoluteLocation={tooltipData.location}
        />
      </TooltipWrapper>
    </Backdrop>
  );
};

export default UspSpotMobileTooltip;
