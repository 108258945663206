import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons';
import { paginationOptions } from './UnitList';
import { isMobileOnly } from 'react-device-detect';

// Components
import PaginationSizeSelect from './PaginationSizeSelect';

// Styling
import styled, { StyledProps } from 'styled-components';

const MainWrapper = styled.div`
  width: 100%;
  background: ${(props) => props.theme.mainBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: ${isMobileOnly ? 'center' : 'space-between'};
  flex-direction: row;
  align-items: center;
  user-select: none;
  padding: 24px 0 ${isMobileOnly ? 12 : 48}px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.gray20};
  font-family: ${({ theme }) => theme.fonts.DMSans};
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Button = styled.button<
  StyledProps<{ active?: boolean; disabled?: boolean }>
>`
  width: 40px;
  height: 40px;
  padding: 11px 0;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ active, theme }) =>
    active ? theme.black : theme.beigeBg10};
  color: ${({ active, theme }) => (active ? theme.white : theme.black)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.DMSans};
`;

const ArrowButton = styled(Button)`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ disabled, theme }) => (disabled ? theme.gray20 : theme.gray50)};
`;

const ArrowIcon = styled(FontAwesomeIcon)<StyledProps<{ disabled: boolean }>>`
  font-size: 0.725rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

interface PaginationControlsProps {
  perPage: number;
  setPerPage: (perPage: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalEntries: number;
  toPreviousPage: () => void;
  toNextPage: () => void;
  enableSizeChange?: boolean;
}

const MAX_PAGE_BUTTONS_IN_MOBILE = 4;

const ArrowButtonComponent = ({ disabled, icon, onClick }: any) => (
  <ArrowButton
    disabled={disabled}
    onClick={() => {
      if (disabled) return;
      onClick();
    }}
  >
    <ArrowIcon icon={icon} size="1x" disabled={disabled} />
  </ArrowButton>
);

const PaginationControls = ({
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  totalEntries,
  toPreviousPage,
  toNextPage
}: PaginationControlsProps) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(Math.ceil(totalEntries / perPage));
  }, [perPage, totalEntries]);

  const allButtons = Array.from({ length: totalPages }).map((_, index) => (
    <Button
      key={index}
      active={currentPage === index}
      onClick={() => setCurrentPage(index)}
    >
      {index + 1}
    </Button>
  ));

  const mobilePageButtons =
    totalPages > MAX_PAGE_BUTTONS_IN_MOBILE ? (
      <>
        {/* If the last page is reached, display the first page button */}
        {currentPage === totalPages - 1 && (
          <>
            <Button
              key={'first-page'}
              active={currentPage === 0}
              onClick={() => setCurrentPage(0)}
            >
              1
            </Button>
            <div>...</div>
          </>
        )}

        {Array.from({
          length: MAX_PAGE_BUTTONS_IN_MOBILE - 1
        }).map((_, index) => {
          const pageIndex =
            currentPage > 2
              ? Math.min(
                  totalPages +
                    index -
                    (currentPage === totalPages - 1
                      ? MAX_PAGE_BUTTONS_IN_MOBILE - 1
                      : MAX_PAGE_BUTTONS_IN_MOBILE),
                  currentPage + index - 2
                )
              : index;
          return (
            <Button
              key={pageIndex}
              active={currentPage === pageIndex}
              onClick={() => setCurrentPage(pageIndex)}
            >
              {pageIndex + 1}
            </Button>
          );
        })}

        {/* Display gap until last page is reached*/}
        {currentPage < totalPages - 1 && (
          <>
            <div>...</div>
            <Button
              key={'last-page'}
              active={totalPages - 1 === currentPage}
              onClick={() => setCurrentPage(totalPages - 1)}
            >
              {totalPages}
            </Button>
          </>
        )}
      </>
    ) : (
      allButtons
    );

  const pageButtons = isMobileOnly ? mobilePageButtons : allButtons;

  return (
    <MainWrapper>
      <Buttons>
        <ArrowButtonComponent
          icon={faChevronLeft}
          disabled={currentPage === 0}
          onClick={toPreviousPage}
        />

        {pageButtons}

        <ArrowButtonComponent
          icon={faChevronRight}
          disabled={currentPage === totalPages - 1}
          onClick={toNextPage}
        />
      </Buttons>

      {!isMobileOnly && (
        <PaginationSizeSelect
          options={paginationOptions}
          selectedOption={perPage}
          onSelect={(option) => {
            setPerPage(option.value);
            setCurrentPage(0);
          }}
        />
      )}
    </MainWrapper>
  );
};

export default PaginationControls;
