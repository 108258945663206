import { useState } from 'react';

// Components
import ShowcaseLoader from 'src/components/Loader';

// Helpers
// https://www.npmjs.com/package/pannellum-react
import { Pannellum } from 'pannellum-react';
import { isMobile } from 'react-device-detect';

// Styling
import styled from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.white};
  position: relative;
  width: 100%;
  height: 100%;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 3;
  background-color: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Instructions = styled.div`
  position: absolute;
  z-index: 1;
  width: 300px;
  left: 0;
  top: 0;
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 0.875rem;
  line-height: 1.1;
  letter-spacing: 1px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.DMSans};

  ${forBiggerThan.mobile`
    bottom: 0;
    right: 0;
  `}
`;

const LoadingText = styled.p`
  color: ${({ theme }) => theme.black};
  letter-spacing: 1px;
  text-align: center;
  max-width: 70%;
  margin: 1rem;
  font-size: 1rem;
`;

const StyledLoader = styled(ShowcaseLoader)`
  flex-grow: 0;
  margin: 0 0 1rem;
  height: auto;
`;

export interface ThreeSixtyImageContentProps {
  contentUri: string;
  onLoad: () => void;
}

const ThreeSixtyImageContent = ({
  contentUri,
  onLoad
}: ThreeSixtyImageContentProps) => {
  const [loading, setLoading] = useState(true);

  return (
    <Wrapper>
      {loading && (
        <LoadingOverlay>
          <StyledLoader />
          <LoadingText>Preparing 360 Image...</LoadingText>
        </LoadingOverlay>
      )}
      <Instructions>
        {isMobile
          ? 'Swipe to navigate through the image'
          : 'Use mouse or keyboard arrows to navigate through the image'}
      </Instructions>
      <Pannellum
        width="100%"
        height="100%"
        image={contentUri}
        pitch={10}
        yaw={180}
        hfov={110}
        autoLoad
        onLoad={() => {
          setLoading(false);
          onLoad();
        }}
        showControls={false}
      />
    </Wrapper>
  );
};

export default ThreeSixtyImageContent;
