import {
  performPostCall,
  performGetCall,
  performPutCall
} from '../api-helpers';

export const enum ProjectFileType {
  FOLDER,
  CONTENT_ITEM
}

export interface ProjectFile {
  uuid?: string;
  projectFiles?: Array<ProjectFile>;
  type?: ProjectFileType;
  contentItemId?: string;
  name?: string;
}

export interface ProjectFolderConfigurationCreateParams {
  folderList?: Array<ProjectFile>;
  projectSectionObjectId: string;
  vaultObjectId: string;
}

export interface ProjectFolderConfigurationUpdateParams {
  folderList?: Array<ProjectFile>;
}

export const ProjectFolderConfiguration = {
  /**
   * Create project folder configuration
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_37
   * @param {Object} body
   * @param {string} sessionToken
   */
  create: (
    body: ProjectFolderConfigurationCreateParams,
    sessionToken: string
  ) => {
    return performPostCall(`projectfolderconfiguration`, body, sessionToken);
  },

  /**
   * Put project folder configuration
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_35
   * @param {string} projectId
   * @param {Object} params
   * @param {string} sessionToken
   */
  update: (
    projectId: string,
    params: ProjectFolderConfigurationUpdateParams,
    sessionToken: string
  ) => {
    return performPutCall(
      `projectfolderconfiguration/${projectId}`,
      params,
      sessionToken
    );
  },

  /**
   * Get project folder configuration
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbyprojectsectionobjectid
   * @param {string} projectId
   * @param {string} sessionToken
   */
  get: (projectId: string, sessionToken?: string) => {
    return performGetCall(
      `projectfolderconfiguration/getByProjectSectionObjectId/${projectId}`,
      {},
      sessionToken
    );
  }
};
