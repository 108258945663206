import { useState, useEffect } from 'react';

// Components
import FilterBlock from './FilterBlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { AnimatePresence, motion } from 'framer-motion';
import { mapStateToLabel, calculateUsedFilters } from '../unitListHelpers';
import { isMobileOnly } from 'react-device-detect';
import localizer from 'src/localization/localizer';
import { capitalize } from 'src/helpers/utils';
import isEqual from 'lodash.isequal';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';

import { UnitsFilterType, IRangeFilter, IOptionFilter } from '../UnitList';

// Styling
import styled, { css } from 'styled-components';

const Block = styled(motion.div)`
  min-height: 100px;
  background-color: ${({ theme }) => theme.beigeBg10};
  display: flex;
  flex-shrink: 0;
`;

const Filters = styled(Block)`
  gap: ${isMobileOnly ? 0 : 64}px;
  margin-bottom: 24px;

  ${isMobileOnly &&
  css`
    position: fixed;
    z-index: 3007; // more than contact us button
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    background-color: ${({ theme }) => theme.beigeBg10};
    flex-direction: column;
    padding: 0 16px;
    box-sizing: border-box;
  `}
`;

const FilterUnit = styled.div`
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
`;

const ClearAllBlock = styled(motion.div)`
  height: 32px;
  box-shadow: inset 1px 0 0 0 ${({ theme }) => theme.gray20};
  padding: 0 0 0 24px;
  align-self: baseline;
  display: flex;
  align-items: center;
`;

const ClearAllButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.beigeBg10};
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
`;

const MobileClearAllButton = styled(ClearAllButton)``;

const FilterButton = styled.button`
  position: absolute;
  right: 0;
  top: 18px;
  min-width: 120px;
  width: fit-content;
  align-self: center;
  height: 44px;
  padding: 5px 15px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.beigeBg20};
  color: ${({ theme }) => theme.black};
  font-size: 0.875rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MobileApplyFilterButton = styled(FilterButton)`
  position: static;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
`;

const FilterIcon = styled(FontAwesomeIcon)`
  pointer-events: none;
  font-size: 1rem;
  margin-right: 8px;
`;

const ApppliedFiltersBadge = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.gold};
  color: ${({ theme }) => theme.white};
  font-size: 0.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ApplyFiltersBlock = styled(motion.div)`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 72px;
  flex-shrink: 0;
  margin: auto -16px 0;
  padding: 14px 16px;
  box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.beigeBg10};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MobileFiltersTitle = styled.div`
  height: 72px;
  padding: 10px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.beigeBg10};
  color: ${({ theme }) => theme.black};
  font-size: 1.25rem;
`;

const CloseFiltersButton = styled.button`
  width: 44px;
  height: 44px;
  margin: 0 0 0 16px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.beigeBg10};
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Types

interface UnitFilterProps {
  updateActiveFilter: (
    filterName: string,
    filterValues: IRangeFilter | IOptionFilter
  ) => void;
  filterOptions: UnitsFilterType;
  resetFilters: () => void;
  applyFilters: (filters: UnitsFilterType) => void;
  initialFilter?: UnitsFilterType;
  showBathroomsInDetails: boolean;
  showBedroomsInDetails: boolean;
  hidePrices: boolean;
  displayMax: any;
}

const UnitFilter = ({
  updateActiveFilter,
  filterOptions,
  resetFilters,
  applyFilters,
  initialFilter,
  showBathroomsInDetails,
  showBedroomsInDetails,
  hidePrices,
  displayMax
}: UnitFilterProps) => {
  const [showFilter, setShowFilter] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<UnitsFilterType>();
  const [usedFilters, setUsedFilters] = useState<string[]>([]);

  const { ProjectsFiltersDispatch } = useProjectsFiltersState();

  useEffect(() => {
    if (filterOptions) {
      setUsedFilters(calculateUsedFilters(filterOptions));
    }
  }, [filterOptions]);

  useEffect(() => {
    if (appliedFilters) return;
    setAppliedFilters(filterOptions);
  }, [filterOptions, filterOptions]);

  const updateFilter = (
    filterName: string,
    filterValues: IRangeFilter | IOptionFilter
  ) => {
    if (isMobileOnly) {
      setAppliedFilters((filter: any) => ({
        ...filter,
        [filterName]: filterValues
      }));
    } else {
      setAppliedFilters((filter: any) => ({
        ...filter,
        [filterName]: filterValues
      }));
      updateActiveFilter(filterName, filterValues);
    }
  };

  const clearButtonEnabled = !isEqual(appliedFilters, initialFilter);

  const toggleFilterModal = (showModal: boolean) => {
    setShowFilter(showModal);

    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const showBedroomsFilter =
    filterOptions?.numberOfBedrooms?.options?.length > 0 &&
    showBedroomsInDetails;
  const showBathroomsFilter =
    filterOptions?.numberOfBathrooms?.options?.length > 0 &&
    showBathroomsInDetails;

  const filtersForm = appliedFilters ? (
    <Filters>
      {isMobileOnly && (
        <MobileFiltersTitle>
          {localizer.unitListComponent.filterUnits}
          <CloseFiltersButton onClick={() => toggleFilterModal(false)}>
            <FontAwesomeIcon icon={['far', 'times']} size="lg" />
          </CloseFiltersButton>
        </MobileFiltersTitle>
      )}

      <FilterUnit>
        <FilterBlock
          title={capitalize(localizer.unitListComponent.filterOptions.status)}
          name="status"
          options={appliedFilters?.status}
          setOptions={updateFilter}
          optionToLabelMap={mapStateToLabel}
        />
      </FilterUnit>

      {!hidePrices && (
        <FilterUnit>
          <FilterBlock
            title={capitalize(localizer.unitListComponent.filterOptions.price)}
            type="range"
            name="price"
            rangeOptions={appliedFilters?.price}
            setOptions={updateFilter}
          />
        </FilterUnit>
      )}

      <FilterUnit>
        <FilterBlock
          title={capitalize(localizer.unitListComponent.filterOptions.surface)}
          type="range"
          name="surface"
          rangeOptions={appliedFilters?.surface}
          setOptions={updateFilter}
        />
      </FilterUnit>

      {showBedroomsFilter && (
        <FilterUnit>
          <FilterBlock
            title={capitalize(
              localizer.unitListComponent.filterOptions.bedrooms
            )}
            name="numberOfBedrooms"
            options={appliedFilters?.numberOfBedrooms}
            setOptions={updateFilter}
            displayMax={displayMax.numberOfBedrooms}
            overflowText={'or more'}
          />
        </FilterUnit>
      )}

      {showBathroomsFilter && (
        <FilterUnit>
          <FilterBlock
            title={capitalize(
              localizer.unitListComponent.filterOptions.bathrooms
            )}
            name="numberOfBathrooms"
            options={appliedFilters?.numberOfBathrooms}
            setOptions={updateFilter}
            displayMax={displayMax.numberOfBathrooms}
            overflowText={'or more'}
          />
        </FilterUnit>
      )}

      <AnimatePresence>
        {isMobileOnly && clearButtonEnabled && (
          <ApplyFiltersBlock
            initial={{ opacity: 0, x: 15 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 15 }}
            transition={{ duration: 0.1, ease: 'linear' }}
          >
            <MobileClearAllButton
              onClick={() => {
                resetFilters();
                setAppliedFilters(initialFilter);
                ProjectsFiltersDispatch({ type: 'clearAllFilters' });
                toggleFilterModal(false);
              }}
            >
              {localizer.unitListComponent.clearAll}
            </MobileClearAllButton>
            <MobileApplyFilterButton
              onClick={() => {
                applyFilters(appliedFilters);
                toggleFilterModal(false);
              }}
            >
              {localizer.unitListComponent.applyFilters}
            </MobileApplyFilterButton>
          </ApplyFiltersBlock>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {!isMobileOnly && clearButtonEnabled && (
          <ClearAllBlock
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'linear' }}
          >
            <ClearAllButton
              onClick={() => {
                resetFilters();
                setAppliedFilters(initialFilter);
                ProjectsFiltersDispatch({ type: 'clearAllFilters' });
              }}
            >
              {localizer.unitListComponent.clearAll}
            </ClearAllButton>
          </ClearAllBlock>
        )}
      </AnimatePresence>
    </Filters>
  ) : null;

  if (isMobileOnly) {
    return (
      <>
        <FilterButton
          onClick={() => {
            toggleFilterModal(true);
          }}
        >
          <FilterIcon icon={['far', 'filter']} size="1x" />
          {capitalize(localizer.unitListComponent.filters)}
          {usedFilters?.length > 0 && (
            <ApppliedFiltersBadge>{usedFilters.length}</ApppliedFiltersBadge>
          )}
        </FilterButton>

        {showFilter && filtersForm}
      </>
    );
  }

  return filtersForm;
};

export default UnitFilter;
