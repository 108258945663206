import { useState, useEffect } from 'react';

import * as pdfjsLib from 'pdfjs-dist/build/pdf';

// Helpers
import { computeCloudinaryThumbnail } from 'src/helpers/utils';
import getCurrentEnvironment from 'src/environment';
import { useDataState } from 'src/store/DataStore';

import { IUnit } from '@prompto-api';

import BrokenTnumbnailPlaceholder from 'src/resources/images/unit-preview-placeholder.png';
import { sortBySortingOrders } from 'src/helpers/vmUnit';

const mediaTypes = ['image', 'video', 'document', 'floorplan'];

// Types
interface IContentItem {
  contentItemType: string;
  contentUri: string;
}

const useGetUnitImagePreview = (
  unit: any,
  fallbackContent: IContentItem | null,
  params: any
) => {
  /**
   * We need to set a worker in order to increase the performance when loading pdf's since the worker will do the heavy lifting.
   * We use a publicly availble worker from https://unpkg.com/ which is commonly used.
   * Make sure that the version specified here matches the pdfjs-dist version in the package.json
   */
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js';

  const [unitHasContent, setUnitHasContent] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [previewContent, setPreviewContent] = useState<IContentItem>();

  const [index, setIndex] = useState(0);
  const [sortedUnitContent, setSortedUnitContent] = useState<IContentItem[]>();
  const [isPreparingURL, setIsPreparingURL] = useState(true);

  const { DataState } = useDataState();
  const { projects, contentCollections } = DataState;

  useEffect(() => {
    if (!unit) return;
    if (!projects || projects.length === 0) return;

    const relatedProject = projects.find(
      ({ objectId }: { objectId: string }) =>
        objectId === unit.parentProjectSection?.objectId
    );

    const thisUnit = relatedProject?.unitList?.find(
      (item: IUnit) => item.objectId === unit.objectId
    );

    if (thisUnit) {
      // check both own and linked collections to see if a unit has any content
      const handleCollections = (ownCollection: any, linkedCollection: any) => {
        const unitContentList =
          ownCollection?.vmContentItemList?.filter((x: IContentItem) =>
            mediaTypes.includes(x.contentItemType)
          ) ?? [];
        const unitLinkedContentList =
          linkedCollection?.vmContentItemList?.filter((x: IContentItem) =>
            mediaTypes.includes(x.contentItemType)
          ) ?? [];
        const contentList = [
          ...unitContentList,
          ...unitLinkedContentList.map((item: IContentItem) => ({
            ...item,
            isLinked: true
          }))
        ];

        if (contentList.length > 0) {
          const contentItemList = thisUnit
            ? (sortBySortingOrders(
                contentList,
                thisUnit?.contentItemCustomSortingValues ?? [],
                relatedProject?.defaultContentItemSorting
              ) as IContentItem[])
            : [];

          setUnitHasContent(true);
          setSortedUnitContent(contentItemList);
        } else {
          setUnitHasContent(false);
          setIsPreparingURL(false);
        }
      };

      // Get loaded collections
      const loadedCollection = contentCollections.find(
        (x: any) => x.objectId === thisUnit.vmContentCollection?.objectId
      );
      const loadedLinkedCollection = contentCollections.find(
        (x: any) => x.objectId === thisUnit.linkedContentCollection?.objectId
      );

      handleCollections(loadedCollection, loadedLinkedCollection);
    }
  }, [unit, projects, contentCollections]);

  useEffect(() => {
    if (
      sortedUnitContent &&
      index < sortedUnitContent.length &&
      isPreparingURL
    ) {
      if (!!sortedUnitContent[index].contentUri) {
        if (
          sortedUnitContent[index].contentItemType === 'document' ||
          sortedUnitContent[index].contentItemType === 'floorplan'
        ) {
          const loadingTask = pdfjsLib.getDocument(
            sortedUnitContent[index].contentUri
          );
          loadingTask.promise
            .then(() => {
              setIsPreparingURL(false);
            })
            .catch(() => {
              setIndex(index + 1);
            });
        } else {
          setIsPreparingURL(false);
        }
      } else {
        setIndex(index + 1);
      }
    }
  }, [sortedUnitContent, index, isPreparingURL]);

  useEffect(() => {
    if (sortedUnitContent) {
      setPreviewContent(sortedUnitContent[index]);
    }
  }, [sortedUnitContent, index]);

  useEffect(() => {
    const baseUrl = getCurrentEnvironment().baseImageUrl;
    const fallback = fallbackContent?.contentUri
      ? `${baseUrl}/${params}/${fallbackContent.contentUri}`
      : BrokenTnumbnailPlaceholder;
    if (unitHasContent && previewContent?.contentUri && !isPreparingURL) {
      if (previewContent?.contentItemType === 'image') {
        const originalImageUri = `${baseUrl}/${
          params
            ? `${params}/${previewContent.contentUri}`
            : `o=true/${previewContent.contentUri}`
        }`;
        setPreviewImageUrl(originalImageUri);
      } else if (previewContent?.contentItemType === 'video') {
        const env = getCurrentEnvironment();
        const cloudinaryImageUri = computeCloudinaryThumbnail(
          decodeURIComponent(previewContent.contentUri),
          env.googleStorageBucketId,
          env.cloudinaryVideoBaseUrl,
          'h_80,c_fill,dn_50'
        );

        setPreviewImageUrl(cloudinaryImageUri ?? '');
      } else if (
        previewContent?.contentItemType === 'document' ||
        previewContent?.contentItemType === 'floorplan'
      ) {
        const env = getCurrentEnvironment();
        const cloudinaryImageUri = computeCloudinaryThumbnail(
          decodeURIComponent(previewContent?.contentUri),
          env.googleStorageBucketId,
          env.cloudinaryImageBaseUrl,
          'h_80,c_fill,dn_50'
        );
        setPreviewImageUrl(cloudinaryImageUri ?? '');
      } else if (fallbackContent && !isPreparingURL) {
        setPreviewImageUrl(fallback);
      }
    } else if (!isPreparingURL) {
      setPreviewImageUrl(fallback);
    }
  }, [previewContent, fallbackContent, unitHasContent, params, isPreparingURL]);

  return { previewImageUrl, isPreparingURL };
};

export default useGetUnitImagePreview;
